import React from 'react';
import styled from 'styled-components';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import If from '../If';
import { Icon } from '../Icon';
import { Info } from '@phosphor-icons/react';
import { Stack, Tooltip } from '@mui/material';
import FormatNumber from '../FormatNumber';

interface CardProps {
  displayType: string; // data displayed as numbers or in a chart
  cardTitle?: string;
  grandTotal?: number;
  percentage?: number;
  thumbColor?: string;
  railColor?: string;
  onClick?: () => void;
  isActive?: boolean;
  backgroundColor?: string;
  tooltip?: string;
}

export const Card: React.FC<CardProps> = ({
  displayType,
  cardTitle,
  grandTotal,
  percentage,
  thumbColor,
  railColor,
  onClick,
  isActive,
  backgroundColor,
  tooltip,
}) => {
  const value = percentage || 0;

  return (
    <Wrapper
      displayType={displayType}
      onClick={() => onClick?.()}
      style={{
        background: isActive ? backgroundColor : '',
        border: isActive ? `1px solid ${railColor}` : '',
      }}
    >
      {displayType === 'numbers' ? (
        <Container>
          <TotalProducts>
            <CardTitle
              displayType={displayType}
              style={{ color: isActive ? thumbColor : '' }}
            >
              <Stack direction="row" gap="5px" alignItems="center">
                {cardTitle}
                <If condition={!!tooltip}>
                  <Tooltip title={tooltip}>
                    <span>
                      <Icon icon={<Info />} />
                    </span>
                  </Tooltip>
                </If>
              </Stack>
            </CardTitle>
            <TotalNumber>
              {grandTotal !== undefined && <FormatNumber number={grandTotal} />}
            </TotalNumber>
          </TotalProducts>
        </Container>
      ) : (
        <Container>
          <TotalProducts>
            <CardTitle
              displayType={displayType}
              style={{ color: isActive ? thumbColor : '' }}
            >
              <Stack direction="row" gap="5px" alignItems="center">
                {cardTitle}
                <If condition={!!tooltip}>
                  <Tooltip title={tooltip}>
                    <span>
                      <Icon icon={<Info />} />
                    </span>
                  </Tooltip>
                </If>
              </Stack>
            </CardTitle>
            <TotalNumber>
              {grandTotal !== undefined && <FormatNumber number={grandTotal} />}
            </TotalNumber>
          </TotalProducts>
          <ProgressbarContainer>
            <CircularProgressbarWithChildren
              value={value}
              styles={buildStyles({
                rotation: 0,
                strokeLinecap: 'round',
                textSize: '16px',
                pathTransitionDuration: 0.5,
                pathColor: thumbColor,
                trailColor: railColor,
                backgroundColor: '#3e98c7',
              })}
            >
              <ProgressbarText>{value}%</ProgressbarText>
            </CircularProgressbarWithChildren>
          </ProgressbarContainer>
        </Container>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ displayType: string }>`
  display: flex;
  padding: 8px 16px;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--colors-grey-300, #e0e0e0);
  background: var(--white, #fff);
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  flex: 1;
`;

const TotalProducts = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
  gap: 4px;
`;

const CardTitle = styled.p<{ displayType: string }>`
  color: var(--colors-grey-500, #9e9e9e);
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
`;

const TotalNumber = styled.p`
  color: #000;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 1.6rem */
  letter-spacing: 0.00938rem;
  margin: 0;
  align-self: flex-start;
`;

const ProgressbarContainer = styled.div`
  width: 51.617px;
  height: 51.617px;
`;

const ProgressbarText = styled.p`
  color: var(--Grey-900, #1e1e1e);
  text-align: center;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 0.61294rem; /* 126.667% */
  margin: 0;
`;
