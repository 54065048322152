import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import axios from 'axios';
import {
  CloudSnow,
  CloudRain,
  CloudLightning,
  Cloud,
  Sun,
} from '@phosphor-icons/react';
import Partially from 'assets/img/map/weather/partially.svg';
import { Icon } from 'app/components/Icon';

interface Props {
  latLng: { lat: string | number; lng: string | number };
}

export const WeatherTag: React.FC<Props> = ({ latLng }) => {
  const [lat, setLat] = useState<any>(null);
  const [lng, setLng] = useState<any>(null);
  const apiKey = '1445f94f62fc9c6bf00b89dedfd48c73';
  const [temp, setTemp] = useState<any>(null);
  const [weatherState, setWeatherState] = useState<any>(null);
  const [detailedWeatherState, setDetailedWeatherState] = useState<any>(null);
  const iconSize = 20;

  useEffect(() => {
    if (latLng?.lat !== undefined && latLng?.lng !== undefined) {
      setLat(latLng?.lat);
      setLng(latLng?.lng);
    }
  }, [latLng]);

  useEffect(() => {
    async function getWeather() {
      try {
        const res = await axios.get(
          `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lng}&appid=${apiKey}&units=metric`,
        );
        setTemp(Math.round(res?.data?.main?.temp));
        setWeatherState(res?.data?.weather[0]?.main?.toLowerCase());
        setDetailedWeatherState(
          res?.data?.weather[0]?.description?.toLowerCase(),
        );
      } catch (error) {
        return error;
      }
    }
    if (lat !== undefined && lng !== undefined) {
      getWeather();
    }
  }, [lat, lng]);

  return (
    <Wrapper>
      <IconContainer>
        {weatherState === 'clouds' ? (
          <>
            {detailedWeatherState === 'few clouds' ||
            detailedWeatherState === 'broken clouds' ? (
              <img src={Partially} alt="" />
            ) : (
              <Icon
                icon={<Cloud />}
                size={iconSize}
                color={themes?.default?.strokePrimary}
              />
            )}
          </>
        ) : weatherState === 'snow' ? (
          <Icon
            icon={<CloudSnow />}
            size={iconSize}
            color={themes?.default?.strokePrimary}
          />
        ) : weatherState === 'rain' || weatherState === 'drizzle' ? (
          <Icon
            icon={<CloudRain />}
            size={iconSize}
            color={themes?.default?.strokePrimary}
          />
        ) : weatherState === 'thunderstorm' ? (
          <Icon
            icon={<CloudLightning />}
            size={iconSize}
            color={themes?.default?.strokePrimary}
          />
        ) : (
          <Icon
            icon={<Sun />}
            size={iconSize}
            color={themes?.default?.Saffron}
          />
        )}
      </IconContainer>
      <TempContainer>{`${temp}°`}</TempContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 66px;
  height: 30px;
  padding: 4px 8px;
  gap: 8px;
  border-radius: 5px;
  border: 1px solid ${themes?.default?.whisper};
  background: ${themes?.default?.accordionWhiteBg};
  display: flex;
  align-items: center;
  box-shadow: 0 2px 4px 0 #00000022;
`;

const IconContainer = styled.div`
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TempContainer = styled.div`
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0.15000000596046448px;
  color: ${themes?.default?.black};
`;
