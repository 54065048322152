import { Typography } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { Accept, ErrorCode, FileRejection, useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import AddImage from 'assets/img/customers/AddImage.svg';
import { themes } from 'styles/theme/themes';

interface UploadedFile extends File {
  documentType: string;
}

interface UploadZoneProps {
  type: string;
  title: string;
  titleNode?: React.ReactNode;
  isUploading?: Boolean;
  description: string;
  onOk: (files: UploadedFile[]) => void;
  acceptFile?: Accept;
  onUnOk: (file: FileRejection) => void;
  maxFiles?: number;
  style?: any;
  items?: JSX.Element | null;
}

export function UploadZone({
  type,
  title,
  titleNode,
  description,
  onOk,
  acceptFile,
  onUnOk,
  maxFiles,
  style,
  isUploading,
  items,
}: UploadZoneProps) {
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: maxFiles || 30000,
    onDropAccepted: (acceptedFiles) => {
      const mapFiles: any[] = [];
      acceptedFiles.map((file, index) => {
        if (
          file?.type === 'application/x-msdownload' ||
          file?.type === 'application/octet-stream'
        ) {
          onUnOk({
            file: file,
            errors: [
              {
                code: ErrorCode.FileInvalidType,
                message: ErrorCode.FileInvalidType,
              },
            ],
          });
          return false;
        }
        Object.defineProperty(file, 'documentType', {
          value: type && 'cni' && index > 0 ? 'cni2' : type,
          configurable: false,
          writable: true,
        });

        mapFiles.push(file);
        // @ts-ignore
      });

      onOk(mapFiles);
    },
    onDropRejected: (fileRejections) => {
      fileRejections.map((file) => {
        onUnOk(file);
      });
    },
    accept: acceptFile,
    maxSize: 3000000,
  });

  // const uploadIcon = useMemo(() => {
  //   return icon ? (
  //     icon
  //   ) : (
  //     <img
  //       src={customerImage}
  //       alt="AddCustomerImage"
  //       style={{ width: '28px', height: '28px', marginTop: '30px' }}
  //     />
  //   );
  // }, [icon]);

  return (
    <UploadContentWrapper>
      <Typography>{title}</Typography>
      {titleNode}
      <DropZone>
        <div
          {...getRootProps({
            className: 'dropzone',
            style: {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              border: `1px dashed ${themes?.default?.lightFill}`,
              width: '100%',
              height: '141px',
              borderRadius: '4px',
              ...style,
            },
          })}
        >
          <input {...getInputProps()} />
          <img
            src={AddImage}
            alt="AddImage"
            style={{ width: '20px', height: '20px', marginTop: '60px' }} // Margin for spacing
          />
          <TextWrapper style={{ textAlign: 'center' }}>
            <Typography
              sx={{
                mr: '4px',
                borderBottom: `solid 1px ${themes?.default?.perano}`,
              }}
              color="primary"
            >
              {/* {t('settings.companyProfile.documentUpload.click')} */}
            </Typography>
            <Typography>
              {/* {t('settings.companyProfile.documentUpload.drag&drop')} */}
            </Typography>
          </TextWrapper>
          <Typography
            fontSize="0.875rem"
            fontWeight="400"
            sx={{
              color: 'rgba(0, 0, 0, 0.60)',
              mt: '8px',
              textAlign: 'center',
            }}
          >
            {description}
          </Typography>
          <LoadingWrapper style={{ textAlign: 'center' }}>
            {isUploading && <CircularProgress />}
          </LoadingWrapper>
          <div style={{ textAlign: 'center' }}>{items}</div>
        </div>
      </DropZone>
    </UploadContentWrapper>
  );
}

const DropZone = styled.div`
  width: 100%;
  position: relative;
`;

const UploadContentWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

const LoadingWrapper = styled.div`
  display: inline-flex;
  margin: 24px auto;
`;
