import * as React from 'react';

import { AddRoundtripWraper } from '../StatusChangePopover';

interface Props {
  action: any;
  label: any;
  // classNameCircle?: any;
  color?: string;
  circleStyle?: React.CSSProperties;
  // style?: React.CSSProperties;
}

export const ItemStatusComponent: React.FC<Props> = ({
  action,
  label,
  // classNameCircle,
  color,
  circleStyle,
  // style,
}) => {
  return (
    <AddRoundtripWraper>
      <div
        style={{
          display: 'flex',
          gap: '17px',
          color: 'black',
          alignItems: 'center',
        }}
        className="roundtrip_item"
        onClick={action}
      >
        <div
          className="circle"
          style={{ background: color || '', ...circleStyle }}
        />
        <div>{label}</div>
      </div>
    </AddRoundtripWraper>
  );
};
