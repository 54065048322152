import { useManagedContext } from '../../../common/UtilityComponents/ManagedContext/useManagedContext';
import { useSelector } from 'react-redux';
import { selectTheme } from '../../../styles/theme/slice/selectors';
import React, { useState } from 'react';
import { DotsThree } from '@phosphor-icons/react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { GridActionMenu } from '../Grid/components/GridActionMenu';
import { MenuButtonContainer, MenuItemsText, Wrapper } from './index';
import IconButton from '@mui/material/IconButton';

function HiddenTabsMenu() {
  const tabsContext = useManagedContext('tabs');
  const theme = useSelector(selectTheme);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton
        size="small"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        sx={{
          borderRadius: '4px',
          background: 'var(--Colors-Grey-200, #EEE)',
          width: 'var(--Spacing-4, 16px)',
          height: 'var(--Spacing-4, 16px)',
          padding: '0 !important',
        }}
      >
        <DotsThree size={10} color="#757575" weight="bold" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 20,
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          borderRadius: '4px',
          boxShadow: '0px 5px 30px 0px rgba(139, 139, 139, 0.25)',
        }}
        PaperProps={{
          style: {
            width: 'fit-content',
            padding: '1px',
          },
        }}
        MenuListProps={{
          style: {
            padding: '1px',
          },
        }}
        elevation={1}
      >
        {tabsContext.hiddenTabs.map((item, index) => {
          return (
            <MenuItem
              onKeyDown={(e) => e.stopPropagation()}
              key={index}
              sx={{
                gap: '4px',
                '&:hover': {
                  background: `var(--selected-row, ${theme.lightBackground})`,
                },
              }}
              onClick={(e: React.MouseEvent<HTMLLIElement>) => {
                const target = e.target as HTMLElement;

                const isGridActionMenuClicked =
                  target.childElementCount === 0 && target.nodeName === 'DIV';
                if (isGridActionMenuClicked) {
                  e.stopPropagation();
                  return;
                }

                tabsContext.updateDataWithFunction((prev) => {
                  prev.hiddenTabs[
                    prev.hiddenTabs.findIndex((tab) => tab.value === item.value)
                  ] = prev.visibleTabs.pop();
                  prev.visibleTabs = [item, ...prev.visibleTabs];
                });
                tabsContext.updateDataWithFunction((prev) => {
                  prev.selectedTab = item.value;
                });
                setAnchorEl(null);
              }}
            >
              <Wrapper>
                <MenuItemsText>{item.label}</MenuItemsText>
                {tabsContext.editable && (
                  <MenuButtonContainer canEdit={tabsContext.canEdit}>
                    <GridActionMenu options={tabsContext.menuActions(item)} />
                  </MenuButtonContainer>
                )}
              </Wrapper>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}

export default HiddenTabsMenu;
