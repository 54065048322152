import React, { createContext, useCallback, useState } from 'react';
import GenericDialog from '../GenericDialog';
import {
  ContextProps,
  DialogActionButtonProps,
  DialogBackButtonProps,
  DialogCancelButtonProps,
  ModalOpenProps,
} from '../types';
import _, { isArray } from 'lodash';
import { SxProps } from '@mui/material';

export const ModalContext = createContext<ContextProps | undefined>(undefined);

export const ModalProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState<React.ReactNode>();
  const [action, setAction] = useState<
    DialogActionButtonProps | DialogActionButtonProps[]
  >();
  const [cancel, setCancel] = useState<DialogCancelButtonProps | boolean>();
  const [back, setBack] = useState<DialogBackButtonProps>();
  const [deleteModal, setDeleteModal] = useState<boolean | undefined>(false);
  const [checkboxText, setCheckboxText] = useState<string>('');
  const [modalSx, setModalSx] = useState<SxProps>({});
  const [hasCloseIcon, setHasIcon] = useState<boolean | undefined>(true);

  const openModal = useCallback(function ({
    title,
    content,
    action,
    cancel,
    back,
    deleteModal,
    checkboxText,
    sx = {},
    hasCloseIcon,
  }: ModalOpenProps) {
    setTitle(title);
    setContent(content);
    setAction(action);
    setCancel(cancel);
    setBack(back);
    setOpen(true);
    setDeleteModal(deleteModal);
    setCheckboxText(checkboxText || '');
    setModalSx(sx);
    setHasIcon(hasCloseIcon);
  }, []);

  const closeModal = useCallback(function () {
    setOpen(false);
    setModalSx({});
    // setTitle('');
    // setContent(null);
  }, []);

  const setActionDisabled = useCallback(function (disabled = true) {
    if (isArray(action)) {
      setAction((prev) => {
        if (!prev || !isArray(prev)) return prev;
        const actions = [...prev];
        return actions.map((action) => {
          action.buttonProps = _.merge(action?.buttonProps || {}, {
            disabled,
          });
          return action;
        });
      });
    } else {
      setAction((prev) => {
        if (!prev) return prev;
        const action = { ...prev };
        //@ts-ignore
        //@ts-ignore
        action.buttonProps = _.merge(action?.buttonProps || {}, {
          disabled,
        });
        return action;
      });
    }
  }, []);

  const setActionButton = useCallback(function (
    action?: DialogActionButtonProps,
  ) {
    setAction(action);
  }, []);

  const setModalTitle = useCallback(function (title) {
    setTitle(title);
  }, []);

  const setModalContent = useCallback(function (content) {
    setContent(content);
  }, []);

  const setBackAction = useCallback(function (action?: DialogBackButtonProps) {
    setBack(action);
  }, []);

  return (
    <ModalContext.Provider
      value={{
        openModal,
        closeModal,
        setActionDisabled,
        setBackAction,
        setActionButton,
        setModalTitle,
        setModalContent,
      }}
    >
      <GenericDialog
        open={open}
        handleClose={closeModal}
        title={title}
        children={content}
        actionYes={action}
        actionNo={cancel}
        actionBack={back}
        deleteModal={deleteModal}
        checkboxText={checkboxText}
        sx={modalSx}
        hasCloseIcon={hasCloseIcon}
      />
      {children}
    </ModalContext.Provider>
  );
};
