import { Stack } from '@mui/material';
import { themes } from '../../../../../styles/theme/themes';
import Typography from '@mui/material/Typography';
import SearchField from '../../../../components/Search/SearchField';
import * as React from 'react';
import { useManagedContext } from '../../../../../common/UtilityComponents/ManagedContext/useManagedContext';
import { useTranslation } from 'react-i18next';

function RoundtripMappingHeader() {
  const { searchValue, updateData } = useManagedContext(
    'RoundtripMappingsContext',
  );
  const { t } = useTranslation();
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      height="40px"
      maxHeight="40px"
      px="20px"
      mb="20px"
      // border-bottom: 1px solid var(--divider, #0000001F)
      borderBottom={`1px solid ${themes.default.lightFill}`}
    >
      <Typography fontSize="1rem" fontWeight="500" alignItems="center">
        {t('mappings')}
      </Typography>
      <Stack direction="row" alignItems="center">
        <SearchField
          value={searchValue}
          setValue={(searchValue) => {
            updateData('searchValue', searchValue);
          }}
          textFieldProps={{
            sx: { marginBottom: '16px !important' },
          }}
        />
      </Stack>
    </Stack>
  );
}
export default RoundtripMappingHeader;
