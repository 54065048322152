import styled from 'styled-components';
import { themes } from 'styles/theme/themes';

export const PopUpContainerFilter = styled.div<{ width: string }>`
  width: ${(props) => props.width};
  .seperator {
    border: 0.8px solid ${themes?.default?.textColorSecondary}80;
    width: 171px;
  }
  button {
    width: 100%;
    justify-content: left;
  }
  .button_icon {
    display: flex;
    align-items: center;
  }

  padding: 12px;
  border-radius: 0.25rem;
`;
