import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { EmptyCenter } from 'app/components/TableComponent/styles';
import { NoDataComponents } from 'app/components/NoData/NoDataComponents';
import ContactsSectionEmptyState from 'assets/img/chat/ContactsSectionEmptyState.svg';
import { StateSetter } from 'types';

interface Props {
  setAddNewChatMode: StateSetter<boolean>;
  buttonBackground?: string;
}

export const EmptyState: React.FC<Props> = ({
  setAddNewChatMode,
  buttonBackground,
}) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <EmptyCenter>
        <NoDataComponents
          Icon={ContactsSectionEmptyState}
          text={t('chat.empty_state_message')}
          textButton={t('chat.start_new_chat')}
          handleClick={() => setAddNewChatMode(true)}
          buttonBackground={buttonBackground}
        />
      </EmptyCenter>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  margin: 0 0 ${60 + 24 + 42}px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;
