import { useTranslation } from 'react-i18next';
import { isNumber } from 'lodash';

import { Tooltip } from '@mui/material';
import { StatusChip } from 'app/components/StatusChip';
import useFormatCurrency from '../../../../../common/hooks/useFormatCurrency';

const getColor = (value) => {
  if (value > 500) {
    return 'error';
  } else if (value > 0) {
    return 'warning';
  } else if (value < 0) {
    return 'success';
  }

  return 'default';
};

export function AccountingBalanceValue({ value }) {
  const formatCurrency = useFormatCurrency();

  if (!isNumber(value)) {
    return null;
  }

  return (
    <StatusChip text={formatCurrency(value || 0)} status={getColor(value)} />
  );
}

export default function AccountingBalance({ total, due, overdue }) {
  const { t } = useTranslation();
  const intlFormat = Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
  });

  return (
    <Tooltip arrow placement="top" title={t('customer.accountingBalanceHelp')}>
      <div style={{ display: 'flex', gap: 1 }}>
        <StatusChip text={intlFormat.format(total)} status={getColor(total)} />
        <StatusChip
          text={intlFormat.format(overdue)}
          status={getColor(overdue)}
        />
        <StatusChip text={intlFormat.format(due)} status={getColor(due)} />
      </div>
    </Tooltip>
  );
}
