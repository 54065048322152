import { useGrid } from '../../../../../../../components/Grid/hooks';
import {
  Permission,
  usePermission,
} from '../../../../../../../../hooks/Abilities/usePermission';
import { useQueryParams } from '../../../../../../../../hooks/useQueryParams';
import {
  useDeleteDepartmentsMutation,
  useGetDepartmentsQuery,
} from '../../../../../../../../common/services/companyDepartmentsApi';
import React, { useEffect, useRef } from 'react';
import { Columns } from '../../data';
import { LoadingIndicator } from '../../../../../../../components/LoadingIndicator';
import { useManagedContext } from '../../../../../../../../common/UtilityComponents/ManagedContext/useManagedContext';
import DepartmentsList from '../DepartmentsList';
import { handleDeleteDepartments } from '../../helpers';
import { themes } from '../../../../../../../../styles/theme/themes';
import { GridToolbar } from '../../../../../../../components/Grid/components/GridToolbar';
import { useToaster } from '../../../../../../../../hooks/useToaster';
import { useModal } from '../../../../../../../components/Dialog/hooks';
import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';
import If from '../../../../../../../components/If';
import { IDepartmentsContext } from '../../index';

export default function FunctionalDepartments() {
  const departmentsContext =
    useManagedContext<IDepartmentsContext>('departmentsContext');
  const {
    setupGrid,
    page,
    rowsPerPage,
    order,
    debouncedSearchTerm,
    orderBy,
    setOrderBy,
  } = useGrid();

  const can = usePermission('companysettings');
  useEffect(() => {
    const canEdit = can(Permission.EDIT);
    departmentsContext.updateData('canEdit', canEdit);
  }, [can, departmentsContext]);

  const queryParams = useQueryParams({
    sort: String(order + orderBy) || 'name',
    limit: rowsPerPage,
    page: page,
    text: debouncedSearchTerm,
  });
  const { isLoading, data: departmentsResponse } =
    useGetDepartmentsQuery(queryParams);
  const toaster = useToaster();
  const { openModal, closeModal } = useModal();
  const { t } = useTranslation();
  useEffect(() => {
    setupGrid({
      columns: Columns,
      rows: departmentsResponse?.departments,
      totalRows: departmentsResponse?.totalDocs || 0,
      withCheckbox: departmentsContext.canEdit,
    });
  }, [
    setupGrid,
    departmentsResponse,
    orderBy,
    setOrderBy,
    can,
    departmentsContext.canEdit,
  ]);
  const [deleteDepartmentsMutation] = useDeleteDepartmentsMutation();

  function handleBulkDelete(selected: string[]) {
    openModal({
      title: t(`delete`),
      content: `${t(`common.dialog.delete.initial_title`)} ${selected.length} ${
        t('common.department') + (selected.length > 1 ? 's' : '')
      }?`,
      action: {
        actionText: t('delete'),
        actionCallback: () => {
          const ids = selected?.join(',');
          handleDeleteDepartments(
            ids,
            deleteDepartmentsMutation,
            closeModal,
            toaster,
          );
        },
        buttonProps: {
          sx: { background: themes?.default?.Cinnabar },
          color: 'error',
        },
      },
      cancel: true,
    });
  }
  const toolBarRef = useRef<HTMLElement | null>(null);
  useEffect(() => {
    toolBarRef.current = document.getElementById('toolbar');
  }, []);
  return (
    <>
      {toolBarRef.current &&
        createPortal(
          <GridToolbar
            onAddClick={
              departmentsContext.canEdit
                ? () => {
                    departmentsContext.updateData('openDrawer', true);
                  }
                : undefined
            }
            disabled={false}
            onBulkDelete={(selected) => {
              handleBulkDelete(selected);
            }}
            sx={{
              pr: '0 !important',
              borderBottom: 'none',
            }}
          />,
          toolBarRef.current,
        )}
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <If condition={!isLoading} otherwise={<LoadingIndicator />}>
          <DepartmentsList departmentsResponse={departmentsResponse!} />;
        </If>
      </div>
    </>
  );
}
