import { Button } from '@mui/material';
import { ArrowBendUpLeft, X } from '@phosphor-icons/react';
import { Icon } from 'app/components/Icon';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';

interface Props {
  customerId: string;
}

export const ReturnButton = ({ customerId }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleReturn = () => {
    navigate(`/customers/${customerId}/info`);
  };

  const [showButton, setShowButton] = useState(true);

  return (
    <div style={{ display: showButton ? 'flex' : 'none' }}>
      <Button
        variant="contained"
        sx={{
          marginLeft: '16px',
          borderRadius: '4px',
          backgroundColor: '#37474F',
          '&:hover': {
            backgroundColor: '#415158',
          },
        }}
        onClick={() => handleReturn()}
        startIcon={<ArrowBendUpLeft />}
      >
        {t('common-buttons-return')}
      </Button>
      <Button
        variant="contained"
        size="small"
        sx={{
          backgroundColor: '#37474F',
          '&:hover': {
            backgroundColor: '#415158',
          },
          minWidth: 0,
          borderRadius: '0 4px 4px 0',
          borderLeft: `0.5px solid ${themes.default.whiteStickyBackGround}`,
          padding: 0,
        }}
      >
        <IconWrapper>
          <Icon
            icon={<X />}
            color="white"
            onClick={() => setShowButton(false)}
          />
        </IconWrapper>
      </Button>
    </div>
  );
};

const IconWrapper = styled.div`
  padding: 4px 10px;
`;
