import { Chip, Typography } from '@mui/material';
import { usePreferredAgent } from 'hooks/PreferredAgent/usePreferredAgent';
import { useTranslation } from 'react-i18next';
import { themes } from 'styles/theme/themes';
import { getVehicleLabel } from 'utils/pages/vehicles';

export const SelectorRenderOption = ({
  option,
  props,
  type,
  minimal = false,
  isRipper,
}: {
  option: any;
  type?: string;
  props: any;
  minimal?: boolean;
  isRipper?: boolean;
}) => {
  const { preferredAgent } = usePreferredAgent({ user: option });

  let text = '';
  if (type === 'vehicle') {
    text = getVehicleLabel(option);
  } else if (type === 'user') {
    text = preferredAgent?.fullName ? preferredAgent?.fullName : '';
  }

  const { t } = useTranslation();

  return (
    <li {...props} style={{ padding: '5px 12px', opacity: 1 }}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          fontSize="0.875rem"
          variant="body1"
          component="div"
          style={{
            width: option?.roundtripCode ? (minimal ? '100%' : '100%') : '100%',
            paddingRight: '5px',
            opacity:
              option?.onLeave || (!!option?.roundtripCode && isRipper)
                ? 0.5
                : 1,
          }}
        >
          {text}
        </Typography>
        {option?.onLeave && (
          <div>
            <Chip
              label={
                <Typography
                  variant="body1"
                  component="span"
                  fontSize="0.875rem"
                  color={themes?.default?.primaryActiveColor}
                >
                  {t('user.on-leave')}
                </Typography>
              }
              variant="outlined"
              size="small"
              sx={{ borderColor: themes?.default?.primaryActiveColor }}
            />
          </div>
        )}
        {option?.roundtripCode && (
          <div>
            <Chip
              label={
                <Typography
                  variant="body1"
                  component="span"
                  fontSize="0.875rem"
                  color={themes?.default?.primaryActiveColor}
                >
                  {option?.roundtripCode}
                </Typography>
              }
              variant="outlined"
              size="small"
              sx={{ borderColor: themes?.default?.primaryActiveColor }}
            />
          </div>
        )}
      </div>
    </li>
  );
};
