import React, { useMemo } from 'react';
import { useManagedContext } from '../../../../../../common/UtilityComponents/ManagedContext/useManagedContext';
import { useToaster } from '../../../../../../hooks/useToaster';
import { useTranslation } from 'react-i18next';
import { A } from '../../../../../components/A';
import { AddressIcon } from '../../../../../components/AddressIcon';
import EllipsisText from '../../../../../components/EllipsisText';
import { getAddressLabel } from '../../../../../helpers/helpers';
import { usePatchWarehouseMutation } from '../../../../../../common/services/organizationApi';
import { GridRow } from '../../../../../components/Grid/components/styles';
import { GridTableCell } from '../../../../../components/Grid/components/GridTableCell';
import { IWarehousesContext } from './index';
import { themes } from '../../../../../../styles/theme/themes';
import { Chip } from '@mui/material';
import { GridActionMenu } from '../../../../../components/Grid/components/GridActionMenu';
import { IWarehouse } from '../../../../../../common/types/Warehouses';

function WarehouseRow({ warehouse }: { warehouse: IWarehouse }) {
  const warehousesContext =
    useManagedContext<IWarehousesContext>('warehousesContext');
  // const { openModal, closeModal } = useModal();
  const toast = useToaster();
  const { t } = useTranslation();
  const [patchWarehouse] = usePatchWarehouseMutation();

  const handleChangeStatus = async (warehouseId: any, status: string) => {
    try {
      await patchWarehouse({
        deckId: warehouseId,
        status,
      });
      if (status === 'active') {
        toast(3000, 'success', 'messages.warehouse.status.activated_success');
      } else {
        toast(3000, 'success', 'messages.warehouse.status.inactivated_success');
      }
    } catch (error) {
      toast(3000, 'error', 'messages.warehouse.status.error');
    }
  };

  const options = useMemo(() => {
    const allowedOptions = [
      {
        label: warehousesContext.canEdit ? t('edit') : t('view'),
        action: () => {
          warehousesContext.updateDataWithFunction((prev) => {
            prev.selectedWarehouse = warehouse;
            prev.openDrawer = true;
            prev.editMode = true;
          });
        },
      },
    ];

    if (warehousesContext.canEdit) {
      allowedOptions.push({
        action: () => {
          handleChangeStatus(
            warehouse._id,
            warehouse.status !== 'active' ? 'active' : 'inactive',
          );
        },
        label: t(
          warehouse.status !== 'active'
            ? 'common.buttons.activate'
            : 'inactivate',
        ),
      });
    }
    return allowedOptions;
  }, [warehouse, warehousesContext.canEdit, t]);

  return (
    <GridRow hover tabIndex={-1}>
      <GridTableCell columnId="name">
        <A
          role="button"
          className="link"
          onClick={() => {
            warehousesContext.updateDataWithFunction((prev) => {
              prev.selectedWarehouse = warehouse;
              prev.openDrawer = true;
              prev.editMode = true;
            });
          }}
        >
          {warehouse.name}
        </A>
      </GridTableCell>
      <GridTableCell columnId="initials">{warehouse.shortName}</GridTableCell>
      <GridTableCell columnId="location">
        <div
          className="locationWrapper"
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={() => {
            warehousesContext.updateDataWithFunction((prev) => {
              prev.mapCoordinates = warehouse.location?.geometry?.coordinates;
              prev.selectedWarehouse = warehouse;
            });
          }}
        >
          <AddressIcon />
          <EllipsisText
            width={200}
            text={getAddressLabel(warehouse.location!)}
          />
        </div>
      </GridTableCell>
      <GridTableCell columnId="capacity">
        {warehouse.capacity ? warehouse.capacity : 0} m<sup>3</sup>
      </GridTableCell>
      <GridTableCell columnId="manager">{warehouse.manager}</GridTableCell>
      <GridTableCell columnId="contact">{warehouse.phone}</GridTableCell>
      <GridTableCell columnId="operatingHours">
        {warehouse?.openingHours?.[0] ? (
          <>
            {warehouse?.openingHours[0].openingTime}
            <span> - </span>
            {warehouse?.openingHours[0].closingTime}
          </>
        ) : null}
      </GridTableCell>
      <GridTableCell columnId="status">
        <Chip
          label={warehouse.status === 'active' ? t('active') : t('inactive')}
          sx={{
            backgroundColor:
              warehouse.status === 'active'
                ? themes?.default?.success
                : themes?.default?.errorBackground,
            color:
              warehouse.status === 'active'
                ? themes?.default?.greenMain
                : themes?.default?.errorMain,
            fontSize: '0.875rem',
            textTransform: 'capitalize',
            height: 'auto',
          }}
        />
      </GridTableCell>
      <GridTableCell columnId="actions" className="actions-cell">
        <GridActionMenu options={options} />
      </GridTableCell>
    </GridRow>
  );
}

export default WarehouseRow;
