import * as React from 'react';
import { MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { PopOverElWrapper } from 'app/pages/AddRoundTrips/data/RoundTripData/components/Popover';
import { selectDateBegin } from 'common/store/roundtrips/selectors';
import {
  getStatusColor,
  getStatusesToShow,
} from 'app/pages/AddRoundTrips/functions';
import {
  RoundtripStatusesActiveDate,
  RoundtripStatusesInActiveDate,
} from 'app/pages/AddRoundTrips/data/status';
import { ManagedTooltip } from '../TableComponent/helpers/Components/ManagedTooltip';

interface Props {
  changeFunction: Function;
}

interface RoundtripStatusIndicatorProps {
  status: string;
  style?: React.CSSProperties;
}

export const RoundTripStatus: React.FC<Props> = ({ changeFunction }) => {
  const { t } = useTranslation();
  const today = dayjs().startOf('day').format('YYYY-MM-DD');
  const date = useSelector(selectDateBegin);

  let statuses;
  if (today === date) {
    statuses = getStatusesToShow(RoundtripStatusesActiveDate);
  } else {
    statuses = getStatusesToShow(RoundtripStatusesInActiveDate);
  }

  return (
    <MenuWrapper>
      {statuses?.map((status) => (
        <MenuItem
          onClick={() => {
            changeFunction(status.status);
          }}
        >
          <StatusIndicator color={status.color} />
          <div className="title">{t(`roundtrips.status.${status.status}`)}</div>
        </MenuItem>
      ))}
    </MenuWrapper>
  );
};

export const RoundtripStatusIndicator: React.FC<
  RoundtripStatusIndicatorProps
> = ({ status, style }) => {
  const { t } = useTranslation();
  const color = getStatusColor(status);

  if (!color) return null;

  return (
    <ManagedTooltip title={t(`roundtrips.status.${status}`)}>
      <StatusIndicator color={color} style={style} />
    </ManagedTooltip>
  );
};

export const MenuWrapper = styled(PopOverElWrapper)`
  display: block;
  & .title {
    padding-left: 10px;
    font-size: 0.857rem;
  }
`;

interface StatusIndicatorProps {
  color: string;
}

interface PulsingProps {
  baseboxShadow: string;
  animationBoxShadow: string;
}

export const StatusIndicator = styled.div<StatusIndicatorProps>`
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;

export const PulsingIndicator = styled(StatusIndicator)<PulsingProps>`
  box-shadow: 0 0 0 0 ${(props) => props.baseboxShadow};
  animation: pulse 1.5s infinite;
  cursor: pointer;

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 ${(props) => props.animationBoxShadow};
    }
    50% {
      box-shadow: 0 0 0 5px ${(props) => props.animationBoxShadow};
    }
    100% {
      box-shadow: 0 0 0 0 ${(props) => props.animationBoxShadow};
    }
  }
`;
