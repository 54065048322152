import Mousetrap from 'mousetrap';

export const bindShortcut = (
  keybinds: string[],
  callback: any,
  stopPropagation?: boolean,
  listener?: string,
) => {
  Mousetrap.bind(
    keybinds,
    function (e) {
      if (!!stopPropagation) e?.stopPropagation();
      e?.preventDefault();
      callback();
    },
    listener || null,
  );
};

export const unbindShortcut = (keybinds: string[]) => {
  Mousetrap.unbind(keybinds);
};
