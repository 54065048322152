import { useSelector } from 'react-redux';
import { selectAuthToken } from 'app/slices/auth/selectors';
import { useToaster } from 'hooks/useToaster';
import { useCallback, useState } from 'react';
import { useLoadingAlert } from 'app/components/LoadingAlert/hooks';
import { useApplication } from './useApplication';

export const useExportXLSX = () => {
  const baseUrl = process.env.REACT_APP_API_URL;

  const authToken = useSelector(selectAuthToken);
  const toast = useToaster();
  const { showLoading, hideLoading } = useLoadingAlert();
  const currentApplication = useApplication();

  const [loadingXLSX, setLoadingXLSX] = useState(false);

  const triggerXLSXExport = useCallback(
    async (queryParams: string, fileName: string) => {
      setLoadingXLSX(true);
      showLoading({ message: 'loading_xlsx' });
      const inSettings = window.location.pathname?.includes('/settings');

      const res = await fetch(
        `${
          baseUrl || 'https://api-dev.bamptee.com'
        }/${queryParams}&format=xlsx&authApplication=${
          inSettings ? 'idm' : currentApplication?.id
        }`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        },
      );
      try {
        const blob = await res.blob();
        const newBlob = new Blob([blob]);

        const blobUrl = window.URL.createObjectURL(newBlob);

        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', `${fileName}.xlsx`);
        document.body.appendChild(link);
        link.click();

        window.URL.revokeObjectURL(blobUrl);
        setLoadingXLSX(false);
        hideLoading();
        toast(5000, 'success', 'xls_export_success');
      } catch (err: any) {
        setLoadingXLSX(false);
        hideLoading();
        toast(5000, 'error', err.message);
      }
    },
    [authToken, baseUrl, hideLoading, showLoading, toast],
  );

  return { loadingXLSX, triggerXLSXExport };
};
