/**
 *
 * Asynchronously loads the component for Audit
 *
 */

import { lazyLoad } from 'utils/loadable';

export const AnalyticsPage = lazyLoad(
  () => import('./index'),
  (module) => module.AnalyticsHeader,
);
