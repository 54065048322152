import { createSlice } from '@reduxjs/toolkit';
import { CallState } from './types';
import callsApi from '../../services/calls';

export const initialState: CallState = {
  voiceCall: null,
  voiceCallJwt: null,
};

const slice = createSlice({
  name: 'call',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addMatcher(
        callsApi.endpoints.createVoiceJwt.matchFulfilled,
        (state: any, response: any) => {
          if (response.payload) {
            const { jwt } = response.payload;

            state.voiceCallJwt = jwt;
          }

          return state;
        },
      )
      .addMatcher(
        callsApi.endpoints.createVoiceCall.matchFulfilled,
        (state: any, response: any) => {
          console.log('createVoiceCall', response);
          if (response.payload) {
            const { call, jwt } = response.payload;

            state.voiceCall = call;
            state.voiceCallJwt = jwt;
          }

          return state;
        },
      );
  },
});

const callReducer = slice.reducer;

export const { actions: callActions } = slice;

export default callReducer;
