export const GRID = 'grid';
export const LIST = 'list';
export const CARD = 'card';
export const BOARD = 'board';
export const TRACK = 'track';
export const REGULAR = 'regular';
export const EXCEL = 'excel';
export const MAP = 'map';
export const REOPTIMIZE = 'reoptimize';
export const REOPTIMIZEMAP = 'reoptimize_map';
