import { useTranslation } from 'react-i18next';

export default function useFormatCurrency(options = {}) {
  const { i18n } = useTranslation();

  const formatter = new Intl.NumberFormat(i18n.language || 'fr-FR', {
    currency: 'EUR',
    style: 'currency',
    ...options,
  });

  return function formatCurrency(value: number) {
    return formatter.format(value);
  };
}
