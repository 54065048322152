import { Button, IconButton, InputAdornment, TextField } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import SearchIcon from '@mui/icons-material/Search';
import { useAddStopRoundtripMutation } from 'common/services/roundtripApi';
import { StatusIndicator } from 'app/components/TableComponent/helpers/Components/StatusPopover';
import { useTranslation } from 'react-i18next';
import { useToaster } from 'hooks/useToaster';
import { handleCloseAssignRoundTrip } from 'functions/setAnchorFunction';
import { themes } from 'styles/theme/themes';
import { AddRoundtripWraper } from 'app/components/RoundTrips/styles/styles';
import { useCreateShortcut } from 'app/pages/RoundTrips/components/KeyboardShortcuts/hooks/useCreateShortcut';

interface Props {
  setAddRoundTripOpen: any;
  roundTripCopy: any;
  order: any;
  deleteStop: Function;
  currentRoundtrip: any;
  accordionStatus?: any;
  setAccordionStatus?: (e) => void;
  toggleAssignToRoundtrip?: any;
  removeStopFromOrders?: any;
}

export const OrderAssignPopover: React.FC<Props> = ({
  setAddRoundTripOpen,
  roundTripCopy,
  order,
  deleteStop,
  currentRoundtrip,
  accordionStatus,
  setAccordionStatus,
  toggleAssignToRoundtrip,
  removeStopFromOrders = () => {},
}) => {
  const { t } = useTranslation();
  const toast = useToaster();

  const [addStopRoundtrip] = useAddStopRoundtripMutation();
  const [searchAddRoundtrip, setSearchAddRoundtrip] = useState<string>('');
  const [filteredRoundTripCopy, setFilteredRoundTripCopy] = useState<any[]>([]);

  useEffect(() => {
    initiateRoundtripCopy();
  }, [roundTripCopy]);
  useEffect(() => {
    if (searchAddRoundtrip !== '')
      setFilteredRoundTripCopy(
        roundTripCopy
          ?.filter((item) => {
            return (
              item?.code &&
              item?.code
                .toLowerCase()
                .includes(searchAddRoundtrip.toLowerCase())
            );
          })
          ?.sort((a, b) => a.code.localeCompare(b.code)),
      );
    else initiateRoundtripCopy();
  }, [searchAddRoundtrip]);

  const handleSearchAddRoundtripChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchAddRoundtrip(event.target.value);
  };

  const assignOrder = async (id, data) => {
    try {
      await deleteStop();
      await addStopRoundtrip({
        id: id,
        data: {
          stops: [data],
        },
      }).unwrap();
      toast(5000, 'success', 'alerts.orders.assign_success');
    } catch (e: any) {}
  };

  const initiateRoundtripCopy = () => {
    setFilteredRoundTripCopy(
      roundTripCopy
        ?.filter((item) => {
          return item?.id !== currentRoundtrip?.id;
        })
        ?.sort((a, b) => a.code.localeCompare(b.code)),
    );
  };

  const toggleAccordion = (group: number, index: number) => {
    if (accordionStatus && setAccordionStatus) {
      let accordionStatusCopy;
      if (Array.isArray(accordionStatus)) {
        accordionStatusCopy = [...accordionStatus];
      }
      accordionStatusCopy[group].value[index] = true;

      setAccordionStatus?.(accordionStatusCopy);
    }
  };

  async function handleAssignOrder(roundtrip) {
    var indexFind = roundTripCopy?.findIndex(
      (trip) => trip?.id === roundtrip?.id,
    );
    const id = order?._order?._id ?? order?._id;
    await assignOrder(roundtrip.id, {
      // ...order,
      _id: id,
      _order: id,
      _place: order?._deck?._id ?? order?._place?._id,
      type: order?.type,
    })
      .then(() => {
        removeStopFromOrders(id);
      })
      .catch(() => {
        return;
      });
    handleCloseAssignRoundTrip(setAddRoundTripOpen);
    toggleAccordion(0, indexFind);
  }

  const [selectedIndex, setSelectedIndex] = useState<null | number>(null);
  const selectedItemRef = useRef<any>(null);

  function SelectOption(direction) {
    const stopsCount = filteredRoundTripCopy?.length;

    if (direction === 'up' && selectedIndex === null) {
      setSelectedIndex(stopsCount - 1);
    } else if (direction === 'down' && selectedIndex === null) {
      setSelectedIndex(0);
    } else if (direction === 'up' && selectedIndex !== null) {
      if (selectedIndex === 0) {
        setSelectedIndex(stopsCount - 1);
      } else {
        setSelectedIndex(selectedIndex - 1);
      }
    } else if (direction === 'down' && selectedIndex !== null) {
      if (selectedIndex === stopsCount - 1) {
        setSelectedIndex(0);
      } else {
        setSelectedIndex(selectedIndex + 1);
      }
    }
  }

  function ConfirmSelection() {
    if (selectedIndex === null) return;
    handleAssignOrder(filteredRoundTripCopy[selectedIndex]);
    toggleAssignToRoundtrip();
  }

  useCreateShortcut({
    shortcut: {
      key1: 'ArrowDown',
      action: {
        callback: () => SelectOption('down'),
        eventName: 'keydown',
      },
    },
  });

  useCreateShortcut({
    shortcut: {
      key1: 'ArrowUp',
      action: {
        callback: () => SelectOption('up'),
        eventName: 'keydown',
      },
    },
  });

  useCreateShortcut({
    shortcut: {
      key1: 'Enter',
      action: {
        callback: () => ConfirmSelection(),
        eventName: 'keyup',
      },
    },
  });

  useEffect(() => {
    if (selectedItemRef.current) {
      selectedItemRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [selectedIndex]);

  return (
    <AddRoundtripWraper
      onMouseLeave={() => {
        setAddRoundTripOpen(null);
      }}
    >
      <TextField
        size="small"
        variant="outlined"
        onChange={handleSearchAddRoundtripChange}
        value={searchAddRoundtrip}
        placeholder={t('search_trips')}
        sx={{ marginTop: '10px' }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton
                aria-label="delete"
                component="label"
                onClick={() => {}}
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <RoundtripListContainer className="roundtrips">
        {filteredRoundTripCopy?.map((roundtrip, index) => {
          if (currentRoundtrip?.id !== roundtrip.id)
            return (
              <Button
                key={roundtrip?.id}
                ref={selectedIndex === index ? selectedItemRef : null}
                sx={{
                  display: 'flex',
                  gap: '17px',
                  color: 'black',
                  width: '100%',
                  justifyContent: 'start',
                  background:
                    selectedIndex === index
                      ? themes?.default?.solitude
                      : 'transparent',
                }}
                className="roundtrip_item"
                onClick={() => handleAssignOrder(roundtrip)}
              >
                <div className="roundtrip_code">
                  <StatusIndicator status={roundtrip.status} />
                  {roundtrip.code}~{roundtrip.internalCode}
                </div>
                <div>
                  {t('stop_count', {
                    count: roundtrip?.cache?.stats?.customers,
                  })}
                  {!!roundtrip?._vehicle?.category
                    ? ` - ${roundtrip?._vehicle?.category}`
                    : ''}
                </div>
              </Button>
            );
        })}
      </RoundtripListContainer>
    </AddRoundtripWraper>
  );
};

const RoundtripListContainer = styled.div`
  max-height: 185px;
  width: 228px;
  overflow-y: scroll;
`;
