import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';

import styled, { css } from 'styled-components';
import {
  ROUNDTRIP_STATUS,
  colorRoundtripStatus,
} from 'app/components/RoundTrips/RoundTripData/StatusColors';
import { themes } from 'styles/theme/themes';
import { ManagedTooltip } from 'app/components/TableComponent/helpers/Components/ManagedTooltip';
import { useTranslation } from 'react-i18next';
import { RoundtripStatus } from 'app/components/RoundTrips/RoundTripData/statuses';

interface ActionsProps {
  seperator?: boolean;
  action: string;
  element: JSX.Element;
}

interface Props {
  handleAction?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    action: string,
    id: string | number,
  ) => void;
  id: string | number;
  allowTouchEvents?: boolean;
  actions: ActionsProps[];
  status?: any;
  tooltipDisabled?: boolean;
  condition1: any;
  condition2: any;
}

interface PulsingProps {
  baseboxShadow: string;
  animationBoxShadow: string;
}

const PULSING_COLORS = {
  loading: {
    boxShadow: 'rgba(41, 182, 246, 0.5)',
    animationBoxShadow: 'rgba(41, 182, 246, 0.2)',
  },
  unloading: {
    boxShadow: 'rgba(41, 182, 246, 0.5)',
    animationBoxShadow: 'rgba(41, 182, 246, 0.2)',
  },
};
const PULSING_STATUSES = [RoundtripStatus.Loading, RoundtripStatus.Unloading];

export const StatusIndicator = styled.div`
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 50px;
  background: ${(props: { status }) => colorRoundtripStatus(props.status)};
`;
export const PulsingIndicator = styled(StatusIndicator)<PulsingProps>`
  box-shadow: 0 0 0 0 ${(props) => props.baseboxShadow};
  animation: pulse 1.5s infinite;
  cursor: pointer;

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 ${(props) => props.animationBoxShadow};
    }
    50% {
      box-shadow: 0 0 0 5px ${(props) => props.animationBoxShadow};
    }
    100% {
      box-shadow: 0 0 0 0 ${(props) => props.animationBoxShadow};
    }
  }
`;

export const PopoverStatus: React.FC<Props> = ({
  status,
  id,
  allowTouchEvents,
  handleAction,
  actions,
  tooltipDisabled,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const handleClick = (
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.TouchEvent<HTMLButtonElement>,
  ) => {
    event.stopPropagation();
    if (event.type === 'touchend' && !allowTouchEvents) return;
    // if (handleAction && !condition1 === true && condition2 !== undefined)
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;
  const isPulsing = PULSING_STATUSES.includes(status);

  return (
    <PopupItem>
      <ManagedTooltip
        title={t(`roundtrips.status.${status}`)}
        disabled={tooltipDisabled}
      >
        <IconButton
          onTouchEnd={handleClick}
          onClick={handleClick}
          aria-label="Example"
          sx={{
            padding: '4px',
          }}
        >
          {isPulsing ? (
            <PulsingIndicator
              status={status}
              baseboxShadow={PULSING_COLORS[status].boxShadow}
              animationBoxShadow={PULSING_COLORS[status].animationBoxShadow}
            />
          ) : (
            <StatusIndicator status={status} />
          )}
        </IconButton>
      </ManagedTooltip>
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Typography
          sx={{ padding: '6px 0' }}
          padding="12px 12px 12px 16px"
          minWidth={123}
        >
          <div>
            {actions.map((action, index) => {
              return (
                <div key={index}>
                  <PopOverElement
                    className={action?.seperator ? 'seperator' : ''}
                    onClick={(e) => {
                      e.stopPropagation();
                      // setAnchorEl(null);
                      if (handleAction) handleAction(e, action.action, id);
                      handleClose();
                      // if (action.functionCall) action.functionCall(id);
                    }}
                  >
                    <PopOverElWrapper style={{ width: '100%' }}>
                      {action.element}
                    </PopOverElWrapper>
                  </PopOverElement>
                </div>
              );
            })}
          </div>
        </Typography>
      </Popover>
    </PopupItem>
  );
};

const PopupItem = styled.div``;

const PopOverElement = styled.div`
  display: flex;
  gap: 14px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) =>
      props?.className === 'seperator' ? '' : themes?.default?.solitude};
  }
`;

const DotStyle = css`
  width: 8px;
  height: 8px;
  border-radius: 100px;
  margin-bottom: 2px;
`;
export const PopOverElWrapper = styled.div`
  .red_dot {
    ${DotStyle};
    background: ${ROUNDTRIP_STATUS.canceled.color};
  }
  .purple_dot {
    ${DotStyle};
    background: ${ROUNDTRIP_STATUS.prepared.color};
  }
  .blue_dot {
    ${DotStyle};
    background: ${ROUNDTRIP_STATUS.loaded.color};
  }
  .blue_pulsing_dot {
    ${DotStyle};
    background: ${ROUNDTRIP_STATUS.loaded.color};
  }
  .orange_dot {
    ${DotStyle};
    background: ${ROUNDTRIP_STATUS.pending.color};
  }
  .purple_light_dot {
    ${DotStyle};
    background: ${ROUNDTRIP_STATUS.on_way.color};
  }
  .green_light_dot {
    ${DotStyle};
    background: ${ROUNDTRIP_STATUS.completed.color};
  }
  .green_dark_dot {
    ${DotStyle};
    background: ${ROUNDTRIP_STATUS.on_dock.color};
  }
  .blue_light_dot {
    ${DotStyle};
    background: ${ROUNDTRIP_STATUS.unloaded.color};
  }
  .blue_light_pulsing_dot {
    ${DotStyle};
    background: ${ROUNDTRIP_STATUS.unloaded.color};
  }
  .black_dot {
    ${DotStyle};
    background: ${ROUNDTRIP_STATUS.parked.color};
  }

  .purple_aqua_dot {
    ${DotStyle};
    background: ${ROUNDTRIP_STATUS.paused.color};
  }
  .green_dot {
    ${DotStyle};
    background: ${ROUNDTRIP_STATUS.planned.color};
  }
  display: flex;
  padding: 6px 0;
  width: 100%;
`;
export const PopoverEl = styled.div`
  font-size: 0.85rem;
  width: 134px;
  .seperator {
    border: 0.8px solid ${themes?.default?.textColorSecondary}80;
    margin-top: 10px;
    width: 164px;
  }
  .delete {
    color: ${themes?.default?.redStatus};
  }
`;
