import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  styled,
  Stack,
  Typography,
} from '@mui/material';
import { useAddStopRoundtripMutation } from 'common/services/roundtripApi';
import SearchIcon from '@mui/icons-material/Search';
import { StatusIndicator } from 'app/components/TableComponent/helpers/Components/StatusPopover';
import { useEffect, useMemo, useState } from 'react';
import { useLazyGetOrderRoundTripsBulkQuery } from 'common/services/orderApi';
import If from 'app/components/If';
import { LoadingPulse } from 'app/components/LoadingPulse';
import DropdownDatePicker from 'app/components/DatePicker/DropdownDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { useQueryParams } from 'hooks/useQueryParams';
import { useToaster } from 'hooks/useToaster';
import { useTranslation } from 'react-i18next';

interface AssignRoundtripProps {
  orderIds: string[];
  atAssign?: () => void;
  selectedOrders: { id: string; placeId: string; type }[];
}

const SearchField = styled(TextField)({
  marginLeft: '5px',
  flexGrow: 1,
  height: '33px',
  marginTop: '0',
  marginBottom: ' 0 !important',
});

const RoundTripButton = styled(Button)({
  width: '100%',
  justifyContent: 'start',
  gap: '5px',
  color: '#000',
});

const Container = styled('div')({
  overflow: 'auto',
  height: '300px',
  width: '550px',
  maxWidth: '100%',
});

const LoadingContainer = styled('div')({
  width: '100%',
  marginTop: '20px',
});

const today = dayjs().startOf('day');

export default function AssignRoundtrip({
  orderIds,
  atAssign,
  selectedOrders,
}: AssignRoundtripProps) {
  const [dateError, setDateError] = useState(false);
  const [search, setSearch] = useState('');
  const [date, setDate] = useState<Dayjs | null>(today);

  const [getRoundTrips, { isFetching, data = [] }] =
    useLazyGetOrderRoundTripsBulkQuery();
  const [addStopRoundtrip, { isLoading }] = useAddStopRoundtripMutation();
  const { t } = useTranslation();
  const toast = useToaster();

  const query = useQueryParams({
    beginAt: date?.startOf('day').format('YYYY-MM-DD'),
    endAt: date?.endOf('day').format('YYYY-MM-DD'),
    orderIds: orderIds.join(','),
  });

  const showLoader = isFetching || isLoading;

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const addOrderToRoundTrip = async (roundtripId: string) => {
    // TODO: Implement a custom hook or another resuable solution/function to handle API responses
    try {
      await addStopRoundtrip({
        id: roundtripId,
        data: {
          stops: selectedOrders?.map((order) => ({
            _order: order?.id,
            _place: order?.placeId,
            type: order?.type,
          })),
        },
      }).unwrap();

      atAssign && atAssign();
    } catch (e: any) {
      toast(3000, 'error', e.data.message);
    }
  };

  const roundTrips = useMemo(() => {
    const filteredData = [...data];
    if (!search) {
      return filteredData?.sort((a, b) => a.code.localeCompare(b.code)) || [];
    }
    return filteredData
      .filter((item) => {
        return (
          item.code && item.code.toLowerCase().includes(search.toLowerCase())
        );
      })
      ?.sort((a, b) => a.code.localeCompare(b.code));
  }, [search, data]);

  useEffect(() => {
    if (!date?.isBefore(today.startOf('day'), 'day') && date?.isValid()) {
      setDateError(false);
      getRoundTrips({ query });
    } else {
      setDateError(true);
    }
  }, [date]);

  return (
    <Stack spacing={3}>
      <DropdownDatePicker
        dateValue={date}
        setDateValue={setDate}
        showDots={true}
      />
      {dateError && (
        <Typography variant="caption" color="error" mt="0 !important">
          {t('past_date_error')}
        </Typography>
      )}
      <SearchField
        size="small"
        variant="outlined"
        onChange={handleSearch}
        value={search}
        placeholder={t('search_trips')}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton aria-label="search" component="label">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Container>
        <If
          condition={!showLoader}
          otherwise={
            <LoadingContainer>
              <LoadingPulse />
            </LoadingContainer>
          }
        >
          {roundTrips &&
            roundTrips.map((roundTrip, index) => {
              return (
                <RoundTripButton
                  key={`${roundTrip.code}-${index}`}
                  className="roundtrip_item"
                  onClick={() => {
                    addOrderToRoundTrip(roundTrip.id);
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="start"
                    gap="5px"
                  >
                    <StatusIndicator status={roundTrip.status} />
                    {roundTrip.code}~{roundTrip.internalCode}
                  </Stack>
                  <div>
                    {`${t('stop_count', {
                      count: roundTrip?.cache?.stats?.customers,
                    })}`}
                    {!!roundTrip?._vehicle?.category
                      ? ` - ${roundTrip?._vehicle?.category}`
                      : ''}
                  </div>
                </RoundTripButton>
              );
            })}
        </If>
      </Container>
    </Stack>
  );
}
