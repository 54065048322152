import { getVehiclesDto } from '../Dtos/mobile/getVehiclesDto';
import { ValidationResponse } from '../types/Vehicles';
import api from './api';

export const vehicleApis = api.injectEndpoints({
  endpoints: builder => ({
    getVehicles: builder.query<any, string>({
      query: urlQuery => ({
        url: `vehicles${urlQuery}`,
      }),
      providesTags: ['Vehicles'],
      transformResponse: (response: any, meta, arg) => {
        const urlParams = new URLSearchParams(arg);
        const dto = urlParams.get('dto');
        if (dto === 'mobile') return getVehiclesDto(response);
        return response;
      },
    }),
    getUnrestrictedVehicles: builder.query<any, string>({
      query: urlQuery => ({
        url: `vehicles/unrestricted-vehicles${urlQuery}`,
      }),
      providesTags: ['Vehicles'],
    }),
    getVehicleCategories: builder.query<any, void>({
      query: () => ({
        url: `vehicles/categories`,
      }),
      transformResponse: (response: any) => {
        response[0]._id = 'All';
        return response;
      },
    }),
    importVehicleValidate: builder.mutation<ValidationResponse, FormData>({
      query: body => ({
        url: '/vehicles/validate-import',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['VehicleValidateImport'],
    }),
    vehicleImport: builder.mutation<unknown, FormData>({
      query: body => ({
        url: '/vehicles/import',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['VehiclessImport'],
    }),
    vehicleDelete: builder.mutation<unknown, string>({
      query: id => ({
        url: `/vehicles/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['VehiclesBulkDelete'],
    }),
    vehicleBulkDelete: builder.mutation<unknown, { vehicleIds: string[] }>({
      query: body => ({
        url: '/vehicles/delete',
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['VehiclesBulkDelete'],
    }),
    postVehicle: builder.mutation<any, any>({
      query: body => ({
        url: 'vehicles',
        method: 'POST',
        body,
      }),
    }),
    updateVehicle: builder.mutation<any, any>({
      query: ({ id, body }) => ({
        url: `vehicles/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Vehicles'],
    }),
    getVehicleManufacturers: builder.query<any, string>({
      query: urlQuery => ({
        url: `vehicles/manufacturers?${urlQuery}`,
        method: 'GET',
      }),
    }),
    getVehicleById: builder.query<any, any>({
      query: ({ id, authApplication }) => ({
        url: `vehicles/${id}${
          authApplication ? `?authApplication=${authApplication}` : ''
        }`,
        method: 'GET',
      }),
    }),
    getVehicleDetails: builder.query<any, any>({
      query: ({ numberPlate }) => ({
        url: `vehicles/details/${numberPlate}`,
        method: 'GET',
      }),
    }),
    deleteVehicle: builder.mutation<any, any>({
      query: id => ({
        url: `vehicles/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetVehiclesQuery,
  useGetUnrestrictedVehiclesQuery,
  useLazyGetVehiclesQuery,
  useGetVehicleCategoriesQuery,
  useImportVehicleValidateMutation,
  useVehicleImportMutation,
  useVehicleDeleteMutation,
  useVehicleBulkDeleteMutation,
  usePostVehicleMutation,
  useUpdateVehicleMutation,
  useGetVehicleManufacturersQuery,
  useGetVehicleByIdQuery,
  useDeleteVehicleMutation,
  useLazyGetVehicleByIdQuery,
  useLazyGetVehicleDetailsQuery,
} = vehicleApis;

export default vehicleApis;
