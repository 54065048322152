import { useChangeAppMutation } from 'common/services/appApi';
import { appActions, BampteeApplications } from 'common/store/app';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { authActions } from 'app/slices/auth';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'app/slices/auth/selectors';

/**
 * Sets the app for the user when the app first loads, use it to prevent navigation to page before it's complete
 */
export function useInitAuthApp() {
  const [loading, setLoading] = useState(true);

  const [changeApp] = useChangeAppMutation();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const authUser = useSelector(selectAuthUser);

  async function setApp(app: string) {
    if (!authUser) {
      return;
    }
    const { sessionId, token, user } = await changeApp({ app }).unwrap();
    const application =
      BampteeApplications.find((application) => application.id === app) ||
      BampteeApplications[0];
    dispatch(authActions.setCredentials({ user, token, sessionId }));
    dispatch(appActions.setCurrentApplication({ application }));
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    const appIds = BampteeApplications.map((app) => app.id);
    const app =
      appIds.find((app) =>
        pathname.toLowerCase().includes(app.toLowerCase()),
      ) || 'bianta';

    setApp(app);
  }, [authUser?._id]);

  return { loading };
}
