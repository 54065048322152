import { Dialog, DialogContent, DialogContentText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Title } from '../Components/Title';
import styled from 'styled-components';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Dayjs } from 'dayjs';
import {
  useLazyGetRoundTripsClusterQuery,
  useRoundtripAddApiMutation,
  useUpdateRoundTripsClusterMutation,
} from 'common/services/roundtripApi';
import { ROUNDTRIP_ADD } from 'utils/routes';
import { LoadingPulse } from 'app/components/LoadingPulse';
import { useToaster } from 'hooks/useToaster';
import { DialogActionsComponent } from 'app/components/DialogActionsComponent';
import { Bold, Paragraphe } from 'styles/components/text';
import { themes } from 'styles/theme/themes';
import { useCountRoundtrip } from '../../../hooks/useCountRoundtrip';
import { triggerClusterByMode } from 'app/components/RoundTrips/function';
import { AgentCategories } from 'app/components/RoundTrips/RoundTripData/agentCategories';

interface Props {
  roundTripCopy?: any;
  dateValue: Dayjs | null;
  warehouse: {
    code: string;
    location: string;
    name: string;
    type: string;
    _id: string;
  };
  begin: string;
  end: string;
  dateSyncedAt?: string;
  allRoundtrip?: any;
  open: boolean;
  handleClose: () => void;
}

export const ApiIntegration: React.FC<Props> = ({
  dateValue,
  warehouse,
  begin,
  end,
  dateSyncedAt,
  roundTripCopy,
  allRoundtrip,
  open,
  handleClose,
}) => {
  const [trigger] = useLazyGetRoundTripsClusterQuery();

  const { t } = useTranslation();
  const [countRoundtrip, setCountRoundtrip] = useState(0);

  const toast = useToaster();
  const handleBack = () => {};
  const [roundtripApiAdd] = useRoundtripAddApiMutation();
  useCountRoundtrip({
    roundTripCopy,
    warehouse,
    allRoundtrip,
    setCountRoundtrip,
  });
  const [updateRoundtripCluster] = useUpdateRoundTripsClusterMutation();

  const apiToManual = async (idCluster) => {
    try {
      await updateRoundtripCluster({
        id: idCluster,
        mode: 'manual',
      }).unwrap();
    } catch (e: any) {
      if (e.status === 401) {
      }
    }
  };
  const addApi = async () => {
    try {
      const resp = await roundtripApiAdd({
        name: warehouse?.['name'] + ' - ' + 'API Integration',
        begin: begin,
        end: begin,
        _deck: warehouse?.['_id'],
        mode: 'automatic',
        agentCategory: AgentCategories.Logistic,
      }).unwrap();
      if (resp) {
        const url = new URL(ROUNDTRIP_ADD, window.location.origin);
        url.searchParams.append('warehouse', JSON.stringify(warehouse));
        url.searchParams.append('dateValue', JSON.stringify(dateValue));
        url.searchParams.append('cluster', JSON.stringify(resp.cluster?._id));
        url.searchParams.append('resultNbr', countRoundtrip + '');
        url.searchParams.append('userContext', AgentCategories.Logistic);
        if (resp.roundtrips?.length) {
          toast(5000, 'success', 'alerts.orders.import_api_success');
          url.searchParams.append('type', 'api');
          url.searchParams.append('mode', 'automatic');
        } else {
          toast(5000, 'error', 'alerts.orders.import_api_empty');
          url.searchParams.append('mode', 'manual');
          url.searchParams.append('emptyApi', 'true');
          const manualResp = await triggerClusterByMode(
            trigger,
            'manual',
            warehouse?.['_id'],
            begin,
            end,
            AgentCategories.Logistic,
          );
          if (manualResp?.data?.totalDocs) {
            return;
          }
          apiToManual(resp?.cluster?._id);
        }

        window.open(url.toString(), '_blank');
      }
    } catch (e: any) {
      toast(5000, 'error', 'alerts.orders.import_error');
      if (e.status === 401) {
      }
    }
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Title
        style={{ width: '592px' }}
        handleBack={handleBack}
        handleCloseActivate={handleClose}
        dateValue={dateValue}
      />
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Paragraphe>
            <span>{t('warehouse')}:</span>
            <Bold> {warehouse?.name}</Bold>
          </Paragraphe>
        </DialogContentText>
        <DialogContentText id="alert-dialog-description">
          <Synced>
            <div className="text-part">
              <CheckCircleIcon />
              <p>{t('last_synced')}</p>
            </div>
            <div>{dateSyncedAt ? <p>{dateSyncedAt}</p> : <LoadingPulse />}</div>
          </Synced>
        </DialogContentText>
      </DialogContent>
      <DialogActionsComponent
        handleCloseApi={handleClose}
        Action={addApi}
        width='auto'
        textCancel={t('common.buttons.cancel')}
        textAction={t('common.buttons.import')}
      />
    </Dialog>
  );
};

const Synced = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  isolation: isolate;
  height: 96px;
  background: rgba(42, 89, 255, 0.08);
  margin-bottom: 6px;
  border-radius: 4px;
  padding: 14px 16px;
  gap: 13px;
  color: ${themes?.default?.rawUmber};
  svg {
    color: ${themes?.default.primary};
  }
  div {
    display: flex;
    gap: 6px;
    color: ${themes?.default?.black};
    align-items: center;
  }
  .text-part {
    padding-right: 50px;
  }
`;
