import {
  KeyPoint,
  StopWrapper,
  TimelineDistance,
  TimelineWrapper,
} from 'styles/components/roundTrips/roundTripsData';
import { stateCreateData } from 'utils/pages/roundTrips';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import Settings from 'assets/img/roundTrips/settings.svg';
import Station from 'assets/img/roundTrips/station.svg';
import StationDisabled from 'assets/img/roundTrips/station-disabled.svg';
import { ActionPopover, PopoverEl } from 'app/components/Popover';

import LoopIcon from '@mui/icons-material/Loop';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { RoundTripInfoPopover } from 'app/components/RoundTrips/components/RoundTripInfoPopover';
import { Stop } from 'app/components/RoundTrips/components/Stop';
import { findRole, roundtripStatus } from 'app/components/RoundTrips/function';
import { Driver } from 'app/components/RoundTrips/RoundTripData/statuses';
import { RoundTripsStatus } from 'app/components/RoundTrips/RoundTripData/RoundTripStatus';
import { themes } from 'styles/theme/themes';

export const TrackData = ({ rowData, theme, t, roundTripCopy, i }) => {
  rowData.data.push({
    data: stateCreateData(
      {
        value: roundTripCopy[i].id,
      },
      {
        value: (
          <div
            style={{
              display: 'flex',
              columnGap: 16,
            }}
          >
            <div style={{ display: 'flex', marginTop: '5px' }}>
              <span>{roundTripCopy[i]?.code}</span>
              <LocalShippingIcon
                sx={{
                  width: 14,
                  color: roundtripStatus(roundTripCopy[i], theme),
                }}
              />
            </div>
            <div>
              <div style={{ fontSize: '0.75rem' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div>
                    <div style={{ fontWeight: 700, color: theme.blackPrimary }}>
                      {roundTripCopy[i]?._vehicle?.numberPlate &&
                        roundTripCopy[i].agents.find((agent) => {
                          return findRole(agent, Driver);
                        })?._user.firstName +
                          ' ' +
                          roundTripCopy[i].agents.find((agent) => {
                            return findRole(agent, Driver);
                          })?._user.lastName}
                      /{roundTripCopy[i]?._vehicle?.numberPlate}
                    </div>
                    <div
                      style={{
                        color: theme.textColorSecondary,
                        fontWeight: 700,
                      }}
                    >
                      {roundTripCopy[i]?._vehicle?.parkNumber &&
                        roundTripCopy[i]?._vehicle?.parkNumber}
                    </div>
                  </div>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <ActionPopover
                      id={roundTripCopy[i].id}
                      handleAction={async () => {}}
                      actions={[
                        {
                          action: 'edit',
                          element: (
                            <>
                              <ModeEditIcon sx={{ color: theme.icon }} />
                              <PopoverEl redStatus={theme.redStatus}>
                                {t('common.button.edit')}
                              </PopoverEl>
                            </>
                          ),
                        },
                        {
                          action: 'status',
                          element: (
                            <>
                              <LoopIcon sx={{ color: theme.icon }} />
                              {/* {roundTripObj.status === 'activated' ? (
                                <PopoverEl>{t('button.deactivate')}</PopoverEl>
                              ) : (
                                <PopoverEl>{t('button.reactivate')}</PopoverEl>
                              )} */}
                              <PopoverEl redStatus={theme.redStatus}>
                                {t('button.deactivate')}
                              </PopoverEl>
                            </>
                          ),
                        },
                        {
                          action: 'delete',
                          element: (
                            <>
                              <DeleteIcon sx={{ color: theme.icon }} />
                              <PopoverEl redStatus={theme.redStatus}>
                                {t('button.delete')}
                              </PopoverEl>
                            </>
                          ),
                        },
                      ]}
                    />
                  </div>
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <RoundTripsStatus status={roundTripCopy[i].status} />

                  <RoundTripInfoPopover />
                </div>
              </div>
            </div>
          </div>
        ),
        sort: roundTripCopy[i].id,
      },
    ),
    timeLine: (
      <TimelineWrapper>
        <KeyPoint>Key point</KeyPoint>
        <div
          style={{
            borderBottom: `0.063rem solid ${themes?.default?.greyDisabled}`,
            position: 'absolute',
            width: '100%',
            top: 10,
            zIndex: 1,
          }}
        />
        {roundTripCopy[i].stops.map((stop) => {
          const date = new Date(stop._order?.deliveryDate);
          const hours: number = date?.getHours();
          const minutes: number = date?.getMinutes();

          const leftValue = (100 / 6) * (hours + minutes / 60 - 0.5);

          return (
            <>
              <Stop
                left={leftValue + '%'}
                time={
                  stop._order?.deliveryDate
                    ? window.dayjs(stop._order?.deliveryDate).format('HH:mm')
                    : '12:00'
                }
                type="blue"
                arriveDuration="20min"
                isDelayed={false}
                delayEarlyNutation="symbol"
              />
              {/* <StopWrapper>
                  <img src={Settings} style={{ width: 10 }} alt="" />
                </StopWrapper> */}
              {/* <StopWrapper>
                  <img src={Station} style={{ width: 10 }} alt="" />
                </StopWrapper> */}
            </>
          );
        })}
        <>
          <Stop
            left={13 + '%'}
            time="asdsa"
            type="blue"
            arriveDuration="20min"
            isDelayed={false}
            delayEarlyNutation="symbol"
          />
          <StopWrapper>
            <img src={Settings} style={{ width: 10 }} alt="" />
          </StopWrapper>
          <StopWrapper>
            <img src={Station} style={{ width: 10 }} alt="" />
          </StopWrapper>
        </>

        <Stop
          left="13.88%"
          time="1:50"
          type="blue"
          arriveDuration="1:33min"
          isDelayed={true}
          delayEarlyNutation="symbol"
        />

        <TimelineDistance left="calc(33% + 12px)" width="calc(14% - 12px)">
          1.3km/3.2km
        </TimelineDistance>
        <Stop
          left="47%"
          time="3:28"
          type="red"
          isDelayed={false}
          delayEarlyNutation="symbol"
        />
        <TimelineDistance left="calc(47% + 12px)" width="calc(8% - 12px)">
          3.2km
        </TimelineDistance>
        <StopWrapper>
          <img src={StationDisabled} style={{ width: 10 }} alt="" />
        </StopWrapper>
        <TimelineDistance left="calc(55% + 22px)" width="calc(16% - 22px)">
          0.4km
        </TimelineDistance>
        <Stop
          left="71%"
          time="3:15"
          type="grey"
          arriveDuration="20min"
          isDelayed={true}
          delayEarlyNutation="word"
        />
        <TimelineDistance left="calc(71% + 12px)" width="calc(14% - 12px)">
          3.2km
        </TimelineDistance>
        <Stop left="85%" time="3:15" type="grey" />
        <TimelineDistance left="calc(85% + 12px)" width="calc(15% - 75px)">
          4.1km
        </TimelineDistance>
        <KeyPoint>Key point</KeyPoint>
      </TimelineWrapper>
    ),
  });
  return rowData;
};
