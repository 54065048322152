import React from 'react';
import styled, { CSSObject } from 'styled-components';
import { themes } from 'styles/theme/themes';
import { ButtonBase, Tooltip } from '@mui/material';
import { Icon } from 'app/components/Icon';
import { CaretDown } from '@phosphor-icons/react';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';

interface Props {
  icon: JSX.Element;
  iconColor?: string;
  iconStyles?: CSSObject;
  size?: number;
  tooltip?: string;
  isDropdown?: boolean;
  customDropdownIcon?: JSX.Element;
  baseSize?: number;
  square?: boolean;
  onClick: (e: React.MouseEvent) => void;
  active?: boolean;
}

export const CustomIconButton: React.FC<Props> = ({
  icon,
  iconColor,
  iconStyles,
  size = 24,
  tooltip = '',
  isDropdown = false,
  customDropdownIcon,
  baseSize,
  square = false,
  onClick,
  active = false,
}) => {
  const theme = useSelector(selectTheme);
  const iconSize = baseSize || size + 8;
  const iconColorToUse = active
    ? theme.primary
    : iconColor || themes?.default?.iconColor;

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    onClick(e);
  };

  return (
    <Tooltip title={tooltip}>
      <StyledButtonBase
        size={iconSize}
        square={square}
        active={active}
        backgroundColor={theme.lightIconButton}
        onClick={handleClick}
        noHoverEffect={!!iconColor}
      >
        {isDropdown ? (
          <DropdownIcons>
            <StyledIcon
              icon={icon}
              color={iconColorToUse}
              size={size}
              iconStyles={iconStyles}
            />
            <StyledIcon
              icon={customDropdownIcon || <CaretDown weight="fill" />}
              color={iconColorToUse}
              size={Math.floor(size / 1.6)}
            />
          </DropdownIcons>
        ) : (
          <StyledIcon
            icon={icon}
            color={iconColorToUse}
            size={size}
            iconStyles={iconStyles}
          />
        )}
      </StyledButtonBase>
    </Tooltip>
  );
};

const StyledButtonBase = styled(ButtonBase)<{
  size: number;
  square?: boolean;
  active?: boolean;
  backgroundColor?: string;
  noHoverEffect?: boolean;
}>`
  height: ${(props) => props.size}px;
  width: ${(props) => props.size + 4}px;
  padding: 8px;
  border-radius: ${(props) => (props.square ? '0' : '6px')} !important;
  background: ${(props) =>
    props.active && props.backgroundColor
      ? props.backgroundColor
      : ''} !important;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86);

  &:hover {
    background: ${(props) =>
      props.noHoverEffect ? '' : themes?.default?.hoverLightGray};
  }
`;

const DropdownIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2px;
`;

const StyledIcon = styled(Icon)<{ iconStyles?: CSSObject }>`
  ${(props) => props.iconStyles && props.iconStyles}
`;
