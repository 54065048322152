import { AlertColor } from '@mui/material';
import { LoadingPulse } from 'app/components/LoadingPulse';
import { appActions } from 'common/store/app';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
export const useToaster = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const toast = useCallback(
    (
      duration = 3000,
      severity: AlertColor,
      message: string,
      vars?: any,
      loader?: boolean,
      title?: string,
    ) => {
      const intlMsg = vars ? t(message, vars) : t(message);
      const content = loader ? (
        <LoaderContainer>
          <LoadingText>{intlMsg}</LoadingText>
          <LoadingPulse />
        </LoaderContainer>
      ) : (
        <Wrapper>
          {!!title && <strong>{title}</strong>}
          {!!intlMsg && intlMsg}
        </Wrapper>
      );

      dispatch(
        appActions.setLastToasterNotification({
          duration: duration,
          severity: severity,
          message: intlMsg,
          content: content,
        }),
      );
    },
    [dispatch, t],
  );

  return toast;
};

const LoaderContainer = styled.div`
  padding-left: 10px;
  display: flex;
  width: 200px;
  justify-content: space-between;
  align-items: center;
`;
const LoadingText = styled.div`
  width: 75%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
