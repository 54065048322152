import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Data } from 'interfaces/roundTrips';
import { useNavigate, useSearchParams } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Drawer } from '@mui/material';
import AddRoundTripEmpty from 'assets/img/roundTrips/add-roundtrips-empty-state.svg';
import { TableNavWrapper } from 'styles/components/roundTrips';
import {
  EXCEL,
  GRID,
  MAP,
  REOPTIMIZE,
  TRACK,
} from 'app/components/TableComponent/constants';
import { useSelectedTable } from 'hooks/useSelectedTable';
import { useDispatch } from 'react-redux';
import roundtripApis, {
  useAddStopRoundtripMutation,
  useLazyGetRoundTripDetailsQuery,
  usePostInitMutation,
  useRoundtripUpdateStatusMutation,
  useUpdateRoundTripMutation,
  useUpdateRoundTripsClusterMutation,
  useLazyGetInitOrderQuery,
  usePostTemplateMutation,
  useDeleteClusterMutation,
  useImportFileValidateMutation,
  useFileImportMutation,
} from 'common/services/roundtripApi';
import { stateHeadCellsConst } from '../RoundTrips/data/stateHeadCellsConst';
import { NoData } from '../RoundTrips/components/NoData';
import { EnhancedTableToolbar } from 'app/components/EnhancedTableToolbar';
import { TableComponent } from './components/TableComponent';
import { HeadCell, excelHeadCellsConst } from './data/excelHeadCellsConst';
import { useFormatAddRoundTripsData } from 'hooks/RoundTrips/useFormatAddRoundTripsData';
import { toolsActions } from 'app/slices/tools';
import { AddRoundtrip } from './components/AddRoundtrip';
import { RightBar } from './components/RightBar';
import {
  selectConfiguration,
  selectOrganization,
} from 'common/store/organization/selectors';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { useSubscribeObject } from 'common/hooks/ws';
import {
  addFuction,
  functionRoundTripStatus,
  schedule,
} from 'app/components/RoundTrips/function/ApiFunctions';
import { roundtripActions } from 'common/store/roundtrips';
import { useToaster } from 'hooks/useToaster';
import { useUsersData } from './hooks/useUsersData';
import { disabledVehicle } from 'app/components/RoundTrips/function';
import { useSetHeads } from './hooks/useSetHeads';
import Dialogs from './components/Dialogs';
import { DASHBOARD } from 'utils/routes';
import { SwipeableDrowerOrder } from './components/SwipeableDrowerOrder';
import { RightBarWrapper, TableLegendWrapper, TableWrapper } from './style';
import { LegendComponent } from './components/LegendComponent';
import { TableNav } from './components/TableNav';
import {
  handleCloseOrderDrawer,
  handleLogoClick,
  updateRoundtripFuction,
} from './functions';
import MapPopover from 'app/components/MapPopover';
import {
  Sales,
  Driver,
  Ripper,
} from 'app/components/RoundTrips/RoundTripData/statuses';
import { useGetRoundTripsClusterQuery } from 'common/services/roundtripApi';
import { useDebounce } from 'common/hooks/useDebounce';
import If from 'app/components/If';
import { toQueryParams, useQueryParams } from 'hooks/useQueryParams';
import { getOrderFilterCount, toggleHeadCellsValue } from 'app/helpers/helpers';
import { filterRoundtripBySearch } from 'app/helpers/roundtripHelper';
import { useUpdateOrderStatusBulkMutation } from 'common/services/orderApi';
import { updateRoundtripsCache } from './data/RoundTripData/cacheUpdate';
import { addObjectsToStops } from './components/RightBar/function';
import { NoDataComponents } from 'app/components/NoData/NoDataComponents';
import { EmptyCenter } from '../Customers';
import { selectLastToasterNotification } from 'common/store/app/selectors';
import { EditCustomer } from '../Customers/components/EditCustomer';
import { useLoadingAlert } from 'app/components/LoadingAlert/hooks';
import { Map } from '../RoundTrips/components/Map';
import {
  usePrintV2 as usePrint,
  usePrint as PrintCluster,
} from 'hooks/RoundTrips/usePrint';
import { RoundtripDrawer } from '../Dashboard/components/RoundtripList/components/RoundtripDrawer';
import { AgentCategories } from 'app/components/RoundTrips/RoundTripData/agentCategories';
import { Metrics } from './components/Metrics';
import { useRtFilterState } from 'app/components/FilterComponents/hooks/useRtFilterState';
import { useOrderFilterState } from 'app/components/FilterComponents/hooks/useOrderFilterState';
import { AddIconButton } from 'app/components/AddIconButton';
import { ActionsMenu } from './components/ActionsMenu';
import { ComponentsStack } from 'app/components/EnhancedTableToolbar/components/ComponentsStack';
import { OnSelectActions } from './components/OnSelectActions';
import { OnMapSelectActions } from './components/OnMapSelectActions';
import { useModal } from 'app/components/Dialog/hooks';
import cloneDeep from 'lodash/cloneDeep';
import api from 'common/services/api';
import FilterIcon from 'app/components/FilterComponents/components/FilterIcon';
import { StopsFilter } from 'app/pages/AddRoundTrips/components/TableComponent/components/StopsFilter';
import { OrdersFilter } from 'app/pages/AddRoundTrips/components/TableComponent/components/OrdersFilter';
import { NoResults } from 'app/components/NoData/NoResults';
import { createContext } from 'react';
import AddByFileForm from 'app/components/AddByFile/AddByFileForm';
import { updateFormData } from 'utils/upload/updateFormFileType';
import { useAbility } from 'hooks/Abilities';
import { APIProvider } from '@vis.gl/react-google-maps';
import { ENV } from 'common/constants';
import { useDisabledToolbar } from 'app/components/EnhancedTableToolbar/hooks/useDisabledToolbar';
import { RoundtripStatus } from '../../../common/utils/enum';

export const OrderDrawerContext_rt = createContext<any>(undefined);

export function AddRoundTrips() {
  // hooks
  const ability = useAbility();
  const { showLoading, hideLoading } = useLoadingAlert();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const toast = useToaster();
  const [roundTripStatus] = useRoundtripUpdateStatusMutation();
  const [triggerRoundtripDetails, { data: roundtripDetails }] =
    useLazyGetRoundTripDetailsQuery();
  const dispatch = useDispatch();
  const [postInit] = usePostInitMutation();
  const configuration: any = useSelector(selectConfiguration);
  const [updateRoundtripCluster] = useUpdateRoundTripsClusterMutation();
  const [addStopRoundtrip] = useAddStopRoundtripMutation();

  // states
  const [roundtripLoading, setRoundtripLoading] = useState<string>('');
  const [searchParams] = useSearchParams();
  const warehouse = JSON.parse(searchParams.get('warehouse') as string);
  const dateValues = JSON.parse(
    searchParams.get('dateValue') as string,
  ) as Dayjs;

  const cluster = JSON.parse(searchParams.get('cluster') as string);
  const dateValue = dayjs(dateValues);
  const paramUserContext =
    searchParams.get('userContext') === 'sales'
      ? AgentCategories.Sales
      : AgentCategories.Logistic;
  const [userContext, setUserContext] = useState<AgentCategories>(
    paramUserContext || AgentCategories.Logistic,
  );

  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openSchedule, setOpenSchedule] = useState(false);
  const [openUnSchedule, setOpenUnSchedule] = useState(false);

  const [checked, setChecked] = useState<any>([]);
  const [expanded, setExpanded] = useState<{}>({});
  const [isDragged, setIsDragged] = useState<boolean>(false);
  const [dropableData, setDropableData] = useState({});
  const [stopsAccordion, setStopsAccordion] = useState(false);
  const [deletedOrdersIds, setDeletedOrdersIds] = useState<string[]>([]);
  const [sort, setSort] = useState('code');
  const [direction, setDirection] = useState('');
  const [accordionStatus, setAccordionStatus] = useState<
    { key: string; value: boolean[] }[]
  >([{ key: '', value: [] }]);

  const [rows, setRows] = useState<
    {
      key: string;
      data: { data: Data; accordion?: JSX.Element }[];
    }[]
  >([]);

  const today = dayjs().format('YYYY-MM-DD');
  const [dateBegin, setDateBegin] = useState<string>(today);
  const [dateEnd, setDateEnd] = useState<string>(today);
  const [selectedStop, setSelectedStop] = useState<null | any>(null);
  const [selectedOrder, setSelectedOrder] = useState<null | any>(null);
  const [selectedCustomer, setSelectedCustomer] = useState<null | any>(null);
  const warehouseData = warehouse;
  const [countOpenAdd, setCountOpenAdd] = useState(0);
  const [roundTripCopy, setRoundTripCopy] = useState<any[]>([]);
  const [headCells, setHeadCells] = useState<any[]>(excelHeadCellsConst);
  const [type, setType] = useState<
    typeof GRID | typeof TRACK | typeof EXCEL | typeof MAP | typeof REOPTIMIZE
  >(MAP);
  const [heads, setHeads] = useState<HeadCell[]>([]);
  const [search, setSearch] = useState<any>(null);
  const [users, setUsers] = useState<any[]>([]);
  const [vehicles, setVehicles] = useState<any[]>([]);
  const [selected, setSelected] = useSelectedTable(
    rows,
    type === GRID || type === TRACK || type === EXCEL,
  );
  const [roundTripCode, setRoundTripCode] = useState<string[]>([]);

  const [clusterId, setClusterId] = useState<string>(cluster);

  useEffect(() => {
    if (cluster) {
      setClusterId(cluster);
    }
  }, [cluster]);

  const [orders, setOrders] = useState<any>(null);
  const [anchorElActivate, setAnchorElActivate] = useState<boolean>(false);
  const [assignStopsMode, setAssignStopsMode] = useState<boolean>(false);
  const [stopsToAssign, setStopsToAssign] = useState<any[]>([]);
  const [fullStopsToAssign, setFullStopsToAssign] = useState<any[]>([]);
  const [anchorElManual, setAnchorElManual] = useState<null | HTMLElement>(
    null,
  );
  const [filterStatus, setFilterStatus] = useState<string | null>(null);

  const [updateRoundtripData] = useUpdateRoundTripMutation();
  const [
    getInitOrder,
    {
      data: initOrdersData,
      isFetching: isFetchingInitOrders,
      isLoading: isLoadingInitOrders,
    },
  ] = useLazyGetInitOrderQuery();

  useEffect(() => {
    if (initOrdersData?.docs) {
      setDataOrdersItems(initOrdersData?.docs);
    }
  }, [initOrdersData?.docs, isFetchingInitOrders]);

  const [updateOrders] = useUpdateOrderStatusBulkMutation();

  const [importValidate] = useImportFileValidateMutation();
  const [importRoundtrip] = useFileImportMutation();

  const [orderInitCount, setOrderInitCount] = useState<number>(0);

  const [roundtripEdit, setRoundtripEdit] = useState<any>({});
  const [data, setData] = useState<any>([]);

  const [displayNoteModal, setDisplayNoteModal] = useState({
    isVisible: false,
    roundtripId: null,
    stopId: null,
  });
  const [usersData, setUsersData] = useState(users);
  const [vehiculeData, setVehiculeData] = useState<any[]>(vehicles);
  const [driverAgentId, setDriverAgentId] = useState<string>('');
  const [ripperAgentId, setRipperAgentId] = useState<string>('');
  const [salesAgentId, setSalesAgentId] = useState<string>('');

  const [orderNext, setOrderNext] = useState<boolean>(false);

  const [nextPageOrder, setNextPageOrder] = useState<number>(1);

  const [dataOrdersItems, setDataOrdersItems] = useState<any>(null);
  const [mapCoordinates, setMapCoordinates] = useState<null | any>(null);
  const [truckCoordinates, setTruckCoordinates] = useState<null | any>(null);
  const [searchRightBar, setSearchRightBar] = useState<any>('');
  const [filterRightBar, setFilterRightBar] = useState<{
    begin?: any;
    end?: any;
    priority: { value: string; label: string }[];
    status: { value: string; label: string }[];
    deliveryType: { value: string; label: string }[];
    truckType: { value: string; label: string }[];
    quantity: any;
    lines: any;
  }>({
    begin: null,
    end: null,
    priority: [],
    truckType: [],
    status: [],
    deliveryType: [],
    quantity: {
      maxQuantity: undefined,
      minQuantity: undefined,
    },
    lines: {
      maxProducts: undefined,
      minProducts: undefined,
    },
  });
  const [sortingRightbar, setSortingRightbar] = useState<{
    value: string;
    direction: string;
  }>({ value: 'deliveryDate', direction: '' });
  const [isTruckMap, setIsTruckMap] = useState<boolean>(false);
  const [vehicle, setVehicle] = useState<any>();

  const [roundtripMapId, setRoundtripMapId] = useState(null);

  const mode = searchParams.get('mode') ? searchParams.get('mode') : null;
  const emptyApi = !!searchParams.get('emptyApi');
  const resultNbr = searchParams.get('resultNbr');

  const lastMessage = useSubscribeObject('roundtrip-cluster', clusterId);
  const currentDate = dayjs();
  const formattedcurrentDate = currentDate.format('YYYY-MM-DD');
  const formattedClusterDate = dayjs(dateValue).format('YYYY-MM-DD');

  const [isDeleted, setIsDeleted] = useState(false);

  const subscribedDate = dateValue?.format('YYYYMMDD');
  const wsDateMessage = useSubscribeObject(
    'roundtrip-cluster-date',
    subscribedDate,
  );

  const lastToasterNotification: any = useSelector(
    selectLastToasterNotification,
  );

  const [defaultTab, setDefaultTab] = useState<number>(0);

  const [filters, setFilters] = useState<{
    status: { value: string; label: string }[];
    warehouse: { value: string; label: string }[];
    type: { value: string; label: string }[];
    weight: { minWeight: any; maxWeight: any };
    noVehicle?: boolean | string;
    noDriver?: boolean | string;
    noRipper?: boolean | string;
    noStops?: boolean | string;
  }>({
    status: [],
    warehouse: [],
    type: [],
    weight: {
      minWeight: 0,
      maxWeight: null,
    },
    noVehicle: '',
    noDriver: '',
    noRipper: '',
    noStops: '',
  });

  const [orderFilters, setOrderFilters] = useState<{
    assigned: { value: string; label: string }[];
    priority: { value: string; label: string }[];
    orderStatus: { value: string; label: string }[];
    clientStatus: { value: string; label: string }[];
    deliveryType: { value: string; label: string }[];
    truckType: { value: string; label: string }[];
  }>({
    assigned: [],
    priority: [],
    orderStatus: [],
    clientStatus: [],
    deliveryType: [],
    truckType: [],
  });

  const debouncedRtFilters = useDebounce(filters, 500);
  const debouncedOrderFilters = useDebounce(orderFilters, 500);

  const commonQueryParams = {
    begin: dateBegin,
    end: dateBegin,
    sort: `${direction}${sort}`,
    device: 'web',
  };

  const rtQueryParams = useQueryParams({
    ...commonQueryParams,
    status: debouncedRtFilters?.status
      ?.map((filter) => {
        return filter.value;
      })
      ?.join(','),
    assigned: debouncedOrderFilters?.assigned
      ?.map((filter) => {
        return filter.value;
      })
      ?.join(','),
    weight: debouncedRtFilters?.weight.maxWeight
      ? `${debouncedRtFilters?.weight.minWeight},${debouncedRtFilters?.weight.maxWeight}`
      : '',
    noVehicle: debouncedRtFilters?.noVehicle,
    noDriver: debouncedRtFilters?.noDriver,
    noRipper: debouncedRtFilters?.noRipper,
    noStops: debouncedRtFilters?.noStops,
  });

  const {
    data: roundTripData,
    isLoading,
    refetch,
  } = useGetRoundTripsClusterQuery(`/${clusterId}?${rtQueryParams}`, {
    skip: !clusterId,
  });

  const [isBefore, setisBefore] = useState<boolean>(
    formattedClusterDate < formattedcurrentDate,
  );

  const [unfinishedRoundtrips, setUnfinishedRoundtrips] = useState<any[]>([]);
  useEffect(() => {
    if (roundTripData?.roundtrips) {
      const unfinished = roundTripData?.roundtrips.filter((rt) => {
        return rt?.status !== RoundtripStatus.PARKED;
      });
      setUnfinishedRoundtrips(unfinished);
    }
  }, [roundTripData?.roundtrips, roundTripData?.roundtrips?.length]);

  const {
    filterOn,
    handleCloseFilter,
    handleOpenFilter,
    filtersCount,
    debouncedFiltersCount,
  } = useRtFilterState({ filters });

  const {
    filterOn: orderFilterOn,
    handleCloseFilter: handleCloseOrderFilter,
    handleOpenFilter: handleOpenOrderFilter,
    filtersCount: orderFiltersCount,
  } = useOrderFilterState({ filters: orderFilters });

  const [maxSliderWeight, setMaxSliderWeight] = useState(0);

  const maxWeight = useMemo(() => {
    var currentMax = 0;
    roundTripData?.roundtrips?.map((rt) => {
      const weight = rt?.cache?.stats?.totalWeight;
      if (weight && weight > currentMax) {
        currentMax = weight;
      }
    });

    return Math.ceil(currentMax);
  }, [roundTripData]);

  useEffect(() => {
    if (filtersCount === 0) setMaxSliderWeight(maxWeight);
  }, [maxWeight]);

  const { triggerPrint } = usePrint();

  const printAllDeliveryNotes = () => {
    const roundtripIds = roundTripCopy.map((r) => r._id);
    const query = toQueryParams({
      format: 'pdf',
      roundtripIds: roundtripIds.join(','),
    });
    triggerPrint({ uri: `roundtrips/generate-delivery-notes${query}` });
  };

  const printDeliveryNote = () => {
    const roundtripIds = selected?.[warehouseData?.name];
    const query = toQueryParams({
      format: 'pdf',
      roundtripIds: roundtripIds.join(','),
    });
    triggerPrint({ uri: `roundtrips/generate-delivery-notes${query}` });
  };

  const printAllPreparationNote = () => {
    const roundtripIds = roundTripCopy.map((r) => r._id);
    const query = toQueryParams({
      format: 'pdf',
      roundtripIds: roundtripIds.join(','),
    });
    triggerPrint({ uri: `roundtrips/generate-preparation-notes${query}` });
  };

  const printPreparationNote = () => {
    const roundtripIds = selected?.[warehouseData?.name];
    const query = toQueryParams({
      format: 'pdf',
      roundtripIds: roundtripIds.join(','),
    });
    triggerPrint({ uri: `roundtrips/generate-preparation-notes${query}` });
  };

  const { triggerPrint: triggerClusterPrint } = PrintCluster();

  const printRoadmap = () => {
    const roundtripIds = selected?.[warehouseData?.name];
    triggerClusterPrint({ clusterId, roundtripId: roundtripIds.join(',') });
  };

  const orderChangeStatus = useCallback(
    async (status: string) => {
      try {
        if (selectedOrder) {
          await updateOrders({
            orderIds: [selectedOrder.id],
            status,
            queryParams: `/${clusterId}?begin=${dateBegin}&end=${dateBegin}&sort=${direction}${sort}&device=web`,
          }).unwrap();
          toast(3000, 'success', 'order_status_success');
        }
      } catch (e: any) {
        toast(3000, 'error', 'order_status_error');
      }
    },
    [selectedOrder],
  );

  const displayNotes = useCallback(
    (roundtripId, stopId) => {
      setDisplayNoteModal({
        isVisible: true,
        roundtripId,
        stopId,
      });
    },
    [displayNoteModal],
  );
  // functions

  const updateRoundtripStatus = useCallback(
    async (status, id, roundTripStatus) => {
      await functionRoundTripStatus(
        status,
        id,
        roundTripStatus,
        dispatch,
        t,
        `/${clusterId}?begin=${dateBegin}&end=${dateBegin}&sort=${direction}${sort}&device=web`,
      );
    },
    [],
  );

  const updateFuction = async (data_update: any) => {
    const resp = await updateRoundtripData(data_update).unwrap();
    return resp;
  };
  const requestAddRoundTripData = async (addingOrders = true) => {
    if (addingOrders) {
      requestOrderData(1);
    }

    try {
      if (dateBegin !== '') {
        const resp = await postInit({
          begin: dateBegin,
          end: dateEnd,
          clusterId: clusterId,
          agentCategory: userContext,
          _deck: warehouseData?._id,
        }).unwrap();
        if (resp) {
          if (addingOrders) {
            toast(5000, 'success', 'alerts.orders.bulk_add_success');
          }
          setUsers(resp.agents);
          setVehicles(resp.vehicles);
        }
      }
    } catch (e: any) {
      console.log('e', e);
      if (e.status === 401) {
        console.log('e:401', e);
      }
    }
  };

  const org: any = useSelector(selectOrganization);
  const lastUserMessage = useSubscribeObject('update-user', org._id);
  useEffect(() => {
    requestAddRoundTripData(false);
  }, [lastUserMessage]);

  useUsersData(usersData, roundTripCopy, users, data, setUsersData);
  useEffect(() => {
    disabledVehicle(
      vehiculeData,
      vehicles,
      setVehiculeData,
      data,
      roundTripCopy,
    );
  }, [vehicles, data, roundTripCopy]);

  const handleClickActivate = useCallback(() => {
    setAnchorElActivate(true);
    setCountOpenAdd((countOpenold) => countOpenold + 1);
  }, [anchorElActivate, countOpenAdd]);

  useEffect(() => {
    if (!anchorElActivate) {
      setAssignStopsMode(false);
    }
  }, [anchorElActivate]);

  const addOrders = async (id, data, roundTrip, index = 0) => {
    try {
      showLoading({
        message: checked?.length > 1 ? 'adding_orders' : 'adding_order',
      });
      const resp = await addStopRoundtrip({
        id: id,
        data: { stop: data, index: index },
        pageRows: `/${clusterId}?begin=${dateBegin}&end=${dateBegin}&sort=${direction}${sort}&device=web`,
      }).unwrap();
      if (resp) {
        hideLoading();
        if (checked?.length > 1) {
          toast(5000, 'success', 'alerts.orders.bulk_add_success');
        } else {
          toast(5000, 'success', 'alerts.orders.add_success');
        }
      }
    } catch (e: any) {
      await getRoundtripDetails(
        roundTrip?.id,
        roundTrip?.cache?.stats?.stops && !roundTrip?.stops?.length,
      );
    } finally {
      hideLoading();
    }
  };

  const debouncedSearchTerm = useDebounce(searchRightBar, 500);
  const debouncedFilters = useDebounce(filterRightBar, 1000);
  const debouncedSorting = useDebounce(sortingRightbar, 500);
  const debouncedOrdersFilters = useDebounce(orderFilters, 500);

  const countRightBarFilter = getOrderFilterCount(filterRightBar);

  const queryParams = useQueryParams({
    // TODO: this is temporary for the filters bugs, infinit scroll should be fixed later
    // limit: type === MAP ? 100 : 10,
    limit: 100,
    _deck: warehouseData?._id,
    clusterId,
    text: debouncedSearchTerm,
    priority:
      type === MAP
        ? debouncedOrdersFilters?.priority
          ? debouncedOrdersFilters?.priority
              ?.map((filter) => {
                return filter.value;
              })
              ?.join(',')
          : ''
        : debouncedFilters?.priority
            ?.map((filter) => {
              return filter.value;
            })
            ?.join(','),
    status:
      type === MAP
        ? debouncedOrdersFilters?.orderStatus
          ? debouncedOrdersFilters?.orderStatus
              ?.map((filter) => {
                return filter.value;
              })
              ?.join(',')
          : ''
        : debouncedFilters?.status
            ?.map((filter) => {
              return filter.value;
            })
            ?.join(','),
    type:
      type === MAP
        ? debouncedOrdersFilters?.deliveryType
          ? debouncedOrdersFilters?.deliveryType
              ?.map((filter) => {
                return filter.value;
              })
              ?.join(',')
          : ''
        : debouncedFilters?.deliveryType
            ?.map((filter) => {
              return filter.value;
            })
            ?.join(','),
    vehicleCondition:
      type === MAP
        ? debouncedOrdersFilters?.truckType
          ? debouncedOrdersFilters?.truckType
              ?.map((filter) => {
                return filter.value;
              })
              ?.join(',')
          : ''
        : debouncedFilters?.truckType
            ?.map((filter) => {
              return filter.value;
            })
            ?.join(','),
    sort: `${debouncedSorting.direction}${debouncedSorting.value}`,
    application: 'bianta',
  });

  const abortControllerRef = useRef<AbortController | null>(null);

  const updateRoundtripAfterCustomerUpdate = useCallback(
    async (customerId) => {
      const rd = roundTripCopy.find((r) =>
        r.stops.some((s) => s._order?._customer?._id === customerId),
      );

      await getRoundtripDetails(rd._id, false);
    },
    [roundTripCopy],
  );

  const requestOrderData = useCallback(
    async (page = 1) => {
      if (
        abortControllerRef.current &&
        dateBegin !== '' &&
        isFetchingInitOrders
      ) {
        abortControllerRef.current.abort();
      } else {
        abortControllerRef.current = null;
      }
      const abortController = new AbortController();
      if (isFetchingInitOrders) {
        abortControllerRef.current = abortController;
      }

      try {
        if (dateBegin !== '' && clusterId) {
          dispatch(api.util.invalidateTags(['OrderFacets']));
          await getInitOrder({
            query: `${queryParams}&page=${page}&agentCategory=${userContext}`,
          }).unwrap();
        }
      } catch (e: any) {
        console.log('e', e);
        if (e.status === 401) {
          console.log('e:401', e);
        }
      } finally {
      }
    },
    [
      abortControllerRef,
      dateBegin,
      clusterId,
      isFetchingInitOrders,
      queryParams,
      userContext,
    ],
  );

  const handleInitOrdersData = useCallback(() => {
    if (initOrdersData && !isFetchingInitOrders) {
      setOrders((prev) => {
        if (!prev) {
          return initOrdersData.docs;
        }
        if (isFetchingInitOrders) return prev;
        const existingIds = new Set(prev?.map((o) => o._id));

        // filter out orders already existing in the list
        const filteredNewOrders = initOrdersData.docs.filter((obj) => {
          return !existingIds.has(obj._id);
        });

        const mergedOrders = [...prev, ...filteredNewOrders];

        return mergedOrders;
      });
      setDataOrdersItems(initOrdersData.docs);
      setOrderNext(initOrdersData.hasNextPage);
      setOrderInitCount(initOrdersData.totalDocs);
      if (initOrdersData.hasNextPage) setNextPageOrder(initOrdersData.nextPage);
    }
  }, [initOrdersData, isFetchingInitOrders]);

  useEffect(() => {
    handleInitOrdersData();
  }, [handleInitOrdersData]);

  const handleUpdateOrdersArray = useCallback(() => {
    if (dataOrdersItems?.[0]) {
      setOrders((oldData) => {
        if (isFetchingInitOrders) return oldData;
        if (
          Object.keys(debouncedFilters).some(
            (key) => debouncedFilters[key]?.length > 0,
          ) ||
          !!debouncedSearchTerm
        )
          return initOrdersData?.docs;
        const newData = dataOrdersItems?.filter(
          (item) => !oldData?.some((oldItem) => oldItem.id === item.id),
        );
        if (!oldData?.[0]) {
          return [
            ...oldData.filter((order) => !deletedOrdersIds.includes(order._id)),
            ...newData,
          ];
          setDeletedOrdersIds([]);
        } else {
          return [...oldData];
        }
      });
    }
  }, [dataOrdersItems, handleInitOrdersData, debouncedOrdersFilters]);

  useEffect(() => {
    handleUpdateOrdersArray();
  }, [handleUpdateOrdersArray]);

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, [open]);
  const handleClickOpenDelete = useCallback(() => {
    setOpenDelete(true);
  }, [openDelete]);
  const handleClickOpenSchedule = useCallback(() => {
    setOpenSchedule(true);
  }, [openSchedule]);
  const handleClickOpenUnSchedule = useCallback(() => {
    setOpenUnSchedule(true);
  }, [openUnSchedule]);
  const onDisplayMap = useCallback(
    (data) => {
      setMapCoordinates(data?.stop);
      setTruckCoordinates(data?.truck);
      setIsTruckMap(false);
      setVehicle(data?.vehicle);
    },
    [mapCoordinates, truckCoordinates, isTruckMap, vehicle],
  );

  const onDisplayDrawerMap = useCallback(
    (roundripId) => {
      setRoundtripMapId(roundripId);
    },
    [roundtripMapId],
  );

  const requestRef = useRef<any>(null);
  const fetchRef = useRef<any>(null);

  const updateorderfunctionWrapper = useCallback(
    async (id, updateObj, updateMutation) => {
      try {
        requestRef.current?.abort();
        fetchRef.current?.abort();
        const request = updateMutation({ id, ...updateObj });
        requestRef.current = request;
      } catch (e) {}
    },
    [requestRef, fetchRef],
  );

  const updateRoundtripCache = (updatedRt) => {
    if (updatedRt && roundTripCopy) {
      //Update the roundtrips cache for fetched roundtrip
      updateRoundtripsCache(
        dispatch,
        roundtripApis,
        updatedRt,
        `/${clusterId}?begin=${dateBegin}&end=${dateBegin}&sort=${direction}${sort}&device=web`,
      );
      setRoundTripCopy((prevRoundTrips) => {
        const updatedRoundtrips = prevRoundTrips?.map((rt) => {
          if (rt._id === updatedRt._id) {
            return {
              ...rt,
              ...updatedRt,
            };
          }
          return rt;
        });
        return updatedRoundtrips;
      });
    }
  };

  const updateOrderCache = useCallback(
    async (id, roundtripId, updateObj) => {
      var requestedCapacity = 0;

      var newRoundtripSupport: any = {};

      const foundRoundtrip = roundTripCopy.find((rt) => {
        return rt._id === roundtripId;
      });

      var oldOrderSupportUnits: any[] = [];

      var oldRequestedCapacity = 0;

      foundRoundtrip?.supportUnitsList?.planned?.map((support) => {
        newRoundtripSupport[support?._supportUnit?._id] = support;
      });

      updateObj?.supportUnitsList?.map((support) => {
        requestedCapacity +=
          (support?.quantity || 0) * (support?._supportUnit?.unit || 1);
      });

      const updatedStops = foundRoundtrip?.stops?.map((stop) => {
        if (stop?._order?._id === id) {
          oldRequestedCapacity =
            stop._order?.supportUnitsRequestedCapacity || 0;
          oldOrderSupportUnits = stop._order?.supportUnitsList?.planned;
          return {
            ...stop,
            _order: {
              ...stop._order,
              supportUnitsRequestedCapacity: requestedCapacity,
              supportUnitsList: {
                ...stop?.supportUnitsList,
                planned: [...updateObj.supportUnitsList],
              },
            },
          };
        }
        return stop;
      });

      updateObj?.supportUnitsList?.map((support) => {
        const oldQuantity = oldOrderSupportUnits?.find((oldSupport) => {
          return support?._supportUnit?._id === oldSupport?._supportUnit?._id;
        })?.quantity;

        const existingSupportUnit =
          foundRoundtrip?.supportUnitsList?.planned?.find(
            (roundtripSupport) =>
              support?._supportUnit?._id ===
              roundtripSupport?._supportUnit?._id,
          );

        const newQuantity = Math.max(
          (existingSupportUnit?.quantity || 0) -
            (oldQuantity || 0) +
            (support?.quantity || 0),
          0,
        );

        newRoundtripSupport[support?._supportUnit?._id] = {
          ...support,
          quantity: newQuantity,
        };
      });

      const supportUnitsRequestedCapacity = Math.max(
        (foundRoundtrip?.supportUnitsRequestedCapacity || 0) -
          (oldRequestedCapacity || 0) +
          (requestedCapacity || 0),
        0,
      );

      //remove old supportunits
      const oldSupportIds = Object.keys(newRoundtripSupport) || [];
      oldSupportIds?.map((id) => {
        if (
          !updatedStops?.find((stop) =>
            stop._order?.supportUnitsList?.planned?.find(
              (support) => id === support._supportUnit?._id,
            ),
          )
        ) {
          delete newRoundtripSupport?.[id];
        }
      });

      const updatedRoundtrip = {
        ...foundRoundtrip,
        supportUnitsRequestedCapacity: supportUnitsRequestedCapacity,
        stops: updatedStops,
        supportUnitsList: {
          ...foundRoundtrip?.supportUnitsList,
          planned: Object.values(newRoundtripSupport),
        },
      };

      updateRoundtripCache(updatedRoundtrip);
    },
    [roundTripCopy, updateRoundtripCache],
  );

  //custom hooks
  useFormatAddRoundTripsData({
    setRoundtripEdit,
    isLoading,
    setRows,
    type,
    roundTripCopy,
    vehicles: vehiculeData,
    usersData,
    functionRoundTripStatus: updateRoundtripStatus,
    updateRoundtripData,
    updateFuction,
    updateOrderFunction: updateorderfunctionWrapper,
    handleClickOpen,
    handleClickOpenDelete,
    onDisplayNotes: displayNotes,
    onDisplayOrder: setSelectedOrder,
    onDisplayCustomer: setSelectedCustomer,
    driverAgentId,
    ripperAgentId,
    onDisplayMap,
    mapDisplayedStop: selectedStop,
    triggerRoundtripDetails,
    setSelectedStop,
    schedule: updateRoundtripCluster,
    clusterStatus: roundTripData?.status,
    queryParams: `/${clusterId}?begin=${dateBegin}&end=${dateBegin}&sort=${direction}${sort}&device=web`,
    isDragged,
    updateOrderCache,
    triggerRoundtripMap: onDisplayDrawerMap,
    salesAgentId,
    userContext: roundTripData?.agentCategory,
    setDefaultTab,
  });
  //useEffects
  useSetHeads(headCells, setHeads);

  const handleTableHeadCells = useMemo(() => {
    return () => {
      const userRole = userContext === AgentCategories.Sales ? Sales : Driver;

      toggleHeadCellsValue({
        headCells: [
          {
            id: Sales,
            disableRow: userRole !== Sales,
            canChangeView: userRole === Sales,
            hidden: userRole !== Sales,
          },
          {
            id: Driver,
            disableRow: userRole === Sales,
            canChangeView: userRole !== Sales,
            hidden: userRole === Sales,
          },
          {
            id: Ripper,
            canChangeView: userRole !== Sales,
            hidden: userRole === Sales,
          },
        ],
        setHeadCells,
      });
    };
  }, [roundTripData?.roundtrips, userContext, type]);

  useEffect(() => {
    handleTableHeadCells();
  }, [handleTableHeadCells]);

  useEffect(() => {
    dispatch(toolsActions.setZoomIn({ status: true }));
    dispatch(roundtripActions.setEditingRoundtripVehicle(false));

    //check if date isBefore onClick
    const handleFocus = () => {
      if (formattedClusterDate < dayjs().format('YYYY-MM-DD')) {
        setisBefore(true);
      }
    };

    window.addEventListener('click', handleFocus);

    return () => {
      window.removeEventListener('click', handleFocus);
    };
  }, []);

  // POST INIT
  useEffect(() => {
    if (dateBegin) {
      requestAddRoundTripData(false);
    }
  }, [dateBegin, clusterId]);

  useEffect(() => {
    // Save the state to localStorage whenever it changes.
    localStorage.setItem('accordionStatus', JSON.stringify(accordionStatus));
  }, [accordionStatus]);

  const handleSetLastMessage = useMemo(() => {
    return () => {
      const message = JSON.parse(lastMessage);
      const action = message?.data.action;
      if (action === 'roundtrip-deleted') {
        refetch();
      }
      if (action === 'roundtrip-cluster-deleted') {
        setIsDeleted(true);
      }
      if (
        [
          'update-order',
          'create-order',
          'roundtrip-stop-deleted',
          'roundtrip-update',
          'roundtrip-stops-add',
        ]?.includes(action)
      ) {
        requestOrderData(1);
        handleInitOrdersData();
      }
      if (message?.data?.roundtripId) {
        triggerRoundtripDetails({
          roundtripId: message?.data?.roundtripId,
        }).then((response) => {
          updateRoundtripCache(response?.data);
        });
      }
    };
  }, [lastMessage]);

  useEffect(() => {
    handleSetLastMessage();
  }, [handleSetLastMessage]);

  useEffect(() => {
    if (!clusterId || !wsDateMessage) {
      return;
    }
    refetch();
  }, [wsDateMessage]);

  const filteredRoundtrips = useMemo(() => {
    return roundTripData?.roundtrips
      ? filterRoundtripBySearch(roundTripData.roundtrips, search, t)
      : [];
  }, [roundTripData, search]);

  useEffect(() => {
    if (!roundtripDetails) return;
    refetch();
  }, [roundtripDetails]);

  const numSelected = useMemo(() => {
    return Object.values(selected)
      .filter(Array.isArray)
      .reduce((acc, curr) => acc + curr.length, 0);
  }, [selected]);

  const handleFilterRoundtrips = useMemo(() => {
    return () => {
      if (roundTripData?.deleted) {
        setIsDeleted(true);
      }
      if (roundTripData && roundTripData.roundtrips) {
        const updatedRts = filteredRoundtrips.map((rt) => {
          return { ...rt };
        });
        setRoundTripCopy(updatedRts);
      }
    };
  }, [roundTripData, filteredRoundtrips]);

  useEffect(() => {
    handleFilterRoundtrips();
  }, [handleFilterRoundtrips]);

  useEffect(() => {
    if (dateValue !== null) {
      setDateBegin(dateValue.format('YYYY-MM-DD'));
      setDateEnd(dateValue.format('YYYY-MM-DD'));
    }
  }, [dateValue]);

  useEffect(() => {
    requestOrderData(1);
  }, [
    debouncedSearchTerm,
    debouncedFilters,
    debouncedOrdersFilters,
    dateBegin,
    dateEnd,
    clusterId,
    type,
  ]);

  const handleConfiguration = useMemo(() => {
    return () => {
      const driverRole = configuration?.roles.find(
        (role) => role.key === Driver,
      );
      const driverRoleId = driverRole ? driverRole._id : '';
      setDriverAgentId(driverRoleId);
      const ripperRole = configuration?.roles.find(
        (role) => role.key === 'ripper',
      );
      const ripperRoleId = ripperRole ? ripperRole._id : '';
      setRipperAgentId(ripperRoleId);
      const salesRole = configuration?.roles.find((role) => role.key === Sales);
      const salesRoleId = salesRole ? salesRole._id : '';
      setSalesAgentId(salesRoleId);
      setRoundTripCode(configuration?.roundtripCodes);
    };
  }, [configuration]);

  useEffect(() => {
    handleConfiguration();
  }, [handleConfiguration]);

  //adding use effect on change of sort, to close all list of rountrips
  useEffect(() => {
    setAccordionStatus([{ key: '', value: [] }]);
  }, [sort, direction]);

  useEffect(() => {
    updateRoundtripCache(roundtripDetails);
  }, [roundtripDetails]);

  const getRoundtripDetails = async (
    roundtripId: string,
    withLoading?: boolean,
  ) => {
    if (withLoading) {
      setRoundtripLoading(roundtripId);
    }
    await triggerRoundtripDetails({ roundtripId });
    setRoundtripLoading('');
  };

  async function onDragEnd(result) {
    setIsDragged(false);
    if (!result.destination) {
      return;
    }
    const destinationIndex = result.destination.index || 0;
    if (dropableData !== null && result.draggableId) {
      const roundTrip = roundTripCopy.find((rt) => rt.id === dropableData);

      if (result.source.droppableId?.includes('droppable-stops')) {
        if (result.source.index === destinationIndex) {
          return;
        }
        const items = Array.from(roundTrip?.stops);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        const updatedRoundtrip = { ...roundTrip, stops: items };

        updateRoundtripCache(updatedRoundtrip);
        await updateFuction({
          id: roundTrip._id,
          data: { stops: items },
        });
        toast(5000, 'success', 'alerts.orders.reorder_success');
        return;
      }

      if (checked?.length === 0) {
        if (roundTrip) {
          const stop = roundTrip.stops;
          if (stop) {
            setChecked([]);
            const order = orders.find((o) => o.id === result.draggableId);

            const updatedOrders = orders.filter(
              (order) => order._id !== result.draggableId,
            );

            setDataOrdersItems(updatedOrders);
            setOrders(updatedOrders);
            setOrderInitCount(orderInitCount - 1);

            await addOrders(
              dropableData,
              {
                ...order,
                _order: order?.id,
                type: 'delivery',
                _place: order?._deck?._id,
              },
              roundTrip,
              destinationIndex,
            )
              .then(async () => {
                // remove orders that got assigned from right bar
                await requestOrderData(1);
                setOrders((prevData) => {
                  return prevData?.filter((data) => {
                    return data?.id !== order?.id;
                  });
                });
              })
              .catch(() => {
                return;
              });
          }
        }
      } else if (roundTrip) {
        const stop = roundTrip.stops;
        if (stop) {
          setChecked([]);
          const checkedOrders = orders.filter((order) =>
            checked.some((check) => check.id === order.id),
          );

          const checkedIds = checked.map((order) => order._id);

          const updatedOrders = orders.filter(
            (order) => !checkedIds.includes(order._id),
          );

          setDataOrdersItems(updatedOrders);
          setOrders(updatedOrders);

          setOrderInitCount(Math.max(orderInitCount - checked?.length || 0, 0));

          //changing many post requests to patch request for bulk assign to roundtrip
          var data = cloneDeep(addObjectsToStops(roundTrip, checkedOrders));
          data.agents.forEach((a) => (a.userkey = userContext));
          data.userkey = userContext;
          showLoading({
            message: checked?.length > 1 ? 'adding_orders' : 'adding_order',
          });
          await updateRoundtripFuction(
            {
              userkey: userContext,
              id: roundTrip.id,
              data,
            },
            updateRoundtripData,
            checked,
            toast,
          );
          hideLoading();
        }
      }
    }
  }
  const handleDragStart = () => {
    setIsDragged(true);
  };

  const displayTruckMap = useCallback(
    (data) => {
      setMapCoordinates(data.coordinates);
      setTruckCoordinates(null);
      setIsTruckMap(true);
      setSelectedStop(null);
      setVehicle(data);
    },
    [mapCoordinates, truckCoordinates, isTruckMap, selectedStop, vehicle],
  );

  const bulkStatusUpdate = useCallback(
    (status: string, selected) => {
      const roundtripIds = selected?.[warehouseData?.name];
      if (roundtripIds?.length) {
        roundTripStatus({
          status: status,
          roundtripIds: [...roundtripIds],
          pageRows: `/${clusterId}?begin=${dateBegin}&end=${dateBegin}&sort=${direction}${sort}&device=web`,
        }).unwrap();
      }
    },
    [selected],
  );

  const isToday = dateValue.isSame(dayjs(), 'day');

  useEffect(() => {
    if (
      lastToasterNotification?.severity === 'error' &&
      formattedClusterDate < dayjs().format('YYYY-MM-DD')
    ) {
      setisBefore(true);
    }
  }, [lastToasterNotification]);

  const [createTemplate] = usePostTemplateMutation();

  const handleSaveTemplate = useCallback(async (formData) => {
    try {
      await createTemplate({
        clusterId: clusterId,
        _deck: warehouseData?._id,
        name: formData.name,
        description: formData.note,
        weekDays: formData.days,
        agentCategory: userContext,
      }).unwrap();
      toast(3000, 'success', 'orders.template.status.saved');
    } catch (error) {
      console.log('Something went worng!');
    }
  }, []);

  useEffect(() => {
    if (roundTripData?.agentCategory) {
      setUserContext(roundTripData?.agentCategory);
    }
  }, [roundTripData?.agentCategory]);

  const { openModal, closeModal } = useModal();

  const [deleteCluster] = useDeleteClusterMutation();

  // TODO: replace these with a reusable function to display dialog
  const handleDeleteAction = useCallback(() => {
    deleteCluster({
      id: clusterId,
    })
      .then(() => {
        toast(3000, 'success', 'roundtrips.delete-bulk.success');
        closeModal();
        setIsDeleted(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [closeModal, clusterId, deleteCluster, toast]);

  const handleDeleteRoundtrips = useCallback(() => {
    openModal({
      title: t('clusters.delete.confirm', {
        cluster: resultNbr
          ? `Re:${resultNbr}`
          : roundTripData?._deck?.shortName,
      }),
      deleteModal: true,
      action: {
        actionCallback: handleDeleteAction,
        actionText: t('common.buttons.delete'),
      },
      cancel: true,
      content: t('cluster.delete.message'),
    });
  }, [
    handleDeleteAction,
    openModal,
    t,
    resultNbr,
    roundTripData?._deck?.shortName,
  ]);

  const updateByFile = () => {
    openModal({
      title: t('add-by-file.dialog.title'),
      content: (
        <AddByFileForm
          onValidate={async (props) => {
            props?.append('beginAt', dateBegin);
            props?.append('endAt', dateBegin);
            props?.append('role', AgentCategories.Logistic);
            props?.append('update', 'true');
            return await importValidate(props).unwrap();
          }}
          onImport={async (props) => {
            const formData = updateFormData(props);
            formData?.append('beginAt', dateBegin);
            formData?.append('endAt', dateBegin);
            formData?.append('role', AgentCategories.Logistic);
            props?.append('update', 'true');

            await importRoundtrip(formData).unwrap();
          }}
          module="order"
          sampleFile={{
            filename: 'roundtrip-sample.csv',
            href: '/csv/roundtrip-sample.csv',
          }}
          defaultWarehouse={warehouse}
          backAction={() => {
            closeModal();
          }}
        />
      ),
      cancel: true,
      action: {
        actionText: t('common.buttons.proceed'),
        actionCallback() {},
        buttonProps: { disabled: true },
      },
    });
  };

  const [assignedOrders, setAssignedOrders] = useState(0);

  const handleSetRoundtripsCount = useMemo(() => {
    return () => {
      if (roundTripData?.roundtrips) {
        var count = 0;
        roundTripData.roundtrips.map((rt) => {
          count += rt?.countOrders;
        });
        setAssignedOrders(count);
      } else {
        setAssignedOrders(0);
      }
    };
  }, [roundTripData]);

  useEffect(() => {
    handleSetRoundtripsCount();
  }, [handleSetRoundtripsCount]);

  const switchOrder = useCallback(
    (to, currentOrderId, currentRtId) => {
      const result = roundTripData?.roundtrips?.filter(
        (rt) => rt._id === currentRtId,
      );
      var orders = result[0]?.stops?.map((stop) => {
        if (stop?.type !== 'fuel' && stop?.type !== 'maintenance') {
          return stop?._order;
        }
      });
      orders = orders?.filter((order) => !!order);
      var currentOrderIndex: any = null;
      orders?.map((order, index) => {
        if (order?._id === currentOrderId) {
          currentOrderIndex = index;
        }
      });
      if (to === 'next') {
        if (currentOrderIndex === orders?.length - 1) return;
        setSelectedOrder(orders[currentOrderIndex + 1]);
      }
      if (to === 'prev') {
        if (currentOrderIndex === 0) return;
        setSelectedOrder(orders[currentOrderIndex - 1]);
      }
    },
    [roundTripData],
  );

  const onDisplayOrder = useCallback(
    (order) => setSelectedOrder(order),
    [selectedOrder],
  );
  const handleSetAccordionStatus = useCallback(
    (status) => setAccordionStatus(status),
    [accordionStatus],
  );
  const handleSetOrders = useCallback(
    (value) => {
      setOrders(value);
    },
    [orders],
  );
  const handleSetOrderInitCount = useCallback(
    (count) => setOrderInitCount(count),
    [orderInitCount],
  );
  const handleSetAnchorElActivate = useCallback(
    (e, assignStopsMode?: boolean) => {
      setAssignStopsMode(!!assignStopsMode);
      handleClickActivate();
    },
    [anchorElActivate],
  );

  const handleSetStopsToAssign = useCallback(
    (value) => {
      setStopsToAssign(value);
    },
    [setStopsToAssign],
  );
  const handleSetRoundtripEdit = useCallback(
    (value) => setRoundtripEdit(value),
    [roundtripEdit],
  );
  const handleSetOrderFilters = useCallback(
    (value) => setOrderFilters(value),
    [orderFilters],
  );
  const handleSetFilterStatus = useCallback(
    (value) => setFilterStatus(value),
    [filterStatus],
  );

  // This state is used to count times a new roundtrip
  // is created with selected stops assigned to it.
  // This count will be used to clear selection after
  // the succesful creation of the new roundtrip.
  const [clearSelectionCount, setClearSelectionCount] = useState(0);

  const handleClearSelection = () => {
    setClearSelectionCount((prevCount) => prevCount + 1);
  };

  const [disableNext, setDisableNext] = useState<boolean>(false);
  const [disablePrev, setDisablePrev] = useState<boolean>(false);
  const [loadingClusterStatusToggle, setloadingClusterStatusToggle] =
    useState<boolean>(false);

  const handleSelectOrderButtonsDisable = useMemo(() => {
    return () => {
      if (selectedOrder === null) return;

      var currentRt: any = null;
      var currentOrderIndex = null;
      roundTripData?.roundtrips?.map((rt) => {
        rt?.stops?.map((stop, index) => {
          if (stop?._order?._id === selectedOrder?._id) {
            currentRt = rt;
            currentOrderIndex = index;
          }
        });
      });

      if (currentRt !== null && currentOrderIndex !== null) {
        const stopsLength = currentRt?.stops?.length;

        if (currentOrderIndex === stopsLength - 1) setDisableNext(true);
        else setDisableNext(false);

        if (currentOrderIndex === 0) setDisablePrev(true);
        else setDisablePrev(false);
      } else {
        setDisablePrev(true);
        setDisableNext(true);
      }
    };
  }, [selectedOrder]);

  useEffect(() => {
    handleSelectOrderButtonsDisable();
  }, [handleSelectOrderButtonsDisable]);

  const { disabledToolbar } = useDisabledToolbar({
    total: roundTripData?.roundtrips?.length || 0,
    searchTerm: debouncedSearchTerm,
    filterCount: debouncedFiltersCount,
  });

  if (!ability.can('canedit', 'roundtrips')) {
    return null;
  }

  if (isLoading) {
    return <LoadingIndicator />;
  } else if (isDeleted) {
    return (
      <EmptyCenter>
        <NoDataComponents
          viewMode={true}
          text={t('cannot_update_old_roundtrips')}
          IconButton={null}
          textButton=""
        />
      </EmptyCenter>
    );
  } else {
    return (
      <OrderDrawerContext_rt.Provider
        value={{ switchOrder, disableNext, disablePrev }}
      >
        <DragDropContext onDragEnd={onDragEnd} onDragStart={handleDragStart}>
          <Dialogs
            open={open}
            setOpen={setOpen}
            addFuction={addFuction}
            roundtripEdit={roundtripEdit}
            updateRoundtripData={updateRoundtripData}
            setOpenSchedule={setOpenSchedule}
            schedule={schedule}
            clusterId={clusterId}
            updateRoundtripCluster={updateRoundtripCluster}
            dispatch={dispatch}
            roundtripActions={roundtripActions}
            setClusterId={setClusterId}
            openSchedule={openSchedule}
            dateValue={dateValue}
            setOpenUnSchedule={setOpenUnSchedule}
            openUnSchedule={openUnSchedule}
            displayNoteModal={displayNoteModal}
            setDisplayNoteModal={setDisplayNoteModal}
            userContext={userContext}
            setloadingClusterStatusToggle={setloadingClusterStatusToggle}
          />
          <Droppable droppableId="droppable" isDropDisabled={true}>
            {(provided) => (
              <TableNavWrapper
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <AddRoundtrip
                  loadingData={isLoading}
                  setData={setData}
                  data={data}
                  setClusterId={setClusterId}
                  roundTripData={roundTripCopy}
                  setAnchorElActivate={setAnchorElActivate}
                  anchorElActivate={anchorElActivate}
                  vehicles={vehicles}
                  users={users}
                  deck={warehouseData}
                  dateAdd={dateBegin}
                  roundTripCode={roundTripCode}
                  clusterId={clusterId}
                  mode={mode}
                  driverAgentId={driverAgentId}
                  emptyApi={emptyApi}
                  userContext={userContext}
                  salesAgentId={salesAgentId}
                  assignStopsMode={assignStopsMode}
                  stopsToAssign={stopsToAssign}
                  setClearSelectionCount={setClearSelectionCount}
                />
                <TableNav
                  handleLogoClick={() => {
                    handleLogoClick(
                      dispatch,
                      toolsActions,
                      DASHBOARD,
                      navigate,
                    );
                  }}
                  roundTripData={roundTripData}
                  mode={mode}
                  dateValue={dateValue}
                  clusterId={clusterId}
                  warehouse={warehouse}
                  type={type}
                  selected={selected}
                  setSelected={setSelected}
                  setHeadCells={setHeadCells}
                  stateHeadCellsConst={stateHeadCellsConst}
                  setType={setType}
                  setExpanded={setExpanded}
                  excelHeadCellsConst={excelHeadCellsConst}
                  isToday={isToday}
                  handleClickOpenUnSchedule={handleClickOpenUnSchedule}
                  handleClickOpenSchedule={handleClickOpenSchedule}
                  resultNbr={resultNbr}
                  handleDelete={handleDeleteRoundtrips}
                  updateByFile={updateByFile}
                  loadingClusterStatusToggle={loadingClusterStatusToggle}
                />
                {(!isBefore ||
                  (isBefore && unfinishedRoundtrips.length > 0)) && (
                  <TableWrapper className={type === MAP ? 'hide-legend' : ''}>
                    <TableLegendWrapper
                      hide={accordionStatus?.[0]?.value.every(
                        (value) => !value,
                      )}
                      fullWidth={type === MAP}
                    >
                      <div style={{ position: 'relative', zIndex: '10' }}>
                        <EnhancedTableToolbar
                          title={t('roundtrip_toolbar_title', {
                            agentCategory: t(userContext),
                          })}
                          disabled={disabledToolbar}
                          leftMenu={
                            <Metrics
                              userContext={userContext}
                              roundtrips={filteredRoundtrips}
                              headCells={headCells}
                            />
                          }
                          search={search}
                          setSearch={setSearch}
                          headCells={headCells}
                          setHeadCells={setHeadCells}
                          heads={heads}
                          numSelected={
                            type === MAP
                              ? stopsToAssign?.length
                              : numSelected || 0
                          }
                          actionsComponents={
                            !disabledToolbar ? (
                              <ComponentsStack>
                                <ActionsMenu
                                  handleSaveTemplate={handleSaveTemplate}
                                  deck={warehouseData}
                                  handlePrint={() =>
                                    triggerPrint({
                                      uri: `roundtrips/clusters/${clusterId}/details?format=pdf`,
                                    })
                                  }
                                  printDeliveryNote={printAllDeliveryNotes}
                                  printPreparationNote={printAllPreparationNote}
                                />
                                <AddIconButton onClick={handleClickActivate} />
                              </ComponentsStack>
                            ) : undefined
                          }
                          onSelectComponents={
                            <>
                              {type === MAP ? (
                                <OnMapSelectActions
                                  selected={stopsToAssign}
                                  roundtrips={roundTripData?.roundtrips}
                                  handleClearSelection={handleClearSelection}
                                  setAddModalOpen={handleSetAnchorElActivate}
                                  selectedItemsCount={
                                    stopsToAssign?.length || 0
                                  }
                                  fullStopsToAssign={fullStopsToAssign}
                                />
                              ) : (
                                <OnSelectActions
                                  bulkChangeStatus={bulkStatusUpdate}
                                  printDeliveryNote={printDeliveryNote}
                                  printPreparationNote={printPreparationNote}
                                  printRoadmap={printRoadmap}
                                  selected={selected}
                                  numSelected={numSelected}
                                />
                              )}
                            </>
                          }
                          filterComponents={
                            <>
                              {type === GRID ? (
                                <FilterIcon
                                  filterOn={filterOn}
                                  filtersCount={filtersCount}
                                  handleOpenFilter={handleOpenFilter}
                                  disabled={!!disabledToolbar}
                                  filterComponent={
                                    <StopsFilter
                                      filterOn={filterOn}
                                      handleCloseFilter={handleCloseFilter}
                                      filters={filters}
                                      setFilters={setFilters}
                                      minWeight={0}
                                      maxSliderWeight={maxSliderWeight || 1000}
                                    />
                                  }
                                />
                              ) : type === MAP ? (
                                <FilterIcon
                                  filterOn={orderFilterOn}
                                  filtersCount={orderFiltersCount}
                                  handleOpenFilter={handleOpenOrderFilter}
                                  disabled={!!disabledToolbar}
                                  filterComponent={
                                    <OrdersFilter
                                      filterOn={orderFilterOn}
                                      handleCloseFilter={handleCloseOrderFilter}
                                      filters={orderFilters}
                                      setFilters={setOrderFilters}
                                    />
                                  }
                                />
                              ) : null}
                            </>
                          }
                        />
                      </div>
                      {type === MAP && (
                        <APIProvider apiKey={ENV.REACT_APP_GOOGLE_MAP_API_KEY}>
                          <Map
                            clusterId={clusterId}
                            defaultLocation={
                              roundTripData?._deck?.location.geometry
                                .coordinates
                            }
                            unassignedOrders={dataOrdersItems}
                            onDisplayOrder={onDisplayOrder}
                            roundTripCopy={roundTripCopy}
                            accordionStatus={accordionStatus}
                            setAccordionStatus={handleSetAccordionStatus}
                            requestOrderData={requestOrderData}
                            orders={orders}
                            setOrders={handleSetOrders}
                            setOrderInitCount={handleSetOrderInitCount}
                            orderInitCount={orderInitCount}
                            roundTripsCount={roundTripData?.roundtrips?.length}
                            updateRoundtripStatus={updateRoundtripStatus}
                            userContext={userContext}
                            handleClickOpen={handleClickOpen}
                            handleClickOpenDelete={handleClickOpenDelete}
                            search={search}
                            setAddModalOpen={handleSetAnchorElActivate}
                            LegendComponent={
                              <LegendComponent
                                setFilterStatus={handleSetFilterStatus}
                                roundtrips={roundTripData?.roundtrips}
                                mapView={true}
                                assigned={assignedOrders}
                                unassigned={dataOrdersItems?.length}
                                filters={orderFilters}
                                setFilters={setOrderFilters}
                              />
                            }
                            setRoundtripEdit={handleSetRoundtripEdit}
                            date={subscribedDate}
                            filters={orderFilters}
                            setFilters={handleSetOrderFilters}
                            setStopsToAssign={handleSetStopsToAssign}
                            setFullStopsToAssign={setFullStopsToAssign}
                            clearSelectionCount={clearSelectionCount}
                            handleClearSelection={handleClearSelection}
                          />
                        </APIProvider>
                      )}
                      {type === GRID && !!roundTripData?.roundtrips?.[0] && (
                        <div className="overflow_hidden">
                          <TableComponent
                            roundtripLoading={roundtripLoading}
                            setSort={setSort}
                            sort={sort}
                            direction={direction}
                            setDirection={setDirection}
                            rows={rows}
                            setDropableData={setDropableData}
                            headCells={headCells}
                            setExpanded={(e) => setExpanded(e)}
                            expanded={expanded}
                            setAccordionStatus={(e) => setAccordionStatus(e)}
                            accordionStatus={accordionStatus}
                            type={type}
                            selected={selected}
                            setSelected={setSelected}
                            stopsAccordion={stopsAccordion}
                            setStopsAccordion={setStopsAccordion}
                            tableHeight="100%"
                            triggerRoundtripDetails={triggerRoundtripDetails}
                            displayTruckMap={displayTruckMap}
                            mapCoordinates={mapCoordinates}
                            search={search}
                            userContext={userContext}
                          />
                        </div>
                      )}
                      <If condition={type === GRID}>
                        {!roundTripData?.roundtrips?.[0] &&
                          !isLoading &&
                          filtersCount > 0 && (
                            <EmptyCenter>
                              <NoResults />
                            </EmptyCenter>
                          )}

                        {!roundTripData?.roundtrips?.[0] &&
                          filtersCount === 0 &&
                          !isLoading && (
                            <NoData
                              setAnchorElManual={setAnchorElManual}
                              anchorElManual={anchorElManual}
                              setAnchorElActivate={setAnchorElActivate}
                              Icon={AddRoundTripEmpty}
                              viewMode={false}
                              isLoading={isLoading}
                            />
                          )}

                        <MapLegendWrapper>
                          <LegendComponent
                            setFilterStatus={setFilterStatus}
                            roundtrips={roundTripData?.roundtrips}
                            mapView={false}
                            assigned={assignedOrders}
                            unassigned={dataOrdersItems?.length}
                          />
                        </MapLegendWrapper>
                      </If>
                    </TableLegendWrapper>
                    {type === GRID && (
                      <RightBarWrapper>
                        <RightBar
                          search={searchRightBar}
                          setSearch={setSearchRightBar}
                          orderNext={orderNext}
                          onDisplayOrder={setSelectedOrder}
                          clusterId={clusterId}
                          checked={checked}
                          setChecked={setChecked}
                          orders={orders}
                          setOrders={setOrders}
                          setDeletedIds={setDeletedOrdersIds}
                          roundTripCopy={roundTripCopy}
                          requestAddRoundTripData={requestAddRoundTripData}
                          accordionStatus={accordionStatus}
                          setAccordionStatus={setAccordionStatus}
                          isLoading={isLoadingInitOrders}
                          isFetching={isFetchingInitOrders}
                          dateValue={dateValue}
                          requestOrderData={requestOrderData}
                          nextPageOrder={nextPageOrder}
                          orderInitCount={orderInitCount}
                          setFilters={setFilterRightBar}
                          filterCount={countRightBarFilter}
                          sorting={sortingRightbar}
                          setSorting={setSortingRightbar}
                          setOrderInitCount={setOrderInitCount}
                          disableDragAndDrop={!ability.can('canedit', 'orders')}
                          userContext={userContext}
                        />
                      </RightBarWrapper>
                    )}
                  </TableWrapper>
                )}
                {/* {type === MAP && rows[0] && <Map rows={rows} />} */}
                {isBefore && unfinishedRoundtrips.length === 0 && (
                  <EmptyCenter>
                    <NoDataComponents
                      viewMode={true}
                      text={t('cannot_update_old_roundtrips')}
                      IconButton={null}
                      textButton=""
                    />
                  </EmptyCenter>
                )}
              </TableNavWrapper>
            )}
          </Droppable>
        </DragDropContext>

        <SwipeableDrowerOrder
          selectedOrder={selectedOrder}
          handleCloseOrderDrawer={() => {
            handleCloseOrderDrawer(setSelectedOrder);
            setDefaultTab(0);
            refetch();
          }}
          orderChangeStatus={orderChangeStatus}
          triggerRoundtripDetails={triggerRoundtripDetails}
          handleDisplayMap={onDisplayDrawerMap}
          defaultTab={defaultTab}
        />

        <Drawer
          anchor="right"
          open={selectedCustomer !== null}
          onClose={() => setSelectedCustomer(null)}
        >
          <Wrapper>
            {selectedCustomer && (
              <EditCustomer
                onClose={() => setSelectedCustomer(null)}
                onOk={updateRoundtripAfterCustomerUpdate}
                customer={selectedCustomer}
              />
            )}
          </Wrapper>
        </Drawer>

        <MapPopover
          open={mapCoordinates}
          handleClose={() => {
            setMapCoordinates(null);
            setSelectedStop(null);
          }}
          latitude={mapCoordinates && mapCoordinates[1]}
          longitude={mapCoordinates && mapCoordinates[0]}
          driverLat={truckCoordinates && truckCoordinates[1]}
          driverLong={truckCoordinates && truckCoordinates[0]}
          editMode={true}
          isTruck={isTruckMap}
          vehicle={vehicle}
        />

        <RoundtripDrawer
          isOpen={roundtripMapId !== null}
          handleClose={() => setRoundtripMapId(null)}
          roundtripId={roundtripMapId}
        />
      </OrderDrawerContext_rt.Provider>
    );
  }
}

const Wrapper = styled.div`
  height: 100%;
  width: 500px;
`;

export interface TableLegendWrapperProps {
  hide?: boolean;
  fullWidth?: boolean;
}

const MapLegendWrapper = styled.div`
  & .map-legend {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    z-index: 100;
  }
`;
