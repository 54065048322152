import api from './api';
import { omit } from 'lodash';
import { ContactData } from '../types/Customer';

export const customerApis = api.injectEndpoints({
  endpoints: builder => ({
    getCustomers: builder.query<any, string>({
      query: urlQuery => ({
        url: `/customers?${urlQuery}`,
      }),
      providesTags: ['Customers'],
    }),
    getCustomerPublicDetails: builder.query<any, any>({
      query: (params: any) => ({
        url: `/customers/pub/${params.token}/details`,
        headers: {
          'x-bamptee-password': params.password,
        },
      }),
      providesTags: ['Customers'],
    }),
    getCustomerPublicOrders: builder.query<any, any>({
      query: (params: any) => ({
        url: `/customers/pub/${params.token}/orders?${params.orderQuery}`,
        headers: {
          'x-bamptee-password': params.password,
        },
      }),
      providesTags: ['Customers'],
    }),
    getCustomerPublicOrder: builder.query<any, any>({
      query: (params: any) => ({
        url: `/customers/pub/${params.token}/order/${params.orderId}`,
        headers: {
          'x-bamptee-password': params.password,
        },
      }),
      providesTags: ['Customers'],
    }),
    updateCustomer: builder.mutation<void, any>({
      query: ({ id, ...body }) => ({
        url: `/customers/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Customers', 'ClientGroups'],
    }),
    cancelInvite: builder.mutation<void, any>({
      query: ({ id, ...body }) => ({
        url: `/customers/${id}/cancel-invitation`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Customers, ClientGroups'],
    }),
    patchDeck: builder.mutation<void, any>({
      query: ({ id, deckId, ...body }) => ({
        url: `/customers/${id}/deck/${deckId}`,
        method: 'PATCH',
        body,
      }),
    }),
    contact: builder.mutation<any, ContactData>({
      query: (body: any) => ({
        url: `/customers/pub/${body.token}/contact`,
        method: 'POST',
        headers: {
          'x-bamptee-password': body.password,
        },
        body: omit(body, ['token', 'password']),
      }),
    }),
    patchCustomerPublic: builder.mutation<void, any>({
      query: body => ({
        url: `/customers/pub/${body.token}`,
        method: 'PATCH',
        headers: {
          'x-bamptee-password': body.password,
        },
        body: omit(body, ['token', 'password']),
      }),
    }),
    getCustomerFiles: builder.query<any, any>({
      query: body => ({
        url: `/customers/pub/${body.token}/files?${body?.query || ''}&type=${
          body.type
        }`,
        method: 'GET',
        headers: {
          'x-bamptee-password': body.password,
        },
      }),
      extraOptions: {
        refetchOnFocus: true,
      },
      providesTags: ['Files'],
    }),
    viewCustomerFile: builder.query<any, any>({
      query: ({ token, password, fileId }) => ({
        url: `/customers/pub/${token}/files/${fileId}/view`,
        method: 'GET',
        headers: {
          'x-bamptee-password': password,
        },
      }),
    }),
    uploadCustomer: builder.mutation<
      any,
      {
        formData: FormData;
        token: string;
        password: string;
        preserveName: boolean;
        type?: string;
      }
    >({
      query: ({ formData, token, preserveName, type, password }) => {
        return {
          url: `/customers/pub/${token}/upload?type=${type}&preserveName=${preserveName}`,
          method: 'POST',
          body: formData,
          headers: {
            'x-bamptee-password': password,
          },
        };
      },
    }),
    getCustomerNewDocuments: builder.query<any, any>({
      query: query => ({
        url: `/customers/new-documents?${query}`,
        method: 'GET',
      }),
    }),
    getCustomerCompletedDocuments: builder.query<any, any>({
      query: query => ({
        url: `/customers/completed-documents?${query}`,
        method: 'GET',
      }),
    }),
    verifyDocument: builder.mutation<void, any>({
      query: ({ id, documentId, action, reason }) => ({
        url: `/customers/${id}/documents/${documentId}/${action}`,
        method: 'POST',
        body: {
          reason,
        },
      }),
    }),
    getCustomerProcessedDocuments: builder.query<any, any>({
      query: query => ({
        url: `/customers/processed-documents?${query}`,
        method: 'GET',
      }),
    }),
    postConversationCustomer: builder.mutation<
      any,
      { token; subject; content; contactAddressId; files; password }
    >({
      query: ({
        token,
        subject,
        content,
        contactAddressId,
        files,
        password,
      }) => ({
        url: `/customers/pub/${token}/conversations`,
        method: 'POST',
        body: { subject, content, contactAddressId, files },
        headers: {
          'x-bamptee-password': password,
        },
      }),
    }),
    postConversationCustomerMessage: builder.mutation<
      any,
      { conversationId; token; content; password; files?: any }
    >({
      query: ({ conversationId, token, content, password, files = [] }) => ({
        url: `/customers/pub/${token}/conversations/${conversationId}/messages`,
        method: 'POST',
        body: { content, files },
        headers: {
          'x-bamptee-password': password,
        },
      }),
    }),
    getListConversationsCustomer: builder.query<
      any,
      { token; page; password; contactAddressId }
    >({
      query: (params: any) => ({
        url: `/customers/pub/${params.token}/conversations?page=${
          params.page || 1
        }&contactAddress=${params.contactAddressId || ''}`,
        method: 'GET',
        headers: {
          'x-bamptee-password': params.password,
        },
      }),
    }),
    getConversationCustomer: builder.query<
      any,
      { token; conversationId; password; page }
    >({
      query: (params: any) => ({
        url: `/customers/pub/${params.token}/conversations/${
          params.conversationId
        }?page=${params.page || 1}&limit=20`,
        method: 'GET',
        headers: {
          'x-bamptee-password': params.password,
        },
      }),
    }),
    getCustomer: builder.query<any, any>({
      query: id => ({
        url: `/customers/${id}`,
        method: 'GET',
      }),
      providesTags: ['Customer', 'Customers'],
    }),
    getCustomersWeb: builder.query<any, string>({
      query: urlQuery => ({
        url: `/customers${urlQuery}`,
      }),
      providesTags: ['Customers'],
    }),
    refreshCreditScore: builder.mutation<any, string>({
      query: id => ({
        url: `/customers/${id}/refresh-credit-safe`,
        method: 'POST',
      }),
    }),
    customersPlaces: builder.query<any, any>({
      query: urlQuery => ({
        url: `/customers/places?${urlQuery}`,
        method: 'GET',
      }),
    }),
    // postCustomer
    postCustomer: builder.mutation<void, any>({
      query: ({ ...body }) => ({
        url: `/customers`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Customers', 'ClientGroups'],
    }),
    //GetClientCategory
    GetCustomerTypes: builder.query({
      query: () => ({
        url: '/customer-types/options',
        method: 'GET',
      }),
    }),
    activateCustomerSepa: builder.mutation<void, any>({
      query: ({ ...body }) => ({
        url: `/customers/sepa/activate`,
        method: 'POST',
        body,
      }),
    }),
    resetCustomerSepa: builder.mutation<void, any>({
      query: () => ({
        url: `/customers/sepa/reset`,
        method: 'POST',
        body: {},
      }),
    }),
    getCustomerSepaStatus: builder.query({
      query: () => ({
        url: '/customers/sepa/status',
        method: 'GET',
      }),
    }),
    getCustomerFinancials: builder.query<any, any>({
      query: ({ id }) => ({
        url: `/customers/${id}/financials`,
        method: 'GET',
      }),
    }),
    getCustomerBranch: builder.query<any, any>({
      query: ({ customerId, branchId }) => ({
        url: `/customers/${customerId}/deck/${branchId}`,
        method: 'GET',
      }),
    }),
    getCustomerBranches: builder.query<any, any>({
      query: ({ customerId, urlQuery }) => ({
        url: `/customers/${customerId}/decks${urlQuery}`,
        method: 'GET',
      }),
    }),
    deleteCustomerBranch: builder.mutation<void, any>({
      query: ({ customerId, branchId }) => ({
        url: `/customers/${customerId}/deck/${branchId}`,
        method: 'DELETE',
      }),
    }),
    updateCustomerBranchStatus: builder.mutation<void, any>({
      query: ({ customerId, branchId, status }) => ({
        url: `/customers/${customerId}/deck/${branchId}/status`,
        method: 'PATCH',
        body: { status },
      }),
    }),
    addCustomerBranch: builder.mutation<void, any>({
      query: ({ body }) => ({
        url: `/customers/${body.customerId}/decks`,
        method: 'POST',
        body,
      }),
    }),
    updateCustomerStatus: builder.mutation<void, any>({
      query: ({ id, status }) => ({
        url: `/customers/${id}/status`,
        method: 'PATCH',
        body: { status },
      }),
    }),
    patchCustomerDeck: builder.mutation<void, any>({
      query: ({ customerId, deckId, body }) => ({
        url: `/customers/${customerId}/deck/${deckId}`,
        method: 'PATCH',
        body,
      }),
    }),
    validateSirenNumber: builder.query<any, any>({
      query: ({ legalId }) => ({
        url: `/customers/check-legalid?legalId=${legalId}`,
        method: 'GET',
      }),
    }),
    validateSiretNumber: builder.query<any, any>({
      query: ({ legalId }) => ({
        url: `/customers/check-deck-legalid?legalId=${legalId}`,
        method: 'GET',
      }),
    }),
    getCustomersBasic: builder.query({
      query: urlQuery => ({
        url: `/customers/basic/list?${urlQuery}`,
        method: 'GET',
      }),
      providesTags: ['Customers'],
    }),
    getCustomerBasic: builder.query<any, any>({
      query: id => ({
        url: `/customers/basic/${id}`,
        method: 'GET',
      }),
      providesTags: ['Customer', 'Customers'],
    }),
    getCustomerFacets: builder.query<any, string>({
      query: urlQuery => ({
        url: `/customers/facets${urlQuery}`,
      }),
      providesTags: ['Customers'],
    }),
    updateCustomerById: builder.mutation<void, any>({
      query: ({ id, updateData }) => ({
        url: `/customers/update`,
        method: 'PUT',
        body: { customerId: id, updateData },
      }),
    }),
    checkCustomerSiren: builder.query<any, string>({
      query: params => `/customers/check-siren?${params}`,
      extraOptions: {
        maxRetries: 0,
      },
      providesTags: ['check-siren'],
    }),
    checkCustomerCode: builder.query<any, string>({
      query: params => `/customers/check-code?${params}`,
      extraOptions: {
        maxRetries: 0,
      },
      providesTags: ['check-code'],
    }),
    changeCustomerStatus: builder.mutation<unknown, any>({
      query: body => ({
        url: `/customers/change-status`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Customers', 'Customer', 'ClientGroups'],
    }),
    getCustomerActivities: builder.query<any, any>({
      query: ({ id, query = '' }) => ({
        url: `/customers/${id}/activities${query}`,
      }),
      providesTags: ['Customer'],
    }),
    getCustomerActivitiesByDate: builder.query<any, any>({
      query: ({ id, query = '' }) => ({
        url: `/customers/${id}/date-activities${query}`,
      }),
      providesTags: ['Customer'],
    }),
  }),
});

export const {
  useUpdateCustomerByIdMutation,
  useLazyValidateSiretNumberQuery,
  useLazyValidateSirenNumberQuery,
  useGetCustomerTypesQuery,
  usePostCustomerMutation,
  useGetCustomersQuery,
  useLazyGetCustomersQuery,
  useGetCustomerPublicDetailsQuery,
  useLazyGetCustomerPublicDetailsQuery,
  useGetCustomerPublicOrdersQuery,
  useLazyGetCustomerPublicOrdersQuery,
  useGetCustomerPublicOrderQuery,
  useLazyGetCustomerPublicOrderQuery,
  usePatchDeckMutation,
  useContactMutation,
  useUploadCustomerMutation,
  useUpdateCustomerMutation,
  usePatchCustomerPublicMutation,
  useGetCustomerFilesQuery,
  useLazyGetCustomerFilesQuery,
  useGetCustomerNewDocumentsQuery,
  useLazyGetCustomerNewDocumentsQuery,
  useLazyGetCustomerCompletedDocumentsQuery,
  useVerifyDocumentMutation,
  useLazyGetCustomerProcessedDocumentsQuery,
  useLazyViewCustomerFileQuery,
  usePostConversationCustomerMutation,
  usePostConversationCustomerMessageMutation,
  useLazyGetListConversationsCustomerQuery,
  useGetListConversationsCustomerQuery,
  useGetConversationCustomerQuery,
  useLazyGetConversationCustomerQuery,
  useGetCustomerQuery,
  useLazyGetCustomerQuery,
  useGetCustomersWebQuery,
  useRefreshCreditScoreMutation,
  useCustomersPlacesQuery,
  useLazyCustomersPlacesQuery,
  useCancelInviteMutation,
  useActivateCustomerSepaMutation,
  useResetCustomerSepaMutation,
  useLazyGetCustomerSepaStatusQuery,
  useGetCustomerFinancialsQuery,
  useGetCustomerBranchQuery,
  useGetCustomerBranchesQuery,
  useDeleteCustomerBranchMutation,
  useUpdateCustomerBranchStatusMutation,
  useAddCustomerBranchMutation,
  useUpdateCustomerStatusMutation,
  usePatchCustomerDeckMutation,
  useLazyGetCustomerBranchQuery,
  useGetCustomersBasicQuery,
  useGetCustomerBasicQuery,
  useGetCustomerFacetsQuery,
  useLazyCheckCustomerSirenQuery,
  useLazyCheckCustomerCodeQuery,
  useChangeCustomerStatusMutation,
  useGetCustomerActivitiesQuery,
  useLazyGetCustomerActivitiesQuery,
  useLazyGetCustomerActivitiesByDateQuery,
  useGetCustomerActivitiesByDateQuery,
} = customerApis;

export default customerApis;
