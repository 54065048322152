import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { themes } from 'styles/theme/themes';
import { t } from 'i18next';
import { ManagedTooltip } from '../TableComponent/helpers/Components/ManagedTooltip';
import {
  CaretDoubleLeft,
  CaretDoubleRight,
  GasPump,
  Wrench,
} from '@phosphor-icons/react';
import { Icon } from '../Icon';
import styled from 'styled-components';

interface StopIconProps {
  type: string;
  tooltipMessage?: string;
  tooltipDisabled?: boolean;
  iconSize?: number;
}
export default function StopIcon({
  type,
  tooltipMessage,
  tooltipDisabled,
  iconSize,
}: StopIconProps) {
  const iconSx = {
    height: themes.default.iconSize,
    width: themes.default.iconSize,
  };
  const iconProps = { size: iconSize || themes.default.iconSize };

  let stopIcon = <Icon icon={<CaretDoubleRight />} {...iconProps} />;

  const message = tooltipMessage || '';

  switch (type) {
    case 'collection':
      stopIcon = <Icon icon={<CaretDoubleLeft />} {...iconProps} />;
      break;

    case 'express':
      stopIcon = (
        <BoltOutlinedIcon
          sx={{
            ...iconSx,
            color: themes?.default?.orange,
          }}
        />
      );
      break;

    case 'repair':
      stopIcon = (
        <PriorityHighIcon
          sx={{
            ...iconSx,
            color: themes?.default?.redStatus,
          }}
        />
      );
      break;

    case 'urgent':
      stopIcon = (
        <PriorityHighIcon
          sx={{
            ...iconSx,
            color: themes?.default?.redStatus,
          }}
        />
      );
      break;

    case 'fuel':
      stopIcon = (
        <StopIconWrapper borderColor={themes.default.deepPurple}>
          <Icon
            icon={<GasPump />}
            color={themes.default.deepPurple}
            {...iconProps}
          />
        </StopIconWrapper>
      );
      break;

    case 'maintenance':
      stopIcon = (
        <StopIconWrapper borderColor={themes.default.teal300}>
          <Icon
            icon={<Wrench />}
            color={themes.default.teal300}
            {...iconProps}
          />
        </StopIconWrapper>
      );
      break;

    default:
      break;
  }

  // if (type === 'delivery' || type === 'collection') {
  //   if (orderCategory === 'removal') {
  //     stopIcon = (
  //       <DoubleArrowOutlinedIcon
  //         sx={{
  //           color: themes?.default?.cornflowerBlue,
  //           transform: 'rotate(180deg)',
  //         }}
  //       />
  //     );
  //   } else if (orderCategory === 'special_delivery') {
  //     stopIcon = (
  //       <BoltOutlinedIcon
  //         sx={{
  //           color: themes?.default?.orange,
  //         }}
  //       />
  //     );
  //   } else if (orderCategory === 'repair') {
  //     stopIcon = (
  //       <PriorityHighIcon
  //         sx={{
  //           color: themes?.default?.redStatus,
  //         }}
  //       />
  //     );
  //   }
  // }
  // else if (type === 'fuel') {
  //   message = `${t('fuel')}`;
  //   stopIcon = (
  //     <LocalGasStationIcon
  //       sx={{
  //         color: themes?.default?.iconGrey,
  //       }}
  //     />
  //   );
  // } else if (type === 'maintenance') {
  //   message = `${t('maintenance')}`;
  //   stopIcon = (
  //     <DirectionsCarIcon
  //       sx={{
  //         color: themes?.default?.iconGrey,
  //       }}
  //     />
  //   );
  // }

  if (message) {
    return (
      <ManagedTooltip
        disabled={tooltipDisabled}
        title={
          message === 'purchase_order' ? `${t('purchase_order')}` : message
        }
      >
        <span>{stopIcon}</span>
      </ManagedTooltip>
    );
  }

  return stopIcon;
}

interface StopIconWrapperProps {
  borderColor?: string;
}

const StopIconWrapper = styled.div<StopIconWrapperProps>`
  position: relative;
  padding: 0 2px;
  border-radius: 50%;
  border: 1px solid ${(props) => props.borderColor};
  margin-right: 4px;
  width: 24px;
  height: 24px;
  & svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
