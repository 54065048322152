import { createSlice } from '@reduxjs/toolkit';
import { pagePreferencesState } from './types';

export const initialState: pagePreferencesState = {
  tabsOrder: {},
  columnsOrder: {},
  filters: {},
};

const slice = createSlice({
  name: 'pagePreferences',
  initialState,
  reducers: {
    setTabsOrder: (state, action) => {
      state.tabsOrder[action.payload.key] = action.payload.order;
    },
    setColumnsOrder: (state, action) => {
      state.columnsOrder[action.payload.key] = action.payload.order;
    },
    setFilters: (state, action) => {
      state.filters[action.payload.key] = action.payload.filters;
    },
  },
});

const pagePreferencesReducer = slice.reducer;

export const { actions: pagePreferencesActions } = slice;

export default pagePreferencesReducer;
