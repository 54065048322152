import React from 'react';
import styled from 'styled-components';
import { ButtonBase, Popover } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Icon } from 'app/components/Icon';
import { X } from '@phosphor-icons/react';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { StateSetter } from 'types';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import i18next from 'i18next';

interface Props {
  open: boolean;
  onClose: Function;
  dateValue: any;
  setDateValue: StateSetter<any>;
}

export const JumpToDatePicker: React.FC<Props> = ({
  open = false,
  onClose = () => {},
  dateValue = '',
  setDateValue = () => {},
}) => {
  /* ----------------------------- Variables ---------------------------- */
  const { t } = useTranslation();

  /* -------------------------------------------------------------------- */

  return (
    <Popover
      open={open}
      anchorReference="none"
      onClose={() => onClose()}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        '.MuiDialogActions-root': {
          display: 'none',
        },
      }}
    >
      <PopoverBody>
        <PopoverHeader>
          <PopoverTitle>{t('date.jump_to_specific_date')}</PopoverTitle>
          <ButtonBase
            sx={{
              padding: '4px',
              borderRadius: '100px',
            }}
            onClick={() => onClose()}
          >
            <Icon icon={<X />} size={16} />
          </ButtonBase>
        </PopoverHeader>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={i18next.language}
        >
          <StaticDatePicker
            value={dateValue}
            showToolbar={false}
            onChange={(newValue) => {
              setDateValue(newValue);
            }}
            reduceAnimations={true}
            renderInput={() => <div />}
            showDaysOutsideCurrentMonth={true}
            disableFuture={true}
          />
        </LocalizationProvider>
      </PopoverBody>
    </Popover>
  );
};

const PopoverHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 16px;
`;

const PopoverTitle = styled.div`
  font-size: 1.14rem;
  font-weight: 600;
  letter-spacing: 0.14px;
`;

const PopoverBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 358px;
  padding: 16px;

  .MuiDayPicker-weekContainer & .MuiDayPicker-header {
    justify-content: space-between !important;
  }
`;
