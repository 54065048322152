import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  userName?: string;
  action: string;
  byManager?: boolean;
}
export const UpdatedLeaveRequest = ({ userName, action, byManager }: Props) => {
  const { t } = useTranslation();
  if (!byManager) {
    return (
      <Stack direction="row" gap="5px" fontSize="0.75rem">
        <Typography fontWeight={700} fontSize="0.75rem">
          <u>{userName}</u>
        </Typography>
        {t('employee_updated_leave', { action: t(`status.${action}`) })}
      </Stack>
    );
  }

  return (
    <Stack direction="row" gap="5px" fontSize="0.75rem">
      {t('manager_updated_leave', { action: t(`status.${action}`) })}
    </Stack>
  );
};
