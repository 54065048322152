import React from 'react';
import { Grid } from '../../../../../components/Grid';
import Each from '../../../../../../common/UtilityComponents/Each';
import WarehouseRow from './WarehouseRow';
import { IWarehouse } from '../../../../../../common/types/Warehouses';

function WarehousesList({ warehouses }: { warehouses: IWarehouse[] }) {
  return (
    <Grid>
      <Each
        of={warehouses}
        render={(warehouse) => {
          return <WarehouseRow key={warehouse._id} warehouse={warehouse} />;
        }}
      />
    </Grid>
  );
}

export default WarehousesList;
