import { Data } from 'interfaces/roundTrips';

export function createData(
  id: { value: number | string },
  key_point?: { value: JSX.Element; sort?: string },
  vehicle?: {
    value: JSX.Element;
    sort?: string;
    empty?: boolean;
    running?: boolean;
    coordinates?: any;
    tracked?: boolean;
    id?: string;
    status?: string;
  },
  sales?: { value: JSX.Element; sort?: string; empty?: boolean },
  driver?: { value: JSX.Element; sort?: string; empty?: boolean },
  ripper?: { value: JSX.Element; sort?: string },
  status?: { value: JSX.Element; sort?: string },
  stops_nbs?: { value: number | JSX.Element; sort?: string },
  weight?: { value: JSX.Element; sort?: string },
  volume?: { value: JSX.Element; sort?: string },
  support_units?: { value: JSX.Element; sort?: string },
  distance?: { value: JSX.Element; sort?: string },
  time?: { value: JSX.Element; sort?: string },
  actions?: { value: JSX.Element | null; sort?: string },
): Data {
  return {
    id,
    key_point,
    vehicle,
    sales,
    driver,
    ripper,
    status,
    stops_nbs,
    weight,
    volume,
    support_units,
    distance,
    time,
    actions,
  };
}

export function stateCreateData(
  id: { value: number | string },
  key_point: { value: JSX.Element; sort?: string },
): Data {
  return {
    id,
    key_point,
  };
}

export function mapCreateData(
  id: { value: number | string },
  key_point: { value: JSX.Element; sort?: string },
): Data {
  return {
    id,
    key_point,
  };
}

export function excelCreateData(
  id: { value: number | string },
  key_point: { value: JSX.Element; sort?: string },
  sales: { value: JSX.Element; sort?: string },
  driver: { value: JSX.Element; sort?: string },
  vehicle: { value: JSX.Element; sort?: string },
  ripper: { value: JSX.Element; sort?: string },
  stops_nbs: { value: JSX.Element; sort?: string },
  load_status: { value: JSX.Element; sort?: string },
  container_collected: { value: JSX.Element; sort?: string },
  roundtrip_cost: { value: JSX.Element; sort?: string },
  roundtrip_fitness: { value: JSX.Element; sort?: string },
  total_penalty: { value: JSX.Element; sort?: string },
  load_penalty: { value: JSX.Element; sort?: string },
  geolocalisation_id: { value: JSX.Element; sort?: string },
  roundtrip_status: { value: JSX.Element; sort?: string },
  parking_place: { value: JSX.Element; sort?: string },
  plugged_in: { value: JSX.Element; sort?: string },
): Data {
  return {
    id,
    key_point,
    sales,
    driver,
    vehicle,
    ripper,
    stops_nbs,
    load_status,
    container_collected,
    roundtrip_cost,
    roundtrip_fitness,
    total_penalty,
    load_penalty,
    geolocalisation_id,
    roundtrip_status,
    parking_place,
    plugged_in,
  };
}
