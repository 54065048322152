import { Tooltip } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

export function CheckStatus({
  checked,
  checkTitle,
  uncheckTitle,
}: {
  checked: boolean;
  checkTitle: string;
  uncheckTitle: string;
}) {
  const icon = checked ? (
    <CheckIcon color="success" />
  ) : (
    <CloseIcon color="error" />
  );

  return (
    <Tooltip title={checked ? checkTitle : uncheckTitle} arrow>
      {icon}
    </Tooltip>
  );
}
