import { removeUndefined } from '../../../utils/dto';

const getCustomerData = (response: any) => {
  if (!response) return;
  return {
    code: response?.code,
    publicName: response?.publicName,
  };
};

const getDeliveryInfo = (response: any) => {
  if (!response) return;
  return {
    distance: response?.distance,
    confirmationSignatureUrl: response?.confirmationSignatureUrl,
    paymentItems: response?.paymentItems,
    damagedItems: response?.damagedItems,
    extraItems: response?.extraItems,
    excessItems: response?.excessItems,
    missingItems: response?.missingItems,
    confirmationCodeOk: response?.confirmationCodeOk,
  };
};

const getCacheData = (response: any) => {
  if (!response) return;
  return {
    _deck: response._deck,
  };
};

const OrderObjDto = (response: any) => {
  if (!response) return;

  return {
    _id: response?._id,
    status: response?.status,
    code: response?.code,
    getDeliveryDateFormat: response?.getDeliveryDateFormat,
    updatedStatusAt: response?.updatedStatusAt,
    countPackages: response?.countPackages,
    type: response?.type,
    distance: response?.distance,
    amountRequested: response?.amountRequested,
    _customer: getCustomerData(response?._customer),
    deliveryInfo: getDeliveryInfo(response?.deliveryInfo),
    cache: getCacheData(response.cache),
    supportUnitsList: response.supportUnitsList,
    totalWeight: response.totalWeight,
    deliveryComment: response.deliveryComment,
    products: response?.products,
  };
};

export const getOrderIdDto = (response: any) => {
  return removeUndefined(OrderObjDto(response));
};
