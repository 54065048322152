import React, { useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  Button,
  TextField,
  OutlinedInput,
  Chip,
  CircularProgress,
} from '@mui/material';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { UploadZone } from 'app/components/UploadFiles';
import { FileRejection } from 'react-dropzone';
import {
  Form,
  FormActions,
  FormSection,
  FormWrapper,
} from 'app/components/Form/styles';
import { useTranslation } from 'react-i18next';
import {
  useAddReportaBugRequestMutation,
  useGetAppsNameQuery,
} from 'common/services/reportabugApi';
import { isEmpty } from 'lodash';
import { useUploadMutation } from 'common/services/files';
import { RejectedFiles } from '../../../RejectedFiles';
import { AcceptedFiles } from 'app/components/AcceptedFiles/Index';
import { useToaster } from 'hooks/useToaster';
import { useSelector } from 'react-redux';
import { selectOrganization } from 'common/store/organization/selectors';
import { selectTheme } from 'styles/theme/slice/selectors';
import { Image } from '@phosphor-icons/react';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, personName: readonly string[], theme: any) {
  return {
    fontWeight: personName.includes(name)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

function ReportABug({ onClose }) {
  const { t } = useTranslation();
  const theme = useTheme();

  const [files, setFiles] = useState<File[]>([]);
  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([]);
  const [product, setProduct] = useState<string[]>([]);
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(true);
  const [uploadFile] = useUploadMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [appNames, setAppNames] = useState<string[]>([]);
  const { data: fetchedAppNames } = useGetAppsNameQuery(undefined);
  const toast = useToaster();
  const organization: any = useSelector(selectOrganization);
  const themes = useSelector(selectTheme);
  // toast(5000, 'error', rejection?.errors?.[0]?.message);
  const handleAcceptedFiles = (acceptedFiles: File[]) => {
    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
  };

  const handleRejectedFiles = (rejectedFile: FileRejection) => {
    setRejectedFiles((prevFiles) => [...prevFiles, rejectedFile]);
  };

  const handleChanges = (event: SelectChangeEvent<typeof product>) => {
    const {
      target: { value },
    } = event;
    setProduct(typeof value === 'string' ? value.split(',') : value);
  };

  const handleDelete = (productToDelete: string) => {
    setProduct((prevSelected) =>
      prevSelected.filter((product) => product !== productToDelete),
    );
  };

  const [AddReportaBug] = useAddReportaBugRequestMutation();

  const uploadDocument = async (documents: File[]) => {
    const urls: { url: string; size: string; name: string; isNew: boolean }[] =
      [];
    if (documents?.length) {
      try {
        const uploadPromises = documents.map(async (document) => {
          const formData = new FormData();
          formData.append('file', document);
          const { size, url } = await uploadFile({
            formData,
            preserveName: false,
            persist: false,
            objectType: 'bugReport',
          }).unwrap();
          if (url) {
            urls.push({
              url,
              size: size || '0',
              name: document.name,
              isNew: true,
            });
          }
        });

        await Promise.all(uploadPromises);
      } catch (e) {
        toast(5000, 'error', t('Report-a-bug-uploadingdocument-error'));
      } finally {
      }
    }
    return urls;
  };

  const onSubmit = async () => {
    console.log('organization', organization);
    try {
      setIsLoading(true);
      // Upload documents first
      const uploadedUrls = await uploadDocument(files);

      // Then submit the report
      const result: any = await AddReportaBug({
        product,
        title,
        description,
        organization: organization?.name,
        imagePaths: uploadedUrls.map((file) => file.url),
      });

      if (result.data) {
        toast(5000, 'success', t('Report-a-bug-success'));
      } else if (result.error?.status === 400) {
        toast(5000, 'error', t('Report-a-bug-failed'));
      }
      setIsLoading(false);
      onClose();
    } catch (error) {
      toast(5000, 'error', t('Report-a-bug-submitting-error'));
    }
  };

  useEffect(() => {
    if (!isEmpty(title) && !isEmpty(description) && !isEmpty(product)) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [title, description, product]);

  function handleDeleteScreenShot(index: number) {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  }

  function handleDeleteRejectedScreenShot(index: number) {
    setRejectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  }

  useEffect(() => {
    if (
      fetchedAppNames &&
      fetchedAppNames.success &&
      Array.isArray(fetchedAppNames.data)
    ) {
      setAppNames(fetchedAppNames.data);
    }
  }, [fetchedAppNames]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{ minWidth: 200, height: '100%', paddingLeft: '4px' }}
        alignItems="center"
      >
        <Form onSubmit={(e) => e.preventDefault()}>
          <FormSection style={{ height: '100%' }}>
            <Box
              sx={{
                overflowY: 'auto',
                maxHeight: '85vh',
                minHeight: '85vh',
                margin: 0,
                paddingBottom: '50px',
              }}
            >
              <FormWrapper>
                <Typography fontWeight="500" fontSize="15px" marginY="16px">
                  {t('reportabug-details')}
                </Typography>

                <FormControl sx={{ width: '413px' }} size="small">
                  <InputLabel id="demo-multiple-chip-label">
                    {t('reportabug-details-product')}
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    required
                    value={product}
                    onChange={handleChanges}
                    input={
                      <OutlinedInput
                        id="select-multiple-chip"
                        label="Product"
                      />
                    }
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip
                            style={{
                              width: '77px',
                              maxHeight: '20px',
                              height: '20px',
                              color: '#673AB7',
                              backgroundColor: '#EDE7F6',
                            }}
                            key={value}
                            label={value}
                            onDelete={() => handleDelete(value)}
                            deleteIcon={
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.99992 0.333496C3.31325 0.333496 0.333252 3.3135 0.333252 7.00016C0.333252 10.6868 3.31325 13.6668 6.99992 13.6668C10.6866 13.6668 13.6666 10.6868 13.6666 7.00016C13.6666 3.3135 10.6866 0.333496 6.99992 0.333496ZM10.3333 9.3935L9.39325 10.3335L6.99992 7.94016L4.60659 10.3335L3.66659 9.3935L6.05992 7.00016L3.66659 4.60683L4.60659 3.66683L6.99992 6.06016L9.39325 3.66683L10.3333 4.60683L7.93992 7.00016L10.3333 9.3935Z"
                                  fill="black"
                                  fillOpacity="0.38"
                                />
                              </svg>
                            }
                            onMouseDown={(event) => {
                              event.preventDefault();
                              handleDelete(value);
                            }}
                          />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {!fetchedAppNames ? (
                      <MenuItem
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <CircularProgress size={18} />
                      </MenuItem>
                    ) : (
                      appNames?.map((name) => (
                        <MenuItem
                          key={name}
                          value={name}
                          style={getStyles(name, product, theme)}
                        >
                          {name}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>

                <TextField
                  id="outlined-basic"
                  label={t('reportabug-details-title')}
                  variant="outlined"
                  size="small"
                  sx={{ width: '413px' }}
                  value={title}
                  required
                  onChange={(e) => setTitle(e.target.value)}
                />

                <TextField
                  id="outlined-basic"
                  label={t('reportabug-details-description')}
                  multiline
                  variant="outlined"
                  size="small"
                  sx={{ width: '413px' }}
                  value={description}
                  required
                  onChange={(e) => setDescription(e.target.value)}
                />

                <Typography
                  fontWeight="500"
                  fontSize="15px"
                  marginBottom="16px"
                >
                  {t('reportabug-screenshots')}
                </Typography>

                <UploadZone
                  type="document"
                  title=""
                  description={t('reportabug-screenshots-description')}
                  onOk={handleAcceptedFiles}
                  onUnOk={handleRejectedFiles}
                  acceptFile={{
                    'image/*': ['.jpeg', '.png'],
                  }}
                  icon={
                    <Image
                      width={25}
                      height={24}
                      color={themes.primary}
                      style={{ marginTop: '25px' }}
                    />
                  }
                  maxFiles={3}
                  disabled={false}
                />
                {files.length > 0 && (
                  <div>
                    {files.map((file, index) => (
                      <div
                        className="row"
                        style={{ display: 'flex', gap: '5px' }}
                      >
                        <AcceptedFiles
                          key={index}
                          deleteFile={() => handleDeleteScreenShot(index)}
                          file={{
                            name: file.name,
                            size: file.size,
                          }}
                          statusFile={{ [file.name]: true }}
                          hideProgressBar={{ [file.name]: true }}
                          fileProgress={{ [file.name]: true }}
                          disabled={false}
                        />
                      </div>
                    ))}
                  </div>
                )}

                {rejectedFiles.length > 0 && (
                  <div>
                    {rejectedFiles.map((file, index) => (
                      <RejectedFiles
                        files={rejectedFiles}
                        deleteFile={() => handleDeleteRejectedScreenShot(index)}
                      />
                    ))}
                  </div>
                )}
              </FormWrapper>
            </Box>
          </FormSection>

          <FormActions
            style={{
              display: 'flex',
              justifyContent: 'end',
              bottom: 0,
              width: '100%',
              backgroundColor: '#fff',
              padding: '16px',
              gap: '8px',
            }}
          >
            <Button
              disableElevation
              sx={{ color: '#9E9E9E' }}
              onClick={onClose}
            >
              {t('common.buttons.cancel')}
            </Button>
            <Button
              variant="contained"
              type="submit"
              onClick={onSubmit}
              disabled={disabled}
            >
              {isLoading ? (
                <CircularProgress size={15} thickness={4} color="inherit" />
              ) : (
                t('reportabug-submit-button')
              )}
            </Button>
          </FormActions>
        </Form>
      </Box>
    </ThemeProvider>
  );
}

export default ReportABug;
