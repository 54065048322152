import { Card, CardContent, Chip, Grid, Typography } from '@mui/material';
import { MouseEventHandler, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  name: string;
  description: string;
  weekDays: Array<string>;
  productsNumber: number;
  isSelected: boolean;
  select: MouseEventHandler<HTMLDivElement>;
  createdBy: string | undefined;
  type: 'orders' | 'roundtrips';
}

export function TemplatesCards({
  name,
  description,
  weekDays,
  productsNumber,
  isSelected,
  select,
  createdBy,
  type,
}: Props) {
  const { t } = useTranslation();
  const myElementRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);

  const shortWeekDays = weekDays.map(
    (day) => day.substring(0, 3).charAt(0).toUpperCase() + day.substring(1, 3),
  );

  function splitCreatedBy() {
    const words = createdBy?.split(' ');

    let initials = '';

    words?.forEach((word) => {
      if (word) {
        initials += word[0].toUpperCase();
      }
    });

    return initials;
  }

  useLayoutEffect(() => {
    if (myElementRef.current) {
      const chipElements =
        myElementRef.current.getElementsByClassName('MuiChip-root');
      const totalWidth = Array.from(chipElements).reduce((acc, chip) => {
        const chipWidth = chip.clientWidth;
        return acc + chipWidth;
      }, 0);
      if (totalWidth > 90) {
        setWidth(totalWidth - 70);
      } else {
        setWidth(0);
      }
    }
  }, [shortWeekDays]);

  return (
    <Card
      onClick={select}
      sx={{
        width: '170px',
        height: '208px',
        border: isSelected
          ? type === 'orders'
            ? '1px solid #B682FF'
            : '1px solid rgb(135, 157, 248)'
          : '1px solid #E0E0E0',
        mr: '11px',
        mb: '11px',
        boxShadow: 'none',
        cursor: 'pointer',
      }}
    >
      <CardContent
        sx={{
          backgroundColor:
            type === 'roundtrips' && isSelected
              ? 'rgb(230, 235, 253)'
              : '#FAFAFA',
          height: '77px',
          margin: '0',
          padding: '10px',
          position: 'relative',
        }}
      >
        <Grid
          sx={{
            width: type === 'roundtrips' ? '100px' : '60px',
            height: '24px',
            zIndex: 99,
            position: 'absolute',
            backgroundColor: '#ffffff99',
            border: '1px solid #d9d9d9',
            borderRadius: '4px',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            margin: 'auto',
          }}
        >
          <Typography
            sx={{
              color: type === 'orders' ? '#6600F4' : '#2A59FF',
              textAlign: 'center',
            }}
          >
            {productsNumber}{' '}
            {type === 'roundtrips' ? t('info.roundtrips') : t('orders.items')}
          </Typography>
        </Grid>
        <div
          className="wrapper"
          style={{
            display: 'flex',
          }}
        >
          <div
            className="square"
            style={{
              height: '20px',
              width: '20px',
              backgroundColor:
                type === 'roundtrips' && isSelected
                  ? 'rgb(193, 202, 236)'
                  : '#E3E3E3',
            }}
          />
          <div
            className="lineWrapper"
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '3px',
            }}
          >
            <div
              className="line"
              style={{
                height: '3.36px',
                width: '64.98px',
                marginLeft: '6px',
                backgroundColor:
                  type === 'roundtrips' && isSelected
                    ? 'rgb(193, 202, 236)'
                    : '#E3E3E3',
                borderRadius: '2px 0px 2px',
              }}
            />
            <div
              className="line"
              style={{
                height: '3.36px',
                width: '43.69px',
                marginTop: '5px',
                marginLeft: '6px',
                backgroundColor:
                  type === 'roundtrips' && isSelected
                    ? 'rgb(193, 202, 236)'
                    : '#E3E3E3',
                borderRadius: '2px 0px 2px',
              }}
            />
          </div>
        </div>
        <div
          className="wrapper"
          style={{
            display: 'flex',
            marginTop: '6px',
          }}
        >
          <div
            className="square"
            style={{
              height: '20px',
              width: '20px',
              backgroundColor:
                type === 'roundtrips' && isSelected
                  ? 'rgb(193, 202, 236)'
                  : '#E3E3E3',
            }}
          />
          <div
            className="lineWrapper"
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '3px',
            }}
          >
            <div
              className="line"
              style={{
                height: '3.36px',
                width: '64.98px',
                marginLeft: '6px',
                backgroundColor:
                  type === 'roundtrips' && isSelected
                    ? 'rgb(193, 202, 236)'
                    : '#E3E3E3',
                borderRadius: '2px 0px 2px',
              }}
            />
            <div
              className="line"
              style={{
                height: '3.36px',
                width: '43.69px',
                marginTop: '5px',
                marginLeft: '6px',
                backgroundColor:
                  type === 'roundtrips' && isSelected
                    ? 'rgb(193, 202, 236)'
                    : '#E3E3E3',
                borderRadius: '2px 0px 2px',
              }}
            />
          </div>
        </div>
        <div
          className="line"
          style={{
            height: '3.36px',
            width: '32px',
            marginTop: '6px',
            marginLeft: 'auto',
            borderRadius: '2px 0px 2px',
            backgroundColor:
              type === 'roundtrips' && isSelected
                ? 'rgb(193, 202, 236)'
                : '#E3E3E3',
          }}
        />
      </CardContent>
      <CardContent
        sx={{
          position: 'relative',
        }}
      >
        <Typography
          fontSize="1rem"
          fontWeight="500"
          sx={{
            height: '40px',
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {!name ? '/' : name}
        </Typography>
        <Grid
          sx={{
            color: 'grey',
            mt: '1px',
            maxHeight: '40px',
            height: '20px',
            overflow: 'hidden',
          }}
        >
          <Typography
            fontSize="0.857rem"
            fontWeight="400"
            sx={{
              color: 'grey',
              mt: '1px',
              height: '20px',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {description}
          </Typography>
        </Grid>
        <Grid
          container
          alignItems="center"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '82%',
            overflow: 'auto',
            position: 'absolute',
            bottom: '-20px',
          }}
        >
          <Grid container alignItems="center">
            <Grid
              sx={{
                width: '100px',
                overflowX: 'hidden',
                display: 'inline-block',
                whiteSpace: 'nowrap',
                transition: '0.8s',
                textOverflow: 'ellipsis',
              }}
            >
              <Wrapper width={width}>
                <div className="container" ref={myElementRef}>
                  {shortWeekDays.map((day) => (
                    <Chip
                      key={day}
                      label={day}
                      size="small"
                      sx={{
                        mr: '4px',
                        height: '18px',
                        mb: '2px',
                      }}
                    />
                  ))}
                </div>
              </Wrapper>
            </Grid>
            <div
              className="avatar"
              style={{
                width: '30px',
                height: '30px',
                backgroundColor:
                  type === 'orders' ? '#F0E5FF' : 'rgb(230,235,253)',
                color: type === 'orders' ? '#6600F4' : '#2A59FF',
                borderRadius: '100%',
                marginLeft: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <span>{splitCreatedBy()}</span>
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

const Wrapper = styled.div<{ width: number }>`
  .container:hover {
    transition: 0.8s;
    transform: translateX(-${(props) => props.width}px);
  }
`;
