import { useEffect, useMemo, useState } from 'react';
import { useApplication } from 'hooks/useApplication';
import i18next from 'i18next';

export interface TabType {
  value?: string;
  label?: JSX.Element;
  isDivider?: boolean;
}

interface Props {
  defaultTabs: TabType[];
  moduleName: string;
}

export const useTabs = ({
  defaultTabs,
  moduleName,
}: Props): [TabType[], React.Dispatch<React.SetStateAction<TabType[]>>] => {
  const [tabs, setTabs] = useState<TabType[]>([]);
  const [tabsOrder, setTabsOrder] = useState<TabType[]>([]);

  const defautTabsValues = useMemo(() => {
    return defaultTabs?.map((defaultTab) => {
      return { value: defaultTab?.value };
    });
  }, [defaultTabs]);

  const currentApplication = useApplication();

  useEffect(() => {
    let savedTabsOrder: any = localStorage.getItem('tabsOrder');

    if (savedTabsOrder) {
      savedTabsOrder = JSON.parse(savedTabsOrder);
      const moduleTabs = savedTabsOrder?.[moduleName] || [];

      if (moduleTabs.length > 0) {
        setTabsOrder(moduleTabs);
        return;
      }
    }

    setTabs(defautTabsValues);
    setTabsOrder(defautTabsValues);
  }, [moduleName, defaultTabs]);

  useEffect(() => {
    if (tabsOrder.length === 0) return;

    const validTabsOrder = tabsOrder.filter((tabOrder) =>
      defautTabsValues.some((tab) => tab?.value === tabOrder?.value),
    );

    const newTabs = defautTabsValues.filter(
      (defaultTab) =>
        !validTabsOrder.some((tab) => tab?.value === defaultTab?.value),
    );

    const updatedTabs = [...validTabsOrder, ...newTabs];

    if (JSON.stringify(tabs) !== JSON.stringify(updatedTabs)) {
      setTabs(updatedTabs);
      setTabsOrder(updatedTabs);
    }
  }, [defautTabsValues, currentApplication?.id, tabsOrder, i18next.language]);

  useEffect(() => {
    if (tabs.length === 0) return;

    let savedTabsOrder: any = localStorage.getItem('tabsOrder');

    if (!savedTabsOrder) {
      savedTabsOrder = { [moduleName]: tabs };
    } else {
      savedTabsOrder = JSON.parse(savedTabsOrder);
      savedTabsOrder = {
        ...savedTabsOrder,
        [moduleName]: tabs,
      };
    }

    const storedTabs = JSON.parse(localStorage.getItem('tabsOrder') || '{}')?.[
      moduleName
    ];
    if (JSON.stringify(storedTabs) !== JSON.stringify(tabs)) {
      localStorage.setItem('tabsOrder', JSON.stringify(savedTabsOrder));
    }
  }, [tabs, moduleName]);

  return [tabs, setTabs];
};
