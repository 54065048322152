import { useContext } from 'react';
import { LoadingAlertContext } from '../context';

export const useLoadingAlert = () => {
  const context = useContext(LoadingAlertContext);
  if (context === undefined) {
    throw new Error(
      'Loading Alert context dependency not provided, please add it to the root file of your project OR root of your page',
    );
  }

  return context;
};
