import { useState } from 'react';
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import styled from 'styled-components';

export const PhoneInputField = (props: PhoneInputProps) => {
  const [active, setActive] = useState(false);

  return (
    <Wrapper active={active}>
      <PhoneInput
        country={props.country || 'fr'}
        specialLabel={props.specialLabel}
        inputProps={{
          variant: 'outlined',
          onFocus: () => setActive(true),
          onBlur: () => setActive(false),
        }}
        containerClass={`phone-input-container ${
          !!props.disabled && 'disabled'
        }`}
        {...props}
      />
    </Wrapper>
  );
};

//Mui styling
const Wrapper = styled.div<{ active: boolean }>`
  flex: 1;
  .special-label {
    color: ${(props) =>
      !!props.active
        ? props.theme.primaryActiveColor
        : props.theme.lightGrayLabel};
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    padding: 0;
    display: block;
    transform-origin: top left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(133% - 32px);
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(14px, -9px) scale(0.75);
    transition:
      color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    z-index: 1;
    pointer-events: auto;
    user-select: none;
  }

  .disabled .special-label {
    background-color: transparent;
  }
  .disabled .selected-flag {
    opacity: 0.5;
  }

  .special-label:focus {
    display: none;
  }

  .phone-input-container {
    position: relative;
    margin-bottom: 16px;
    flex: 1;
  }

  .react-tel-input .form-control:focus {
    border-color: ${(props) => props.theme.primaryActiveColor};
    box-shadow: 0px 0px 0px 1px ${(props) => props.theme.primaryActiveColor};
  }

  .react-tel-input .form-control:disabled {
    background-color: ${(props) => props.theme.darkGrayBg};
    color: ${(props) => props.theme.nobel};
    border: none;
  }
  .react-tel-input .form-control {
    transition-duration: 10ms;
    width: 100%;
    height: 1.4375em;
    border-radius: 4px;
  }
`;
