export const handlesetAnchorFunction = (
  event: React.MouseEvent<HTMLElement>,
  setAnchorEl,
) => {
  setAnchorEl(event.currentTarget);
};

export const handleCloseAssignRoundTrip = (setAddRoundTripOpen) => {
  setAddRoundTripOpen(null);
};
