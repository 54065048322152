import { RoundtripStatus } from 'common/utils/enum';
import { OrderStatus } from 'types';

export enum NotificationType {
  ROUNDTRIP = 'roundtrip',
  ORDER = 'order',
}

export interface Notification {
  data: {
    activity: {
      data: {
        objectType: NotificationType;
        objectId: string;
        order?: {
          newStatus: OrderStatus;
          clientCode: string;
          clientName: string;
          objectCode: string;
          orderCode: string;
          userName: string;
        };
        roundtrip?: {
          objectCode: string;
          newStatus: RoundtripStatus;
          roundtripCode: string;
          userName: string;
        };
      };
    };
  };
}
