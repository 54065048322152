import { SxProps, Typography } from '@mui/material';
import { Icon } from 'app/components/Icon';
import { ManagedTooltip } from 'app/components/TableComponent/helpers/Components/ManagedTooltip';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';

interface MetricItemProps {
  value: string;
  icon: JSX.Element;
  title?: string;
  textSx?: SxProps;
}
export const MetricItem = ({ icon, value, title, textSx }: MetricItemProps) => {
  return (
    <ManagedTooltip title={title}>
      <MetricWrapper>
        <Icon icon={icon} />
        <Typography
          fontSize="0.75rem"
          lineHeight="10px"
          fontWeight={500}
          color={themes.default.black60}
          sx={textSx}
        >
          {value}
        </Typography>
      </MetricWrapper>
    </ManagedTooltip>
  );
};

interface MetricWrapperProps {
  noBorder?: boolean;
}

export const MetricWrapper = styled.div<MetricWrapperProps>`
  display: flex;
  height: 24px;
  padding: 0 6.83px;
  justify-content: center;
  align-items: center;
  gap: 7px;
  line-height: 10px !important;

  border-radius: 3px;
  border: ${(props) =>
    props.noBorder ? '' : `0.5px solid ${props.theme.gainsboro2}`};
  background: transparent;
`;
