import {
  Box,
  Button,
  Grid,
  Grow,
  IconButton,
  Modal,
  MobileStepper,
  Typography,
  Fade,
} from '@mui/material';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import Welcomerafiki2 from 'assets/img/settings/Welcome-rafiki 2.svg';
import Frame12541 from 'assets/img/settings/Frame12541.svg';
import Frame49471 from 'assets/img/settings/Frame49471.svg';
import Frame49519 from 'assets/img/settings/Frame49519.svg';
import Frame49518 from 'assets/img/settings/Frame49518.svg';
import { themes } from 'styles/theme/themes';
import React, { useMemo } from 'react';
export function WorkflowPopup({ open, handleClose }) {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = 5;
  const handleNext = () => {
    if (activeStep === maxSteps - 1) {
      handleClose();
    } else {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };
  const handleBack = () => {
    if (activeStep === 0) {
      handleClose();
    } else {
      setActiveStep((prevStep) => prevStep - 1);
    }
  };
  const image = useMemo(() => {
    switch (activeStep) {
      case 0:
        return Welcomerafiki2;
      case 1:
        return Frame12541;
      case 2:
        return Frame49471;
      case 3:
        return Frame49519;
      case 4:
        return Frame49518;
      default:
        return Welcomerafiki2;
    }
  }, [activeStep]);

  return (
    <StyledModal open={open} onClose={handleClose}>
      <Grow in={open} timeout={{ enter: 800, exit: 400 }}>
        <Wrapper>
          <IconButton
            onClick={handleClose}
            sx={{
              marginLeft: 'auto',
            }}
          >
            <CloseIcon />
          </IconButton>
          <Fade in={true} timeout={{ enter: 500, exit: 500 }}>
            <img
              src={image}
              alt={`Welcome Step ${activeStep + 1}`}
              style={{ height: '340px', transition: 'opacity 0.5s ease' }}
            />
          </Fade>
          <Grid container flexDirection="column">
            <Typography
              fontWeight={700}
              fontSize="20px"
              sx={{
                textAlign: 'center',
                fontFamily: 'Roboto, sans-serif',
              }}
            >
              {t(
                activeStep === 0
                  ? 'workflow-activestep-title-zero'
                  : activeStep === 1
                    ? 'workflow-activestep-title-one'
                    : activeStep === 2
                      ? 'workflow-activestep-title-two'
                      : activeStep === 3
                        ? 'workflow-activestep-title-three'
                        : 'workflow-activestep-title-four',
              )}
            </Typography>
            <Typography
              marginTop={1}
              fontWeight={400}
              fontSize="16px"
              sx={{
                fontFamily: 'Roboto, sans-serif',
              }}
            >
              {t(
                activeStep === 0
                  ? 'workflow-activestep-body-zero'
                  : activeStep === 1
                    ? 'workflow-activestep-body-one'
                    : activeStep === 2
                      ? 'workflow-activestep-body-two'
                      : activeStep === 3
                        ? 'workflow-activestep-body-three'
                        : 'workflow-activestep-body-four',
              )}
            </Typography>
          </Grid>
          <MobileStepper
            variant="dots"
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            sx={{
              maxWidth: 400,
              flexGrow: 1,
              marginY: '16px',
              '.MuiMobileStepper-dot': {
                backgroundColor: themes.bianta.grayLight,
                width: 6,
                height: 6,
                borderRadius: '50%',
              },
              '.MuiMobileStepper-dotActive': {
                backgroundColor: themes.bianta.primaryActiveColor,
                width: 10,
                height: 6,
                borderRadius: '4px',
                transition: 'width 0.3s ease',
              },
            }}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1}
              >
                {t('')}
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                {t('')}
              </Button>
            }
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              marginTop: '16px',
            }}
          >
            {activeStep === maxSteps - 1 ? (
              <Button
                variant="contained"
                onClick={handleNext}
                sx={{
                  width: '100%',
                  backgroundColor: themes.bianta.primaryActiveColor,
                  '&:hover': {
                    backgroundColor: themes.bianta.primaryActiveColor,
                  },
                }}
              >
                {t('workflow-letstart')}
              </Button>
            ) : (
              <>
                <Button
                  onClick={handleClose}
                  sx={{
                    width: '50%',
                    color: themes.bianta.primaryActiveColor,
                  }}
                >
                  {t('workflow-skip')}
                </Button>
                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{
                    width: '50%',
                    backgroundColor: themes.bianta.primaryActiveColor,
                    '&:hover': {
                      backgroundColor: themes.bianta.primaryActiveColor,
                    },
                  }}
                >
                  {t('workflow-done')}
                </Button>
              </>
            )}
          </Box>
        </Wrapper>
      </Grow>
    </StyledModal>
  );
}
const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Wrapper = styled.div`
  background: white;
  gap: 24px;
  height: 700px;
  width: 552px;
  box-shadow: -24px 24px 48px -12px #674daf57;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px;
`;
