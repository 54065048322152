import { Permission, usePermission } from 'hooks/Abilities/usePermission';
import LOCK from 'assets/img/lock.svg';
import { Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ImgWrapper, Overlay } from './style';
import styled from 'styled-components';

export const AccessBlur = ({ subject }: { subject?: string }) => {
  const { t } = useTranslation();
  const can = usePermission(subject || '');
  if (subject && can(Permission.VIEW)) {
    return null;
  }
  return (
    <BlurLayer>
      <Tooltip
        title={
          <Typography
            width="188px"
            textAlign="center"
            fontSize="0.635rem"
            fontWeight={500}
            lineHeight="0.875rem"
          >
            {t('no_section_access')}
          </Typography>
        }
      >
        <ImgWrapper>
          <img src={LOCK} alt="Lock" />
        </ImgWrapper>
      </Tooltip>
    </BlurLayer>
  );
};

const BlurLayer = styled(Overlay)`
  backdrop-filter: blur(6px);
`;
