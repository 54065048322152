import { useState } from 'react';

export const useModel = (setAnchorElActivate, warehouses) => {
  const [disabledProceed, setDisabledProceed] = useState(true);
  const [alreadyExists, setAlreadyExists] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [defaultWarehouse, setDefaultWarehouse] = useState(
    warehouses?.decks[0],
  );
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleCloseActivate = () => {
    setDisabledProceed(true);
    setAlreadyExists(false);
    setIsLoading(false);
    setAnchorElActivate(false);
  };
  return {
    disabledProceed,
    setDisabledProceed,
    alreadyExists,
    setAlreadyExists,
    isLoading,
    setIsLoading,
    defaultWarehouse,
    setDefaultWarehouse,
    handleCloseActivate,
    selectedIndex,
    setSelectedIndex,
  };
};
