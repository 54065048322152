import { Autocomplete, Chip, TextField, TextFieldProps } from '@mui/material';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useDebounce } from 'common/hooks/useDebounce';
import { CountChip } from '../../../CountChip';
import {
  DisabledChip,
  SelectOptionWrapper,
} from 'app/components/FilterComponents/styles';

interface CheckboxGrid {
  filterItems: {
    value: string;
    label: string;
    count?: number;
    disabled?: boolean;
    color?: string;
  }[];
  selectedOptions: { value: string; label: string }[];
  placeholder?: string;
  handleFilterChange: (options: { value: string; label: string }[]) => void;
  onSearch?: (search: string) => void;
  checkboxesOptions?: boolean;
  autoCompleteProps?: any;
  textFieldProps?: TextFieldProps;
}

const MultiSelectAutocomplete = ({
  filterItems,
  selectedOptions,
  placeholder,
  handleFilterChange,
  onSearch,
  checkboxesOptions,
  autoCompleteProps,
  textFieldProps,
}: CheckboxGrid) => {
  const [search, setSearch] = useState('');

  const handleOptionSelected = (
    _event: any,
    selectedOptions: { value: string; label: string }[],
  ) => {
    setSearch('');
    handleFilterChange(selectedOptions);
  };

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };
  const handleBlur = () => {
    setSearch('');
  };

  const debouncedSearch = useDebounce(search, 300);

  useEffect(() => {
    onSearch && onSearch(debouncedSearch);
  }, [debouncedSearch]);
  const renderTags = (selectedOptions, getTagProps) => {
    return selectedOptions.map((option, index) => (
      <Chip
        size="small"
        key={option.value}
        label={option.label}
        onDelete={() => {}}
        {...getTagProps({ index })}
        deleteIcon={<CloseIcon />}
        sx={{
          background: `${option?.color}20`,
          color: option?.color,
        }}
      />
    ));
  };

  const filterOptions = (options) => {
    if (!checkboxesOptions) {
      return options?.filter(
        (option) =>
          !selectedOptions?.find(
            (selectedOption) => selectedOption.value === option.value,
          ),
      );
    } else {
      return options;
    }
  };

  const renderOption = (props, option, index) => {
    return (
      <div key={index} {...props}>
        <SelectOptionWrapper>
          <div>{option.label}</div>
          {option.disabled ? (
            <DisabledChip label="disabled" size="small" />
          ) : (
            option?.count > -1 && <CountChip count={option?.count} />
          )}
        </SelectOptionWrapper>
      </div>
    );
  };

  const autocompleteOptions = useMemo(() => {
    if (onSearch) {
      return filterItems;
    }
    return filterItems?.filter((item) =>
      item?.label?.toLowerCase()?.includes(search?.toLowerCase()),
    );
  }, [search, filterItems]);

  return (
    <Autocomplete
      multiple
      value={selectedOptions}
      options={autocompleteOptions}
      getOptionLabel={(option: { label: string }) => option.label}
      onChange={handleOptionSelected}
      getOptionKey={(option: { value: any }) => option.value}
      renderTags={renderTags}
      filterOptions={filterOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          variant="outlined"
          label={placeholder}
          onChange={handleSearch}
          onBlur={handleBlur}
          sx={{
            fontSize: '0.766rem',
            '& .MuiAutocomplete-endAdornment': {
              display: 'flex',
              justifyContent: 'center',
            },
            '& button': {
              width: 'fit-content',
            },
          }}
          {...textFieldProps}
        />
      )}
      renderOption={renderOption}
      {...autoCompleteProps}
    />
  );
};

export default MultiSelectAutocomplete;
