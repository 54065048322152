import api from './api';

export const ReportaBugApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addReportaBugRequest: builder.mutation<any, any>({
      query: (body) => ({
        url: `/bug-report`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ReportaBugRequest'],
    }),
    getAppsName: builder.query<any, void>({
      query: () => ({
        url: `/application-names`,
      }),
      providesTags: ['AppNames'],
    }),
  }),
});

export const { useAddReportaBugRequestMutation, useGetAppsNameQuery } =
  ReportaBugApi;

export default ReportaBugApi;
