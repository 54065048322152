import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useInfiniteScroll } from 'hooks/useInfiniteScroll';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LoadingPulse } from '../LoadingPulse';
import { LoaderText, TimelineContainer } from './styles';
import { TimelineItem } from './components/TimeLineItem';

export interface Record {
  name: string;
  createdAt: string;
  data: any;
}

export interface RecordGroup {
  _id: string;
  hasMoreRecords: boolean;
  totalMoreRecords: number;
  records: Record[];
}

interface ActivityTimelineProps {
  data: {
    docs: RecordGroup[];
    hasNextPage: boolean;
  };
  isLoading: boolean;
  page: number;
  setPage: any;
  getActivities: Function;
  titlesMap: (item: Record) => React.ReactNode;
}

export const ActivityTimeline = ({
  data,
  isLoading,
  page,
  setPage,
  getActivities,
  titlesMap,
}: ActivityTimelineProps) => {
  const [activities, setActivities] = useState<any>([]);

  useInfiniteScroll({
    responseData: data?.docs,
    data: activities,
    setData: setActivities,
    page,
  });

  const loadMore = () => {
    setPage((prev) => prev + 1);
  };

  if (isLoading) {
    return (
      <LoaderText>
        <LoadingPulse />
      </LoaderText>
    );
  }

  if (!activities?.length) {
    return null;
  }

  return (
    <Box sx={{ p: 2, background: 'white' }}>
      <TimelineContainer>
        <InfiniteScroll
          dataLength={activities?.length || 0}
          next={loadMore}
          hasMore={data?.hasNextPage}
          loader={
            <LoaderText>
              <LoadingPulse />
            </LoaderText>
          }
          scrollableTarget="scrollable-timeline"
          style={{ overflow: 'visible' }}
        >
          {activities?.map((activityDate, index) => (
            <TimelineItem
              key={activityDate?._id}
              date={activityDate?._id}
              items={activityDate?.records}
              hasMoreRecords={!!activityDate?.hasMoreRecords}
              totalMoreRecords={activityDate?.totalMoreRecords}
              getActivities={getActivities}
              titlesMap={titlesMap}
              index={index}
            />
          ))}
        </InfiniteScroll>
      </TimelineContainer>
    </Box>
  );
};
