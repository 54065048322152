import styled from 'styled-components';
import { TableLegendWrapperProps } from '.';
import { themes } from 'styles/theme/themes';

export const RightBarWrapper = styled.div`
  max-width: 251px;
  min-width: 251px;
  background: ${themes?.default?.accordionWhiteBg};
  border-left: 1px solid rgba(42, 89, 255, 0.08);
  box-shadow: 0px 3px 15px rgba(139, 139, 139, 0.1);
`;
export const TableWrapper = styled.div`
  height: calc(100vh - ${(p) => p.theme.addRoundtripTopBarHeight});
  background: ${themes?.default?.accordionWhiteBg};
  display: flex;

  &.hide-legend {
    height: 100vh;
  }
`;

export const TableLegendWrapper = styled.div<TableLegendWrapperProps>`
  position: relative;

  .MuiCollapse-hidden {
    ${({ hide }) =>
      !!hide &&
      `
  display: none;
  `}
  }

  width: ${(props) => (props.fullWidth ? '100vw' : 'calc(100vw - 251px)')};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  .overflow_hidden {
    height: calc(
      100vh - ${(p) => p.theme.addRoundtripTopBarHeight} -
        ${(p) => p.theme.addRoundtripBottomBarHeight} -
        ${(p) => p.theme.toolBarHeight} - 2px
    );
    padding-top: 15px;
    background-color: ${(props) => props.theme.roundtripGreyBg};
  }
  .MuiBox-root.css-8atqhb {
    height: 100%;
  }
  .MuiPaper-root.MuiPaper-elevation {
    height: 100%;
  }
  .MuiTableContainer-root.css-y6co4y-MuiTableContainer-root {
    height: calc(
      100vh - ${(p) => p.theme.addRoundtripTopBarHeight} -
        ${(p) => p.theme.addRoundtripBottomBarHeight} -
        ${(p) => p.theme.columnsBarHeight}
    );
  }
  background-color: rgba(250, 251, 252, 1);
`;

export const Legend = styled.div`
  position: relative;
  z-index: 100;
  height: 40px;
  background: ${themes?.default?.accordionWhiteBg};
  border-top: 1px solid ${themes?.default?.lightFill};
`;
export const ItemLegend = styled.div<{ opaque?: boolean }>`
  display: flex;
  align-items: center;
  font-size: 0.65rem;
  pointer: cursor;
  opacity: ${(props) => (props.opaque ? '0.3' : '1')};
  p {
    margin: 9px;
  }
  .green {
    width: 8px;
    height: 8px;
    background: ${themes?.default?.greenStatus};
    border-radius: 100px;
  }
  .orange {
    width: 8px;
    height: 8px;
    background: ${themes?.default?.darkTangerine};
    border-radius: 100px;
  }
  .red {
    width: 8px;
    height: 8px;
    background: ${themes?.default?.redStatus};
    border-radius: 100px;
  }
`;
export const CenterItems = styled.div`
  width: max-content;
  margin: auto;
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: space-between;
`;

export const AlertContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  margin: 20px 25%;
`;
