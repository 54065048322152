import { Collapse } from '@mui/material';
import * as React from 'react';
import Card from '@mui/material/Card';
import { themes } from '../../../../../../styles/theme/themes';
import WarehouseRoundtripsCardContent from './WarehouseRoundtripsCardContent';
import { IWarehouseMapping } from '../../index';
import { WarehouseCardHeader } from '../WarehouseCardHeader';
import AddRoundtripsWithTemplate from '../AddRoundtripsWithTemplate';

function WarehouseCollapseCard({
  warehouse,
}: {
  warehouse: IWarehouseMapping;
}) {
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  return (
    <Card
      sx={{
        bgcolor: themes.default.lightGrey,
        boxShadow: 'none',
        border: `1px solid ${themes.default.gainsboro2}`,
      }}
    >
      <WarehouseCardHeader
        isExpanded={expanded}
        onExpandClick={handleExpandClick}
        warehouse={warehouse}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {warehouse.nbOfRoundtrips === 0 ? (
          <AddRoundtripsWithTemplate warehouseId={warehouse.id} />
        ) : (
          <WarehouseRoundtripsCardContent warehouse={warehouse} />
        )}
      </Collapse>
    </Card>
  );
}
export default WarehouseCollapseCard;
