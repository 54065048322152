import React from 'react';
import { GridProvider } from '../../../../../components/Grid/context';
import ManagedContext from '../../../../../../common/UtilityComponents/ManagedContext/ManagedContext';
import FunctionalWarehouses from './FunctionalWarehouses';
import { IWarehouse } from '../../../../../../common/types/Warehouses';

export interface IWarehousesContext {
  openDrawer: boolean;
  canEdit: boolean;
  selectedWarehouse: IWarehouse | null;
  editMode?: boolean;
  mapCoordinates: number[] | undefined;
}

function Index() {
  return (
    <GridProvider>
      <ManagedContext
        contextName="warehousesContext"
        defaultValue={
          {
            openDrawer: false,
            canEdit: false,
            selectedWarehouse: null,
            editMode: false,
            mapCoordinates: undefined,
          } as IWarehousesContext
        }
      >
        <FunctionalWarehouses />
      </ManagedContext>
    </GridProvider>
  );
}

export default Index;
