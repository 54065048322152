import React from 'react';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { Checkbox } from '@mui/material';

interface Props {
  checked: boolean;
  handleToggleSelect?: (messageUuid: string, selected: boolean) => void;
  messageUuid: string;
}

export const SelectionCheckbox: React.FC<Props> = ({
  checked,
  handleToggleSelect,
  messageUuid,
}) => {
  return (
    <CheckboxWrapper>
      <Checkbox
        checked={checked}
        value={checked}
        style={{
          color: themes?.default?.teal700,
        }}
        sx={{
          '.MuiTouchRipple-child': {
            backgroundColor: themes?.default?.teal700,
          },
        }}
        onChange={() =>
          handleToggleSelect && handleToggleSelect(messageUuid, !checked)
        }
        size="small"
      />
    </CheckboxWrapper>
  );
};

const CheckboxWrapper = styled.div`
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`;
