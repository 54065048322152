import React, { useEffect, useMemo, useState } from 'react';
import { Alert, Button, IconButton, Typography, Grid } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { useUploadCustomerMutation } from 'common/services/customerApi';
import {
  Accept,
  DropzoneOptions,
  ErrorCode,
  FileRejection,
  useDropzone,
} from 'react-dropzone';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'react-i18next';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
  handleDeleteFiles,
  selectAllCheckbox,
} from 'app/pages/UploadFilesPage/function';

import { themes } from 'styles/theme/themes';
import { ButtonWrapper, WrapperFile } from 'app/pages/RoundTrips/styles';
import If from '../If';
import { AcceptedFiles } from 'app/pages/Vehicles/components/AddVehicles/components/Documents/components/acceptedFiles';
import { RejectedFiles } from '../RejectedFiles';
import { DownloadedFiles } from 'app/pages/Vehicles/components/AddVehicles/components/Documents/components/DowloadedFiles';

interface UploadedFile extends File {
  documentType: string;
}

interface UploadZoneProps extends DropzoneOptions {
  type?: string;
  title?: string;
  titleNode?: React.ReactNode;
  isUploading?: Boolean;
  description?: string;
  onOk: (files: UploadedFile[]) => void;
  acceptFile?: Accept;
  onUnOk: (file: FileRejection) => void;
  maxFiles?: number;
  style?: any;
  items?: JSX.Element;
  icon?: JSX.Element;
  disabled?: boolean;
  customUI?: boolean;
  maxSize?: number;
}

export function UploadZone({
  type,
  title,
  titleNode,
  description,
  onOk,
  acceptFile,
  onUnOk,
  maxFiles,
  style,
  isUploading,
  items,
  icon,
  disabled,
  customUI = false,
  maxSize,
}: UploadZoneProps) {
  const { t } = useTranslation();

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: maxFiles || 30000,
    onDropAccepted: (acceptedFiles) => {
      const mapFiles: any[] = [];
      acceptedFiles.map((file, index) => {
        if (
          file?.type === 'application/x-msdownload' ||
          file?.type === 'application/octet-stream'
        ) {
          onUnOk({
            file: file,
            errors: [
              {
                code: ErrorCode.FileInvalidType,
                message: ErrorCode.FileInvalidType,
              },
            ],
          });
          return false;
        }
        Object.defineProperty(file, 'documentType', {
          value: type && 'cni' && index > 0 ? 'cni2' : type,
          configurable: false,
          writable: true,
        });

        mapFiles.push(file);
        // @ts-ignore
      });

      onOk(mapFiles);
    },
    onDropRejected: (fileRejections) => {
      fileRejections.map((file) => {
        onUnOk(file);
      });
    },
    accept: acceptFile,
    maxSize: maxSize || 3000000,
    disabled: !!disabled,
  });

  const uploadIcon = useMemo(() => {
    return (
      icon || (
        <UploadFileIcon
          color="primary"
          sx={{ width: '28px', height: '28px', marginTop: '30px' }}
        />
      )
    );
  }, [icon]);

  return (
    <UploadContentWrapper>
      <Typography>{title}</Typography>
      {titleNode}
      <DropZone>
        <div
          {...getRootProps({
            disabled: !!disabled,
            className: !!disabled ? 'dropzone disabled' : 'dropzone',
            style: {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              border: `1px dashed ${themes?.default?.lightFill}`,
              width: '100%',
              height: '141px',
              borderRadius: '4px',
              ...style,
            },
          })}
        >
          <input {...getInputProps()} />
          {uploadIcon}

          <If condition={!customUI}>
            <TextWrapper>
              <Typography
                sx={{
                  mr: '4px',
                  borderBottom: `solid 1px ${themes?.default?.perano}`,
                }}
                color="primary"
              >
                {t('settings.companyProfile.documentUpload.click')}
              </Typography>
              <Typography>
                {t('settings.companyProfile.documentUpload.drag&drop')}
              </Typography>
            </TextWrapper>
            <Typography
              fontSize="0.875rem"
              fontWeight="400"
              sx={{
                color: 'rgba(0, 0, 0, 0.60)',
                mt: '8px',
              }}
            >
              {description}
            </Typography>
          </If>

          <LoadingWrapper>{isUploading && <CircularProgress />}</LoadingWrapper>
          {items}
        </div>
      </DropZone>
    </UploadContentWrapper>
  );
}

export function UploadFiles({
  customer,
  tokenPassword,
  onUploadSuccess,
  type = 'id', // id, payment
}) {
  const [files, setFiles] = useState<UploadedFile[]>([]);
  const [loading, setLoading] = useState(false);
  const [showInfoAlert, setShowInfoAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [numberFiles, setNumberFiles] = useState<number>();
  const [upload] = useUploadCustomerMutation();
  const [checkedFiles, setCheckedFiles] = useState<string[]>([]);
  const { t } = useTranslation();
  const [filesRejected, setFilesRejected] = useState<FileRejection[]>([]);
  const statusFile = {};
  const hideProgressBar = {};
  const fileProgress = {};

  ///Close the info banner when file is been sent
  function closeInfo() {
    setShowInfoAlert(false);
  }

  ///Close the error banner when an error occured on sent
  function closeError() {
    setShowErrorAlert(false);
  }

  async function HandleUploadFiles() {
    setLoading(true);

    for (const file of files) {
      const formData = new FormData();
      formData.append('file', file);

      await upload({
        formData,
        token: customer.token,
        password: tokenPassword,
        preserveName: false,
        type: file.documentType,
      })
        .unwrap()
        .then(() => {
          setNumberFiles(files.length);
          setShowInfoAlert(true);
        })
        .catch(() => {
          setShowErrorAlert(true);
        });
    }

    if (typeof onUploadSuccess === 'function') {
      onUploadSuccess();
    }

    setLoading(false);
    setFiles([]);
  }

  ///On click Delete a single file
  function handleDeleteFile(fileName) {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  }

  const onUpload = async (uploadedFiles: UploadedFile[]) => {
    const upFiles = [...uploadedFiles];
    const cniFileIndex = files.findIndex((f) => f.documentType === 'cni');

    if (cniFileIndex > -1) {
      const uploadCniFileIndex = upFiles.findIndex(
        (f) => f.documentType === 'cni',
      );

      if (uploadCniFileIndex > -1) {
        const upFile = upFiles[uploadCniFileIndex];
        Object.defineProperty(upFile, 'documentType', {
          value: 'cni2',
        });

        upFiles[uploadCniFileIndex] = upFile;
      }
    }

    setFiles([...files, ...upFiles]);
  };

  const onNotUpload = (file: FileRejection) => {
    setFilesRejected([file]);
  };

  function handleDeleteRejectedFiles(fileName) {
    setFilesRejected((prevFiles) =>
      prevFiles.filter((file) => file.file.name !== fileName),
    );
  }

  useEffect(() => {
    selectAllCheckbox(false, setCheckedFiles, files);
  }, [files]);

  return (
    <Grid
      sx={{
        overflowY: 'hidden',
        height: '100%',
      }}
    >
      <Grid
        container
        alignItems="center"
        sx={{
          marginBottom: '2vh',
        }}
      >
        <Typography sx={{ fontWeight: 500 }} variant="h5">
          Envoyez vos documents
        </Typography>
        <Typography
          variant="body2"
          fontStyle="italic"
          fontSize="14px"
          sx={{
            textDecoration: 'italic',
            color: 'grey',
          }}
        >
          Taille maximum de 2MB, au format JPG, PNG ou PDF
        </Typography>
      </Grid>
      {showInfoAlert && (
        <Alert
          variant="filled"
          severity="info"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={closeInfo}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {numberFiles} - Files successfully uploaded
        </Alert>
      )}
      {showErrorAlert && (
        <Alert
          variant="filled"
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                closeError();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          An error occured, <strong>Retry !</strong>
        </Alert>
      )}
      <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
        <If condition={type === 'id'}>
          <Grid item xs={12} md={12}>
            <UploadZone
              onOk={onUpload}
              onUnOk={onNotUpload}
              maxFiles={1}
              style={{
                border: `1.5px dashed ${themes?.default?.lightFill}`,
              }}
              title=""
              titleNode={
                <TitleUploadWrapper>
                  <p style={{ margin: 0 }}>KBIS</p>
                </TitleUploadWrapper>
              }
              description=""
              type="kbis"
            />

            {customer.documents.map((doc, index) => {
              if (doc.type === 'kbis') {
                return (
                  <DownloadedFiles
                    key={index}
                    name={doc.type}
                    droppedAt={doc.droppedAt}
                    url={doc.url}
                    docId={doc.id}
                    triggerVehicle={() => {}}
                    vehicleId=""
                    deleteOption={false}
                  />
                );
              }
              return null;
            })}
          </Grid>

          <Grid item xs={12} md={12}>
            <UploadZone
              onOk={onUpload}
              maxFiles={2}
              style={{
                border: `1.5px dashed ${themes?.default?.lightFill}`,
              }}
              title=""
              titleNode={
                <TitleUploadWrapper>
                  <p style={{ margin: 0 }}>ID</p>
                </TitleUploadWrapper>
              }
              description="Recto & verso"
              type="cni"
              onUnOk={onNotUpload}
            />
            {customer.documents.map((doc, index) => {
              if (doc.type === 'cni') {
                return (
                  <DownloadedFiles
                    key={index}
                    name={doc.type}
                    droppedAt={doc.droppedAt}
                    url={doc.url}
                    docId={doc.id}
                    triggerVehicle={() => {}}
                    vehicleId=""
                    deleteOption={false}
                  />
                );
              }
              return null;
            })}
          </Grid>
        </If>

        <If condition={type === 'payment'}>
          <Grid item xs={12} md={12}>
            <UploadZone
              onOk={onUpload}
              onUnOk={onNotUpload}
              maxFiles={1}
              style={{
                border: `1.5px dashed ${themes?.default?.lightFill}`,
              }}
              title=""
              titleNode={
                <TitleUploadWrapper>
                  <p style={{ margin: 0 }}>RIB</p>
                </TitleUploadWrapper>
              }
              description=""
              type="rib"
            />

            {customer.documents.map((doc, index) => {
              if (doc.type === 'rib') {
                return (
                  <DownloadedFiles
                    key={index}
                    name={doc.type}
                    droppedAt={doc.droppedAt}
                    url={doc.url}
                    docId={doc.id}
                    triggerVehicle={() => {}}
                    vehicleId=""
                    deleteOption={false}
                  />
                );
              }
              return null;
            })}
          </Grid>

          <Grid item xs={12} md={12}>
            <UploadZone
              onOk={onUpload}
              onUnOk={onNotUpload}
              maxFiles={1}
              style={{
                border: `1.5px dashed ${themes?.default?.lightFill}`,
              }}
              title=""
              titleNode={
                <TitleUploadWrapper>
                  <p style={{ margin: 0 }}>Mandat SEPA</p>
                  {/*
                <a
                  href={'#'}
                  style={{ padding: 0 }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button sx={{ marginLeft: 3, padding: 0 }}>
                    Mandat SEPA à remplir
                  </Button>
                </a>
                */}
                </TitleUploadWrapper>
              }
              description=""
              type="sepa"
            />

            {customer.documents.map((doc, index) => {
              if (doc.type === 'sepa') {
                return (
                  <DownloadedFiles
                    key={index}
                    name={doc.type}
                    droppedAt={doc.droppedAt}
                    url={doc.url}
                    docId={doc.id}
                    triggerVehicle={() => {}}
                    vehicleId=""
                    deleteOption={false}
                  />
                );
              }
              return null;
            })}
          </Grid>
        </If>
      </Grid>

      <aside>
        <If
          condition={files.length > 0 || filesRejected.length > 0}
          otherwise={
            <Typography sx={{ textAlign: 'center', marginTop: 3 }}>
              {t('uploadFiles.noFile')}
            </Typography>
          }
        >
          <WrapperFile
            style={{
              maxHeight: '190px',
            }}
          >
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              sx={{
                mt: '10px',
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: 500, mr: '20px' }}>
                {t('uploadFiles.filesToUpload')}
              </Typography>
            </Grid>
            <AcceptedFiles
              handleDeleteFile={handleDeleteFile}
              files={files}
              statusFile={statusFile}
              hideProgressBar={hideProgressBar}
              fileProgress={fileProgress}
            />
            <RejectedFiles
              files={filesRejected}
              deleteFile={handleDeleteRejectedFiles}
            />
          </WrapperFile>
        </If>
      </aside>
      <ButtonWrapper
        style={{
          margin: '10px',
        }}
      >
        {files.length > 0 && (
          <Button
            style={{ width: '230px', textAlign: 'center' }}
            startIcon={<ClearIcon />}
            color="warning"
            variant="contained"
            onClick={() =>
              handleDeleteFiles(files, checkedFiles, setFiles, setCheckedFiles)
            }
          >
            {t('delete_files')}
          </Button>
        )}
        {loading ? (
          <LoadingButton
            style={{ width: '230px' }}
            variant="contained"
            loading
            loadingPosition="start"
            endIcon={<SendIcon />}
          >
            {t('common.buttons.send')}
          </LoadingButton>
        ) : (
          <Button
            style={{ width: '230px' }}
            variant="contained"
            onClick={HandleUploadFiles}
            endIcon={<SendIcon />}
          >
            {t('common.buttons.send')}
          </Button>
        )}
      </ButtonWrapper>
    </Grid>
  );
}

const DropZone = styled.div`
  width: 100%;
  position: relative;
`;

const UploadContentWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TitleUploadWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  margin: 0 auto;
  margin-bottom: 8px;
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

const LoadingWrapper = styled.div`
  display: inline-flex;
`;
