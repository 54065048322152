import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import WarehouseMarker from 'assets/img/map/marker/WarehousePointMarker.svg';
import MaintenancePin from 'assets/img/map/CustomerMap/MaintenancePin.png';
import FuelPin from 'assets/img/map/CustomerMap/FuelPin.png';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { useToaster } from 'hooks/useToaster';
import { useUpdateRoundTripMutation } from 'common/services/roundtripApi';
import { themes } from 'styles/theme/themes';
import { WarehouseInfoPopover } from 'app/components/WarehouseInfoPopover';
import { AdvancedMarker, InfoWindow } from '@vis.gl/react-google-maps';

interface MarkerStepProps {
  place: any;
  stopType?: string;
  data?: any;
  type: string;
  index?: any;
  roundTripCopy?: any;
  setAssignedOrders?: any;
  warehouseData?: any;
  roundTripsCount?: any;
  ordersCount?: any;
  overlayWarehouseVisible?: boolean;
  setOverlayWarehouseVisible?: any;
  emptyRoundtripsCount?: number;
}

export default function MarkerStep({
  place,
  type,
  data,
  stopType,
  index,
  roundTripCopy,
  setAssignedOrders,
  warehouseData,
  roundTripsCount,
  ordersCount,
  overlayWarehouseVisible,
  setOverlayWarehouseVisible,
  emptyRoundtripsCount = 0,
}: MarkerStepProps) {
  const { t } = useTranslation();
  const [updateRoundtripData] = useUpdateRoundTripMutation();
  const toast = useToaster();

  const [rtId, setRtId] = useState('');
  const [currentRt, setCurrentRt]: any = useState();

  useEffect(() => {
    roundTripCopy?.map((rt) => {
      rt?.stops.map((st) => {
        if (st?._id === data?._id) {
          setRtId(rt._id);
          setCurrentRt(rt);
        }
      });
    });
  }, [roundTripCopy]);

  const handleDragStart_ = (e) => {
    const orderData = {
      oldIndex: index,
      orderId: data?._id,
      isAssigned: true,
    };
    const orderDataString = JSON.stringify(orderData);
    e.dataTransfer.setData('application/json', orderDataString);
  };

  const drop = (e) => {
    e.preventDefault();
    const srcDataString = e.dataTransfer.getData('application/json');
    const srcData = JSON.parse(srcDataString);
    const destIndex = parseInt(e.target.attributes.alt.value) - 1;
    const srcIndex = srcData.oldIndex - 1;
    const srcId = srcData.orderId;
    const destId = e.target.id;

    const rtOrdersIds: string[] = [];
    currentRt.stops.map((stop) => {
      if (stop.type === 'maintenance' || stop.type === 'fuel') {
        rtOrdersIds.push(stop._id);
      } else if (stop._order) {
        rtOrdersIds.push(stop._order._id);
      } else {
        return;
      }
    });

    if (rtOrdersIds.includes(srcId) && rtOrdersIds.includes(destId)) {
      handleSwitchStop(e, srcIndex, destIndex);
    }
  };

  const handleSwitchStop = async (e, oldIndex, newIndex) => {
    e?.stopPropagation();
    if (
      currentRt?.stops &&
      oldIndex >= 0 &&
      newIndex >= 0 &&
      oldIndex !== newIndex
    ) {
      const from = currentRt.stops[oldIndex];
      const to = currentRt.stops[newIndex];
      const newOrder = [...currentRt.stops];

      newOrder[newIndex] = from;
      newOrder[oldIndex] = to;

      setAssignedOrders(newOrder);
      await updateRoundtripData({
        id: rtId,
        data: { stops: newOrder },
      });
      toast(5000, 'success', 'roundtrip.switched_stop');
    }
  };

  if (!place?.coordinates) {
    return null;
  }

  const position = {
    lat: place.coordinates[1],
    lng: place.coordinates[0],
  };

  if (type === 'deck') {
    return (
      <AdvancedMarker
        position={position}
        clickable={true}
        onClick={(e: any) => {
          if (e?.domEvent?.code === 'Space') return;
          setOverlayWarehouseVisible &&
            setOverlayWarehouseVisible(!overlayWarehouseVisible);
        }}
        zIndex={501}
      >
        <Tooltip
          title={t(
            emptyRoundtripsCount === 1 ? 'empty_roundtrip' : 'empty_roundtrips',
            { count: emptyRoundtripsCount },
          )}
        >
          <WarehouseIconContainer>
            <img
              src="#"
              alt=""
              style={{
                content: `url(${WarehouseMarker})`,
              }}
              width="52px"
              height="59px"
            />
            {emptyRoundtripsCount ? (
              <CountBadge>{emptyRoundtripsCount}</CountBadge>
            ) : null}
          </WarehouseIconContainer>
        </Tooltip>
        {overlayWarehouseVisible && (
          <InfoWindow position={position} pixelOffset={[0, 384]} zIndex={201}>
            <WarehouseInfoPopover
              warehouseData={warehouseData}
              roundTripsCount={roundTripsCount}
              ordersCount={ordersCount}
            />
          </InfoWindow>
        )}
      </AdvancedMarker>
    );
  }

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
      {...props}
      slotProps={{
        popper: {
          modifiers: [{ name: 'offset', options: { offset: [0, -10] } }],
        },
      }}
      arrow
      classes={{ popper: className }}
    />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#616161E5',
      color: '#FFFFFF',
      maxWidth: 220,
      fontSize: '0.625rem',
      borderRadius: '4px',
      letterSpacing: '0px',
    },
  }));

  return (
    <>
      {stopType === 'maintenance' || stopType === 'fuel' ? (
        <InfoWindow
          position={position}
          pixelOffset={[0, 12]}
          zIndex={100}
          disableAutoPan={true}
        >
          <HtmlTooltip
            title={
              <>
                <TooltipTitle color="inherit">
                  {data?._place?.location?.city}
                </TooltipTitle>
                <TooltipContent>{data?._place?.name}</TooltipContent>
              </>
            }
          >
            <div>
              <img
                id={data?._id}
                src="#"
                alt={index}
                draggable={true}
                onDragStart={handleDragStart_}
                onDragOver={(e) => {
                  e.preventDefault();
                }}
                onDrop={drop}
                style={{
                  content: `url(${
                    stopType === 'maintenance' ? MaintenancePin : FuelPin
                  })`,
                }}
                width="22px"
                height="26px"
              />
            </div>
          </HtmlTooltip>
        </InfoWindow>
      ) : null}
    </>
  );
}

const TooltipTitle = styled.div`
  font-weight: 500;
`;

const TooltipContent = styled.div`
  font-weight: 400;
`;

const WarehouseIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 32px;
  cursor: pointer;
  position: relative;
`;

const CountBadge = styled.div`
  height: 15px;
  width: 15px;
  background: ${themes?.default?.warningMain};
  border-radius: 8px;
  border: 1px solid white;
  position: absolute;
  top: -8px;
  right: -2px;
  color: white;
  line-height: 15px;
  font-size: 0.65rem;
  text-align: center;
`;
