import { useEffect, useState, useRef, useMemo } from 'react';
import { Grid, Typography } from '@mui/material';
import MarkerRed from 'assets/img/map/CustomerMap/circleDanger.svg';
import MarkerOrange from 'assets/img/map/CustomerMap/circleWarning.svg';
import MarkerBlue from 'assets/img/map/CustomerMap/circleActive.svg';
import If from 'app/components/If';
import { Marker, InfoWindow } from '@vis.gl/react-google-maps';

interface MarkerClientProps {
  coordinates: [number, number];
  code?: string;
  color: string;
  name?: string;
  amountPerWeight: number;
  onToggleOpen: () => void;
  isSelected: boolean;
  turnover: boolean;
  names: boolean;
  nmbOrders: boolean;
  euroByKg: boolean;
  totalAmount: number;
  totalOrders: number;
}

export const MarkerClient = ({
  coordinates,
  code,
  name,
  amountPerWeight,
  onToggleOpen,
  isSelected,
  turnover,
  names,
  nmbOrders,
  euroByKg,
  totalAmount,
  totalOrders,
  color,
}: MarkerClientProps) => {
  const [openLayer, setOpenLayer] = useState<boolean>(false);
  const infoWindowContentRef: any = useRef(null);

  const places = {
    lat: coordinates[1],
    lng: coordinates[0],
  };

  const handleOpenLayer = () => {
    setOpenLayer(!openLayer);
  };

  let icon = MarkerRed;
  let textColor = 'red';

  if (color === 'orange') {
    icon = MarkerOrange;
    textColor = '#FF9800';
  } else if (color === 'blue') {
    icon = MarkerBlue;
    textColor = '#4CAF50';
  }

  useEffect(() => {
    if (!isSelected) {
      setOpenLayer(false);
    }
  }, [isSelected]);

  function formatKNumber(number) {
    if (number < 1000) {
      return number.toFixed(1);
    } else {
      return (number / 1000).toFixed(1) + 'K';
    }
  }

  const infoWindowPixelOffset: [number, number] = useMemo(() => {
    if (infoWindowContentRef?.current?.offsetHeight) {
      return [0, infoWindowContentRef.current.offsetHeight + 20];
    } else {
      return [0, 140];
    }
  }, [infoWindowContentRef]);

  return (
    <>
      <Marker
        position={places}
        clickable={true}
        onClick={() => {
          handleOpenLayer();
          onToggleOpen();
        }}
        icon={icon}
      />
      {openLayer && (
        <InfoWindow
          position={places}
          pixelOffset={infoWindowPixelOffset}
          zIndex={201}
        >
          <div
            style={{
              backgroundColor: '#fff',
              minWidth: '175px',
              minHeight: names ? '39px' : '25px',
              position: 'relative',
              borderRadius: '4px',
              zIndex: 999,
              display: 'flex',
              flexDirection: 'column',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
              visibility:
                !names && !nmbOrders && !euroByKg && !turnover
                  ? 'hidden'
                  : 'visible',
            }}
            ref={infoWindowContentRef}
          >
            <Grid
              sx={{
                gap: '9px',
                padding: '5px 10px',
              }}
            >
              <If condition={names}>
                <Grid container alignItems="center">
                  <Typography
                    fontSize="10px"
                    fontWeight="700"
                    sx={{
                      marginRight: '4px',
                    }}
                  >
                    {code}
                  </Typography>
                  <Typography
                    fontSize="10px"
                    fontWeight="400"
                    sx={{
                      color: '#9E9E9E',
                      maxWidth: '80px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {name}
                  </Typography>
                </Grid>
              </If>
              <Grid container alignItems="center">
                <If condition={nmbOrders}>
                  <span
                    style={{
                      marginRight: '4px',
                    }}
                  >
                    {totalOrders ?? 0} Orders
                  </span>
                </If>
                <If condition={euroByKg}>
                  <span
                    style={{
                      color: textColor,
                    }}
                  >
                    {amountPerWeight?.toFixed(1)}
                  </span>
                </If>
                <If condition={turnover}>
                  <Typography
                    fontSize="10px"
                    fontWeight="700"
                    sx={{
                      marginLeft: 'auto',
                    }}
                  >
                    € {formatKNumber(totalAmount)}
                  </Typography>
                </If>
              </Grid>
            </Grid>
          </div>
        </InfoWindow>
      )}
    </>
  );
};
