import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MUIIconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { styled } from '@material-ui/core';

export const LargeDialog = styled(Dialog)({
  '& div[role="dialog"]': {
    minWidth: '30vw',
    padding: '15px 12px',
  },
});

export const Title = styled(DialogTitle)({
  fontSize: '1.429rem',
  fontWeight: 500,
  padding: '16px 10px !important',
});

export const IconButton = styled(MUIIconButton)({
  color: 'gray',
});

export const Actions = styled(DialogActions)({
  paddingTop: '20px',
  paddingBottom: '20px',
  paddingRight: '20px',
});

export const CancelButton = styled(Button)({
  color: '#000!important',
});

export const Content = styled(DialogContent)({
  fontSize: '1rem',
  color: 'black',
  fontWeight: 400,
  paddingBottom: '15px',
  paddingRight: '10px !important',
  paddingLeft: '10px !important',
});
