import api from './api';

export const templatesApi = api.injectEndpoints({
  endpoints: builder => ({
    getTemplatesOrder: builder.query<any, string>({
      query: urlParams => ({
        url: `/templates${urlParams}`,
      }),
      providesTags: ['Templates'],
    }),
  }),
});

export const { useGetTemplatesOrderQuery, useLazyGetTemplatesOrderQuery } =
  templatesApi;

export default templatesApi;
