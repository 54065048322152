import styled from 'styled-components';
import Map from '../../../assets/img/login/map.svg';
import { useEffect, useState } from 'react';
import { FooterLogin } from 'app/components/FooterLogin';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Title } from 'app/components/SignUp/Title';
import {
  LoginContentWrapper,
  LoginDescription,
  LoginDescriptionWrapper,
  LoginText,
  MobileLoginDescription,
  Wrapper,
} from 'styles/components/signin';
import { useTranslation } from 'react-i18next';
import { SIGNUP } from 'utils/routes';

export function VerificationLink() {
  const { t } = useTranslation();
  const [stateVerification, setStateVerification] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state as { email: string; site_name: string };
  useEffect(() => {
    setStateVerification(true);
  }, []);
  useEffect(() => {
    if (stateVerification) {
      navigate(`/signup/details/${state.email}/${state.site_name}`);
    }
  }, [stateVerification]);
  if (state && state.email && state.site_name) {
    return (
      <Wrapper>
        {/* <img src={ImageBackground} style={{ width: '100%' }} /> */}
        <Title />
        <LoginContentWrapper>
          <LoginDescriptionWrapper>
            <LoginText>
              {t('auth.pages.signup.verification_link.title')}{' '}
            </LoginText>
            <LoginDescription>
              {t('auth.pages.signup.verification_link.description')}(
              {state.email})
            </LoginDescription>
          </LoginDescriptionWrapper>
          <MobileLoginDescription>
            <img src={Map} alt="map" />
            <div className="CreatePresence">
              <LoginText>
                {t('auth.pages.signup.verification_link.title')}{' '}
              </LoginText>
              <LoginDescription>
                {t('auth.pages.signup.verification_link.description')}(
                {state.email})
              </LoginDescription>
            </div>
          </MobileLoginDescription>
          <LoginFormWrapper className="displayNone">
            <img src={Map} alt="map" />
          </LoginFormWrapper>
        </LoginContentWrapper>
        <FooterLogin />
      </Wrapper>
    );
  } else {
    return <Navigate to={SIGNUP} replace={true} />;
  }
}

const LoginFormWrapper = styled.div`
  width: 420px;
  height: 370px;
  @media only screen and (max-width: 1000px) {
    margin-top: 60px;
  }
`;
