import { Tooltip } from '@mui/material';
import { themes } from 'styles/theme/themes';
import React from 'react';

interface Props {
  title: any;
  color: any;
  number: any;
  onClick?: any;
  backgroundActive?: any;
}

export const RoundTripStatus: React.FC<Props> = ({
  title,
  color,
  number,
  onClick,
  backgroundActive,
}) => {
  return (
    <Tooltip title={title} onClick={onClick}>
      <div
        className="status_count"
        style={{
          border: backgroundActive
            ? `1.5px solid ${themes?.default?.primaryActiveColor}`
            : '',
        }}
      >
        <div className="circle" style={{ background: color }} />
        {number}
      </div>
    </Tooltip>
  );
};
