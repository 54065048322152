import { getId } from './document';

export function getAgent(user, organization) {
  return (
    user?.agents?.find(a => getId(a._organization) === getId(organization)) ||
    user?.cache?.agents[getId(organization)]
  );
}

export function getAgentFullName(agent) {
  if (agent?.firstName && agent?.lastName) {
    return `${agent.firstName} ${agent.lastName}`;
  }
  return agent?.email || '';
}
