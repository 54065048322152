import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { ButtonBase, Slider } from '@mui/material';
import { Icon } from 'app/components/Icon';
import { Play, Pause, Trash } from '@phosphor-icons/react';
import { StateSetter } from 'types';

interface Props {
  isRecording: boolean;
  setIsRecording: StateSetter<boolean>;
  recorderAudio: any;
  setRecorderAudio: StateSetter<any>;
}

export const AudioRecorder: React.FC<Props> = ({
  isRecording,
  setIsRecording,
  recorderAudio,
  setRecorderAudio,
}) => {
  const [recordedUrl, setRecordedUrl] = useState('');
  const [elapsedTime, setElapsedTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);

  const mediaStream: any = useRef(null);
  const mediaRecorder: any = useRef(null);
  const chunks: any = useRef([]);
  const audioRef: any = useRef(null);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaStream.current = stream;
      mediaRecorder.current = new MediaRecorder(stream);
      mediaRecorder.current.ondataavailable = (e: any) => {
        if (e.data.size > 0) {
          chunks.current.push(e.data);
        }
      };
      mediaRecorder.current.onstop = () => {
        const recordedBlob = new Blob(chunks.current, { type: 'audio/webm' });
        const url = URL.createObjectURL(recordedBlob);
        setRecordedUrl(url);
        setRecorderAudio(recordedBlob);
        setIsRecording(false);
        mediaRecorder.current.stop();
        chunks.current = [];
      };
      mediaRecorder.current.start();
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === 'recording') {
      mediaRecorder.current.stop();
    }
    if (mediaStream.current) {
      mediaStream.current.getTracks().forEach((track) => {
        track.stop();
      });
    }
  };

  const handleCancelRecording = () => {
    setIsRecording(false);
    setRecorderAudio(null);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  const togglePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime.toFixed(0));
    if (audioRef.current.currentTime === audioRef.current.duration) {
      setIsPlaying(false);
      setCurrentTime(0);
    }
  };

  const handleSliderChange = (e, newValue) => {
    if (audioRef.current) {
      audioRef.current.currentTime = newValue;
      setCurrentTime(newValue);
    }
  };

  useEffect(() => {
    if (isRecording) startRecording();
  }, [isRecording]);

  useEffect(() => {
    let timer;
    if (isRecording) {
      startRecording();
      timer = setInterval(() => {
        setElapsedTime((prevTime) => prevTime + 1);
      }, 1000);
    } else if (recorderAudio !== null) {
      return;
    } else {
      setElapsedTime(0);
    }
    return () => clearInterval(timer);
  }, [isRecording]);

  return (
    <AudioRecorderWrapper>
      <div>
        <ButtonBase
          sx={{
            borderRadius: '50px',
            padding: '4px',
          }}
          onClick={handleCancelRecording}
        >
          <Icon icon={<Trash />} size={18} />
        </ButtonBase>
        {isRecording && <RecordingRedDot />}
        <ElapsedTimeContainer>
          {recordedUrl ? formatTime(currentTime) : formatTime(elapsedTime)}
        </ElapsedTimeContainer>
        {recordedUrl && (
          <Container>
            <AudioPlayer
              ref={audioRef}
              src={recordedUrl}
              onTimeUpdate={handleTimeUpdate}
            />
            <Slider
              size="small"
              value={currentTime}
              min={0}
              max={audioRef.current ? audioRef.current.duration : 100}
              onChange={handleSliderChange}
              sx={{ margin: '0 8px' }}
            />
            <ButtonBase
              sx={{
                borderRadius: '50px',
                padding: '4px',
              }}
              onClick={togglePlayPause}
            >
              <Icon icon={isPlaying ? <Pause /> : <Play />} size={18} />
            </ButtonBase>
          </Container>
        )}
      </div>
      {!recordedUrl && (
        <ButtonBase
          sx={{
            borderRadius: '50px',
            padding: '4px',
            width: '32px',
            height: '32px',
          }}
          onClick={stopRecording}
        >
          <Icon icon={<Pause />} size={18} />
        </ButtonBase>
      )}
    </AudioRecorderWrapper>
  );
};

const AudioRecorderWrapper = styled.div`
  width: calc(100% - 28px - 21px - 12px - 50px);
  height: 28px;
  padding-left: 8px;
  position: absolute;
  top: calc(12px + 10px);
  left: 21px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  div {
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1;
  }
`;

const RecordingRedDot = styled.div`
  height: 8px;
  width: 8px;
  max-width: 8px;
  border-radius: 8px;
  background: ${themes?.default?.redStatus};
  animation: blink 1s infinite;

  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const ElapsedTimeContainer = styled.p`
  margin: 0;
  height: 12px;
  font-family: Roboto;
  font-size: 0.875rem;
  line-height: 12px;
  color: ${themes?.default?.textColorDashboardNoOpacity};
`;

const AudioPlayer = styled.audio`
  display: none;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;
