import { Data } from 'interfaces/vehicles';

export function createData(
  id: { value: number | string },
  truckIcon: { value: JSX.Element },
  numberPlate: { value: JSX.Element; sort?: string },
  parkNumber: { value: JSX.Element; sort?: string },
  parkingPlace: { value: JSX.Element; sort?: string },
  primaryDriver: { value: JSX.Element; sort?: string },
  brand: { value: JSX.Element; sort?: string },
  model: { value: JSX.Element; sort?: string },
  mainCategory: { value: JSX.Element; sort?: string },
  category: { value: JSX.Element; sort?: string },
  type: { value: JSX.Element; sort?: string },
  fuelLevelPercentage: { value: JSX.Element; sort?: string },
  odometer: { value: JSX.Element; sort?: number },
  availabilityStatus: { value: JSX.Element; sort?: string },
  pluggedIn: { value: JSX.Element; sort?: boolean },
  notes: { value: JSX.Element },
  action: { value: JSX.Element },
): Data {
  return {
    id,
    truckIcon,
    numberPlate,
    parkNumber,
    parkingPlace,
    brand,
    model,
    mainCategory,
    category,
    type,
    fuelLevelPercentage,
    primaryDriver,
    odometer,
    availabilityStatus,
    pluggedIn,
    notes,
    action,
  };
}

export const getVehicleLabel = (v: any) => {
  return v?.model && v?.numberPlate
    ? v?.model +
        '/' +
        v?.numberPlate +
        (v?.parkNumber ? `/${v.parkNumber}` : '')
    : v?.numberPlate
      ? v?.numberPlate + (v?.parkNumber ? `/${v.parkNumber}` : '')
      : '';
};
