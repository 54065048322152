import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import {
  usePostConversationCustomerMessageMutation,
  useLazyGetConversationCustomerQuery,
} from 'common/services/customerApi';

import {
  usePostConversationMessageMutation as usePostConversationMessageAuthMutation,
  usePatchConversationMutation,
  useLazyGetConversationQuery,
} from 'common/services/conversationApi';

import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  SwipeableDrawer,
  Pagination,
  Stack,
} from '@mui/material';
import Message, { getIsMe } from '../../Message';
import If from '../../If';
import Conversation from '../index';
import { useToaster } from '../../../../hooks/useToaster';

interface ConversationDetailProps {
  authUser: any;
  customer?: any;
  token?: string;
  password?: string;
  conversation: any | null;
  onClose: Function;
}

const ConversationDetail: React.FC<ConversationDetailProps> = ({
  authUser,
  customer,
  conversation,
  token,
  password,
  onClose,
}) => {
  const [currentAddressId, setCurrentAddressId] = useState(
    conversation?.metadata?.contactAddressId,
  );
  const [page, setPage] = useState(1);
  const [archiveConfirmIsOpen, setArchiveConfirmIsOpen] = useState(false);
  const [triggerCustomer, { data: dataCustomer }] =
    useLazyGetConversationCustomerQuery();
  const [triggerAuth, { data: dataAuth }] = useLazyGetConversationQuery();
  const [createCustomerMessage] = usePostConversationCustomerMessageMutation();
  const [createAuthMessage] = usePostConversationMessageAuthMutation();
  const [patchConversation] = usePatchConversationMutation();
  const { t } = useTranslation();
  const toast = useToaster();

  const handleChangeOrderPagination = (event, value) => {
    setPage(value);
  };

  const handleArchive = async (val) => {
    setArchiveConfirmIsOpen(false);
    onClose();

    if (authUser && conversation) {
      await patchConversation({
        conversationId: conversation._id,
        body: {
          isArchived: val,
        },
      }).unwrap();
    }
  };

  const handleChangeContactAddress = async (val) => {
    if (authUser && conversation) {
      await patchConversation({
        conversationId: conversation._id,
        body: {
          contactAddressId: val,
        },
      }).unwrap();

      setCurrentAddressId(val);

      toast(3000, 'success', 'messages.changeAddressSuccess');
    }
  };

  const fetchMessages = (page) => {
    if (token && conversation && password) {
      triggerCustomer({
        token,
        password,
        page,
        conversationId: conversation._id,
      });
    } else if (authUser && conversation) {
      triggerAuth({
        page,
        conversationId: conversation._id,
      });
    }
  };

  useEffect(() => {
    if (conversation) {
      setCurrentAddressId(conversation?.metadata.contactAddressId);
    }

    fetchMessages(1);
  }, [conversation?._id]);

  useEffect(() => {
    fetchMessages(page);
  }, [page]);

  const res = customer ? dataCustomer : dataAuth;

  const handleCreateMessage = async (message, files = []) => {
    if (!authUser) {
      await createCustomerMessage({
        password,
        token,
        content: message,
        conversationId: conversation._id,
        files,
      }).unwrap();
    } else {
      await createAuthMessage({
        content: message,
        conversationId: conversation._id,
        files,
      }).unwrap();
    }

    fetchMessages(1);
  };

  return (
    <>
      <SwipeableDrawer
        anchor="right"
        open={conversation !== null}
        onOpen={() => console.log('open')}
        onClose={() => onClose()}
      >
        <Wrapper>
          <If condition={conversation !== null}>
            {conversation ? (
              <Conversation
                authUser={authUser}
                customer={conversation._customer}
                subject={conversation.subject}
                date={conversation.createdAt}
                style={{ marginBottom: '16px' }}
                onClick={() => {}}
                onChangeContactAddress={handleChangeContactAddress}
                contactAddressId={currentAddressId}
                isArchived={conversation.isArchived}
                onArchive={
                  authUser ? () => setArchiveConfirmIsOpen(true) : undefined
                }
              />
            ) : null}

            <MessageWrapper>
              {authUser || customer ? (
                <Message
                  isMe
                  title={t('messages.reply')}
                  message=""
                  mode="create"
                  onSave={(msg, files) => {
                    handleCreateMessage(msg, files);
                  }}
                  onDelete={() => {
                    console.log('Delete');
                  }}
                  messageId="newMessage"
                  style={{ marginBottom: '16px' }}
                />
              ) : null}

              {conversation &&
                res?.docs.map((message) => (
                  <Message
                    key={message._id}
                    isMe={getIsMe(authUser, customer, message._sender)}
                    sender={message._sender}
                    message={message.content}
                    mode="display"
                    messageId={message._id}
                    style={{ marginBottom: '16px' }}
                    date={message.createdAt}
                    files={message.files}
                  />
                ))}
            </MessageWrapper>
          </If>

          <Stack spacing={2}>
            <Pagination
              count={res?.totalPages || 0}
              onChange={handleChangeOrderPagination}
            />
          </Stack>
        </Wrapper>
      </SwipeableDrawer>
      <Dialog
        open={archiveConfirmIsOpen}
        onClose={() => setArchiveConfirmIsOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <strong>
            {conversation?.isArchived
              ? t('messages.confirmUnarchiveTitle')
              : t('messages.confirmArchiveTitle')}
          </strong>
        </DialogTitle>

        <DialogActions>
          <Button onClick={() => setArchiveConfirmIsOpen(false)}>
            {t('common.buttons.cancel')}
          </Button>
          <Button
            onClick={() => handleArchive(!conversation.isArchived)}
            autoFocus
          >
            {t('common.buttons.ok')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const Wrapper = styled.div`
  height: 100vh;
  overflow: auto;
  width: 980px;

  @media (max-width: 1000px) {
    width: 100vw;
  }
`;

const MessageWrapper = styled.div`
  padding: 24px;
`;

export default ConversationDetail;
