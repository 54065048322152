import styled from 'styled-components';
import { themes } from 'styles/theme/themes';

export const AccordionCellContent = styled.div(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
}));

export const AccordionSummaryContentWrapper = styled.div((props) => ({
  width: '100%',
  '&: hover': {
    backgroundColor: props.theme.grey,
    '& .number-stops-label': {
      backgroundColor: props.theme.grey,
    },
  },
}));

export const NumberOfStopsWrapper = styled.div(() => ({
  width: 'fit-content',
  cursor: 'pointer',
  '& svg': {
    marginLeft: 15,
  },
}));

export const AccordionSummaryLabel = styled.div((props) => ({
  width: '180px',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: '6px 16px',
  borderRight: `0.063rem solid ${props.theme.primaryLightO}`,

  position: 'sticky',
  left: 0,
  backgroundColor: themes?.default?.accordionWhiteBg,
  zIndex: 1,
}));
