import api from './api';

export const carrierApi = api.injectEndpoints({
  endpoints: builder => ({
    getCarriers: builder.query<any, void>({
      query: () => ({
        url: `/carriers`,
      }),
      providesTags: ['carriers'],
    }),
  }),
});

export const { useGetCarriersQuery, useLazyGetCarriersQuery } = carrierApi;

export default carrierApi;
