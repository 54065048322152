import styled from 'styled-components';

export const StickyContent = styled.div`
  max-height: ${(props) => props.theme?.dashboardStickySectionHeight};
  overflow-y: scroll;
`;

export const StickyContainer = styled.div`
  height: 50%;
  padding: 0 0 20px 0;
  position: relative;
`;

export const StickyEmptyState = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-right: 10px;
  width: 100%;

  img {
    z-index: 2;
  }
  img.background {
    z-index: 1;
    position: absolute;
  }
`;

export const RoundtripItemWrapper = styled.div`
  margin-bottom: 20px;
  cursor: pointer;
`;

export const StickySection = styled.div`
  position: absolute;
  right: 0;
  width: ${(props) => props.theme?.dashboardStickySectionWidth};
  height: ${(props) => `calc(100vh - ${props.theme.dashboardTopSection})`};
`;
export const MainSection = styled.div`
  width: ${(props) =>
    `calc(100% - ${props.theme.dashboardStickySectionWidth})`};
  height: ${(props) => `calc(100vh - ${props.theme.dashboardTopSection})`};
  overflow: scroll;
  padding-right: 1rem;
  padding-left: 1rem;
`;
export const BottomSection = styled.div`
  margin-top: 1rem;
  width: 100%;
  min-width: ${(props) => props.theme?.dashboardLargeCardWidth};
`;
export const ScrollSection = styled.div`
  min-width: ${(props) =>
    `calc(${props.theme?.dashboardLargeCardWidth} + 2rem)`};
`;

export const MapWrapper = styled.div`
  /* position: absolute; */
  width: 100%;
  height: 100%;
  /* z-index: 100; */
`;

interface IColorCircle {
  color: string;
}
export const ColorCircle = styled.div<IColorCircle>`
  background-color: ${(props) => props.color};
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
`;

export const GradiantBg = styled.div`
  border-radius: 517px;
  background: ${(props) => props.theme.purpleLinearGradiant};
  filter: blur(72px);
  position: absolute;
  height: 50%;
  width: 50%;
  transform: translateX(50%);
  top: 25%;
`;
