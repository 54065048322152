import { Department } from '../../../../../../../../common/types/CompanyDepartments';
import React, { useMemo } from 'react';
import {
  GridAction,
  GridActionMenu,
} from '../../../../../../../components/Grid/components/GridActionMenu';
import { GridRow } from '../../../../../../../components/Grid/components/styles';
import { GridCheckbox } from '../../../../../../../components/Grid/components/GridCheckbox';
import { GridTableCell } from '../../../../../../../components/Grid/components/GridTableCell';
import { A } from '../../../../../../../components/A';
import { useManagedContext } from '../../../../../../../../common/UtilityComponents/ManagedContext/useManagedContext';
import { useModal } from '../../../../../../../components/Dialog/hooks';
import { themes } from '../../../../../../../../styles/theme/themes';
import { useTranslation } from 'react-i18next';
import { useDeleteDepartmentsMutation } from '../../../../../../../../common/services/companyDepartmentsApi';
import { useToaster } from '../../../../../../../../hooks/useToaster';
import { handleDeleteDepartments } from '../../helpers';
import { IDepartmentsContext } from '../../index';

export function DepartmentRow({ department }: { department: Department }) {
  const departmentsContext =
    useManagedContext<IDepartmentsContext>('departmentsContext');
  const { openModal, closeModal } = useModal();
  const toast = useToaster();
  const { t } = useTranslation();
  const [deleteDepartmentsMutation] = useDeleteDepartmentsMutation();
  const options = useMemo(() => {
    const allowedOptions: GridAction[] = [
      {
        label: departmentsContext.canEdit ? t('edit') : t('view'),
        action: () => {
          departmentsContext.updateDataWithFunction((prev) => {
            prev.selectedDepartment = department;
            prev.openDrawer = true;
            prev.editMode = true;
          });
        },
      },
    ];
    if (departmentsContext.canEdit)
      allowedOptions.push({
        label: t(`delete`),
        isDelete: true,
        action: () => {
          openModal({
            title: t(`delete`),
            content: `${t(`common.dialog.delete.initial_title`)} ${
              department.name
            }?`,
            action: {
              actionText: t('delete'),
              actionCallback: () => {
                handleDeleteDepartments(
                  department.id,
                  deleteDepartmentsMutation,
                  closeModal,
                  toast,
                );
              },
              buttonProps: {
                sx: { background: themes?.default?.Cinnabar },
                color: 'error',
              },
            },
            cancel: true,
          });
        },
      });

    return allowedOptions;
  }, [
    departmentsContext,
    department,
    openModal,
    t,
    deleteDepartmentsMutation,
    closeModal,
  ]);
  return (
    <GridRow hover tabIndex={-1}>
      <GridCheckbox id={department.id} />
      <GridTableCell columnId="name">
        <A
          role="button"
          className="link"
          onClick={() => {
            departmentsContext.updateDataWithFunction((prev) => {
              prev.selectedDepartment = department;
              prev.openDrawer = true;
              prev.editMode = true;
            });
          }}
        >
          {department.name}
        </A>
      </GridTableCell>
      <GridTableCell columnId="manager">{department.manager}</GridTableCell>
      <GridTableCell columnId="actions" className="actions-cell">
        <GridActionMenu options={options} />
      </GridTableCell>
    </GridRow>
  );
}
