import { Checkbox, TableCell } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { CheckboxLabelWrapper } from '../../styles';
import { CSSProperties } from '@mui/styles';
import React from 'react';

interface Props {
  type: any;
  EXCEL: any;
  rows: any;
  selectedRowIndex: any;
  isSelected: any;
  group: any;
  orderBy: any;
  order: any;
  onSelectColumn: any;
  selected: any;
  setSelected: any;
}

export const ExelExist: React.FC<Props> = ({
  type,
  EXCEL,
  rows,
  selectedRowIndex,
  isSelected,
  group,
  orderBy,
  order,
  onSelectColumn,
  selected,
  setSelected,
}) => {
  const theme = useSelector(selectTheme);

  return (
    <>
      {type === EXCEL &&
        rows.length &&
        rows[selectedRowIndex].data.map((row, rowIndex) => {
          if (row.disableRow) return null;
          let cellStyle: CSSProperties | undefined = {
            minWidth: 250,
          };
          if (rowIndex !== rows[selectedRowIndex].data.length - 1)
            cellStyle = {
              borderRight: `0.063rem solid ${theme.primaryLightO}`,
              minWidth: 250,
            };

          const labelId = `enhanced-table-checkbox-${rowIndex}`;

          const isItemSelected = !!isSelected(group, row.data.id.value);

          return (
            <TableCell
              key={row.data.id.value}
              align="left"
              padding={row.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === row.id ? order : false}
              style={{
                flex: 1,
                overflow: 'hidden',
                ...cellStyle,
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <CheckboxLabelWrapper>
                  <Checkbox
                    checked={isItemSelected}
                    onClick={(event) => {
                      onSelectColumn(
                        event,
                        group,
                        row.data.id.value,
                        selected,
                        setSelected,
                      );
                    }}
                    color="primary"
                    inputProps={{
                      'aria-labelledby': labelId,
                    }}
                    sx={{
                      height: 'fit-content',
                      padding: 'unset',
                    }}
                  />
                  <span style={{ fontWeight: 500 }}>
                    {row.data.key_point.value}
                  </span>
                </CheckboxLabelWrapper>
              </div>
            </TableCell>
          );
        })}
    </>
  );
};
