import React, { useEffect } from 'react';

interface Props {
  center: google.maps.LatLngLiteral;
  options: google.maps.CircleOptions;
  map: google.maps.Map | null;
  onClick?: () => void;
}

export const CircleComponent: React.FC<Props> = ({
  center,
  options,
  map,
  onClick,
}) => {
  useEffect(() => {
    if (!map) return;

    const circle = new window.google.maps.Circle({
      center,
      ...options,
      map,
    });

    if (onClick) {
      circle.addListener('click', onClick);
    }

    return () => {
      circle.setMap(null);
    };
  }, [center, options, map, onClick]);

  return null;
};
