import { ITabsContext } from 'app/components/ManagedGrid/types';
import ManagedTabs from 'app/components/ManagedTabs';
import TabsContext from 'app/components/ManagedTabs/TabsContext';
import OrderInformationTab from 'app/components/OrderDetail/tabs/OrderInformation';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IOrderDetailsContext } from '../..';
import { Box, Stack } from '@mui/material';
import { themes } from 'styles/theme/themes';
import { DeliveryInformationTab } from 'app/pages/Ordoria/Orders/components/OrderDetailsDrawer/DeliveryInformationTab';
import { TrackOrderTab } from 'app/pages/Ordoria/Orders/components/OrderDetailsDrawer/TrackOrderTab';
import { OrderNotesTab } from 'app/pages/Ordoria/Orders/components/OrderDetailsDrawer/OrderNotesTab';

const TabComponent = () => {
  const { selectedTab, tabs } = useManagedContext<ITabsContext>('tabs');
  const { order, canEdit, height } =
    useManagedContext<IOrderDetailsContext>('orderDetails');

  return (
    <Stack borderRight={`1px solid ${themes.default.gainsboro2}`} height="100%">
      <Box
        borderBottom={`1px solid ${themes.default.gainsboro2}`}
        paddingX="24px"
        height="40px"
      >
        <ManagedTabs suppressPersist />
      </Box>

      <Box paddingLeft="24px" flex="1">
        {selectedTab === tabs[0].value && (
          <OrderInformationTab
            isCustomer={false}
            fullOrder={order}
            fullOrderCustomer={order}
            isFetching={false}
            order={order}
            handlePaymentCollectionChange={() => {}}
            setAmountRequested={() => {}}
            triggerGetOrder={() => {}}
            viewMode={!canEdit}
            height={height}
          />
        )}

        {selectedTab === tabs[1].value && (
          <DeliveryInformationTab order={order} />
        )}

        {selectedTab === tabs[2].value && (
          <TrackOrderTab
            orderId={order?._id}
            orderStatus={order.status}
            height={height}
          />
        )}

        {selectedTab === tabs[3].value && (
          <OrderNotesTab orderId={order?._id} />
        )}
      </Box>
    </Stack>
  );
};

export const Tabs = () => {
  const { defaultTabIndex } =
    useManagedContext<IOrderDetailsContext>('orderDetails');
  const { t } = useTranslation();

  const tabs = useMemo(() => {
    return [
      {
        label: t('order_information'),
        value: 'order_information',
      },
      {
        label: t('delivery_information'),
        value: 'delivery_information',
      },
      {
        label: t('track_order'),
        value: 'track_order',
      },
      {
        label: t('order_notes'),
        value: 'order_notes',
      },
    ];
  }, [t]);

  return (
    <TabsContext initialTab={tabs[defaultTabIndex || 0].value} tabs={tabs}>
      <TabComponent />
    </TabsContext>
  );
};
