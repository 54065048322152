import { Cart } from '../types/Cart';
import api from './api';

type ChangeProductQuantity = {
  productId: string;
  quantity: number;
};

type GeneralResponseBody = {
  message: string;
};

type CartResponseBody = Cart;

export const cartApi = api.injectEndpoints({
  endpoints: builder => ({
    getCart: builder.query<CartResponseBody, void>({
      query: () => '/carts',
      providesTags: ['Cart'],
    }),

    addNewProductToCart: builder.mutation<
      GeneralResponseBody,
      { productId: string; quantity: number }
    >({
      query: ({ productId, quantity }) => ({
        url: `/carts/products/${productId}`,
        method: 'POST',
        body: {
          quantity: quantity,
        },
      }),
      invalidatesTags: ['Cart'],
    }),

    changeProductQuantity: builder.mutation<
      GeneralResponseBody,
      ChangeProductQuantity
    >({
      query: ({ productId, quantity }) => ({
        url: `/carts/products/${productId}`,
        method: 'PATCH',
        body: { quantity: quantity },
      }),
      invalidatesTags: ['Cart'],
    }),

    deleteProductsFromCart: builder.mutation<any, string>({
      query: ids => ({
        url: `/carts/products?${ids}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Cart'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCartQuery,
  useAddNewProductToCartMutation,
  useChangeProductQuantityMutation,
  useDeleteProductsFromCartMutation,
} = cartApi;

export default cartApi;
