import { DialogTitle, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { CSSProperties } from 'styled-components';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';

interface Props {
  handleCloseActivate: () => void;
  handleBack?: () => void;
  style: CSSProperties | undefined;
  dateValue?: Dayjs | null;
  handleClickActivate?: (event: React.MouseEvent<HTMLElement>) => void;
  title_text?: string;
}
export const Title: React.FC<Props> = ({
  handleCloseActivate,
  handleBack,
  style,
  dateValue,
  handleClickActivate,
  title_text,
}) => {
  const { t } = useTranslation();

  return (
    <DialogTitle
      style={{
        fontSize: '1.25rem',

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

        marginTop: '22px',
        ...style,
      }}
      id="alert-dialog-title"
    >
      <div>
        {handleBack && (
          <ArrowBackOutlinedIcon
            style={{ marginRight: '15px' }}
            onClick={(e) => {
              handleBack();
              if (handleClickActivate) {
                handleClickActivate(e as any);
                handleCloseActivate();
              }
            }}
          />
        )}
        {title_text || t('drawer.modal.Create_roundtrips_by_existing')} (
        {dayjs(dateValue).format('DD/MM/YYYY')})
      </div>
      <IconButton
        aria-label="close"
        onClick={() => {
          handleCloseActivate();
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
};
