import { TableCell } from '@mui/material';
import { TimeLine } from 'app/components/TableComponent/helpers';

export function TimeLineComponent() {
  return (
    <TableCell
      align="left"
      style={{
        flex: 1,
        overflow: 'hidden',
      }}
    >
      <TimeLine>
        {Array.from({ length: 14 }, (_, i) => (
          <div className="time">{`${7 + i}:00`}</div>
        ))}
      </TimeLine>
    </TableCell>
  );
}
