export const capitalizeMonth = (option, dayjsClass) => {
  // Store the original format function
  const oldFormat = dayjsClass.prototype.format;

  // Override the default format function
  dayjsClass.prototype.format = function (template) {
    // Get the original formatted string
    const formatted = oldFormat.bind(this)(template);

    // If no format is specified, return original
    if (!template) return formatted;

    // Check if the format includes month format tokens
    const hasMMM = template.includes('MMM') && !template.includes('MMMM');
    const hasMMMM = template.includes('MMMM');

    if (!hasMMM && !hasMMMM) return formatted;

    // Split the string and modify month where needed
    return formatted
      .split(' ')
      .map((part, index) => {
        // Check if current part corresponds to a month token in template
        const templateParts = template.split(' ');
        const isMonth = templateParts[index]?.includes('MMM');

        if (isMonth && part.length >= 3) {
          // First capitalize
          let modifiedPart = part.charAt(0).toUpperCase() + part.slice(1);

          // For MMM format, add period only if it's not already there
          if (hasMMM && !modifiedPart.endsWith('.')) {
            modifiedPart += '.';
          }

          return modifiedPart;
        }
        return part;
      })
      .join(' ');
  };
};
