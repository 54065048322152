import { TableCell, TableRow } from '@mui/material';
import React, { useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { AccordionCellContent } from './styles';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled } from '@mui/material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'app/pages/AddRoundTrips/components/TableComponent/styles/accordion';
import { RowDataValue } from 'app/components/RoundTrips/components/RowDataValue';
import {
  Driver,
  Sales,
} from 'app/components/RoundTrips/RoundTripData/statuses';
import { themes } from 'styles/theme/themes';
import { LoadingPulse } from 'app/components/LoadingPulse';
import { LoadingContainer } from '../components/AccordionItemCluster';
import If from 'app/components/If';
import TruckIcon from 'app/components/TruckIcon';
import { AgentCategories } from 'app/components/RoundTrips/RoundTripData/agentCategories';

export const GridFunction = ({
  rows,
  isSelected,
  headCells,
  handleAccordionToggle,
  accordionStatus,
  theme,
  setAccordionStatus,
  displayTruckMap,
  mapCoordinates,
  filterStatus,
  userContext,
}) => {
  const keyArrayRef = useRef<string[]>([]);
  const tablesData: any = [];
  rows.map((rowsGroup, rowsGroupIndex) => {
    tablesData.push(
      rowsGroup.data.map((row, index) => {
        if (accordionStatus?.[rowsGroupIndex]?.value[index]) {
          keyArrayRef.current = [...keyArrayRef.current, row.data.id?.value];
        }
        if (filterStatus) {
          const filter = filterStatus.find((statusObj) => {
            return statusObj.roundtripId === row.data.cluster?.value;
          });
          if (
            filter?.statuses?.length &&
            !filter?.statuses?.includes(row?.data?.r_status?.statusValue)
          ) {
            return;
          }
        }
        const isItemSelected = !!isSelected(rowsGroup.key, row.data.id?.value);
        let styles = {};
        if (isItemSelected) {
          styles = { backgroundColor: theme.primaryLightO };
        }
        const uuid = uuidv4();

        return (
          <React.Fragment key={`${rowsGroup.key}-${uuid}`}>
            <StyledTableRow
              hover
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={uuid}
              selected={isItemSelected}
              sx={{
                display: 'flex',
              }}
            >
              <TableCell
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  borderBottom: 'unset',
                  zIndex: '2',
                  padding: '6px 16px 6px 4px',
                }}
              >
                <div
                  onClick={() => {
                    displayTruckMap({
                      coordinates: row.data['vehicle'].coordinates,
                      status: row.data['vehicle'].status,
                      engineRunning: row.data['vehicle'].running,
                      isTracked: row.data['vehicle'].tracked,
                    });
                  }}
                >
                  <TruckIcon
                    driver={
                      userContext === AgentCategories.Sales
                        ? row.data[Sales]?.empty
                        : row.data[Driver]?.empty
                    }
                    vehicle={row.data['vehicle']?.empty}
                    running={row.data['vehicle']?.running}
                    notTracked={!row.data['vehicle']?.tracked}
                    mapOpen={
                      mapCoordinates &&
                      mapCoordinates === row.data['vehicle']?.coordinates
                    }
                    status={row.data['vehicle']?.status}
                  />
                </div>
              </TableCell>

              <TableCell
                padding="checkbox"
                style={{ ...styles }}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '50px',
                  minWidth: '50px',
                  borderBottom: 'unset',
                }}
              >
                {accordionStatus?.[rowsGroupIndex]?.value[index] ? (
                  <div
                    style={{ cursor: 'pointer', padding: '22px 22px 22px 0' }}
                    onClick={(e) => {
                      handleAccordionToggle(
                        e,
                        rowsGroupIndex,
                        index,
                        accordionStatus,
                        setAccordionStatus,
                        row.data.id.value,
                      );
                    }}
                  >
                    <KeyboardArrowUpIcon sx={{ color: theme.primary }} />
                  </div>
                ) : (
                  <div
                    style={{ cursor: 'pointer', padding: '22px 22px 22px 0' }}
                    onClick={(e) => {
                      handleAccordionToggle(
                        e,
                        rowsGroupIndex,
                        index,
                        accordionStatus,
                        setAccordionStatus,
                        row.data.id.value,
                      );
                    }}
                  >
                    <KeyboardArrowDownIcon
                      sx={{ color: themes?.default?.black }}
                    />
                  </div>
                )}
                {isItemSelected}
                {/* <Checkbox
                  onClick={event => {
                    handleClickAccordionEl(
                      rowsGroup.key,
                      row.data.id.value,
                      selected,
                      setSelected,
                    );
                  }}
                  color="primary"
                  checked={isItemSelected}
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                  sx={{
                    height: 'fit-content',
                  }}
                /> */}
              </TableCell>
              {headCells.map((headCell) => {
                if (headCell.disableRow || !headCell.id) return null;
                return (
                  <TableCell
                    key={headCell.id}
                    align="left"
                    style={{ ...styles }}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      borderBottom: 'unset',
                      flex: 1,
                      minWidth: headCell.minWidth || 0,
                      maxWidth: headCell.maxWidth,
                      overflow: 'hidden',
                    }}
                    onClick={(e) => {
                      if (headCell.isAccordion) {
                        handleAccordionToggle(
                          e,
                          rowsGroupIndex,
                          index,
                          accordionStatus,
                          setAccordionStatus,
                          row.data.id.value,
                        );
                      }
                    }}
                  >
                    {!headCell.isAccordion ? (
                      row.data[headCell.id]?.value
                    ) : (
                      <AccordionCellContent>
                        <RowDataValue
                          accordionStatus={accordionStatus}
                          rowsGroupIndex={rowsGroupIndex}
                          index={index}
                          row={row}
                          headCell={headCell}
                        />
                      </AccordionCellContent>
                    )}
                  </TableCell>
                );
              })}
            </StyledTableRow>

            <Accordion
              expanded={
                accordionStatus?.[rowsGroupIndex]?.value[index] || false
              }
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                style={{ display: 'none' }}
              />
              <AccordionDetails>
                <If
                  condition={
                    row.accordion &&
                    (accordionStatus?.[rowsGroupIndex]?.value[index] ||
                      keyArrayRef.current?.includes(row.data.id.value))
                  }
                  otherwise={
                    <LoadingContainer style={{ marginLeft: '20px' }}>
                      <LoadingPulse />
                    </LoadingContainer>
                  }
                >
                  <>{row.accordion}</>
                </If>
              </AccordionDetails>
            </Accordion>
            <div
              style={{
                borderBottom: `0.063rem solid ${theme.tableBorder}`,
              }}
            />
          </React.Fragment>
        );
      }),
    );
  });
  return tablesData;
};

// @ts-ignore
export const StyledTableRow = styled(TableRow)(() => ({
  height: '50px',
  position: 'sticky',
  top: '75px',
  background: themes?.default?.accordionWhiteBg,
  zIndex: '3',
  '&:hover': {
    '& td': {
      backgroundColor: themes?.default?.grey,
      '&:first-child': {
        backgroundColor: themes?.default?.grey,
      },
      '&:nth-child(2)': {
        backgroundColor: themes?.default?.grey,
      },
      '&:nth-child(3)': {
        backgroundColor: themes?.default?.grey,
      },
      '&:nth-last-child(2)': {
        backgroundColor: themes?.default?.grey,
      },
      '&:nth-last-child(3)': {
        backgroundColor: themes?.default?.grey,
      },
      '&:last-child': {
        backgroundColor: themes?.default?.grey,
      },
    },
  },
  '& td': {
    whiteSpace: 'nowrap !important',
    fontSize: '0.875rem',

    '&:first-child': {
      position: 'sticky',
      left: '0',
      width: '40px',
      paddingLeft: '20px',
      backgroundColor: themes?.default?.accordionWhiteBg,
    },
    '&:nth-child(2)': {
      position: 'sticky',
      left: '40px',
      backgroundColor: themes?.default?.whiteStickyBackGround,
      zIndex: 1,
      paddingLeft: '20px',
      paddingRight: '0',
    },
    '&:nth-child(3)': {
      position: 'sticky',
      left: '90px',
      backgroundColor: themes?.default?.whiteStickyBackGround,
      zIndex: 1,
      paddingLeft: '10px',
    },
    '&:nth-last-child(2)': {
      position: 'sticky',
      right: '60px',
      backgroundColor: themes?.default?.whiteStickyBackGround,
      zIndex: 1,
    },
    '&:nth-last-child(3)': {
      position: 'sticky',
      right: '180px',
      backgroundColor: themes?.default?.whiteStickyBackGround,
      zIndex: 1,
      marginLeft: 'auto',
    },
    '&:last-child': {
      position: 'sticky',
      right: 0,
      backgroundColor: themes?.default?.whiteStickyBackGround,
      zIndex: 1,
    },
  },
}));

export const RegularPageTitle = styled('div')`
  font-size: 1.125rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  height: 100%;
`;
