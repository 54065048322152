import React, { useContext, useMemo } from 'react';
import { ChatContext } from 'app/components/Chat';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ContactCard } from '../ContactCard';
import { LastContactCard } from '../LastContactCard';
import { ContactCardSkeleton } from '../ContactCardSkeleton';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'app/slices/auth/selectors';

export const AddContacts = () => {
  const {
    contacts,
    setSelectedUser,
    contactsLimit,
    setContactsLimit,
    initialItemsCount,
    totalDocs,
    isFetchingContacts,
    search,
    currentUserId,
  } = useContext(ChatContext);
  const { t } = useTranslation();

  const handleRenderMoreItems = () => {
    if (contacts?.length >= totalDocs) return;
    setContactsLimit(contactsLimit + initialItemsCount);
  };
  const authUser = useSelector(selectAuthUser);
  const currentOrganizationId = useMemo(() => {
    return authUser?._currentOrganization?._id;
  }, [authUser]);

  return (
    <Wrapper>
      <Typography
        fontSize="1.07rem"
        fontWeight={500}
        letterSpacing="0.15px"
        color={themes?.default?.black}
      >
        {t('suggestion')}
      </Typography>
      <List>
        {contacts?.map((contactObject, index) => {
          let contact = contactObject;

          contactObject?.agents?.forEach(
            (agent: { _organization: any; firstName: any; lastName: any }) => {
              if (agent?._organization === currentOrganizationId) {
                contact = {
                  ...contactObject,
                  ...agent,
                  _id: contactObject?._id,
                  fullName: `${agent?.firstName} ${agent?.lastName}`,
                  role: contactObject?.cache?.agents?.[currentOrganizationId]
                    ?._role,
                };
              }
            },
          );
          if (index !== contacts?.length - 1) {
            return (
              <ContactCard
                contact={contact}
                setSelectedUser={setSelectedUser}
              />
            );
          } else {
            return (
              <LastContactCard
                contact={contact}
                setSelectedUser={setSelectedUser}
                handleRenderMoreItems={handleRenderMoreItems}
                isCurrentUser={currentUserId === contact?._id}
              />
            );
          }
        })}
        {isFetchingContacts && !search && (
          <ContactCardSkeleton length={initialItemsCount} />
        )}
      </List>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  flex: 1;
  padding: 0 0 0 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: calc(100% - 60px - 24px);
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: auto;
  height: calc(100% - 20px - 8px);
  margin-bottom: 20px;
  padding-right: 17px;
`;
