import { styled } from '@mui/material/styles';
import { Checkbox as MuiCheckbox, CheckboxProps } from '@mui/material';
import { themes } from 'styles/theme/themes';

const CustomIcon = styled('span')(() => ({
  borderRadius: 2,
  width: 16,
  height: 16,
  fontSize: '1.5rem',
  border: `0.5px solid ${themes.default.nobel}`,
  backgroundColor: 'transparent',
  'input:disabled ~ &': {
    borderColor: `${themes.default.nobel}80`,
  },
  margin: '2.5px',
}));

export const Checkbox = (props: CheckboxProps) => {
  return (
    <MuiCheckbox
      sx={{
        '&:hover': { bgcolor: 'transparent' },
      }}
      icon={<CustomIcon />}
      {...props}
    />
  );
};
