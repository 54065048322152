import api from './api';

const activityApis = api.injectEndpoints({
  endpoints: builder => ({
    getActivities: builder.query<any, string>({
      query: urlQuery => ({
        url: `/activities?${urlQuery}`,
      }),
      providesTags: ['Activities'],
    }),
    getActivitiesStats: builder.query({
      query: () => ({
        url: '/activities/stats',
      }),
      providesTags: ['Activities'],
    }),
    activitiesRead: builder.mutation<any, { activityIds: string[] }>({
      query: ({ activityIds }) => ({
        url: `/activities/read`,
        method: 'POST',
        body: { activityIds },
      }),
      invalidatesTags: ['Activities'],
    }),
  }),
});

export const {
  useGetActivitiesQuery,
  useGetActivitiesStatsQuery,
  useActivitiesReadMutation,
} = activityApis;

export default activityApis;
