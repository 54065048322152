import { removeUndefined } from '../../../utils/dto';

const vehicleObjDto = (response: any) => {
  return {
    _id: response._id,
    engineRunning: response.engineRunning,
    status: response.status,
    fuelLevelPercentage: response.fuelLevelPercentage,
    numberPlate: response.numberPlate,
    parkNumber: response.parkNumber,
    brand: response.brand,
    logisticCategory: response.logisticCategory,
    lastSensors: response.lastSensors,
    _replacedBy: response._replacedBy,
    pluggedIn: response.pluggedIn,
    available: response.available,
    model: response.model,
  };
};

const vehiclesDocsDto = (response: any) => {
  if (!response) return;
  return response.map((res: any) => {
    return vehicleObjDto(res);
  });
};
export const getVehiclesDto = (response: any) => {
  return removeUndefined({
    // a: data.a1,
    // state: transferState(data.state1),
    _id: response._id,
    totalDocs: response.totalDocs,
    limit: response.limit,
    page: response.page,
    totalPages: response.totalPages,
    pagingCounter: response.pagingCounter,
    hasPrevPage: response.hasPrevPage,
    hasNextPage: response.hasNextPage,
    prevPage: response.prevPage,
    nextPage: response.nextPage,
    facets: response.facets,
    docs: vehiclesDocsDto(response.docs),
  });
};
