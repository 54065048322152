import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { StatusChip } from 'app/components/StatusChip';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
interface TablePaymentsProps {
  payments: any[];
  onSwitchStatus: Function;
  status: string;
}

export function TablePayments({
  payments,
  status,
  onSwitchStatus,
}: TablePaymentsProps) {
  const { t } = useTranslation();
  const theme = useSelector(selectTheme);

  return (
    <Grid
      sx={{
        m: '10px 20px',
      }}
    >
      <TableContainer>
        <Table
          size="small"
          sx={{
            backgroundColor: theme.baseWhite,
          }}
        >
          <TableHead
            sx={{
              backgroundColor: theme.cultured,
              height: '10px',
            }}
          >
            <TableRow>
              <TableCell align="left">
                {t('common.date').toUpperCase()}
              </TableCell>
              <TableCell align="left">
                {t('common.customer').toUpperCase()}
              </TableCell>
              <TableCell>{t('payments.amount').toUpperCase()}</TableCell>
              <TableCell>
                {t('payments.paymentMode.label').toUpperCase()}
              </TableCell>
              <TableCell>{t('payments.status').toUpperCase()}</TableCell>
              <TableCell>{t('payments.invoice').toUpperCase()}</TableCell>
              <TableCell>{t('payments.orderCode').toUpperCase()}</TableCell>
              {status === 'processed' && (
                <TableCell>{t('payments.processedBy').toUpperCase()}</TableCell>
              )}
              <TableCell>{t('common.action').toUpperCase()}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payments?.map((doc, index) => {
              const invoice = doc?._files?.[0] || null;

              const processedBy = doc.accountingStatusUpdatedBy?.currentAgent;

              return (
                <TableRow key={index}>
                  <TableCell>
                    <Typography>
                      {window.dayjs(doc?.statusUpdatedAt).format('D MMM YYYY')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        display: 'inline',
                        ':hover': {
                          textDecoration: 'underline',
                          color: theme.primary,
                        },
                      }}
                    >
                      {doc?._customer.code} - {doc?._customer.legalName}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{doc?.amount}</Typography>
                  </TableCell>
                  <TableCell>{t(`payments.paymentMode.${doc.type}`)}</TableCell>
                  <TableCell>
                    <StatusChip
                      text={t(`payments.status.${doc.status}`)}
                      status={doc.status}
                    />
                  </TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        display: 'inline',
                        ':hover': {
                          textDecoration: 'underline',
                          color: theme.primary,
                        },
                      }}
                      onClick={() => {
                        window.open(invoice.url);
                      }}
                    >
                      {invoice?.metadata?.invoiceCode}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{invoice?.metadata?.orderCode}</Typography>
                  </TableCell>
                  {status === 'processed' ? (
                    <TableCell>
                      <Tooltip
                        arrow
                        placement="top"
                        title={window
                          .dayjs(doc.accountingStatusUpdatedAt)
                          .format('D MMM YYYY')}
                      >
                        <span>
                          {processedBy?.firstName} {processedBy?.lastName}
                        </span>
                      </Tooltip>
                    </TableCell>
                  ) : null}
                  <TableCell>
                    <Button
                      variant="outlined"
                      color={
                        doc.accountingStatus === 'unprocessed'
                          ? 'primary'
                          : 'error'
                      }
                      sx={{ marginRight: 5 }}
                      onClick={() => onSwitchStatus(doc)}
                    >
                      {doc.accountingStatus === 'unprocessed'
                        ? t('payments.processAccounting')
                        : t('common.cancel')}
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
