import React from 'react';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { UserInfo } from 'app/components/Chat/components/ContactsList/components/ChatCard';
import EllipsisText from 'app/components/EllipsisText';
import { UserAvatar } from 'app/components/Chat/components/UserAvatar';
import { CustomIconButton } from 'app/components/Chat/components/CustomIconButton';
import { X } from '@phosphor-icons/react';

interface Props {
  senderPictureUrl: string;
  senderFullName: string;
  lastMessageAt: string;
  lastMessage: string;
  sendeUnreadMessagesCount: number;
  handleClose: () => void;
  onClick: () => void;
}

export const NewMessageNotification: React.FC<Props> = ({
  senderPictureUrl,
  senderFullName,
  lastMessageAt,
  lastMessage,
  sendeUnreadMessagesCount,
  handleClose,
  onClick,
}) => {
  return (
    <Wrapper>
      <Message onClick={onClick}>
        <MessageBody>
          <div
            style={{
              flex: '1',
              display: 'flex',
              alignItems: 'center',
              gap: '12px',
            }}
          >
            <UserAvatar
              size={36}
              userImagePath={senderPictureUrl}
              fullName={senderFullName}
              count={sendeUnreadMessagesCount || 0}
            />
            <UserInfo>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div style={{ display: 'flex', gap: '2px' }}>
                  <p className="name">{senderFullName}</p>
                </div>
                <p className="time">{lastMessageAt}</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p className="lastMessage">
                  <EllipsisText width={220} text={lastMessage} />
                </p>
              </div>
            </UserInfo>
          </div>
          <div style={{ margin: '-8px -7px 0 0' }}>
            <CustomIconButton icon={<X />} size={16} onClick={handleClose} />
          </div>
        </MessageBody>
      </Message>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  top: 2px;
  right: 16px;
  border-radius: 4px;
  overflow: hidden;
  box-shadow:
    0px 24px 51px -12px #2a8afb36,
    0px 4px 10px 0px #9575cd2e;
`;

const Message = styled.div`
  display: flex;
  min-width: 352px;
  width: fit-content;
  height: 54px;
  background-image: linear-gradient(180deg, #29b6f6 -0.09%, #2a59ff 105.26%);
  padding: 1px;
  cursor: pointer;
  border-radius: 4px;
`;

const MessageBody = styled.div`
  flex: 1;
  background: ${themes?.default?.lightBlueBg};
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  padding: 8px;
  gap: 4px;
`;
