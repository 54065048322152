import {
  Button,
  Dialog,
  DialogContent,
  RadioGroup,
  Typography,
} from '@mui/material';
import { UploadZone } from '../UploadFiles';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import If from '../If';
import styled from 'styled-components';
import SendIcon from '@mui/icons-material/Send';
import ClearIcon from '@mui/icons-material/Clear';
import { useDropzone } from 'react-dropzone';

export interface UploadedFile extends File {
  documentType: string;
}

interface UploadComponentProps {
  openModal: boolean;
  onClose: () => void;
  title: string;
  type: 'button' | 'dropzone';
  description: string;
}

export function UploadComponent({
  openModal,
  onClose,
  title,
  type,
  description,
}: UploadComponentProps) {
  const [files, setFiles] = useState<UploadedFile[]>([]);
  const { t } = useTranslation();

  const { getRootProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
  });

  const fileType = files.map((file) => {
    return file.type.split('/')[0];
  });

  const onUpload = (files: UploadedFile[]) => {
    setFiles(files);
  };

  const filesList = files.map((file) => (
    <RadioGroup key={file.name}>
      <li>
        {file.name} - {file.size} bytes
      </li>
    </RadioGroup>
  ));

  function HandleUploadFiles() {
    if (fileType[0] === 'image') {
      onClose();
    } else {
    }
  }

  function handleDeleteFiles() {
    setFiles([]);
  }

  return (
    <If
      condition={type === 'button'}
      otherwise={
        <Dialog
          open={openModal}
          onClose={onClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            '& .MuiDialog-container': {
              '& .MuiPaper-root': {
                width: '800px',
                height: files.length > 0 ? '420px' : '275px',
                maxWidth: '1200px',
                overflow: 'hidden',
              },
            },
            '.css-ypiqx9-MuiDialogContent-root': {
              overflow: 'hidden',
            },
          }}
        >
          <DialogContent>
            <Typography fontSize="1.25rem" fontWeight="500">
              <FileUploadIcon sx={{ mr: '1px', mb: '1px' }} />
              {title}
            </Typography>
            <div
              className="container"
              style={{ margin: '0 auto', marginTop: '5vh' }}
            >
              <UploadZone
                type="Logo"
                title=""
                description={description}
                onOk={onUpload}
                onUnOk={() => {}}
              />
            </div>
            <If condition={files.length > 0} otherwise={null}>
              <Typography fontSize="1rem" fontWeight="500" sx={{ mt: '20px' }}>
                {t('uploadFiles.files')} :
              </Typography>
            </If>
            <div
              className="filesWrapper"
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              {filesList}
            </div>
            <ButtonWrapper>
              <If condition={files.length > 0} otherwise={null}>
                <Button
                  style={{ width: '300px' }}
                  variant="contained"
                  onClick={HandleUploadFiles}
                  endIcon={<SendIcon />}
                  sx={{ mr: 5 }}
                >
                  {t('uploadFiles.button.send')}
                </Button>
                <Button
                  style={{ width: 300, textAlign: 'center' }}
                  startIcon={<ClearIcon />}
                  color="warning"
                  variant="contained"
                  onClick={() => handleDeleteFiles()}
                >
                  {t('uploadFiles.button.delete')}
                </Button>
              </If>
            </ButtonWrapper>
          </DialogContent>
        </Dialog>
      }
    >
      <div className="container">
        <div {...getRootProps({ className: 'dropzone' })}>
          <Button
            sx={{ ml: '16px', height: '30px', mb: '15px' }}
            type="button"
            onClick={open}
          >
            {t('settings.companyProfile.change')}
          </Button>
        </div>
      </div>
    </If>
  );
}

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 5vh;
  justify-content: center;
`;
