import React from 'react';
import { Button, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { IRoundtripMappingsContext, IWarehouseMapping } from '../../index';
import RoundtripFormRow from '../RoundtripFormRow';
import { Plus } from '@phosphor-icons/react';
import { themes } from '../../../../../../styles/theme/themes';
import { useManagedContext } from '../../../../../../common/UtilityComponents/ManagedContext/useManagedContext';
import { useTranslation } from 'react-i18next';

function WarehouseRoundtripsCardContent({
  warehouse,
}: {
  warehouse: IWarehouseMapping;
}) {
  const RoundtripMappingsContext = useManagedContext<IRoundtripMappingsContext>(
    'RoundtripMappingsContext',
  );
  const { t } = useTranslation();
  return (
    <CardContent
      sx={{
        paddingLeft: '30px',
        paddingRight: '15px',
        display: 'grid',
        position: 'relative',
        gridTemplateColumns: '35px repeat(3, 2fr) 35px',
        alignItems: 'center',
        gap: '10px',
      }}
    >
      <Stack />
      <Typography
        sx={{
          fontSize: '12px',
          fontWeight: '400',
          lineHeight: '17.16px',
          letterSpacing: '0.17px',
          textAlign: 'left',
        }}
      >
        {t('common.roundtrip') + ' Gescom'}
      </Typography>
      <Typography
        sx={{
          fontSize: '12px',
          fontWeight: '400',
          lineHeight: '17.16px',
          letterSpacing: '0.17px',
          textAlign: 'left',
        }}
      >
        {t('common.driver')}
      </Typography>
      <Typography
        sx={{
          fontSize: '12px',
          fontWeight: '400',
          lineHeight: '17.16px',
          letterSpacing: '0.17px',
          textAlign: 'left',
        }}
      >
        {t('common.vehicle')}
      </Typography>
      <Stack />

      {warehouse.roundtrips.map((roundtrip, i) => {
        return (
          <RoundtripFormRow
            index={i}
            warehouseId={warehouse.id}
            roundtrip={roundtrip}
            key={roundtrip.id}
          />
        );
      })}
      <Button
        startIcon={<Plus />}
        onClick={() => {
          RoundtripMappingsContext.updateDataWithFunction((prev) => {
            prev.roundtripMappings = prev.roundtripMappings.map((mapping) => {
              if (mapping.id === warehouse.id) {
                mapping.roundtrips = [
                  ...mapping.roundtrips,
                  {
                    id: `New`,
                    area: '',
                    roundtripCode: '',
                    driver: '',
                    vehicle: '',
                  },
                ];
                mapping.nbOfRoundtrips += 1;
              }
              return mapping;
            });
          });
        }}
        sx={{
          width: 'fit-content',
          marginLeft: '25px',
          color: themes.default.primary,
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
        disableRipple
      >
        {t('add')}
      </Button>
    </CardContent>
  );
}

export default WarehouseRoundtripsCardContent;
