import api from './api';

export const statsApi = api.injectEndpoints({
  endpoints: builder => ({
    getOrdersStats: builder.query<any, string>({
      query: urlParams => ({
        url: `/statistics/orders?${urlParams}`,
      }),
      extraOptions: {
        refetchOnFocus: true,
      },
      providesTags: ['StatsOrders', 'Stats'],
    }),
    getOrdersActivities: builder.query<any, string>({
      query: urlParams => ({
        url: `/statistics/activities?${urlParams}`,
        method: 'GET',
      }),
      providesTags: ['Stats'],
    }),
    getRoundtripStats: builder.query<any, string>({
      query: urlParams => ({
        url: `/statistics/roundtrips${urlParams}`,
        method: 'GET',
      }),
      providesTags: ['Stats'],
    }),
    getRoundtripsByDate: builder.query<any, string>({
      query: urlParams => ({
        url: `/statistics/roundtrips-today${urlParams}`,
        method: 'GET',
      }),
      providesTags: ['Stats', 'Roundtrips'],
    }),
    getRoundtripsDistance: builder.query<any, string>({
      query: urlParams => ({
        url: `/statistics/roundtrips-distance${urlParams}`,
        method: 'GET',
      }),
      providesTags: ['Stats'],
    }),
    getRoundtripCompletionRate: builder.query<any, string>({
      query: urlParams => ({
        url: `/statistics/roundtrips-completion-rate${urlParams}`,
        method: 'GET',
      }),
      providesTags: ['Stats'],
    }),
    getOrderDetailsStats: builder.query<any, string>({
      query: urlParams => ({
        url: `/statistics/orders-stats?${urlParams}`,
      }),
      providesTags: ['Stats'],
    }),
  }),
});

export const {
  useGetOrdersStatsQuery,
  useLazyGetOrdersStatsQuery,
  useLazyGetOrdersActivitiesQuery,
  useGetRoundtripStatsQuery,
  useLazyGetRoundtripStatsQuery,
  useGetRoundtripsByDateQuery,
  useGetRoundtripsDistanceQuery,
  useGetRoundtripCompletionRateQuery,
  useGetOrderDetailsStatsQuery,
} = statsApi;

export default statsApi;
