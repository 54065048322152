import { Chip } from '@mui/material';
import { themes } from 'styles/theme/themes';

export const OutlinedChip = ({ content }) => {
  return (
    <Chip
      label={content}
      size="small"
      sx={{
        backgroundColor: themes.default.accordionWhiteBg,
        border: `solid 1px ${themes.default.gainsboro2}`,
        borderRadius: '3px !important',
        padding: '4px 6px',
        '& .MuiChip-label': {
          padding: 0,
          lineHeight: 'normal',
        },
      }}
    />
  );
};
