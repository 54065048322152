import { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { VonageClient, ConfigRegion, LoggingLevel } from '@vonage/client-sdk';
import { useCreateVoiceJwtMutation } from 'common/services/calls';

declare global {
  interface Window {
    vonageClient: any;
    vonageSession: any;
    vonageJwt: any;
  }
}

export function useVonageHook(
  authUser,
  onCallInvite,
  onCallUpdate,
  onCallCreate,
  onCallDelete,
) {
  const [client] = useState(() => {
    // Initialize client with optional config (default: ERROR logging, US region).
    const client = new VonageClient({
      loggingLevel: LoggingLevel.Error,
      region: ConfigRegion.EU,
    });

    window.vonageClient = client;
    return client;
  });

  const [sessionUser, setSessionUser] = useState<any>();
  const [session, setSession] = useState<any>();
  const [createVoiceJwt] = useCreateVoiceJwtMutation();

  async function connectVonageUser() {
    const { jwt } = await createVoiceJwt({}).unwrap();
    const session = await window.vonageClient.createSession(jwt);

    console.log(session);
    setSession(session);

    const meUser = await client.getUser('me');

    setSessionUser(meUser);

    window.vonageSession = session;
    window.vonageJwt = jwt;
  }

  useEffect(() => {
    if (isEmpty(authUser)) return;
    connectVonageUser();

    const callInviteListener = window.vonageClient.on(
      'callInvite',
      async (callId: string, from: any) => {
        const callUser = await client.getUser(from);
        onCallInvite(callId, callUser);
      },
    );

    const callUpdateListener = window.vonageClient.on(
      'legStatusUpdate',
      (callId: string, legId: string, status: string) => {
        onCallUpdate(callId, legId, status);
      },
    );

    const conversationListener = window.vonageClient.on(
      'conversationEvent',
      (event) => {
        if (event.kind === 'member:invited') {
          if (
            event.body?.channel?.id &&
            event?.body?.user &&
            event?.body?.user?.name !== authUser?._id
          ) {
            onCallCreate(
              event.body.channel.id,
              event.body.user,
              event.conversationId,
            );
          }
        } else if (event.kind === 'member:left') {
          onCallDelete();
        }
      },
    );

    return () => {
      window.vonageClient.off('callInvite', callInviteListener);
      window.vonageClient.off('legStatusUpdate', callUpdateListener);
      window.vonageClient.off('conversationEvent', conversationListener);
    };
  }, [authUser?.voiceStatus]);

  return { sessionUser, session, client };
}
