export interface CustomerDocs {
  _id?: string;
  id?: string;
  code: string;
  createdAt: string;
  email: string;
  invoicePeriod: string;
  paymentPeriod: number;
  legalId: string;
  legalName: string;
  phone: string;
  publicName: string;
  updatedAt: string;
  vatNumber: string;
  mainLocation?: { geometry: { coordinates: number[] } };
  _organizationDeck?: { _id: string; nextDeliveryDate: string };
}

export interface ContactData {
  email: string;
  subject: string;
  message: string;
  token: string;
  password: string;
}

export enum CustomerStatus {
  IN_REVIEW = 'in-review',
  ACTIVE = 'active',
  ON_WATCH = 'on-watch',
  BLOCKED = 'blocked',
  ARCHIVED = 'archived',
}

export enum CustomerActions {
  CUSTOMER_EDIT = 'customer-edit',
  CUSTOMER_ADD = 'customer-add',
  CUSTOMER_EDIT_DECK = 'customer-edit-deck',
  CUSTOMER_ADD_DECK = 'customer-add-deck',
  CUSTOMER_CANCEL_SEPA = 'customer-cancel-sepa',
  CUSTOMER_VALIDATE_SEPA = 'customer-validate-sepa',
  CUSTOMER_DELETE_DECK = 'customer-delete-deck',
  CUSTOMER_STATUS_UPDATED = 'customer-status-updated',
}
