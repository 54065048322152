import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EnhancedTableToolbar } from 'app/components/EnhancedTableToolbar';
import { TabsMenu } from 'app/components/TabsMenu';
import { InfoForm } from './InfoTab';
import { BranchGrid } from './BranchTab';
import { AddIconButton } from 'app/components/AddIconButton';
import { DrawerComponent } from 'app/components/DrawerComponent';
import { GRID, MAP } from 'app/components/TableComponent/constants';
import { TableViewPopover } from 'styles/components/muitable/TableViewPopover';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { MapPin, Table } from '@phosphor-icons/react';
import { useFilterState } from 'app/components/FilterComponents/hooks/useFilterState';
import { useGrid } from 'app/components/Grid/hooks';
import { PlacesFilter } from '../Filter';
import FilterIcon from 'app/components/FilterComponents/components/FilterIcon';
import If from 'app/components/If';
import { useParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import { themes } from 'styles/theme/themes';
import { Columns } from '../../data';
import { useDisabledToolbar } from 'app/components/EnhancedTableToolbar/hooks/useDisabledToolbar';
import { getFilterCount } from 'app/helpers/helpers';
import { useApiCalls } from '../../hooks/useApiCalls';
import { usePlaceContext } from '../../hooks/usePlaceContext';
import {
  bindShortcut,
  unbindShortcut,
} from 'app/pages/RoundTrips/components/KeyboardShortcuts/functions';
import { useLazyGetCustomerBranchQuery } from 'common/services/customerApi';
import { BranchForm } from './BranchForm';
import { useTabs, TabType } from 'app/components/TabsMenu/hooks/useTabs';
import { isEmpty } from 'lodash';
import {
  PageWrapper,
  TabsSection,
} from 'app/styledComponents/DetailsSection.styled';
import { selectIsZoomedIn } from 'app/slices/tools/selectors';
import { HistoryTab } from './HistoryTab';

export const CustomerTabs = ({ customerDetail }) => {
  const { t } = useTranslation();
  const theme = useSelector(selectTheme);
  const zoomedIn = useSelector(selectIsZoomedIn);

  const { id } = useParams<Record<string, string>>();

  const { refetch, branches } = useApiCalls(id || '');
  const {
    filters,
    debouncedSearchTerm,
    totalRows,
    debouncedFilters,
    search,
    setSearch,
    setFilters,
  } = useGrid();

  const { disabledToolbar } = useDisabledToolbar({
    total: totalRows,
    searchTerm: debouncedSearchTerm,
    filterCount: getFilterCount(debouncedFilters),
  });

  const {
    drawerOpen,
    setDrawerOpen,
    setSelectedBranchId,
    selectedBranchId,
    canEdit,
  } = usePlaceContext();

  const [selectedTab, setSelectedTab] = useState('info');

  const [headCells, setHeadCells] = useState<any>(Columns);
  const handleOnSwitchTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const [getBranchData, { data: branchData, isFetching }] =
    useLazyGetCustomerBranchQuery();

  useEffect(() => {
    if (selectedBranchId !== '') {
      getBranchData({
        customerId: id,
        branchId: selectedBranchId,
      });
    }
  }, [drawerOpen, selectedBranchId]);

  const {
    filterOn,
    handleCloseFilter,
    handleOpenFilter,
    debouncedFiltersCount,
  } = useFilterState({ filters });

  const [type, setType] = useState<typeof GRID | typeof MAP>(GRID);

  const shortcuts = [
    {
      keybinds: ['ctrl+1'],
      callback: () => setSelectedTab('info'),
      listener: 'keydown',
    },
    {
      keybinds: ['ctrl+2'],
      callback: () => setSelectedTab('branches'),
      listener: 'keydown',
    },
    {
      keybinds: ['ctrl+3'],
      callback: () => setSelectedTab('accounting'),
      listener: 'keydown',
    },
    {
      keybinds: ['ctrl+4'],
      callback: () => setSelectedTab('financials'),
      listener: 'keydown',
    },
    {
      keybinds: ['ctrl+5'],
      callback: () => setSelectedTab('contracts'),
      listener: 'keydown',
    },
    {
      keybinds: ['ctrl+a'],
      callback: () => {
        setSelectedBranchId('');
        setDrawerOpen((drawerOpen) => !drawerOpen);
      },
      listener: 'keydown',
    },
  ];

  const handleBindShortcuts = useMemo(() => {
    return () => {
      shortcuts.map((shortcut) => {
        bindShortcut(shortcut.keybinds, shortcut.callback);
      });
    };
  }, []);

  const handleUnbindShortcuts = useMemo(() => {
    return () => {
      shortcuts.map((shortcut) => {
        unbindShortcut(shortcut.keybinds);
      });
    };
  }, []);

  useEffect(() => {
    handleBindShortcuts();
    return () => {
      handleUnbindShortcuts();
    };
  }, [handleBindShortcuts, handleUnbindShortcuts]);

  const defaultTabs: TabType[] = useMemo(() => {
    return [
      {
        value: 'info',
        label: t('info'),
      },
      {
        value: 'branches',
        label: t('common.branches'),
      },
      {
        value: 'accounting',
        label: t('customer_Accounting'),
      },
      {
        value: 'financials',
        label: t('customer_Financials'),
      },
      {
        value: 'contracts',
        label: t('customer_Contracts'),
      },
      {
        value: 'history',
        label: t('common.history'),
      },
    ];
  }, []);

  const [tabs, setTabs] = useTabs({
    defaultTabs: defaultTabs,
    moduleName: 'customerInfo',
  });
  const filterActivated =
    filters?.deliveryTypes?.length ||
    filters?.vehicleCondition?.length ||
    filters?.status?.length ||
    search !== '';

  useEffect(() => {
    if (isEmpty(filters)) {
      setFilters({
        deliveryTypes: [],
        vehicleCondition: [],
        status: [],
      });
    }
  }, [filters, setFilters]);

  const height = useMemo(() => {
    const addedHeight = selectedTab === 'leaveRequests' ? '+ 16px' : ''; //no padding for leave requests tab
    if (zoomedIn) {
      return `calc(100vh - 185px ${addedHeight})`;
    }
    return `calc(100vh - 185px - ${themes.default?.topBarHeight} ${addedHeight})`;
  }, [zoomedIn, selectedTab]);

  return (
    <>
      <If
        condition={(!!branchData || isFetching) && selectedBranchId !== ''}
        otherwise={
          <DrawerComponent
            title={t('customer.addBranch.title')}
            open={drawerOpen}
            handleClose={() => {
              setDrawerOpen(false);
            }}
            width="839px"
          >
            <BranchForm
              onClose={() => {
                setDrawerOpen(false);
              }}
              open={drawerOpen}
              onOk={() => {
                refetch();
              }}
              selectedBranch={null}
            />
          </DrawerComponent>
        }
      >
        <DrawerComponent
          title={t(
            !!canEdit ? 'customer.editBranch.title' : 'customer.view_branch',
          )}
          open={drawerOpen}
          handleClose={() => {
            setDrawerOpen(false);
          }}
          width="839px"
        >
          <If condition={!isFetching && branchData}>
            <BranchForm
              onClose={() => {
                setDrawerOpen(false);
              }}
              open={drawerOpen}
              onOk={() => {
                refetch();
              }}
              selectedBranch={branchData}
            />
          </If>
        </DrawerComponent>
      </If>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        padding="0 20px"
        borderBottom={`1px solid ${themes.default.lightFill}`}
        sx={{
          backgroundColor: 'white',
          height: themes.default.tabsBarHeight,
        }}
      >
        <TabsMenu
          handleOnSwitchTab={handleOnSwitchTab}
          selectedTab={selectedTab}
          defaultTabs={defaultTabs}
          tabs={tabs}
          setTabs={setTabs}
        />

        <If
          condition={
            selectedTab === 'branches' &&
            (branches.docs.length || (!branches.docs.length && filterActivated))
          }
        >
          <EnhancedTableToolbar
            search={search}
            setSearch={setSearch}
            headCells={headCells}
            setHeadCells={setHeadCells}
            disabled={!!disabledToolbar}
            filterComponents={
              <FilterIcon
                filterOn={filterOn}
                handleOpenFilter={handleOpenFilter}
                filtersCount={debouncedFiltersCount}
                filterComponent={
                  <PlacesFilter
                    filterOn={filterOn}
                    handleCloseFilter={handleCloseFilter}
                  />
                }
              />
            }
            actionsComponents={
              <>
                {type === GRID || type === MAP ? (
                  <TableViewPopover
                    views={[
                      {
                        type: GRID,
                        icon: (
                          <Table
                            style={{
                              color: theme.icon,
                            }}
                          />
                        ),
                        label: `Grid`,
                      },
                      {
                        type: MAP,
                        icon: (
                          <MapPin
                            style={{
                              color: theme.icon,
                            }}
                          />
                        ),
                        label: `Map`,
                      },
                    ]}
                    tableType={type}
                    handleAction={(e, action) => {
                      if (action === MAP) {
                        setType(MAP);
                      }
                      if (action === GRID) {
                        setType(GRID);
                      }
                    }}
                  />
                ) : null}
                <If condition={!!canEdit}>
                  <AddIconButton
                    onClick={() => {
                      setDrawerOpen(true);
                      setSelectedBranchId('');
                    }}
                    tooltip={t(`Add-Branch`)}
                  />
                </If>
              </>
            }
          />
        </If>
      </Stack>
      <PageWrapper>
        <TabsSection height={height} padding="0">
          <If condition={selectedTab === 'info' && !!customerDetail}>
            <InfoForm customerDetail={customerDetail} />
          </If>
          <If condition={selectedTab === 'branches'}>
            <BranchGrid type={type} />
          </If>
          <If condition={selectedTab === 'history'}>
            <HistoryTab customerId={customerDetail?._id} />
          </If>
        </TabsSection>
      </PageWrapper>
    </>
  );
};
