import { useCallback, useEffect, useRef, useState } from 'react';
import { Typography, List, ListItem } from '@mui/material';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getStatusColor } from 'app/pages/AddRoundTrips/functions';
import { useLazyGetOrderActivityQuery } from 'common/services/orderApi';
import { StatusOrder } from '../../../../AddRoundTrips/data/RoundTripData/components/StatusOrder';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'app/slices/auth/selectors';
import { useInfiniteScroll } from 'hooks/useInfiniteScroll';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LoadingPulse } from 'app/components/LoadingPulse';
import { themes } from 'styles/theme/themes';

interface Action {
  _id: string;
  createdAt: string;
  data: { newStatus: string };
  _user: { _id: string; fullName: string; firstName: string; lastName: string };
}

interface TrackOrderTabProps {
  orderId: string;
  orderStatus?: string;
  height?: number;
}

export function TrackOrderTab({
  orderId,
  orderStatus,
  height,
}: TrackOrderTabProps) {
  const authUser = useSelector(selectAuthUser);
  const { i18n, t } = useTranslation();
  const [trigger, { data }] = useLazyGetOrderActivityQuery();
  const [activities, setActivities] = useState([]);
  const page = useRef(1);

  useInfiniteScroll({
    responseData: data?.docs,
    data: activities,
    setData: setActivities,
    page: page.current,
  });

  const fetchData = useCallback(
    (page: number) => {
      if (!page) return;
      trigger({
        id: orderId,
        query: `limit=20&page=${page}`,
      });
    },
    [orderId, trigger],
  );

  const loadMore = () => {
    page.current += 1;
    fetchData(page.current);
  };

  useEffect(() => {
    if (page.current !== 1) return;
    fetchData(1);
  }, [fetchData, orderId, orderStatus]);

  const formatDate = useCallback(
    (dateString: string) => {
      const date = new Date(dateString);
      const locale = i18n.language;
      return date.toLocaleDateString(locale, {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    },
    [i18n.language],
  );

  const formatTime = useCallback(
    (dateString: string) => {
      const date = new Date(dateString);
      const locale = i18n.language;
      return date.toLocaleTimeString(locale, {
        hour: '2-digit',
        minute: '2-digit',
      });
    },
    [i18n.language],
  );

  const actions = useCallback(() => {
    if (!activities) return {};

    return activities.reduce((acc, curr: Action, index) => {
      const date = formatDate(curr.createdAt);
      const time = formatTime(curr.createdAt);
      let name =
        curr._user?._id === authUser?._id ? t('you') : curr._user?.fullName;
      const created = index === 0;
      if (curr?._id === 'init') {
        name = curr?._user?.firstName;
      }
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push({
        time,
        status: curr.data?.newStatus,
        updatedBy: name,
        created,
      });
      return acc;
    }, {});
  }, [activities, authUser?._id, formatDate, formatTime, t]);

  const groupedActions = actions();

  return (
    <ScrollContainer height={height} id="scrollable-timeline">
      <InfiniteScroll
        dataLength={activities?.length || 0}
        next={loadMore}
        hasMore={data?.hasNextPage}
        loader={
          <LoaderText>
            <LoadingPulse />
          </LoaderText>
        }
        scrollableTarget="scrollable-timeline"
        style={{ overflow: 'visible' }}
      >
        {Object.keys(groupedActions).map((date, parentIndex) => (
          <DayContainer key={parentIndex}>
            <DayContent>{date}</DayContent>
            <List>
              {groupedActions[date].map((item, index) => (
                <ListItem key={index} sx={{ paddingLeft: '0' }}>
                  <StatusBlock
                    status={item?.status}
                    isLast={
                      parentIndex === Object.keys(groupedActions).length - 1 &&
                      index === groupedActions[date].length - 1
                    }
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography
                        marginRight="20px"
                        color={themes.default.lightGrayLabel}
                      >
                        {item.time}
                      </Typography>
                      <StatusOrder status={item?.status} />
                    </div>
                    {item?.updatedBy && (
                      <Typography
                        color={themes.default.lightGrayLabel}
                        fontSize="0.857rem"
                        marginTop="10px"
                        variant="body1"
                      >
                        <i>
                          {t(
                            item?.created ? 'common.created_by' : 'updated_by',
                          )}{' '}
                          {item.updatedBy}
                        </i>
                      </Typography>
                    )}
                  </StatusBlock>
                </ListItem>
              ))}
            </List>
          </DayContainer>
        ))}
      </InfiniteScroll>
    </ScrollContainer>
  );
}

const ScrollContainer = styled.div<{ height?: number }>`
  height: ${(props) =>
    props.height ? `calc(${props.height}px - 170px)` : 'auto'};
  overflow-y: auto;
  margin-top: 25px;
  padding: 0 20px;
  position: relative;
`;

const LoaderText = styled.div`
  text-align: center;
  padding: 20px 0;
`;

const StatusBlock = styled.div<any>`
  position: relative;
  padding: 10px 0; /* Space between each status item */

  /* Line before the status (from top to the status) */
  &::before {
    ${(props) => (!props.isLast ? ' content: "" ' : '')};
    position: absolute;
    top: 30px;
    left: -20px; /* Adjust to position the line correctly */
    width: 2px;
    height: 100%; /* Half the height of the item */
    background-color: rgba(224, 224, 224, 1); /* Color of the line */
  }

  /* Line after the status (from status to the bottom) */
  &::after {
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 10px;
    top: 19px;
    left: -22px;
    background-color: ${(props) =>
      props.isLast ? getStatusColor(props.status) : 'rgba(224,224,224,1)'};
  }
`;

const DayContainer = styled.div`
  position: relative;
  padding-left: 30px;
  margin-bottom: 20px;
`;

const DayContent = styled.h6`
  position: relative;
  font-size: 1.143rem;
  margin-bottom: 10px;
  margin-top: 10px;

  &::before {
    content: '';
    position: absolute;
    top: 22px;
    left: -20px;
    width: 2px;
    height: 175%;
    background-color: rgba(224, 224, 224, 1);
  }

  &::after {
    content: '';
    position: absolute;
    width: 9px;
    height: 9px;
    border-radius: 10px;
    top: 8px;
    left: -23px;
    background-color: rgba(224, 224, 224, 1);
  }
`;
