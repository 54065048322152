import { themes } from 'styles/theme/themes';
import { StatusBranch } from './BranchStatus';

export enum ClusterStatus {
  Active = 'active',
  InActive = 'inactive',
}
export const status = [
  { status: 'Full' },
  { status: 'First' },
  { status: 'None' },
];
export const BranchStatuses = [
  {
    classNameCircle: 'orange_circle',
    status: StatusBranch.Active,
    color: themes?.default?.green3,
  },
  {
    classNameCircle: 'orange_circle',
    status: StatusBranch.InActive,
    color: themes?.default?.gainsboro,
  },
  {
    classNameCircle: 'orange_circle',
    status: StatusBranch.Stopped,
    color: themes?.default?.warningMain,
  },
  {
    classNameCircle: 'orange_circle',
    status: StatusBranch.Blocked,
    color: themes?.default?.errorMain,
  },
  {
    classNameCircle: 'orange_circle',
    status: StatusBranch.Preliminary,
    color: themes?.default?.TurquoiseSurf,
  },
];
export const BranchStatus = [
  StatusBranch.Active,
  StatusBranch.InActive,
  StatusBranch.Stopped,
  StatusBranch.Blocked,
  StatusBranch.Preliminary,
];
