import { Stack } from '@mui/material';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { Grid3x3Outlined } from '@mui/icons-material';
import { Icon } from 'app/components/Icon';
import { Star } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import EllipsisText from 'app/components/EllipsisText';
import If from 'app/components/If';
import { IGridContext } from 'app/components/ManagedGrid/types';
import { IEmployeeRowData } from 'app/pages/Users/data/types';
import { UserDoc } from 'common/types/User';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';

export const NameCell = (params) => {
  const { t } = useTranslation();
  const gridContext =
    useManagedContext<IGridContext<UserDoc, IEmployeeRowData>>('grid');

  return (
    <StyledStack
      flexDirection="row"
      gap="12px"
      alignItems="center"
      onClick={() => {
        gridContext.updateDataWithFunction((prev) => {
          prev.selectedRow = params.value.cellData.fullProduct;
        });
      }}
    >
      <ImageContainer>
        <img src={params.value.cellData.pictureUrl} alt="prod" />
      </ImageContainer>
      <Stack gap="2px">
        <Stack flexDirection="row" gap="8px" alignItems="center">
          <ItemCode>
            <span>
              <Icon
                icon={
                  <Grid3x3Outlined
                    sx={{
                      width: '16px',
                      color: `${themes?.default?.black}8A`,
                    }}
                  />
                }
              />
            </span>
            {params.value.cellData.code}
          </ItemCode>
          <If condition={params.value.cellData.isNew}>
            <NewChip>
              <Icon
                icon={<Star weight="fill" />}
                size={10}
                color={themes?.default?.accordionWhiteBg}
              />
              {t('new')}
            </NewChip>
          </If>
        </Stack>
        <ProdName>
          <EllipsisText width={190} text={params.value.cellData.name} />
        </ProdName>
      </Stack>
    </StyledStack>
  );
};

const StyledStack = styled(Stack)`
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

const ImageContainer = styled.div`
  img {
    height: 40px;
    width: 40px;
    border-radius: 4px;
  }
`;

export const ItemCode = styled.p`
  margin: 0;
  font-size: 0.625rem;
  font-weight: 400;
  line-height: 0.75rem;
  color: ${themes?.default?.textColorDashboard};
`;

export const NewChip = styled.div`
  margin-left: 2px;
  height: 16px;
  padding: 2px 11px;
  display: flex;
  align-items: center;
  gap: 2px;
  border-radius: 5px;
  background-color: ${themes?.default.ordoriaLight};
  font-size: 0.625rem;
  line-height: 10px;
  color: ${themes?.default?.accordionWhiteBg};
`;

export const ProdName = styled.p`
  margin: 0;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1rem;
  text-align: left;
  color: ${themes?.default?.textBlack};
`;
