import React, { useEffect, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Button } from '@mui/material';
import { roundtripActions } from 'common/store/roundtrips';
import { useDispatch } from 'react-redux';
import { selectEditingRoundtripVehicle } from 'common/store/roundtrips/selectors';
import { useSelectors } from './hook/useSelector';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { themes } from 'styles/theme/themes';
import { SelectorRenderOption } from './components/SelectorRenderOption';
import { getVehicleLabel } from '../../../../../../utils/pages/vehicles';
import { AgentCategories } from 'app/components/RoundTrips/RoundTripData/agentCategories';
import { Plus } from '@phosphor-icons/react';

interface VehicleSelectorProps {
  vehicle: any;
  VehiculeProps: { getOptionLabel: any; options: any };
  vehicles: any;
  addFuction: any;
  roundtripId: string;
  updateRoundtripData: any;
  schedule?: Function;
  clusterId?: string;
  clusterStatus?: string;
  userContext?: AgentCategories;
  minimal?: boolean;
}

const VehicleSelector = React.memo(
  function VehicleSelector({
    vehicle,
    vehicles,
    addFuction,
    roundtripId,
    updateRoundtripData,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
    schedule,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
    clusterId,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
    clusterStatus,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
    userContext,
    minimal,
  }: VehicleSelectorProps) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [selectedVehicle, setSelectedVehicle] = useState(vehicle);
    const selectedRef = useRef(null);

    useEffect(() => {
      setSelectedVehicle(vehicle);
    }, [vehicle]);

    const {
      handleSelectClick,
      handleSelectLeave,
      isSelected,
      loadingUpdate,
      editProduct,
      setLoadingUpdate,
      setIsSelected,
    } = useSelectors(
      selectedRef,
      roundtripActions,
      selectEditingRoundtripVehicle,
      vehicles,
    );
    const VehiculeProps = {
      options: !vehicles ? [{ label: 'Loading...', id: 0 }] : vehicles,
      // getOptionDisabled: option => option.disabled,
      getOptionLabel: (option: any) => getVehicleLabel(option),
      renderOption: (props, option) => {
        return (
          <SelectorRenderOption
            props={props}
            type="vehicle"
            option={option}
            minimal={minimal}
          />
        );
      },
    };

    const displaySelected =
      !!selectedVehicle?.numberPlate && !isSelected
        ? {
            display: 'flex',
            height: '100%',
            alignItems: 'center',
            padding: minimal ? 0 : '22px 16px',
          }
        : { display: 'none' };

    const displayAdd =
      !selectedVehicle?.numberPlate && !isSelected
        ? {
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            padding: minimal ? 0 : '0 16px',
          }
        : { display: 'none' };

    const addButton = {
      justifyContent: 'flex-start',
      color: themes?.default?.iconColor,
      border: `0.5px solid ${themes?.default?.gainsboro2}`,
      padding: '2px 6px !important',
      borderRadius: '20px',
      alignItems: 'center',
      gap: '2px',
      textTransform: 'none',
      fontSize: '0.625rem',
      lineHeight: '9px',
      fontWeight: '400',
      maxWidth: '47px',
      minWidth: '47px',
      maxHeight: '20px',
      minHeight: '20px',
      '&:hover': {
        backgroundColor: themes?.default?.gainsboro2,
      },
    };

    return (
      <div
        ref={selectedRef}
        style={{
          width: '100%',
          minHeight: minimal ? '20px' : '40px',
          cursor: 'text',
        }}
        onClick={() => {
          setIsSelected(true);
          handleSelectClick();
        }}
      >
        <div style={displayAdd}>
          <Button size="small" sx={addButton}>
            <Plus size={9} color={themes?.default?.iconColor} />
            {t('common.add')}
          </Button>
        </div>
        <div style={displaySelected} id={`${roundtripId}vehicle`}>
          {selectedVehicle?.model}
          {selectedVehicle?.model && selectedVehicle?.numberPlate ? '/' : ''}
          {selectedVehicle?.numberPlate}
          {vehicle?.parkNumber && !loadingUpdate
            ? `/${vehicle.parkNumber}`
            : ''}
        </div>
        {isSelected && (
          <Autocomplete
            style={{ flex: 1, border: 'none', width: '280px' }}
            {...VehiculeProps}
            autoComplete={false}
            open={true}
            disabled={editProduct}
            onChange={async (e, value) => {
              //hide condition
              // if (clusterStatus === 'active' && vehicle?.engineRunning) {
              //   toast(
              //     5000,
              //     'error',
              //     userContext === AgentCategories.Sales
              //       ? 'alerts.roundtrips.prevent_clear_sales'
              //       : 'alerts.roundtrips.prevent_clear',
              //   );
              //   return;
              // }
              setSelectedVehicle(value);
              setLoadingUpdate(true);
              dispatch(roundtripActions.setEditingRoundtripVehicle(true));
              try {
                await addFuction({
                  id: roundtripId,
                  data: {
                    _vehicle: value?._id || null,
                  },
                  UpdateRoundtripData: updateRoundtripData,
                });
              } catch (e) {
                setSelectedVehicle(vehicle);
              }
              setLoadingUpdate(false);
              dispatch(roundtripActions.setEditingRoundtripVehicle(false));
              handleSelectLeave();
              //    vehicleData = value?.['_id'] || '';
            }}
            isOptionEqualToValue={(option, value) => {
              return option?.['_id'] === value?.key;
            }}
            defaultValue={{
              key: vehicle?.['_id'],
              model: vehicle?.model,
              numberPlate: vehicle?.numberPlate,
              parkNumber: vehicle?.parkNumber,
              _id: vehicle?.['_id'],
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '0',
                padding: '0',
              },
              '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                name="vehicle"
                size="small"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  style: {
                    fontSize: '0.875rem',
                    alignItems: 'center',
                    padding: minimal ? '0 65px 0 0' : 'auto',
                  },
                }}
              />
            )}
          />
        )}
      </div>
    );
  },
  (prevProps, nextProps) => {
    return (
      _.isEqual(prevProps.vehicle, nextProps.vehicle) &&
      _.isEqual(prevProps.vehicles, nextProps.vehicles) &&
      prevProps.clusterStatus === nextProps.clusterStatus
    );
  },
);

export default VehicleSelector;
