import { createGlobalStyle } from 'styled-components';
/* istanbul ignore next */
export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    line-height: 1.5;
  }

  body {
    font-family: 'Roboto';
    background-color: ${(p) => p.theme.background};
  }

  body.fontLoaded {
    font-family: 'Roboto';
  }
  
  p,
  label {
    line-height: 1.5em;
  }

  input, select, button {
    font-family: inherit;
    font-size: inherit;
  }

  .icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .MuiFormControl-root {
    margin-bottom: 16px !important;
  }
  
  .bamptee-pdf-viewer .react-pdf__Page__canvas {
    margin: auto;
  }
`;
