export const cancelationReason = [
  {
    label: 'wrong_address',
  },
  {
    label: 'shop_closed',
  },
  {
    label: 'restricted_access',
  },
  {
    label: 'no_one_available_receive',
  },
  {
    label: 'delivery_refused',
  },
  {
    label: 'delivery_schedule_conflict',
  },
  {
    label: 'payment_issues',
  },
  {
    label: 'other',
  },
];
