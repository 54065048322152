import api from './api';

export const roleApi = api.injectEndpoints({
  endpoints: builder => ({
    getRoles: builder.query<any, string>({
      query: urlQuery => ({
        url: `/roles${urlQuery}`,
      }),
      providesTags: ['RolePermissions'],
    }),
    createRole: builder.mutation<any, any>({
      query: body => ({
        url: `/roles`,
        method: 'POST',
        body: { ...body },
      }),
      invalidatesTags: ['RolePermissions', 'OrganizationConfiguration'],
    }),
    editRole: builder.mutation<any, any>({
      query: body => ({
        url: `/roles/${body.id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['RolePermissions', 'OrganizationConfiguration'],
    }),
    toggleRoleView: builder.mutation<any, { roleId: string; appIds: string[] }>(
      {
        query: body => ({
          url: `/roles/${body.roleId}/toggle-role-visibility`,
          method: 'PATCH',
          body: { apps: body.appIds },
        }),
        invalidatesTags: ['RolePermissions'],
      },
    ),
    deleteRole: builder.mutation<any, any>({
      query: id => ({
        url: `/roles/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['RolePermissions', 'OrganizationConfiguration'],
    }),
    bulkDelete: builder.mutation<any, any>({
      query: body => ({
        url: `/roles/bulk`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['RolePermissions', 'OrganizationConfiguration'],
    }),
    changeRoleStatus: builder.mutation<any, any>({
      query: body => ({
        url: `/roles/activate/${body?.id}/${body?.status}`,
        method: 'PATCH',
      }),
      invalidatesTags: ['RolePermissions', 'OrganizationConfiguration'],
    }),
    getRole: builder.query<any, any>({
      query: id => ({
        url: `/roles/${id}`,
        method: 'GET',
      }),
      providesTags: ['RolePermissions'],
    }),
  }),
});

export const {
  useCreateRoleMutation,
  useGetRolesQuery,
  useEditRoleMutation,
  useToggleRoleViewMutation,
  useDeleteRoleMutation,
  useBulkDeleteMutation,
  useChangeRoleStatusMutation,
  useGetRoleQuery,
} = roleApi;

export default roleApi;
