import {
  useDeleteProductNoteMutation,
  useLazyGetProductNotesQuery,
  usePatchProductNoteMutation,
  useCreateProductNoteMutation,
} from 'common/services/orderApi';
import { useEffect, useState } from 'react';
import { useToaster } from 'hooks/useToaster';
import NoteContent from 'app/components/Note/components/NoteContent';

export function ProductNotes({
  open,
  onCloseModal,
  productId,
  orderId,
  viewMode,
  refreshData,
}) {
  const toast = useToaster();

  const [note, setNote] = useState<string>('');
  const [updatedNote, setUpdatedNote] = useState<string>('');
  const [createNotes] = useCreateProductNoteMutation();
  const [deleteNote] = useDeleteProductNoteMutation();
  const [patchNote] = usePatchProductNoteMutation();

  const [trigger, { data: notesData }] = useLazyGetProductNotesQuery();

  //TODO: make these functions reusable
  async function handleAddNote() {
    if (note !== '') {
      await createNotes({
        orderId,
        productId,
        content: note,
      })
        .unwrap()
        .then(() => {
          trigger({ orderId, productId });
          setNote('');
          toast(5000, 'success', 'alerts.notes.add_success');
        })
        .catch((err) => console.log(err));

      refreshData();
    }
  }

  const fetchNotes = () => {
    if (orderId && productId) {
      trigger({ orderId, productId });
    }
  };

  useEffect(() => {
    fetchNotes();
  }, [orderId, productId]);

  function handleNoteChange(event) {
    setNote(event.target.value);
  }

  function handleUpdatedNoteChange(event) {
    setUpdatedNote(event.target.value);
  }

  async function handleDeleteNote(noteId: string) {
    await deleteNote({
      orderId: orderId,
      noteId: noteId,
    }).then(() => {
      toast(5000, 'success', 'alerts.notes.delete_success');
      fetchNotes();
    });
    refreshData();
  }

  async function handleUpdateNote(noteId: string) {
    if (updatedNote !== '') {
      await patchNote({
        orderId: orderId,
        noteId: noteId,
        content: updatedNote,
      })
        .unwrap()
        .then(() => {
          toast(5000, 'success', 'alerts.notes.update_success');
          fetchNotes();
          setUpdatedNote('');
        });
      refreshData();
    }
  }

  return (
    <NoteContent
      open={open}
      note={note}
      onCloseModal={onCloseModal}
      viewMode={viewMode}
      notesData={notesData}
      handleAddNote={handleAddNote}
      handleNoteChange={handleNoteChange}
      handleDeleteNote={handleDeleteNote}
      handleUpdatedNoteChange={handleUpdatedNoteChange}
      handleUpdateNote={handleUpdateNote}
    />
  );
}
