import TruckIcon from 'app/components/TruckIcon';
import { useMemo } from 'react';
import { getRoundtripUserAndVehicleInfo } from 'app/helpers/helpers';
import { AdvancedMarker, InfoWindow } from '@vis.gl/react-google-maps';
import { Driver } from 'app/components/RoundTrips/RoundTripData/statuses';
import { VehicleData } from 'app/pages/Vehicles/types';
import { VehicleInfoCard } from 'app/pages/Vehicles/components/VehiclesMap/components/InfoCard';
// import { StateSetter } from 'types';

interface Props {
  vehicle: any;
  selectedVehicle?: VehicleData | null;
  // setSelectedVehicle?: StateSetter<VehicleData | null>;
  onClick?: any;
}

export default function MarkerVehicle({
  vehicle,
  selectedVehicle = null,
  // setSelectedVehicle = () => {},
  onClick = () => {},
}: Props) {
  const userRole = Driver;

  const roundtripInfo = useMemo(() => {
    return getRoundtripUserAndVehicleInfo({
      agents: vehicle?.roundtrip?.agents,
      vehicle: vehicle?.numberPlate,
      role: userRole,
    });
  }, [vehicle, userRole]);

  if (!vehicle?.lastPosition?.geometry?.coordinates) {
    return null;
  }
  return (
    <div>
      <AdvancedMarker
        position={{
          lat: vehicle.lastPosition.geometry.coordinates[1],
          lng: vehicle.lastPosition.geometry.coordinates[0],
        }}
        onClick={(e) => {
          e?.domEvent?.stopPropagation();
          e?.domEvent?.preventDefault();
          onClick?.(vehicle);
        }}
      >
        {!roundtripInfo?.missingUser && !!roundtripInfo?.hasVehicle && (
          <span
            style={{
              position: 'relative',
              zIndex: 4,
            }}
          >
            <TruckIcon
              driver={!!roundtripInfo?.missingUser}
              vehicle={!roundtripInfo?.hasVehicle}
              running={vehicle?.roundtrip?._vehicle?.engineRunning}
              status={vehicle?.roundtrip?._vehicle?.status}
              style={{
                position: 'relative',
                transform: `rotate(${vehicle?.roundtrip?._vehicle?.angle}deg)`,
              }}
              marker={true}
            />
          </span>
        )}
      </AdvancedMarker>
      {!!selectedVehicle && (
        <InfoWindow
          position={{
            lat: selectedVehicle?.lastPosition?.geometry?.coordinates[1],
            lng: selectedVehicle?.lastPosition?.geometry?.coordinates[0],
          }}
          zIndex={201}
          pixelOffset={[0, -30]}
        >
          <VehicleInfoCard selectedVehicle={selectedVehicle} />
        </InfoWindow>
      )}
    </div>
  );
}
