import { useNavigate, useParams } from 'react-router-dom';
import { Tab, Tabs } from '@mui/material';
import styled from 'styled-components';
import { useState } from 'react';
import React from 'react';
import {
  useGetPaymentsQuery,
  usePatchPaymentAccountingStatusMutation,
} from 'common/services/payments';
import { EnhancedTableToolbar } from '../../components/EnhancedTableToolbar';
import { useFilterState } from 'app/components/FilterComponents/hooks/useFilterState';
import { TablePayments } from './components/TablePayments';

export function Payments() {
  const navigate = useNavigate();
  const { status } = useParams<{ status: string }>();

  // removed the setPage since it is never used
  // to resolve ES/TS error.
  const [page /*setPage*/] = useState<number>(1);

  const [search, setSearch] = useState<string>('');
  const { data, refetch } = useGetPaymentsQuery(
    `?accountingStatus=${status}&page=${page}`,
  );
  const [patchAccountingStatus] = usePatchPaymentAccountingStatusMutation();

  const currentTabIndex = status === 'unprocessed' ? 0 : 1;

  const handleTabChange = (e, tabIndex) => {
    if (tabIndex === 0) {
      navigate(`/customers/payments/unprocessed`);
    } else {
      navigate(`/customers/payments/processed`);
    }
  };

  ///Toolbar Filtration states
  const [filters /*setFilters*/] = useState<{
    salesman: { value: string; label: string }[];
  }>({
    salesman: [],
  });

  const payments = data?.docs || [];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { filterOn, handleOpenFilter, filtersCount, handleCloseFilter } =
    useFilterState({
      filters,
    });

  const handleSwitchStatus = async (payment) => {
    await patchAccountingStatus({
      id: payment._id,
      status:
        payment.accountingStatus === 'processed' ? 'unprocessed' : 'processed',
    }).unwrap();

    refetch();
  };

  return (
    <MainWrapper>
      <ToolbarWrapper>
        <EnhancedTableToolbar
          title="nav.mainNav.payments"
          disabled={false}
          numSelected={0}
          search={search}
          setSearch={setSearch}
          tabMenuCenter={
            <Tabs
              sx={{ minHeight: '44px' }}
              value={currentTabIndex}
              onChange={handleTabChange}
              centered
            >
              <Tab label="A Traiter" sx={{ p: 0, minHeight: '44px' }} />
              <Tab label="Traités" sx={{ p: 0, minHeight: '44px' }} />
            </Tabs>
          }
        />
      </ToolbarWrapper>

      <Wrapper>
        <TablePayments
          payments={payments}
          onSwitchStatus={handleSwitchStatus}
          status={status || 'unprocessed'}
        />
      </Wrapper>
    </MainWrapper>
  );
}

const MainWrapper = styled.div`
  width: 100%;
  display: block;
`;

const Wrapper = styled.div`
  margin: 0 auto;
  padding-top: 20px;
  max-width: 1400px;
`;

const ToolbarWrapper = styled.div`
  width: 100%;
  display: block;
`;
