import { useDispatch, useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { authActions } from '../../../../slices/auth';
import { selectMainMenuSize } from '../../../../slices/auth/selectors';

interface Props {
  classList: string;
}

const ArrowSVG: React.FC<Props> = ({ classList }) => {
  const theme = useSelector(selectTheme);
  const dispatch = useDispatch();
  const mainMenuSize = useSelector(selectMainMenuSize);
  const handleToggleMenuSize = () => {
    dispatch(
      authActions.setMainMenuSize({
        size: mainMenuSize === 'full' ? 'minify' : 'full',
      }),
    );
  };
  return (
    <svg
      className={classList}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={handleToggleMenuSize}
    >
      <path
        d="M0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10ZM10 9H14V11H10V14L6 10L10 6V9Z"
        fill={theme.primary}
      />
    </svg>
  );
};

export default ArrowSVG;
