import React, { useRef } from 'react';
// import styled from 'styled-components';
// import { themes } from 'styles/theme/themes';
import { Popover } from '@mui/material';
import { PopoverAction } from 'app/pages/AddRoundTrips/data/RoundTripData/components/PopoverAction';
import { StateSetter } from 'types';
import { RoundtripStatus } from '../../../../../../../common/utils/enum';

interface Props {
  open: boolean;
  position: { x: number; y: number };
  handleMenuClose: () => void;
  viewMode?: boolean;
  roundtrip: any;
  functionRoundTripStatus: Function;
  handleClickOpen: Function;
  handleClickOpenDelete: Function;
  setRoundtripEdit: StateSetter<any>;
}

export const PolygonOptionsMenu: React.FC<Props> = ({
  open,
  position,
  handleMenuClose,
  viewMode,
  roundtrip,
  functionRoundTripStatus,
  handleClickOpen,
  handleClickOpenDelete,
  setRoundtripEdit,
}) => {
  const popoverRef: any = useRef(null);
  return (
    <Popover
      open={open}
      anchorOrigin={{
        vertical: position.y || 'center',
        horizontal: position.x || 'center',
      }}
      onClick={(e) => {
        e?.preventDefault();
        e?.stopPropagation();
      }}
      onContextMenuCapture={(e) => {
        e?.preventDefault();
        e?.stopPropagation();
      }}
      onClose={handleMenuClose}
      ref={popoverRef}
    >
      <PopoverAction
        typeEdit={!viewMode}
        roundtrip={roundtrip}
        disableActions={roundtrip.status === RoundtripStatus.PARKED}
        functionRoundTripStatus={functionRoundTripStatus}
        handleClickOpen={handleClickOpen}
        handleClickOpenDelete={handleClickOpenDelete}
        setRoundtripEdit={setRoundtripEdit}
        openByDefault={true}
        handleParentClose={handleMenuClose}
      />
    </Popover>
  );
};
