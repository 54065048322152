import { TableCell } from '@mui/material';
import { useGrid } from '../../hooks';
import { Checkbox } from 'app/components/CustomCheckbox';

export const GridCheckbox = ({ id }: { id: string }) => {
  const { selected, setSelected, withCheckbox } = useGrid();

  const toggleCheck = () => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  return withCheckbox ? (
    <TableCell
      padding="checkbox"
      className="checkbox-cell"
      sx={{
        maxWidth: '50px',
      }}
    >
      <Checkbox
        color="primary"
        checked={selected?.includes(id)}
        inputProps={{
          'aria-labelledby': `table-checkbox-${id}`,
        }}
        onClick={toggleCheck}
        sx={{
          padding: 0,
        }}
      />
    </TableCell>
  ) : null;
};
