import { StopDataWrapper } from 'styles/components/roundTrips/roundTripsData';

interface Props {
  stop: any;
  StopStation: any;
}

export const StopDataGas: React.FC<Props> = ({ stop, StopStation }) => {
  return (
    <StopDataWrapper key={2} borderRight={true}>
      <div className="station-wrapper">
        <img src={StopStation} style={{ height: 40, width: 38 }} />
        <div
          style={{
            height: '100%',
            width: '100%',
            paddingTop: 10,
          }}
        >
          <div className="time">[7:34am]</div>
          <div style={{ fontWeight: 700 }}>{stop._place?.name}</div>
          <div style={{ marginTop: 5 }}>{stop._place?.location?.name} </div>
        </div>
      </div>
    </StopDataWrapper>
  );
};
