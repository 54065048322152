const statSuffixes = ['K', 'M', 'B', 'T', 'Q'];

export const formatNumber = function (number: number) {
  let suffixIdx = -1;
  while (number >= 1000) {
    number /= 1000;
    suffixIdx += 1;
  }
  const doubleDigits = suffixIdx === -1;
  const roundingAmount = doubleDigits ? 100 : 10;
  number = Math.round(number * roundingAmount) / roundingAmount;
  return `${number}${suffixIdx > -1 ? statSuffixes[suffixIdx] : ''}`;
};
