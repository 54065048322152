import React, { useEffect } from 'react';
import { ITabsContext, TabType } from '../ManagedGrid/types';
import ManagedContext from '../../../common/UtilityComponents/ManagedContext/ManagedContext';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { selectTabsPreferences } from '../../../common/store/pagePreferences/selectors';
import { useManagedContext } from '../../../common/UtilityComponents/ManagedContext/useManagedContext';

type TabsContextProps = {
  children: React.ReactNode;
  tabs: TabType[];
  initialTab: string;
  onTabChange?: (tab: string) => void;
  canEdit?: boolean;
};

let PREVIOUS_TABS_STATE: TabType[] = [];

function TabsContext({
  children,
  tabs,
  initialTab,
  onTabChange,
  canEdit,
}: TabsContextProps) {
  return (
    <ManagedContext
      contextName="tabs"
      defaultValue={
        {
          tabs: tabs,
          visibleTabs: tabs.length
            ? [
                tabs.find((t) => t.value === initialTab),
                ...tabs.filter((t) => t.value !== initialTab)?.slice(0, 3),
              ]
            : [],
          hiddenTabs:
            tabs.length > 4
              ? [...tabs?.filter((t) => t.value !== initialTab).slice(3)]
              : [],
          currentTabEdit: null,
          editValue: null,
          isHovering: false,
          editable: false,
          api: {
            onInputBlur: (_label: string, _id: string) => {},
          },
          menuActions: (_tab) => {},
          isDragging: false,
          onAddClick: () => {},
          canEdit: canEdit,
          selectedTab: initialTab ?? tabs?.[0]?.value,
          onTabChange,
          extraData: {},
          onAddIconOptions: {},
        } as ITabsContext
      }
    >
      <DataSetter />
      {children}
    </ManagedContext>
  );
}
function DataSetter() {
  const context = useManagedContext('tabs');
  const currentRoute = useLocation().pathname.split('/')[1];
  const tabsOrder = useSelector(selectTabsPreferences)[currentRoute];

  useEffect(() => {
    if (!context.tabs?.length) return;
    const orderedTabs = [...context.tabs].sort((a, b) => {
      return tabsOrder?.indexOf(a.value) - tabsOrder?.indexOf(b.value);
    });
    context.updateDataWithFunction((prev) => {
      if (prev.selectedTab) {
        console.log('prev.selectedTab', prev.selectedTab);
        prev.visibleTabs = [
          orderedTabs.find((t) => t.value === context.selectedTab),
          ...orderedTabs
            .filter((t) => t.value !== context.selectedTab)
            ?.slice(0, 3),
        ].filter(Boolean);
        if (orderedTabs.length > 4) {
          prev.hiddenTabs = [
            ...orderedTabs
              ?.filter((t) => t.value !== context.selectedTab)
              .slice(3),
          ].filter(Boolean);
        }
        if (
          PREVIOUS_TABS_STATE.length !== orderedTabs.length &&
          PREVIOUS_TABS_STATE.length
        ) {
          const newTab = orderedTabs.find(
            (t) => !PREVIOUS_TABS_STATE.find((pt) => pt.value === t.value),
          );
          if (newTab) {
            prev.selectedTab = newTab.value;
          } else {
            prev.selectedTab = orderedTabs?.[0]?.value;
            prev.visibleTabs = orderedTabs?.slice(0, 4).filter(Boolean);
            if (orderedTabs.length > 4) {
              prev.hiddenTabs = orderedTabs.slice(4).filter(Boolean);
            }
          }
        }
        if (!prev.tabs.find((t) => t.value === prev.selectedTab)) {
          prev.selectedTab = orderedTabs?.[0]?.value;
        }
      } else {
        console.log('prev.selectedTab1', prev.selectedTab);
        prev.visibleTabs = [
          orderedTabs?.[0],
          ...orderedTabs?.slice(1, 4),
        ].filter(Boolean);
        if (orderedTabs.length > 4) {
          prev.hiddenTabs = orderedTabs.slice(4).filter(Boolean);
        }
        prev.selectedTab = orderedTabs?.[0]?.value;
      }
      PREVIOUS_TABS_STATE = orderedTabs;
    });
  }, [context.tabs]);
  return null;
}
export default TabsContext;
