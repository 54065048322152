import { useMemo } from 'react';

interface Props {
  total: number;
  numSelected: number;
  limit?: number;
}

export const useDisabledExport = ({
  total,
  numSelected,
  limit = 25000,
}: Props) => {
  const disabledExport = useMemo(() => {
    return Boolean(
      !total ||
        (!numSelected && total > limit) ||
        (numSelected && numSelected > limit),
    );
  }, [total, numSelected, limit]);

  return { disabledExport };
};
