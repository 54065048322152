import {
  Chip,
  FormControlLabel,
  Radio,
  RadioGroup,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { themes } from 'styles/theme/themes';

export const ReOptimizeHeader = ({
  selectedResult,
  setSelectedResult,
  rows,
  selectedRoundTrip,
}) => {
  const theme = useSelector(selectTheme);
  return (
    <TableHead
      sx={{
        position: 'sticky',
        top: 0,
        display: 'flex',
        justifyContent: 'space-between',
        height: 37.8,
        backgroundColor: themes?.default?.accordionWhiteBg,
        zIndex: 2,
        borderBottom: theme.tableBorder,
      }}
    >
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={selectedResult}
        onChange={(event) => {
          setSelectedResult?.(+event.target.value);
        }}
        sx={{
          width: '100%',
        }}
      >
        <TableRow
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            height: '100%',
          }}
        >
          {rows[selectedRoundTrip!]?.roundtrip.option.map((_o, index) => {
            if (!index)
              return (
                <React.Fragment key={index}>
                  <TableCell
                    style={{
                      position: 'sticky',
                      left: 0,
                      flex: 1,
                      maxWidth: 180,
                      borderRight: `0.063rem solid ${theme.primaryLightO}`,
                      backgroundColor: themes?.default?.accordionWhiteBg,
                      zIndex: 1,
                    }}
                  />
                  <TableCell
                    sx={{
                      flex: 1,
                      display: 'flex',
                      alignItems: 'center',
                      columnGap: '30px',
                      borderRight:
                        rows[selectedRoundTrip!]?.roundtrip.option.length -
                          1 !==
                        index
                          ? `0.063rem solid ${theme.primaryLightO}`
                          : 'unset',
                    }}
                  >
                    <FormControlLabel
                      value={index}
                      sx={{
                        marginRight: 'unset',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      control={<Radio size="small" />}
                      label={`Resut ${index + 1}`}
                    />
                    <Chip
                      label="Best Score"
                      sx={{
                        color: themes?.default?.accordionWhiteBg,
                        fontWeight: 400,
                        backgroundColor: theme.green,
                        height: '24px',
                      }}
                    />
                  </TableCell>
                </React.Fragment>
              );
            return (
              <TableCell
                key={index}
                style={{
                  flex: 1,
                  borderBottom: theme.tableBorder,
                  display: 'flex',
                  alignItems: 'center',
                  borderRight:
                    rows[selectedRoundTrip!]?.roundtrip.option.length - 1 !==
                    index
                      ? `0.063rem solid ${theme.primaryLightO}`
                      : 'unset',
                }}
              >
                <FormControlLabel
                  value={index}
                  sx={{
                    marginRight: 'unset',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  control={<Radio size="small" />}
                  label={`Resut ${index + 1}`}
                />
              </TableCell>
            );
          })}
        </TableRow>
      </RadioGroup>
    </TableHead>
  );
};
