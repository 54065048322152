import api from './api';

export const appApi = api.injectEndpoints({
  endpoints: builder => ({
    getApplications: builder.query<any, string>({
      query: urlQuery => ({
        url: `/app/configuration${urlQuery}`,
      }),
      providesTags: ['Applications'],
    }),
    changeApp: builder.mutation<any, { app: string }>({
      query: data => ({
        url: '/app/change',
        method: 'PATCH',
        body: {
          app: data.app,
        },
      }),
      extraOptions: {
        maxRetries: 0,
      },
    }),
  }),
});

export const { useGetApplicationsQuery, useChangeAppMutation } = appApi;

export default appApi;
