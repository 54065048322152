import React from 'react';
import { useTranslation } from 'react-i18next';
import { Command } from '@phosphor-icons/react';
import { bindShortcut } from 'app/pages/RoundTrips/components/KeyboardShortcuts/functions';
import { MapLegendPopover } from 'app/MapLegendPopover';

interface ShortcutsProps {
  shortcuts: {
    key1: string;
    key2?: string;
    label: string;
    icon: JSX.Element | any;
    action: {
      callback: (num?: string) => void;
      eventName: string;
    };
  }[];
}

export const KeyboardShortcuts: React.FC<ShortcutsProps> = ({ shortcuts }) => {
  const { t } = useTranslation();
  const legends: { text: string; icon: JSX.Element | string }[] = [];

  const mapLegends = [
    {
      title: t('shortcuts'),
      legends: legends,
    },
  ];

  shortcuts.forEach((shortcut) => {
    if (shortcut.label || shortcut.icon) {
      legends.push({
        text: shortcut.label,
        icon: shortcut.icon,
      });
    }
  });

  return (
    <>
      <MapLegendPopover buttonIcon={<Command />} content={mapLegends} />
      <>
        {shortcuts.map((shortcut, index) => (
          <React.Fragment key={index}>
            <Shortcut shortcut={shortcut} />
          </React.Fragment>
        ))}
      </>
    </>
  );
};

function Shortcut({ shortcut }) {
  let keybinds = shortcut.key1;
  if (shortcut?.key2) {
    keybinds = keybinds + '+' + shortcut.key2;
  }
  bindShortcut(
    [keybinds],
    shortcut.action.callback,
    true,
    shortcut.action.eventName,
  );
  return null;
}
