import React from 'react';
import { handleAccordionToggle } from 'app/components/RoundTrips/function';

export const useAccordionDetailsToggle = (
  rows,
  triggerRoundtripDetails,
  loading?: any,
) => {
  const handleAccordionToggleWrapper = async (
    e: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
    group: number,
    index: number,
    accordionStatus,
    setAccordionStatus,
  ) => {
    if (loading) {
      return;
    }
    handleAccordionToggle(e, group, index, accordionStatus, setAccordionStatus);
    // if (!(rows[group] && rows[group]['data'] && rows[group]['data'][index]?.accordionRowCount)) {
    //     await getRoundtripDetails(roundtripId);
    // }
  };

  return { handleAccordionToggleWrapper, loading };
};
