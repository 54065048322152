import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import StopsList from './StopsList';
import { LoadStatus } from 'styles/components/roundTrips';
import { PersonPopover } from './PersonPopover';

export const RoundTripInfoPopover = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const theme = useSelector(selectTheme);

  const handleClick = (
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.TouchEvent<HTMLButtonElement>,
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <IconButton
        onTouchEnd={handleClick}
        onClick={handleClick}
        sx={{ height: 30, width: 30 }}
      >
        <InfoOutlinedIcon sx={{ color: theme.icon, height: 15 }} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography minWidth={123}>
          <PopoverWrapper
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <DriverRipperFields>
              <div className="title">Driver</div>
              <div className="name">Oliver digonal</div>

              <PersonPopoverWrapper>
                <PersonPopover />
              </PersonPopoverWrapper>
              {/* <PersonIcon
                sx={{
                  position: 'absolute',
                  right: '10px',
                  width: '14px',
                  height: '14px',
                  color: theme.textColorSecondary,
                }}
              /> */}
            </DriverRipperFields>
            <DriverRipperFields>
              <div className="title">Ripper</div>
              <div className="name">James bond</div>
              <PersonPopoverWrapper>
                <PersonPopover />
              </PersonPopoverWrapper>
            </DriverRipperFields>
            <StopsList />
            <LaodStatusField>
              <div className="title">Load status</div>
              <div className="name">
                <LoadStatus style={{ width: 'fit-content' }} status="fully">
                  <span>Fully (13/13)</span>
                </LoadStatus>
              </div>
            </LaodStatusField>
            <PopoverFieldWrapper>
              <div className="title">Driver</div>
              <div className="name">___</div>
            </PopoverFieldWrapper>
            <PopoverFieldWrapper>
              <div className="title">Roundtrip cost</div>
              <div className="name">400.989 €</div>
            </PopoverFieldWrapper>
            <PopoverFieldWrapper>
              <div className="title">Roundtrip cost</div>
              <div className="name">434.489 €</div>
            </PopoverFieldWrapper>
            <PopoverFieldWrapper>
              <div className="title">Roundtrip cost</div>
              <div className="name">30.989 €</div>
            </PopoverFieldWrapper>
            <PopoverFieldWrapper>
              <div className="title">Roundtrip cost</div>
              <div className="name">400.989 €</div>
            </PopoverFieldWrapper>
            <PopoverFieldWrapper>
              <div className="title">Geolocalisation Id</div>
              <div className="name">3378590512</div>
            </PopoverFieldWrapper>
          </PopoverWrapper>
        </Typography>
      </Popover>
    </div>
  );
};

const PopoverWrapper = styled.div(() => ({
  width: 370,
  fontSize: '0.75rem',
  maxHeight: '380px',
}));

const DriverRipperFields = styled.div((props) => ({
  display: 'flex',
  height: 38,
  borderBottom: `0.063rem solid ${props.theme.borderGrey}`,
  alignItems: 'center',
  paddingLeft: 26,

  '& .title': {
    fontWeight: 700,
    width: 150,
  },
  '& .name': {
    textDecoration: 'underline',
  },
}));

const LaodStatusField = styled.div((props) => ({
  display: 'flex',
  height: 38,
  borderBottom: `0.063rem solid ${props.theme.borderGrey}`,
  alignItems: 'center',
  paddingLeft: 26,

  '& .title': {
    fontWeight: 700,
    width: 150,
  },
}));

const PopoverFieldWrapper = styled.div((props) => ({
  display: 'flex',
  height: 38,
  borderBottom: `0.063rem solid ${props.theme.borderGrey}`,
  alignItems: 'center',
  paddingLeft: 26,

  '& .title': {
    fontWeight: 700,
    width: 150,
  },
}));

const PersonPopoverWrapper = styled.div((props) => ({
  display: 'flex',
  alignItems: 'center',
  '& .popover-wrapper': {
    position: 'absolute',
    right: 0,
  },
  '& svg': {
    color: props.theme.textColorSecondary,
  },
}));
