import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { appActions } from 'common/store/app';
import { BampteeApplications } from 'common/store/app';
import { selectCurrentApplication } from 'common/store/app/selectors';
import { Application } from 'common/store/app/types';
import { themeActions } from '../styles/theme/slice';
import { selectAuthUser } from 'app/slices/auth/selectors';
import {
  ACCESS_DENIED,
  ACCESSORIES,
  BIANTA,
  CALL_LIST,
  CATALOG,
  CUSTOMER,
  DASHBOARD,
  IAM,
  NOT_FOUND,
  NOTES,
  ORDERS,
  ORDORIA,
  PAYMENTCARDS,
  PRODUCTS,
  QUOTATIONS,
  REPORTS,
  ROLES_PERMISSIONS,
  ROUNDTRIP,
  SCHEDULED_ROUNDTRIPS,
  SETTINGS,
  SETTINGS_COMPANY_PROFILE,
  SETTINGS_CUSTOMIZATION,
  SETTINGS_LANGUAGE,
  SETTINGS_USERS,
  STOCKEE,
  STOCKEE_CUSTOMER,
  STOCKEE_CUSTOMER_GROUP,
  STOCKEE_DESCOUNT_GRID,
  STOCKEE_STOCK,
  STORAGELOCATIONS,
  SUPPLIERS,
  SUPPORTUNIT,
  TOOLS,
  TRANSACTIONS,
  USERS,
  VEHICLES_LOGISTIC,
} from 'utils/routes';
import { useDefineAbilitiesFor } from 'utils/ability';

export const useApplication = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentApplication: Application = useSelector(selectCurrentApplication);
  const navigate = useNavigate();

  const authUser = useSelector(selectAuthUser);
  const isAdmin = authUser?.isSuperAdmin;

  const ability = useDefineAbilitiesFor(authUser, currentApplication);

  const biantaModules = useMemo(() => {
    return [
      {
        name: 'dashboard',
        url: DASHBOARD,
      },
      {
        name: 'roundtrips',
        url: `${ROUNDTRIP}/${SCHEDULED_ROUNDTRIPS}`,
      },
      {
        name: 'orders',
        url: ORDERS,
      },
      {
        name: 'customers',
        url: CUSTOMER,
      },
      {
        name: 'reports',
        url: REPORTS,
      },
      {
        name: 'users',
        url: USERS,
      },
      {
        name: 'vehicles',
        url: VEHICLES_LOGISTIC,
      },
      {
        name: 'accessories',
        url: ACCESSORIES,
      },
      {
        name: 'tools',
        url: TOOLS,
      },
      {
        name: 'paymentcards',
        url: PAYMENTCARDS,
      },
      {
        name: 'supportunits',
        url: SUPPORTUNIT,
      },
    ];
  }, []);

  const ordoriaModules = useMemo(() => {
    return [
      {
        name: 'dashboard',
        url: ORDORIA,
      },
      {
        name: 'catalog',
        url: CATALOG,
      },
      {
        name: 'orders',
        url: `${ORDERS}`,
      },
      {
        name: 'reports',
        url: `${REPORTS}`,
      },
      {
        name: 'customers',
        url: `${CUSTOMER}`,
      },
      {
        name: 'callplan',
        url: `${CALL_LIST}`,
      },
      {
        name: 'notes',
        url: `${NOTES}`,
      },
      {
        name: 'quotations',
        url: `${QUOTATIONS}`,
      },
      {
        name: 'transactions',
        url: `${TRANSACTIONS}`,
      },
    ];
  }, []);

  const stockeeModules = useMemo(() => {
    return [
      {
        name: 'dashboard',
        url: STOCKEE,
      },
      {
        name: 'reports',
        url: `${REPORTS}`,
      },
      {
        name: 'products',
        url: `${PRODUCTS}`,
      },
      {
        name: 'storage',
        url: STORAGELOCATIONS,
      },
      {
        name: 'stock',
        url: STOCKEE_STOCK,
      },
      {
        name: 'supplires',
        url: SUPPLIERS,
      },
      {
        name: 'client_group',
        url: STOCKEE_CUSTOMER_GROUP,
      },
      {
        name: 'clients',
        url: STOCKEE_CUSTOMER,
      },
      {
        name: 'discount_grid',
        url: STOCKEE_DESCOUNT_GRID,
      },
    ];
  }, []);

  const idmModules = useMemo(() => {
    return [
      {
        name: 'companysettings',
        url: SETTINGS_COMPANY_PROFILE,
      },
      {
        name: 'roles',
        url: ROLES_PERMISSIONS,
      },
      {
        name: 'users',
        url: SETTINGS_USERS,
      },
      {
        name: 'language',
        url: SETTINGS_LANGUAGE,
      },
      {
        name: 'customization',
        url: SETTINGS_CUSTOMIZATION,
      },
    ];
  }, []);

  const iamModules = useMemo(() => {
    return [
      {
        name: 'users',
        url: USERS,
      },
    ];
  }, []);

  const currentApplicationId = location?.pathname?.includes(SETTINGS)
    ? SETTINGS
    : '/' + currentApplication?.id;

  useEffect(() => {
    // execute on location change
    const findApp = BampteeApplications.find(
      (app: any) => location.pathname.indexOf(app.url) > -1,
    );

    if (findApp && !location.pathname?.includes(SETTINGS)) {
      dispatch(appActions.setCurrentApplication({ application: findApp }));
      // @ts-ignore
      dispatch(themeActions.changeTheme(findApp.id));
    }

    if (!isAdmin) {
      let applicationModules;

      switch (currentApplicationId) {
        case ORDORIA:
          applicationModules = ordoriaModules;
          return;
        case STOCKEE:
          applicationModules = stockeeModules;
          return;
        case BIANTA:
          applicationModules = biantaModules;
          return;
        case IAM:
          applicationModules = iamModules;
          return;
        case SETTINGS:
          applicationModules = idmModules;
          return;
        default:
          applicationModules = biantaModules;
      }

      const currentModule = applicationModules.find(
        (module) => module.url === location.pathname,
      );

      if (currentModule) {
        if (ability.can('canview', currentModule.name)) {
          navigate(currentModule.url);
          return;
        } else {
          navigate(ACCESS_DENIED);
          return;
        }
      } else {
        navigate(NOT_FOUND);
        return;
      }
    }
  }, [
    location.pathname,
    dispatch,
    currentApplicationId,
    isAdmin,
    currentApplication?.url,
    biantaModules,
    idmModules,
    navigate,
    authUser?.currentAgent,
    authUser?.currentApplication,
  ]);

  return currentApplication;
};
