import React, { useEffect, useState } from 'react';
import {
  InputAdornment,
  Pagination,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useLazyGetCustomerPublicOrdersQuery } from 'common/services/customerApi';
import styled from 'styled-components';
import SearchIcon from '@mui/icons-material/Search';
import { OrderItem } from './OrderItem';
import { SwipeableDrowerOrder } from 'app/pages/AddRoundTrips/components/SwipeableDrowerOrder';

export function OrdersList({ token, password }) {
  const [selectedOrder, setSelectedOrder] = useState<null | any>(null);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState<string>('');

  const orderQuery = `page=${page}&limit=10&text=${search}`;

  const [trigger, { data: ordersData }] = useLazyGetCustomerPublicOrdersQuery();

  const handleChangeOrderPagination = (
    _event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
  };
  const handleClickOrder = (order) => {
    setSelectedOrder(order);
  };

  const handleCloseDrawer = () => {
    setSelectedOrder(null);
  };

  useEffect(() => {
    trigger({
      token,
      password,
      orderQuery,
    });
  }, [token, page, search]);

  const handleUpdateOrder = () => {
    trigger({
      token,
      password,
      orderQuery,
    });
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  return (
    <Container>
      <div>
        <TextField
          size="small"
          variant="outlined"
          onChange={handleSearchChange}
          value={search}
          placeholder="Recherche"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <HeaderWrapper>
        <Typography sx={{ marginLeft: 4 }} fontWeight="500" fontSize="0.875rem">
          Date
        </Typography>
        <Typography sx={{ marginLeft: 8 }} fontWeight="500" fontSize="0.875rem">
          Numero BC
        </Typography>

        <RightTypo>
          <Typography
            sx={{ marginRight: 6 }}
            fontWeight="500"
            fontSize="0.875rem"
          >
            Montant HT
          </Typography>
          <Typography
            sx={{ marginRight: 6 }}
            fontWeight="500"
            fontSize="0.875rem"
          >
            Nb colis
          </Typography>
          <Typography
            sx={{ marginRight: 6 }}
            fontWeight="500"
            fontSize="0.875rem"
          >
            Nb lignes
          </Typography>
          <Typography
            sx={{ marginRight: 1 }}
            fontWeight="500"
            fontSize="0.875rem"
          >
            A collecter
          </Typography>
        </RightTypo>
      </HeaderWrapper>
      <Wrapper>
        {ordersData?.docs.length === 0 && (
          <Wrapper>
            <h1>Aucune commande</h1>
          </Wrapper>
        )}
        {ordersData?.docs.map((order) => (
          <OrderItem
            key={order.id}
            order={order}
            onClick={() => handleClickOrder(order)}
          />
        ))}

        <Stack spacing={2}>
          <Pagination
            count={ordersData?.totalPages || 0}
            onChange={handleChangeOrderPagination}
          />
        </Stack>
      </Wrapper>
      <Wrapper>
        <SwipeableDrowerOrder
          selectedOrder={selectedOrder}
          handleCloseOrderDrawer={handleCloseDrawer}
          onUpdate={handleUpdateOrder}
        />
      </Wrapper>
    </Container>
  );
}

const Wrapper = styled.div`
  height: 70vh;
  width: 100%;
  margin: auto;
`;

const HeaderWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;

  @media (max-width: 900px) {
    display: none;
  }
`;

const RightTypo = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
`;

const Container = styled.div`
  width: 920px;
  max-width: 100%;
  margin: 0 auto;
`;
