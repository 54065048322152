import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Tooltip } from '@mui/material';

interface EllipsisTextProps {
  width: number;
  text?: string;
  tooltip?: string;
  tooltipVerticalOffset?: number;
  fontSize?: string;
}

/**
 * Component to render a text and on hover show tooltip title txt
 * @width number
 * @text string
 * @tooltip string
 * @returns
 */
export default function EllipsisText({
  width,
  text,
  tooltip,
  tooltipVerticalOffset = 0,
  fontSize,
}: EllipsisTextProps) {
  if (!tooltip) {
    tooltip = text;
  }
  const [showTooltip, setShowTooltip] = useState(false);
  const textRef = useRef<any>(null);

  useEffect(() => {
    if (textRef.current?.offsetWidth) {
      setShowTooltip(textRef.current?.offsetWidth > width);
    }
  }, [textRef.current?.offsetWidth, width]);

  if (showTooltip) {
    return (
      <Tooltip
        title={tooltip}
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, tooltipVerticalOffset],
              },
            },
          ],
        }}
      >
        <Text fontSize={fontSize} width={width}>
          <span ref={textRef}>{text}</span>
        </Text>
      </Tooltip>
    );
  }

  return (
    <Text fontSize={fontSize} width={width}>
      <span ref={textRef}>{text}</span>
    </Text>
  );
}

const Text = styled.div<EllipsisTextProps>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${(props) => props.width}px;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '')};
`;
