import { removeUndefined } from '../../../utils/dto';

const stopOrderDto = (response: any) => {
  if (!response) return;
  return {
    supportUnitsList: response.supportUnitsList,
    type: response.type,
    status: response.status,
  };
};

const vehicleDto = (response: any) => {
  if (!response) return;
  return {
    parkNumber: response?.parkNumber,
  };
};

const stopResponse = (response: any) => {
  return {
    _id: response._id,
    _order: stopOrderDto(response._order),
    type: response.type,
    status: response.status,
  };
};

const getRoundtripStops = (response: any) => {
  if (!response) return;
  return response.map((stop: any) => {
    return stopResponse(stop);
  });
};

const roundTripObjDto = (response: any) => {
  return {
    _id: response._id,
    status: response.status,
    truckMoved: response.truckMoved,
    stops: getRoundtripStops(response.stops),
    agents: response.agents,
    cache: response.cache,
    _updatedStatusBy: response._updatedStatusBy,
    code: response.code,
    _vehicle: vehicleDto(response._vehicle),
    internalCode: response.internalCode,
    countOrdersLoaded: response.countOrdersLoaded,
  };
};

const roundTripDocsDto = (response: any) => {
  if (!response) return;
  return response.map((res: any) => {
    return roundTripObjDto(res);
  });
};

export const getRoundTripListDto = (response: any) => {
  return removeUndefined({
    _id: response._id,
    docs: roundTripDocsDto(response.docs),
  });
};
