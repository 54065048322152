import { Box, Stack } from '@mui/material';
import { themes } from '../../../../../styles/theme/themes';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import { Icon } from '../../../../components/Icon';
import { WarningCircle } from '@phosphor-icons/react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as React from 'react';
interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const WarehouseStatusChip = ({ status }: { status: string }) => {
  if (!status) return null;
  return (
    <Stack
      sx={{
        justifyContent: 'center',
        bgcolor: themes.default.chipRedFont,
        borderRadius: '16px',
        color: themes.default.chipRedBg,
        fontWeight: '400',
        lineHeight: '17.16px',
        letterSpacing: '0.17px',
        textAlign: 'left',
        padding: '4px 6px',
      }}
    >
      <Typography
        fontSize="11px"
        fontWeight="400"
        lineHeight="14.85px"
        textAlign="left"
        letterSpacing={0.4}
      >
        {status}
      </Typography>
    </Stack>
  );
};

const RoundtripsCountChip = ({ count }: { count: number }) => {
  if (!count) return null;
  return (
    <Stack
      sx={{
        justifyContent: 'center',
        bgcolor: themes.default.whisper,
        borderRadius: '4px',
        color: themes.default.chipTextColor,
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '17.16px',
        letterSpacing: '0.17px',
        textAlign: 'left',
        padding: '2px 4px',
      }}
    >
      <Typography
        fontSize="10px"
        fontWeight="500"
        lineHeight="14px"
        textAlign="left"
      >
        {count + ' roundtrips'}
      </Typography>
    </Stack>
  );
};

const ExpandMore = styled((props: ExpandMoreProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: 'rotate(0deg)',
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: 'rotate(180deg)',
      },
    },
  ],
}));

export const WarehouseCardHeader = ({
  onExpandClick,
  isExpanded,
  warehouse,
}) => {
  return (
    <Stack
      direction="row"
      // px={2}
      // py={1}
      justifyContent="space-between"
      // gap={2}
      alignItems="center"
    >
      <Stack
        direction="row"
        px={2}
        py={1}
        // justifyContent="space-between"
        gap={0.7}
        alignItems="center"
      >
        <Avatar
          sx={{ bgcolor: warehouse.color, width: '24px', height: '24px' }}
          aria-label="recipe"
        >
          {warehouse.name.split(' ')[1].charAt(0).toUpperCase()}
        </Avatar>
        <Stack direction="row" gap={1.5}>
          <Typography
            fontSize="12px"
            fontWeight="400"
            lineHeight="19.2px"
            letterSpacing="0.15px"
          >
            {warehouse.name}
          </Typography>
          <RoundtripsCountChip count={warehouse.nbOfRoundtrips} />
          {warehouse.status === 'inactive' && (
            <WarehouseStatusChip status={warehouse.status} />
          )}
        </Stack>
      </Stack>
      <Box>
        {warehouse.roundtrips?.some((r) => !r.roundtripCode) && (
          <Icon
            icon={<WarningCircle />}
            color={themes.default.errorMain}
            aria-label="warning"
          />
        )}
        <ExpandMore
          expand={isExpanded}
          onClick={onExpandClick}
          aria-expanded={isExpanded}
          disableRipple
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </Box>
    </Stack>
  );
};
