import styled from 'styled-components';
import { Skeleton } from '@mui/material';
import { useMemo } from 'react';

export const ChatSkeleton = () => {
  const getRandomWidth = () => {
    const widths = [80, 95, 110, 118, 120, 130, 140, 150];
    return widths[Math.floor(Math.random() * widths.length)];
  };

  // const getRandomHeight = () => {
  //   const widths = [32, 52, 72];
  //   return widths[Math.floor(Math.random() * widths.length)];
  // };

  const getRandomSide = () => {
    return Math.random() > 0.5 ? 'left' : 'right';
  };

  const renderSkeletons = useMemo(() => {
    return Array.from({ length: 30 }).map((_, index) => {
      const width = getRandomWidth();
      const height = 32;
      const side = getRandomSide();
      const mb = 4;

      return (
        <SkeletonContainer key={index} side={side} mb={mb}>
          <Skeleton
            variant="rounded"
            animation="wave"
            width={width}
            height={height}
            sx={{ borderRadius: '8px' }}
          />
        </SkeletonContainer>
      );
    });
  }, []);

  return <Wrapper>{renderSkeletons}</Wrapper>;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 13px 12px 20px;
`;

const SkeletonContainer = styled.div<{ side: 'left' | 'right'; mb: number }>`
  align-self: ${(props) => (props.side === 'left' ? 'flex-start' : 'flex-end')};
  margin-bottom: ${(props) => (props.mb === 4 ? 4 : 12)}px;
`;
