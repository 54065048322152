import { REOPTIMIZE } from 'app/components/TableComponent/constants';
import React from 'react';

export const useAccordionValues = (
  rows,
  setExpanded,
  setAccordionStatus,
  type,
) => {
  React.useEffect(() => {
    if (type === REOPTIMIZE || !rows) return;
    const groups = {};
    for (let i = 0; i < rows.length; i++) {
      groups[rows[i].key] = false;
    }
    setExpanded?.(groups);
  }, []);
};
