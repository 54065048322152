import styled from 'styled-components';
import Grid3x3Icon from '@mui/icons-material/Grid3x3';
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useMemo, useState } from 'react';
import { getImgCdn } from 'common/helpers/cdn';
import { useTranslation } from 'react-i18next';
import RefreshIcon from '@mui/icons-material/Refresh';

export function ProductRowLayoutSales({
  data,
  onProductOrdered,
  quantityIn,
  approvedQuantityIn,
  discountIn,
  initialDiscountIn,
  setAction,
  handleProductDetails,
  orderStatus,
}) {
  const { t } = useTranslation();
  const price = data?.salePrice ? Number(data.salePrice.toFixed(2)) : 0;
  const quantity = quantityIn;
  const [approvedQuantity, setApprovedQuantity] =
    useState<number>(approvedQuantityIn);
  const [discount, setDiscount] = useState<number | null>(discountIn);

  useEffect(() => {
    const updatedProduct = {
      ...data,
      discount,
      approvedQuantity,
    };
    onProductOrdered(updatedProduct);
  }, [approvedQuantity, discount]);

  const resetDiscount = () => {
    setDiscount(initialDiscountIn);
    setAction('reset discount');
  };

  // Memoize the JSX elements that depend on quantity, price, and discount
  function addQty(quantity: number) {
    setApprovedQuantity(quantity + 1);
    setAction(t('orders.items.statuses.updated'));
  }

  function substractQty(quantity: number) {
    if (quantity !== 0) {
      setApprovedQuantity(quantity - 1);
      setAction(t('orders.items.statuses.updated'));
    }
  }

  function handleQtyChange(e) {
    const value = parseInt(e.target.value.replace(/^-/, ''), 10) || 0;
    setApprovedQuantity(value);
    setAction(t('orders.items.statuses.updated'));
  }

  function handleDiscount(discount: number | null) {
    setDiscount(discount);
    setAction('discount');
  }

  function calculPrice(approvedQuantity: number, price: number) {
    return (approvedQuantity * price).toFixed(2);
  }

  function calculTotalWithDiscount(
    quantity: number,
    price: number,
    discount: number | null,
  ) {
    const totalWithoutDiscount = quantity * price;
    if (discount === null) {
      discount = 0;
    }
    const discountAmount = (discount / 100) * totalWithoutDiscount;
    const totalWithDiscount = totalWithoutDiscount - discountAmount;
    return totalWithDiscount.toFixed(2);
  }

  return useMemo(
    () => (
      <Wrapper isHighlighted={approvedQuantity === 0}>
        <Grid container alignItems="center">
          {data?.product?.pictures?.length > 0 &&
          data?.product?.pictures !== undefined ? (
            <img
              src={getImgCdn(data?.product?.pictures[0]?.key, 64)}
              style={{
                width: '40px',
                height: '40px',
                objectFit: 'cover',
                borderRadius: '7px',
              }}
              onClick={() => handleProductDetails(data.product)}
            />
          ) : (
            <div
              className="logoImg"
              style={{
                height: '40px',
                width: '40px',
                backgroundColor: '#E1E1E1',
                borderRadius: '7px',
              }}
              onClick={() => handleProductDetails(data.product)}
            />
          )}
          <ContentTitleWrapper>
            <TitleWrapper>
              <Grid3x3Icon
                sx={{
                  color: '#819198',
                  height: '16px',
                  width: '16px',
                  ml: '16px',
                }}
              />
              <Typography
                fontSize="0.857rem"
                fontWeight="400"
                sx={{
                  color: 'var(--text-secondary, rgba(0, 0, 0, 0.60))',
                }}
              >
                {data?.product?.code}
              </Typography>
            </TitleWrapper>
            <TypoContainer>
              <Typography
                sx={{
                  ml: '16px',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
                onClick={() => handleProductDetails(data.product)}
              >
                {data?.product?.name}
              </Typography>
            </TypoContainer>
            <Typography
              fontSize="0.75rem"
              fontWeight={400}
              sx={{
                color: '#00000099',
                ml: '16px',
              }}
            >
              {data?.product?.boxingUnit} Item/Box
            </Typography>
          </ContentTitleWrapper>
          <OperationProductWrapper>
            <Grid container alignItems="center">
              <Typography
                sx={{
                  ml: '4px',
                  color: 'var(--text-primary, rgba(0, 0, 0, 0.87))',
                }}
              >
                {quantity} {quantity > 1 ? 'Boxes' : 'Box'}
              </Typography>
            </Grid>
          </OperationProductWrapper>
          <AvailableQtyWrapper>
            <Grid container alignItems="center">
              <RemoveIcon
                onClick={() => {
                  if (orderStatus !== 'pending-customer') {
                    substractQty(approvedQuantity);
                  }
                }}
                sx={{
                  color: '#00000042',
                  mr: '5px',
                  cursor:
                    orderStatus === 'pending-customer'
                      ? 'not-allowed'
                      : 'pointer',
                }}
              />
              <CounterContainer isHighlighted={approvedQuantity === 0}>
                <TextField
                  value={approvedQuantity}
                  onChange={(e) => {
                    if (orderStatus !== 'pending-customer') {
                      handleQtyChange(e);
                    }
                  }}
                  size="small"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  inputProps={{ min: 0, style: { textAlign: 'center' } }}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: '22px',
                    width: '18px',
                    backgroundColor: 'rgb(252, 248, 247)',
                    borderRadius: '4px',
                    marginBottom: '0 !important',
                    '.MuiInputBase-input': {
                      p: 0,
                    },
                  }}
                />
              </CounterContainer>
              <AddIcon
                onClick={() => {
                  if (orderStatus !== 'pending-customer') {
                    addQty(approvedQuantity);
                  }
                }}
                sx={{
                  color: '#0000008F',
                  ml: '5px',
                  cursor:
                    orderStatus === 'pending-customer'
                      ? 'not-allowed'
                      : 'pointer',
                }}
              />
              <Typography
                sx={{
                  ml: '4px',
                  color: 'var(--text-primary, rgba(0, 0, 0, 0.87))',
                }}
              >
                Box
              </Typography>
            </Grid>
          </AvailableQtyWrapper>
          {/* <StockQtyWrapper>
            <Typography
              sx={{
                ml: '4px',
                color: 'var(--text-primary, rgba(0, 0, 0, 0.87))',
              }}
            >
              12
            </Typography>
          </StockQtyWrapper> */}
          <PriceWrapper>
            <Typography fontSize="1rem" fontWeight="400">
              € {calculPrice(approvedQuantity, price)}
            </Typography>
            <Typography
              fontSize="0.714rem"
              fontWeight="500"
              sx={{
                color: 'var(--text-secondary, rgba(0, 0, 0, 0.60))',
                mt: '5px',
              }}
            >
              {approvedQuantity} x € {price}
            </Typography>
          </PriceWrapper>
          <DiscountWrapper>
            <Typography fontSize="1rem" fontWeight="400">
              <FormControl
                sx={{
                  width: '12ch',
                  marginBottom: '0 !important',
                  backgroundColor: 'rgba(238, 238, 238, 1)',
                }}
                variant="outlined"
              >
                <OutlinedInput
                  id="outlined-adornment-discount"
                  endAdornment={
                    <InputAdornment position="end">%</InputAdornment>
                  }
                  aria-describedby="outlined-discount-helper-text"
                  onChange={(e) => {
                    const newDiscountValue = e.target.value;
                    if (newDiscountValue === '') {
                      handleDiscount(null);
                    } else {
                      const newDiscount = parseFloat(newDiscountValue);
                      if (
                        !isNaN(newDiscount) &&
                        newDiscount >= 0 &&
                        newDiscount <= 100
                      ) {
                        handleDiscount(newDiscount);
                      }
                    }
                  }}
                  value={discount}
                  size="small"
                  inputProps={{
                    min: 0,
                    max: 100,
                    type: 'number',
                  }}
                  disabled={orderStatus === 'pending-customer'}
                />
              </FormControl>
              <IconButton
                sx={{ color: 'rgba(129, 145, 152, 1)' }}
                aria-label="reset discount"
                onClick={() => resetDiscount()}
                edge="end"
                disabled={orderStatus === 'pending-customer'}
              >
                <RefreshIcon />
              </IconButton>
            </Typography>
          </DiscountWrapper>
          <TotalWrapper>
            <Typography
              fontWeight="500"
              fontSize="1rem"
              sx={{
                padding: '8px 16px',
              }}
            >
              € {calculTotalWithDiscount(approvedQuantity, price, discount)}
            </Typography>
          </TotalWrapper>
        </Grid>
      </Wrapper>
    ),
    [data, approvedQuantity, price, discount, onProductOrdered],
  );
}

interface WrapperProps {
  isHighlighted: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  min-height: 58px;
  background-color: ${(props) =>
    props.isHighlighted ? 'rgba(211, 47, 47, 0.08)' : '#fff'};
  padding: 6.5px 12px;
  border-bottom: solid 1px #d6d6d6;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ContentTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TypoContainer = styled.div`
  width: 273px;
`;

const OperationProductWrapper = styled.div`
  padding: 0px 10px;
  width: 152px;
`;

const AvailableQtyWrapper = styled.div`
  padding: 0px 10px;
  width: 162px;
`;

const PriceWrapper = styled.div`
  padding: 0px 8px;
  width: 152px;
`;

const DiscountWrapper = styled.div`
  padding: 8px 8px;
  width: 152px;
`;

const TotalWrapper = styled.div`
  padding: 8px 12px;
  width: 152px;
`;

interface CounterContainerProps {
  isHighlighted: boolean;
}

const CounterContainer = styled.div<CounterContainerProps>`
  ${(props) =>
    props.isHighlighted ? 'border: solid thin rgba(239, 83, 80, 1);' : ''};
  background-color: ${(props) =>
    props.isHighlighted ? 'rgba(246, 210, 210, 1)' : 'rgb(240, 241, 247)'};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  min-width: 18px;
  border-radius: 4px;
`;
