import { Box, Chip, Radio, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';

export const ChipWithRadio = ({
  value,
  label,
  selectedValue,
  disabled = false,
}) => {
  const checked = selectedValue === value;
  const theme = useSelector(selectTheme);
  return (
    <Chip
      label={
        <Box display="flex" alignItems="center" marginRight="12px">
          <Radio
            value={value}
            checked={checked}
            sx={{
              p: 0,
              mr: '4px',
              color: checked
                ? theme?.primaryActiveColor
                : theme?.chipsBorderGrey,
              '&.Mui-checked': {
                color: checked
                  ? theme?.primaryActiveColor
                  : theme?.chipsBorderGrey,
              },
            }}
            disabled={disabled}
          />
          <Typography fontWeight={checked ? '500' : '400'} fontSize="1rem">
            {label}
          </Typography>
        </Box>
      }
      variant="outlined"
      style={{
        backgroundColor: checked ? theme?.primaryLight1 : 'white',
        borderColor: checked ? theme?.strokePrimary : theme?.chipsBorderGrey,
        color: checked ? theme?.primaryActiveColor : theme?.textBlack,
        marginLeft: '12px',
      }}
      size="medium"
    />
  );
};
