import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../types/State';
import { initialState } from './index';

const selectSlice = (state: RootState) => state.pagePreferences || initialState;

export const selectTabsPreferences = createSelector(
  [selectSlice],
  state => state.tabsOrder,
);

export const selectColumnsPreferences = createSelector(
  [selectSlice],
  state => state.columnsOrder,
);

export const selectFiltersPreferences = createSelector(
  [selectSlice],
  state => state.filters,
);
