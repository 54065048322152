import React, { useEffect, useRef, useState } from 'react';
import { roundtripActions } from 'common/store/roundtrips';
import { useDispatch } from 'react-redux';
import { selectEditingRoundtripUser } from 'common/store/roundtrips/selectors';
import { useSelectors } from './hook/useSelector';
import SelectorComponent from './components/SelectorComponent';
import { UserPropsObj } from './components/UserPropsObj';
import { Driver } from 'app/components/RoundTrips/RoundTripData/statuses';

interface VehicleSelectorProps {
  user: any;
  users: any;
  addFuction: any;
  roundTripCopy: any;
  updateRoundtripData: any;
  roundtripId: any;
  driverAgentId: any;
  ripperAgentId: any;
}

function RipperSelector({
  user,
  users,
  addFuction,
  roundTripCopy,
  updateRoundtripData,
  roundtripId,
  driverAgentId,
  ripperAgentId,
}: VehicleSelectorProps) {
  const dispatch = useDispatch();

  const [selectedRipper, setSelectedRipper] = useState(user);
  const selectedRef = useRef(null);

  useEffect(() => {
    setSelectedRipper(user);
  }, [user]);

  const {
    handleSelectClick,
    handleSelectLeave,
    isSelected,
    loadingUpdate,
    editProduct,
    setLoadingUpdate,
    setIsSelected,
  } = useSelectors(
    selectedRef,
    roundtripActions,
    selectEditingRoundtripUser,
    users,
  );
  const UserProps = UserPropsObj(users);
  return (
    <SelectorComponent
      selectedRef={selectedRef}
      roundtripId={roundtripId}
      user={selectedRipper}
      handleSelectClick={() => {
        setIsSelected(true);
        handleSelectClick();
      }}
      loadingUpdate={loadingUpdate}
      isSelected={isSelected}
      editProduct={editProduct}
      UserProps={UserProps}
      type="ripper"
      onChange={async (_e, value: any) => {
        setSelectedRipper(value);
        setLoadingUpdate(true);
        dispatch(roundtripActions.setEditingRoundtripUser(true));
        try {
          await addFuction({
            id: roundTripCopy.id,
            data: {
              agents: [
                {
                  _user: value?.['_id'],
                  _role: ripperAgentId,
                },
                {
                  _user: roundTripCopy?.agents?.find(
                    (data) => data?._role?.key === Driver,
                  )?._user?.id,
                  _role: driverAgentId,
                },
              ],
            },
            UpdateRoundtripData: updateRoundtripData,
          });
        } catch (e) {
          setSelectedRipper(user);
        }
        dispatch(roundtripActions.setEditingRoundtripUser(false));
        handleSelectLeave();
      }}
    />
  );
}
export default RipperSelector;
