import styled from 'styled-components';
import { themes } from 'styles/theme/themes';

export const Flex = styled.div`
  display: flex;
  margin-bottom: 5px;
  margin-top: 31px;
  margin-right: 16px;
  margin-left: 16px;
  justify-content: space-evenly;
  height : 100%;

  .orange_status {
    color: ${themes?.default?.warningMain};
    align-items: center;
    padding: 4px 10px;
    height: 22px;
    background: ${themes?.default?.warningMain}40;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 135%;
    border-radius: 16px;
    margin-left: 10px;
  }
  .grey_status {
    color: ${themes?.default?.tapa};
    align-items: center;
    padding: 4px 10px;
    height: 22px;
    background: ${themes?.default?.grey2}40;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 135%;
    border-radius: 16px;
    margin-left: 10px;
  }
  .green_status {
    color: ${themes?.default?.greenMain}
    height: 22px;
    background: ${themes?.default?.success};
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 135%;
    border-radius: 16px;
    margin-left: 10px;
  }
  .red_status {
    color: ${themes?.default?.errorMain};
    align-items: center;
    padding: 4px 10px;
    height: 22px;
    background: ${themes?.default?.errorBackground};
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 135%;
    border-radius: 16px;
    margin-left: 10px;
  }
  .time {
    padding: 1px 10px;
    max-width: 70px;
    border: 1px solid ${themes?.default?.greyDisabled};
    border-radius: 16px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 0.625rem;
    line-height: 18px;
    margin-left: 10px;
    display: flex;
    align-items: center
  }

  *::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background-color: none;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    background-color: darkgray;
  }
`;

export const LeftSide = styled.div`
  height: 100%;
  overflow: hidden;
  .left_header {
    display: flex;
    gap: 16px;
    align-items: flex-start;
    position: relative;
    padding: 1px 0 0 0;
  }
  width: 50%;
  .title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 143%;
    letter-spacing: 0.17px;
    color: ${themes?.default?.textColorPrimary};
    margin-bottom: 20px;
  }
  .content {
    overflow-y: auto;
    height: calc(100% - 75px);
  }

  /* .top_content {
    padding-left: 22px;
  } */
`;

export const RightSide = styled.div`
  min-width: 380px;
  overflow: hidden;
  .content {
    overflow-y: auto;
    height: inherit;
  }

  /* .content {
    height: calc(100% - 30px);
  }
  .gray_value_data {
    height: fit-content;
  }*/

  .title {
    /* margin-top: 10px; */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 0.813rem;
    line-height: 160%;
    letter-spacing: 0.15px;
    color: ${themes?.default?.lightTextPrimary};
  }
  .date {
    margin-top: 18px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 20px;
    letter-spacing: 0.14px;
  }
`;
export const Seperator = styled.div`
  background: ${themes?.default?.iconColor};
  height: 95%;
  width: 1px;
  margin: 0px 10px;
`;

export const Item = styled.div`
  .content {
    gap: 5px;
    display: flex;
    height: 100%;
  }
  .gray_value {
    min-width: max-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3.06667px 3.83333px;
    width: fit-content;
    height: 23.13px;
    background: rgba(0, 0, 0, 0.04);
    border-radius: 3.06667px;
    font-size: 0.625rem;
    line-height: 17px;
    letter-spacing: 0.352667px;
  }
  height: 97px;
  border-bottom: 1px solid ${themes?.default?.gainsboro};
  margin-top: 0px;
  margin-top: 27px;
  display: flex;
  gap: 44px;
  padding-bottom: 22px;
  .data_Name_Location {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 143%;
    letter-spacing: 0.17px;
    max-width: 271px;
    min-width: 271px;
    overflow: hidden;
    height: 22px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .checkbox-group {
    span.MuiButtonBase-root {
      padding: 0px !important;
    }
  }
  .checkbox-group {
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    .code {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 0.75rem;
      line-height: 143%;
      letter-spacing: 0.17px;
      color: ${themes?.default?.lightTextPrimary};
      display: inherit;
    }
  }
`;

export const CheckboxItem = styled.div`
  border-bottom: 1px solid ${themes?.default?.gainsboro};
  margin-top: 0px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding-bottom: 8px;
  .checkbox-group {
    span.MuiButtonBase-root {
      padding: 0px !important;
    }
  }
  .checkbox-group {
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    .code {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 0.75rem;
      line-height: 143%;
      letter-spacing: 0.17px;
      color: ${themes?.default?.lightTextPrimary};
      display: inherit;
    }
  }
`;

export const CenterDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 37%;
`;
