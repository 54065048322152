import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { OverviewCell } from './components/OverviewCell';
import { Icon } from 'app/components/Icon';
import { Truck, SteeringWheel, Stack } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import Ripper from 'assets/img/map/legend/warehouse/Ripper.svg';
import { getStatusesToShow } from 'app/pages/AddRoundTrips/functions';
import { RoundtripStatus } from 'app/components/RoundTrips/RoundTripData/statuses';
import { StatusIndicator } from 'app/components/RoundtripStatus';
import { getOrderStatusesToShow } from 'app/helpers/helpers';
import { selectConfiguration } from 'common/store/organization/selectors';
import { useSelector } from 'react-redux';

export const WarehouseInfoPopover = ({
  warehouseData,
  roundTripsCount,
  ordersCount,
}) => {
  const { t } = useTranslation();

  const roundtripStatusesToShow = [
    RoundtripStatus.Pending,
    RoundtripStatus.Planned,
    RoundtripStatus.Loaded,
    RoundtripStatus.OnWay,
    RoundtripStatus.Completed,
    RoundtripStatus.Paused,
    RoundtripStatus.OnDock,
    RoundtripStatus.Unloaded,
    RoundtripStatus.Parked,
    RoundtripStatus.Canceled,
  ];

  const statusesToShow = getStatusesToShow(roundtripStatusesToShow);
  const configuration = useSelector(selectConfiguration);

  const statuses: Array<{
    status: string;
    label?: string;
    classNameCircle: string;
    color: string;
  }> = getOrderStatusesToShow(configuration?.orderStatusLogistic || []);

  return (
    <WarehousInfoPopover>
      <WarehouseOverviewWrapper>
        <OverviewCell
          icon={
            <Icon
              icon={<Truck />}
              color={themes?.default?.CRMSPrimary}
              size={25}
            />
          }
          title={t('vehicles')}
          count={warehouseData?.vehicles || 0}
        />
        <OverviewCell
          icon={
            <Icon
              icon={<SteeringWheel />}
              color={themes?.default?.primary}
              size={25}
            />
          }
          title={t('user.role.driver.plural')}
          count={warehouseData?.driver || 0}
        />
        <OverviewCell
          icon={
            <Icon
              icon={<img src={Ripper} alt="" />}
              color={themes?.default?.markerWarning2}
              size={25}
            />
          }
          title={t('rippers')}
          count={warehouseData?.ripper || 0}
        />
        <OverviewCell
          icon={
            <Icon
              icon={<Stack />}
              color={themes?.default?.ordoriaPrimary}
              size={25}
            />
          }
          title={t('home.support_units')}
          count={warehouseData?.supportUnits || 0}
        />
      </WarehouseOverviewWrapper>
      <Divider />
      <StatusSection>
        <SectionTitle>
          {t('roundtrips_statuses')}
          <Count>{roundTripsCount || 0}</Count>
        </SectionTitle>
        <SectionBody>
          {statusesToShow?.map((roundtripStatus) => {
            return (
              <StatusWrapper
                opaque={
                  !warehouseData?.roundtripStatus?.[roundtripStatus.status]
                }
              >
                <StatusIndicator
                  className="statusIndicator"
                  color={roundtripStatus.color}
                />
                <LegendText>
                  {t(`roundtrips.status.${roundtripStatus?.status}`)}
                </LegendText>
                {roundtripStatus.status === 'pending' ? (
                  <LegnedCount>
                    {warehouseData?.roundtripStatus?.pending || 0}
                  </LegnedCount>
                ) : roundtripStatus.status === 'prepared' ? (
                  <LegnedCount>
                    {warehouseData?.roundtripStatus?.prepared || 0}
                  </LegnedCount>
                ) : roundtripStatus.status === 'planned' ? (
                  <LegnedCount>
                    {warehouseData?.roundtripStatus?.planned || 0}
                  </LegnedCount>
                ) : roundtripStatus.status === 'loaded' ? (
                  <LegnedCount>
                    {warehouseData?.roundtripStatus?.loaded || 0}
                  </LegnedCount>
                ) : roundtripStatus.status === 'on-way' ? (
                  <LegnedCount>
                    {warehouseData?.roundtripStatus?.['on-way'] || 0}
                  </LegnedCount>
                ) : roundtripStatus.status === 'completed' ? (
                  <LegnedCount>
                    {warehouseData?.roundtripStatus?.completed || 0}
                  </LegnedCount>
                ) : roundtripStatus.status === 'on-dock' ? (
                  <LegnedCount>
                    {warehouseData?.roundtripStatus?.['on-dock'] || 0}
                  </LegnedCount>
                ) : roundtripStatus.status === 'unloaded' ? (
                  <LegnedCount>
                    {warehouseData?.roundtripStatus?.unloaded || 0}
                  </LegnedCount>
                ) : roundtripStatus.status === 'parked' ? (
                  <LegnedCount>
                    {warehouseData?.roundtripStatus?.parked || 0}
                  </LegnedCount>
                ) : roundtripStatus.status === 'paused' ? (
                  <LegnedCount>
                    {warehouseData?.roundtripStatus?.paused || 0}
                  </LegnedCount>
                ) : roundtripStatus.status === 'canceled' ? (
                  <LegnedCount>
                    {warehouseData?.roundtripStatus?.canceled || 0}
                  </LegnedCount>
                ) : roundtripStatus.status === 'loading' ? (
                  <LegnedCount>
                    {warehouseData?.roundtripStatus?.loading || 0}
                  </LegnedCount>
                ) : roundtripStatus.status === 'unloading' ? (
                  <LegnedCount>
                    {warehouseData?.roundtripStatus?.unloading || 0}
                  </LegnedCount>
                ) : (
                  <LegnedCount>|| 00</LegnedCount>
                )}
              </StatusWrapper>
            );
          })}
        </SectionBody>
      </StatusSection>
      <Divider />
      <StatusSection>
        <SectionTitle>
          {t('to_be_delivered_orders')}
          <Count>{ordersCount || 0}</Count>
        </SectionTitle>
        <SectionBody>
          {statuses?.map((status) => {
            return (
              <StatusWrapper
                opaque={!warehouseData?.orderStatuses?.[status.status]}
              >
                <StatusIndicator
                  className="statusIndicator"
                  color={status.color}
                />
                <LegendText>
                  {t(`orders.status.${status.label || status.status}`)}
                </LegendText>
                {status.status === 'blocked' ? (
                  <LegnedCount>
                    {warehouseData?.orderStatuses?.blocked || 0}
                  </LegnedCount>
                ) : status.status === 'canceled' ? (
                  <LegnedCount>
                    {warehouseData?.orderStatuses?.canceled || 0}
                  </LegnedCount>
                ) : status.status === 'confirmed' ? (
                  <LegnedCount>
                    {warehouseData?.orderStatuses?.confirmed || 0}
                  </LegnedCount>
                ) : status.status === 'loaded' ? (
                  <LegnedCount>
                    {warehouseData?.orderStatuses?.loaded || 0}
                  </LegnedCount>
                ) : status.status === 'on-the-road' ? (
                  <LegnedCount>
                    {warehouseData?.orderStatuses?.['on-the-road'] || 0}
                  </LegnedCount>
                ) : status.status === 'planned' ? (
                  <LegnedCount>
                    {warehouseData?.orderStatuses?.planned || 0}
                  </LegnedCount>
                ) : status.status === 'prepared' ? (
                  <LegnedCount>
                    {warehouseData?.orderStatuses?.prepared || 0}
                  </LegnedCount>
                ) : status.status === 'ready-for-delivery' ? (
                  <LegnedCount>
                    {warehouseData?.orderStatuses?.['ready-for-delivery'] || 0}
                  </LegnedCount>
                ) : status.status === 'validated' ? (
                  <LegnedCount>
                    {warehouseData?.orderStatuses?.validated || 0}
                  </LegnedCount>
                ) : status.status === 'delivered' ? (
                  <LegnedCount>
                    {warehouseData?.orderStatuses?.delivered || 0}
                  </LegnedCount>
                ) : status.status === 'unloaded' ? (
                  <LegnedCount>
                    {warehouseData?.orderStatuses?.unloaded || 0}
                  </LegnedCount>
                ) : (
                  <LegnedCount>0</LegnedCount>
                )}
              </StatusWrapper>
            );
          })}
        </SectionBody>
      </StatusSection>
    </WarehousInfoPopover>
  );
};

const WarehousInfoPopover = styled.div`
  margin: 20px 0 0 0;
  border: 1px solid ${themes?.default?.gainsboro2};
  min-width: 350px;
  min-height: 365.14px;
  border-radius: 8px;
  background: ${themes?.default?.accordionWhiteBg};
  box-shadow: 0px 2px 4px -1px #00000033;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 13px;
`;

const WarehouseOverviewWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${themes?.default?.gainsboro2};
`;

const StatusSection = styled.div`
  width: 100%;
  min-height: 104.4px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0.3526666760444641px;
  color: ${themes?.default?.black};
`;

const Count = styled.div`
  width: 18px;
  height: 17px;
  padding: 2px 4px 2px 4px;
  border-radius: 3.07px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.5rem;
  letter-spacing: 0.1599999964237213px;
  color: ${themes?.default?.black}99;
  background: ${themes?.default?.black}0A;
`;

const SectionBody = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
  max-width: 318px;
`;

const StatusWrapper = styled.div<{ opaque?: boolean }>`
  min-height: 23.13px;
  padding: 3.07px 3.83px 3.07px 3.83px;
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 3.07px;
  justify: space-between;
  background: ${themes?.default?.black}0A;
  opacity: ${(props) => (props.opaque ? '0.3' : '1')};

  .statusIndicator {
    height: 8px !important;
    width: 8px !important;
  }
`;

const LegendText = styled.p`
  margin: 0 !important;
  white-space: nowrap;
  height: fit-content;
  color: ${themes?.default?.black};
  font-size: 0.625rem;
  letter-spacing: 0.3526666760444641px;
`;

const LegnedCount = styled.span`
  font-size: 0.625rem;
  font-weight: 500;
  letter-spacing: 0.3526666760444641px;
  color: ${themes?.default?.black};
`;
