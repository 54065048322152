import { InputProps, TextField } from '@mui/material';
import { useErrorFormatter } from 'hooks/Forms/useErrorFormatter';
import _ from 'lodash';
import { forwardRef } from 'react';
import {
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericField = forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatField(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        allowNegative={false}
        thousandSeparator
        valueIsNumericString
      />
    );
  },
);

const NumericWithPrefixFormatField = forwardRef<
  NumericFormatProps,
  CustomProps
>(function NumericFormatField(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      allowNegative={false}
      valueIsNumericString
      prefix="$"
    />
  );
});

export const FormTextField = <T extends FieldValues>(
  props: UseControllerProps<T> & {
    label: string;
    numeric?: boolean;
    disabled?: boolean;
    maxRows?: number;
    rows?: number;
    num?: boolean;
    multiline?: boolean;
    type?: string;
    style?: any;
    required?: boolean;
    inputProps?: Partial<InputProps>;
    errors?: string;
  },
) => {
  const controllerProps = _.omit(props, ['label', 'disabled']);
  const {
    field: { value, onChange, onBlur },
    fieldState: { error },
  } = useController(controllerProps);
  const { formatError } = useErrorFormatter();
  const { t } = useTranslation();
  return (
    <TextField
      name={props.name}
      maxRows={props.maxRows}
      required={props.required}
      value={value}
      rows={props?.rows}
      type={props?.type}
      multiline={props?.multiline}
      onChange={onChange}
      onBlur={onBlur}
      error={!!props.errors || !!error}
      helperText={props.errors || (error?.message ?? formatError(error))}
      label={t(props.label)}
      disabled={props.disabled}
      sx={props.style ? props.style : { width: '100%' }}
      InputProps={{
        inputComponent: props.numeric
          ? (NumericWithPrefixFormatField as any)
          : props.num
            ? (NumericField as any)
            : undefined,

        ...props.inputProps,
      }}
    />
  );
};

export default FormTextField;
