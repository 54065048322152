import { toolsActions } from 'app/slices/tools';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
export const ContactUs = () => {
  const logoUrl = `${process.env.PUBLIC_URL}/BampteeLogo.svg`;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(toolsActions.setZoomIn({ status: true }));
    return () => {
      dispatch(toolsActions.setZoomIn({ status: false }));
    };
  });
  return (
    <Wrapper>
      <ImgWrapper>
        <img src={logoUrl} alt="Bamptee" width="75px" />
      </ImgWrapper>
      <p>
        For further inquiries and assistance, please do not hesitate to contact
        us at <a href="mailTo:support@bamptee.com">support@bamptee.com</a>.
      </p>
      <p>
        Our dedicated support team is here to provide you with the help you
        need. Whether you have questions about using our product, need technical
        support, or require any other assistance, we are committed to ensuring
        your success and productivity.
      </p>
      <p>
        Our team strives to respond promptly and address your concerns with the
        utmost care and professionalism. Thank you for your continued
        collaboration and trust in our product. We look forward to assisting
        you.
      </p>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  text-align: justify;
`;
const ImgWrapper = styled.div`
  width: 100%;
  text-align: center;
  padding-bottom: 20px;
`;
