import { SxProps, TableCell, TableRow, Theme } from '@mui/material';
import { withStyles } from '@mui/styles';
import React from 'react';
import { AccordionSummaryContent, StopTitle } from '../styles';

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  AccordionSummaryContentWrapper,
  AccordionSummaryLabel,
  NumberOfStopsWrapper,
} from './styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'app/pages/AddRoundTrips/components/TableComponent/styles/accordion';
import { themes } from 'styles/theme/themes';

export const ExcelFunction = ({
  rows,
  headCells,
  stopsAccordion,
  setStopsAccordion,
  selectedAccordionGroupIndex,
  theme,
  selected,
  selectedAccordionGroup,
}) => {
  let maxStops: number = 0;
  const tablesData: any = [];
  for (let i = 0; i < rows[selectedAccordionGroupIndex]?.data.length; i++) {
    if (
      rows[selectedAccordionGroupIndex].data[i].accordion?.length > maxStops
    ) {
      maxStops = rows[selectedAccordionGroupIndex].data[i].accordion.length;
    }
  }
  const dummyArray: any = [];
  for (let i = 0; i < maxStops; i++) {
    dummyArray.push(0);
  }
  tablesData.push(
    headCells.map((headCell) => {
      if (headCell.id === 'key_point') return null;
      if (headCell.isAccordion) {
        const isSelected =
          rows[selectedAccordionGroupIndex]?.data?.length &&
          rows[selectedAccordionGroupIndex]?.data?.length ===
            selected[selectedAccordionGroup]?.length;
        let styles: React.CSSProperties | undefined = { height: '100%' };
        if (isSelected) {
          styles = { ...styles, background: `${theme.primaryLightO}` };
        }
        return (
          <Accordion key={headCell.id} expanded={stopsAccordion}>
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              expandIcon={null}
              sx={{ height: 38, minHeight: 'unset', padding: 'unset' }}
            >
              <AccordionSummaryContentWrapper>
                <AccordionSummaryContent
                  style={{
                    borderBottom: `0.063rem solid ${theme.tableBorder}`,
                  }}
                >
                  <AccordionSummaryLabel
                    className="number-stops-label"
                    style={{ ...styles }}
                  >
                    <NumberOfStopsWrapper
                      onClick={(event) => {
                        event.stopPropagation();
                        setStopsAccordion((oldState) => {
                          return !oldState;
                        });
                      }}
                    >
                      <span>Number of Stops </span>
                      {stopsAccordion ? (
                        <ArrowDropUpIcon />
                      ) : (
                        <ArrowDropDownIcon />
                      )}
                    </NumberOfStopsWrapper>
                  </AccordionSummaryLabel>
                  {rows[selectedAccordionGroupIndex]?.data.map((row, index) => {
                    const isSelected = selected[
                      selectedAccordionGroup
                    ]?.includes(row.data.id.value);
                    let styles: SxProps<Theme> | undefined = { height: '100%' };
                    if (isSelected) {
                      styles = { ...styles, background: theme.primaryLightO };
                    }
                    let cellWidth: {};
                    if (!index) {
                      cellWidth = {
                        minWidth: 250,
                      };
                    } else {
                      cellWidth = {
                        minWidth: 250,
                      };
                    }

                    return (
                      <TableCell
                        key={row.data.id.value}
                        align="left"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          cursor: 'pointer',
                          borderBottom: 'unset',
                          height: '38px',
                          borderRight:
                            index !==
                            rows[selectedAccordionGroupIndex]?.data.length - 1
                              ? `0.063rem solid ${theme.primaryLightO}`
                              : 'unset',
                          ...styles,
                        }}
                        style={{
                          flex: 1,
                          overflow: 'hidden',
                          ...cellWidth,
                        }}
                      >
                        {row.data[headCell.id]?.value}
                      </TableCell>
                    );
                  })}
                </AccordionSummaryContent>
              </AccordionSummaryContentWrapper>
            </AccordionSummary>
            <AccordionDetails>
              {dummyArray.map((_, parentMapIndex) => {
                return (
                  <div
                    key={parentMapIndex}
                    style={{
                      display: 'flex',
                    }}
                  >
                    <StopTitle style={{ position: 'sticky', left: 0 }}>
                      Stop{parentMapIndex + 1}
                    </StopTitle>
                    {rows[selectedAccordionGroupIndex]?.data.map((row) => {
                      return (
                        (
                          <div key={row.data.id.value} style={{ flex: 1 }}>
                            {row.accordion[parentMapIndex]}
                          </div>
                        ) || (
                          <div
                            key={row.data.id.value}
                            style={{ minWidth: 250, flex: 1 }}
                          />
                        )
                      );
                    })}
                  </div>
                );
              })}
            </AccordionDetails>
          </Accordion>
        );
      }
      const isSelected =
        rows[selectedAccordionGroupIndex]?.data?.length &&
        rows[selectedAccordionGroupIndex]?.data?.length ===
          selected[selectedAccordionGroup]?.length;
      let styles = {};
      if (isSelected) {
        styles = { background: `${theme.primaryLightO} !important` };
      }
      return (
        <React.Fragment key={headCell.id}>
          <StyledTableRow hover sx={{ display: 'flex' }}>
            <TableCell
              align="left"
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                borderBottom: 'unset',
                borderRight: `0.063rem solid ${theme.primaryLightO}`,
                flex: 1,
                overflow: 'hidden',
                minWidth: 180,
                maxWidth: 180,
                fontWeight: 700,
                height: '38px',
                ...styles,
              }}
            >
              {headCell.label}
            </TableCell>
            {rows[selectedAccordionGroupIndex]?.data.map((row, index) => {
              const isSelected = selected[selectedAccordionGroup]?.includes(
                row.data.id.value,
              );
              let styles = {};
              if (isSelected) {
                styles = { background: theme.primaryLightO };
              }

              return (
                <TableCell
                  key={row.data.id.value}
                  align="left"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    borderBottom: 'unset',
                    borderRight:
                      index !==
                      rows[selectedAccordionGroupIndex]?.data.length - 1
                        ? `0.063rem solid ${theme.primaryLightO}`
                        : 'unset',
                    height: '38px',
                    ...styles,
                  }}
                  style={{
                    flex: 1,
                    overflow: 'hidden',
                    minWidth: 250,
                  }}
                >
                  {row.data[headCell.id]?.value}
                </TableCell>
              );
            })}
          </StyledTableRow>
          <div
            style={{
              borderBottom: `0.063rem solid ${theme.tableBorder}`,
            }}
          />
        </React.Fragment>
      );
    }),
  );
  tablesData[0].push(
    <React.Fragment key="empty-row">
      <StyledTableRow hover sx={{ display: 'flex' }}>
        <TableCell
          align="left"
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            borderBottom: 'unset',
            flex: 1,
            overflow: 'hidden',
            minWidth: 180,
            maxWidth: 180,
            fontWeight: 700,
            height: '38px',
          }}
        />
        {rows[selectedAccordionGroupIndex]?.data.map((row) => {
          const isSelected = selected[selectedAccordionGroup]?.includes(
            row.data.id.value,
          );
          let styles = {};
          if (isSelected) {
            styles = { background: theme.primaryLightO };
          }

          return (
            <TableCell
              key={row.data.id.value}
              align="left"
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                borderBottom: 'unset',
                height: '38px',
                ...styles,
              }}
              style={{
                flex: 1,
                overflow: 'hidden',
                minWidth: 250,
              }}
            />
          );
        })}
      </StyledTableRow>
    </React.Fragment>,
  );
  return tablesData;
};

export const StyledTableRow = withStyles(() => ({
  root: {
    '&:hover': {
      '& td': {
        // '&:first-child': {
        backgroundColor: themes?.default?.grey,
        // },
      },
    },
    '& td': {
      whiteSpace: 'nowrap !important',
      '&:first-child': {
        position: 'sticky',
        left: 0,
        backgroundColor: themes?.default?.accordionWhiteBg,
        zIndex: 1,
      },
    },
  },
}))(TableRow);
