import { useLazyCheckCustomerCodeQuery } from 'common/services/customerApi';
import { useToaster } from 'hooks/useToaster';
import { useRef } from 'react';

interface IOptions {
  existingCode?: string;
  code?: string;
  id?: string;
}

export const useCheckCode = () => {
  const ref = useRef<any>();
  const [trigger, { data }] = useLazyCheckCustomerCodeQuery();
  const toast = useToaster();

  const exists = data?.exists;
  const validate = async function ({
    existingCode,
    code = '',
    id = '',
  }: IOptions) {
    if (existingCode && existingCode === code) {
      return true;
    }
    ref?.current?.abort();
    try {
      const query = trigger(`id=${id}&code=${code}`, true);
      ref.current = query;
      const { data } = await query;
      return !data?.exists;
    } catch (e: any) {
      if (e?.statusCode === 500) {
        toast(3000, 'error', 'common.error');
      }
      return false;
    }
  };

  return { exists, validate };
};
