import { createSlice } from '@reduxjs/toolkit';
import { CartState } from './types';

export const initialState: CartState = {
  selectedProducts: {
    allSelected: true,
    products: {},
  },
  cartVisibilityState: false,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setNewSelectedProduct: (state, action) => {
      state.selectedProducts.products[action.payload.productId] = true;

      const allSelected = Object.values(state.selectedProducts.products).every(
        selected => selected === true,
      );
      state.selectedProducts.allSelected = allSelected;
    },
    removeSelectedProduct: (state, action) => {
      if (!!state.selectedProducts.products[action.payload.productId]) {
        state.selectedProducts.products[action.payload.productId] = false;
      }

      state.selectedProducts.allSelected = false;
    },
    removeAllProductsFromCart: state => {
      state.selectedProducts.products = {};
      state.selectedProducts.allSelected = false;
    },
    changeAllProductSelection: state => {
      const newAllSelectedState = !state.selectedProducts.allSelected;
      state.selectedProducts.allSelected = newAllSelectedState;

      Object.keys(state.selectedProducts.products).forEach(id => {
        state.selectedProducts.products[id] = newAllSelectedState;
      });
    },
    changeCartVisibilityState: state => {
      state.cartVisibilityState = !state.cartVisibilityState;
    },
    cartIsVisible: state => {
      state.cartVisibilityState = true;
    },
  },
});

export const {
  changeCartVisibilityState,
  cartIsVisible,
  setNewSelectedProduct,
  removeSelectedProduct,
  changeAllProductSelection,
  removeAllProductsFromCart,
} = cartSlice.actions;
export const cartReducer = cartSlice.reducer;
