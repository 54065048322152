import React, { useMemo } from 'react';
import styled from 'styled-components';
import txt from 'assets/img/chat/FileTypeIcons/txt.svg';
import pdf from 'assets/img/chat/FileTypeIcons/pdf.svg';
import csv from 'assets/img/chat/FileTypeIcons/csv.svg';
import xlsx from 'assets/img/chat/FileTypeIcons/xlsx.svg';
import ppt from 'assets/img/chat/FileTypeIcons/ppt.svg';
import pptx from 'assets/img/chat/FileTypeIcons/pptx.svg';
import doc from 'assets/img/chat/FileTypeIcons/doc.svg';
import docx from 'assets/img/chat/FileTypeIcons/docx.svg';

interface Props {
  fileName?: string;
  fileType?: string;
  size?: { height: number; width: number };
}

export const FileIcon: React.FC<Props> = ({ fileName, fileType, size }) => {
  const type = useMemo(() => {
    if (fileType) {
      return fileType;
    } else if (fileName) {
      const splittedFileName = fileName.split('.');
      return splittedFileName[splittedFileName?.length - 1];
    } else {
      return '';
    }
  }, [fileName, fileType]);

  const icon = useMemo(() => {
    if (!type) return '';
    switch (type) {
      case 'txt':
        return txt;
      case 'pdf':
        return pdf;
      case 'csv':
        return csv;
      case 'xlsx':
        return xlsx;
      case 'ppt':
        return ppt;
      case 'pptx':
        return pptx;
      case 'doc':
        return doc;
      case 'docx':
        return docx;
      default:
        return 'file';
    }
  }, [type]);

  return (
    <Wrapper size={size}>
      <img src={icon} alt={type} />
    </Wrapper>
  );
};

const Wrapper = styled.div<{ size?: { height: number; width: number } }>`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: ${(props) => `${props?.size?.height}px !important` || 'none'};
    width: ${(props) => `${props?.size?.width}px !important` || 'none'};
  }
`;
