import { FuelDetails } from 'app/components/FuelDetails';
import If from 'app/components/If';
import { OrderDetails } from 'app/components/OrderDetails';
import { TwoViewModal } from 'app/components/TwoViewModal';

import { isEmpty } from 'lodash';

import styled from 'styled-components';

interface SwipeableDrowerOrderProps {
  selectedOrder: any;
  handleCloseOrderDrawer: () => void;
  onUpdate?: () => void;
  orderChangeStatus?: (status: string) => void;
  triggerRoundtripDetails?: Function;
  viewMode?: boolean;
  handleDisplayMap?: (orderId: string) => void;
  defaultTab?: number;
}

export function SwipeableDrowerOrder({
  selectedOrder,
  handleCloseOrderDrawer,
  onUpdate,
  triggerRoundtripDetails,
  handleDisplayMap = () => {},
  defaultTab = 0,
}: SwipeableDrowerOrderProps) {
  // hooks

  return (
    <TwoViewModal
      onOpen={!isEmpty(selectedOrder)}
      onClose={() => handleCloseOrderDrawer()}
      width="auto"
      scrollY={false}
    >
      <DrawerContent>
        <If condition={selectedOrder}>
          <If
            condition={selectedOrder?.type === 'fuel'}
            otherwise={
              <OrderDetails
                open={!!selectedOrder}
                onClose={handleCloseOrderDrawer}
                orderId={selectedOrder?._id}
                refreshRoundtrip={(roundtripId) => {
                  if (!!roundtripId) {
                    triggerRoundtripDetails?.({ roundtripId });
                  }
                  onUpdate?.();
                }}
                defaultTabIndex={defaultTab}
                handleDisplayMap={handleDisplayMap}
              />
            }
          >
            <FuelDetails
              order={selectedOrder}
              onCloseDrawer={handleCloseOrderDrawer}
            />
          </If>
        </If>
      </DrawerContent>
    </TwoViewModal>
  );
}

const DrawerContent = styled.div`
  height: calc(100vh - 60px);
  max-width: 100%;
  margin: auto;
`;
