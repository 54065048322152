import { themes } from 'styles/theme/themes';
import {
  Canceled,
  Completed,
  Loaded,
  OnDock,
  OnWay,
  Parked,
  Paused,
  Pending,
  Planned,
  Prepared,
  Unloaded,
  Loading,
  Unloading,
} from './statuses';

export const ROUNDTRIP_STATUS = {
  pending: {
    color: themes?.default?.warningMain,
    background: `${themes?.default?.warningMain}1A`,
  },
  loaded: {
    color: themes?.default.primary,
    background: `${themes?.default.primary}1A`,
  },
  prepared: {
    color: themes?.default?.purple,
    background: `${themes?.default?.purple}1A`,
  },
  planned: {
    color: themes?.default?.greenStatus,
    background: `${themes?.default?.greenStatus}1A`,
  },
  on_way: {
    color: themes?.default?.darkOrchid,
    background: `${themes?.default?.darkOrchid}1A`,
  },
  completed: {
    color: themes?.default?.lasPalmas,
    background: `${themes?.default?.lasPalmas}1A`,
  },
  on_dock: {
    color: themes?.default?.greenMain,
    background: `${themes?.default?.greenMain}1A`,
  },
  unloaded: {
    color: themes?.default?.summerSky,
    background: `${themes?.default?.summerSky}1A`,
  },
  unloading: {
    color: themes?.default?.summerSky,
    background: `${themes?.default?.summerSky}1A`,
  },
  parked: {
    color: themes?.default?.textColorPrimary,
    background: `${themes?.default?.textColorPrimary}1A`,
  },
  paused: {
    color: themes.default.echoBlue,
    background: `${themes?.default?.echoBlue}1A`,
  },
  canceled: {
    color: themes?.default?.redStatus,
    background: `${themes?.default?.redStatus}1A`,
  },
  loading: {
    color: themes?.default.primary,
    background: `${themes?.default.primary}1A`,
  },
};

// TODO: remove ternary operators
export const colorRoundtripStatus = (status: string) => {
  return status === Planned
    ? ROUNDTRIP_STATUS.planned.color
    : status === Prepared
      ? ROUNDTRIP_STATUS.prepared.color
      : status === Loaded
        ? ROUNDTRIP_STATUS.loaded.color
        : status === Canceled
          ? ROUNDTRIP_STATUS.canceled.color
          : status === OnWay
            ? ROUNDTRIP_STATUS.on_way.color
            : status === Completed
              ? ROUNDTRIP_STATUS.completed.color
              : status === OnDock
                ? ROUNDTRIP_STATUS.on_dock.color
                : status === Unloaded
                  ? ROUNDTRIP_STATUS.unloaded.color
                  : status === Parked
                    ? ROUNDTRIP_STATUS.parked.color
                    : status === Paused
                      ? ROUNDTRIP_STATUS.paused.color
                      : status === Pending
                        ? ROUNDTRIP_STATUS.pending.color
                        : status === Loading
                          ? ROUNDTRIP_STATUS.loading.color
                          : status === Unloading
                            ? ROUNDTRIP_STATUS.unloading.color
                            : '';
};
