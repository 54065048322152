import {
  useGetCustomerPublicDetailsQuery,
  usePatchCustomerPublicMutation,
} from 'common/services/customerApi';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import {
  Alert,
  Card,
  Chip,
  Dialog,
  DialogContent,
  IconButton,
  Snackbar,
  Typography,
  Grid,
  Switch,
  FormControlLabel,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { UploadFiles } from '../UploadFiles';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import If from '../If';
import ModalPDFViewer from '../ModalPDFViewer';
import { themes } from 'styles/theme/themes';
import { useToaster } from '../../../hooks/useToaster';
import { CheckStatus } from '../CheckStatus';
import ChorusForm from '../../pages/CustomerPublic/components/ChorusForm';

export function InfosList({ token, password, customer }) {
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] =
    useState<boolean>(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState<boolean>(false);
  const [updateCustomer] = usePatchCustomerPublicMutation();
  const [doc, setDoc] = useState<any>(null);
  const [customerFull, setCustomerFull] = useState<any>(customer);

  const toast = useToaster();

  const useStyles = makeStyles({
    customDialog: {
      width: '110vh',
    },
  });

  useEffect(() => {
    if (openSuccessSnackbar || openErrorSnackbar) {
      const timer = setTimeout(() => {
        setOpenSuccessSnackbar(false);
        setOpenErrorSnackbar(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [openSuccessSnackbar, openErrorSnackbar]);

  const classes = useStyles();

  const { data: customerData, refetch } = useGetCustomerPublicDetailsQuery({
    token,
    password,
  });

  useEffect(() => {
    if (customerData) {
      setCustomerFull(customerData);
    }
  }, [customerData]);

  const onToggleValue = async (key, value) => {
    const c = await updateCustomer({
      token,
      password,
      [key]: value,
    }).unwrap();

    setCustomerFull(c);
    toast(3000, 'success', 'common.save.success');
  };

  const handleChorusSubmit = async (values) => {
    console.log('handleChorusSubmit', values);
    const c = await updateCustomer({
      token,
      password,
      ...values,
    }).unwrap();

    setCustomerFull(c);
    toast(3000, 'success', 'common.save.success');
  };

  const handleCloseModal = () => setOpenModal(false);

  return (
    <>
      <Wrapper>
        <Snackbar sx={{ marginLeft: '265px' }} open={openSuccessSnackbar}>
          <Alert
            variant="filled"
            severity="success"
            sx={{ width: '100%' }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenSuccessSnackbar(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          />
        </Snackbar>
        <Snackbar sx={{ marginLeft: '265px' }} open={openErrorSnackbar}>
          <Alert
            variant="filled"
            severity="error"
            sx={{ width: '100%' }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenErrorSnackbar(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          />
        </Snackbar>

        {openModal && (
          <Dialog
            open={openModal}
            onClose={handleCloseModal}
            maxWidth="md"
            classes={{ paper: classes.customDialog }}
          >
            <DialogContent>
              <UploadFiles
                customer={customer}
                tokenPassword={password}
                onUploadSuccess={() => {
                  refetch();
                  setOpenModal(false);
                }}
              />
            </DialogContent>
          </Dialog>
        )}

        <div className="card" style={{ marginTop: '2vh' }}>
          <Card
            sx={{
              marginTop: 3,
              padding: '12px 40px 12px',
              width: '800px',
              margin: '0 auto',
              maxWidth: '100%',
            }}
          >
            <Typography
              variant="h5"
              fontWeight="500"
              sx={{ paddingBottom: 2, color: themes?.default?.darkGray }}
            >
              Information générale
            </Typography>

            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={4} md={3}>
                <Typography align="right">
                  <strong>Code client :</strong>
                </Typography>
              </Grid>
              <Grid item xs={8} md={9}>
                <Typography fontWeight="bold">{customer.code}</Typography>
              </Grid>
            </Grid>

            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={4} md={3}>
                <Typography align="right">
                  <strong>Raison Sociale :</strong>
                </Typography>
              </Grid>
              <Grid item xs={8} md={9}>
                <Typography>
                  {customer.legalName ? customer.legalName : '__'}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={4} md={3}>
                <Typography align="right">
                  <strong>Adresse de facturation :</strong>
                </Typography>
              </Grid>
              <Grid item xs={8} md={9}>
                <Typography>
                  {customer.decks[0]?.location?.name
                    ? customer.decks[0]?.location?.name
                    : '__'}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={4} md={3}>
                <Typography align="right">
                  <strong>SIRET :</strong>
                </Typography>
              </Grid>
              <Grid item xs={8} md={9}>
                <Typography>
                  {customer.legalId ? customer.legalId : '__'}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={4} md={3}>
                <Typography align="right">
                  <strong>TVA :</strong>
                </Typography>
              </Grid>
              <Grid item xs={8} md={9}>
                <Typography>
                  {customer.vatNumber ? customer.vatNumber : '__'}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={4} md={3}>
                <Typography align="right">
                  <strong>Mode de réglement :</strong>
                </Typography>
              </Grid>
              <Grid item xs={8} md={9}>
                <Typography>
                  {customer.defaultPaymentType
                    ? t(`paymentTypes.${customer.defaultPaymentType}`)
                    : '__'}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={4} md={3}>
                <Typography align="right">
                  <strong>Email :</strong>
                </Typography>
              </Grid>
              <Grid item xs={8} md={9}>
                <If condition={!isEmpty(customer.email)}>
                  <Typography>
                    {customer.email ? customer.email : '__'}
                    <CheckStatus
                      checked={customer.emailVerified}
                      checkTitle="Vérifié"
                      uncheckTitle="Non vérifié"
                    />
                  </Typography>
                </If>
              </Grid>
            </Grid>

            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={4} md={3}>
                <Typography align="right">
                  <strong>Email facturation :</strong>
                </Typography>
              </Grid>
              <Grid item xs={8} md={9}>
                <If condition={customer.billingEmail}>
                  <Typography>
                    {customer.billingEmail ? customer.billingEmail : '__'}
                    <CheckStatus
                      checked={customer.billingEmailVerified}
                      checkTitle="Vérifié"
                      uncheckTitle="Non vérifié"
                    />
                  </Typography>
                </If>
              </Grid>
            </Grid>

            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={4} md={3}>
                <Typography align="right">
                  <strong>Tel :</strong>
                </Typography>
              </Grid>
              <Grid item xs={8} md={9}>
                <Typography>
                  {customer.phone ? customer.phone : '__'}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={4} md={3}>
                <Typography align="right">
                  <strong>Mobile :</strong>
                </Typography>
              </Grid>
              <Grid item xs={8} md={9}>
                <Typography>
                  {customer.mobilePhone ? customer.mobilePhone : '__'}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </div>

        <div className="card" style={{ marginTop: '2vh' }}>
          <Card
            sx={{
              padding: '12px 40px 12px',
              width: '800px',
              margin: '0 auto',
              maxWidth: '100%',
            }}
          >
            <Typography
              variant="h5"
              fontWeight="500"
              sx={{ marginRight: 1, pb: 1, color: themes?.default?.darkGray }}
            >
              Adresses de Livraison
            </Typography>
            {customerData?.decks
              .filter((d) => d.location)
              .map((deck, index) => (
                <>
                  <DeckWrapper key={deck._id}>
                    <div>
                      <AdresseWrapper>
                        <AdresseTypoWrapper>
                          <Typography fontWeight="500" sx={{ marginRight: 1 }}>
                            Adresse n°{index + 1} :{' '}
                          </Typography>
                          <Typography>
                            {deck.location.name
                              ? deck.location.name
                              : t('customerPortal.infoSection.noAddress')}
                          </Typography>
                        </AdresseTypoWrapper>
                      </AdresseWrapper>
                    </div>
                  </DeckWrapper>
                  <DeliveryWrapper>
                    {deck.deliveryDays.map((deliveryDay) => (
                      <Chip
                        key={deliveryDay}
                        label={t(`calendar.days.${deliveryDay}`)}
                        sx={{
                          mr: 1,
                          mb: 1,
                        }}
                        color="primary"
                      />
                    ))}
                  </DeliveryWrapper>
                  <DeliveryWrapper>
                    {deck.deliveryHours?.map((hour) => (
                      <Chip
                        key={hour._id}
                        label={`${hour.begin} - ${hour.end}`}
                        sx={{
                          mr: '15px',
                          mb: 2,
                        }}
                      />
                    ))}
                  </DeliveryWrapper>
                </>
              ))}
          </Card>
        </div>

        <div className="card" style={{ marginTop: '2vh' }}>
          <Card
            sx={{
              marginTop: 3,
              padding: '12px 40px 12px',
              width: '800px',
              margin: '0 auto',
              maxWidth: '100%',
            }}
          >
            <Typography
              variant="h5"
              fontWeight="500"
              sx={{ paddingBottom: 2, color: themes?.default?.darkGray }}
            >
              Commercial
            </Typography>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={4} md={3}>
                <Typography align="right">
                  <strong>Nom :</strong>
                </Typography>
              </Grid>
              <Grid item xs={8} md={9}>
                <Typography>
                  {customerData?._salesman?.fullName || 'N/A'}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={4} md={3}>
                <Typography align="right">
                  <strong>Email :</strong>
                </Typography>
              </Grid>
              <Grid item xs={8} md={9}>
                <Typography>
                  {customerData?._salesman?.email || 'N/A'}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={4} md={3}>
                <Typography align="right">
                  <strong>Tel :</strong>
                </Typography>
              </Grid>
              <Grid item xs={8} md={9}>
                <Typography>
                  {customerData?._salesman?.phone || 'N/A'}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </div>

        <div className="card" style={{ marginTop: '2vh' }}>
          <Card
            sx={{
              marginTop: 3,
              padding: '12px 40px 12px',
              width: '800px',
              margin: '0 auto',
              maxWidth: '100%',
            }}
          >
            <Typography
              variant="h5"
              fontWeight="500"
              sx={{ paddingBottom: 2, color: themes?.default?.darkGray }}
            >
              Préférences pour la reception des documents
            </Typography>
            <Grid
              container
              alignItems="center"
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={4} md={3}>
                <Typography align="right">
                  <strong>Email</strong>
                </Typography>
              </Grid>
              <Grid item xs={8} md={9}>
                <FormControlLabel
                  label={
                    customerFull.blockDocumentsEmail !== true
                      ? 'Activé'
                      : 'Désactivé'
                  }
                  control={
                    <Switch
                      checked={customerFull.blockDocumentsEmail !== true}
                      onChange={(e) =>
                        onToggleValue('blockDocumentsEmail', !e.target.checked)
                      }
                    />
                  }
                />
              </Grid>
            </Grid>

            <Grid
              container
              alignItems="center"
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={4} md={3}>
                <Typography align="right">
                  <strong>Courrier Postal</strong>
                </Typography>
              </Grid>
              <Grid item xs={8} md={9}>
                <FormControlLabel
                  label={
                    customerFull.blockDocumentsPaper !== true
                      ? 'Activé'
                      : 'Désactivé'
                  }
                  control={
                    <Switch
                      checked={customerFull.blockDocumentsPaper !== true}
                      onChange={(e) =>
                        onToggleValue('blockDocumentsPaper', !e.target.checked)
                      }
                    />
                  }
                />
              </Grid>
            </Grid>
          </Card>
        </div>

        <div className="card" style={{ marginTop: '2vh' }}>
          <Card
            sx={{
              marginTop: 3,
              padding: '12px 40px 12px',
              width: '800px',
              margin: '0 auto',
              maxWidth: '100%',
            }}
          >
            <Typography
              variant="h5"
              fontWeight="500"
              sx={{ paddingBottom: 2, color: themes?.default?.darkGray }}
            >
              Chorus Pro
            </Typography>

            <ChorusForm
              isActive={customerFull.chorusIsActive}
              serviceCode={customerFull.chorusServiceCode}
              onSubmit={handleChorusSubmit}
            />
          </Card>
        </div>
      </Wrapper>

      <ModalPDFViewer
        open={doc !== null}
        url={doc?.url}
        url2={doc?.url2}
        fileName={doc?.name}
        onClose={() => setDoc(null)}
      />
    </>
  );
}

const AdresseWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Wrapper = styled.div`
  height: 100vh;
  margin: 0 auto;
`;

const DeckWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

const DeliveryWrapper = styled.div`
  margin-left: 90px;
  width: 100%;
`;

const AdresseTypoWrapper = styled.div`
  display: flex;
  align-items: center;
`;
