import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  useMediaQuery,
} from '@mui/material';
import If from 'app/components/If';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { Filters } from 'app/components/Filters';
import { productsHeadCellsConst } from 'interfaces/products';
import { filterItems } from 'functions/searchItemInList';
import { get, groupBy } from 'lodash';
import { LoadingPulse } from 'app/components/LoadingPulse';
import { EnhancedTableHead } from '../../../EnhancedTableHead';
import { Order } from 'app/components/TableComponent';
import { ProductNotes } from '../../components/ProductNotes';
import { ProductTableRow } from '../../components/ProductTableRow';
import SearchField from 'app/components/Search/SearchField';
import { toggleHeadCellsValue } from 'app/helpers/helpers';

interface OrderInformationProps {
  isCustomer: boolean;
  order: any;
  fullOrder: any;
  fullOrderCustomer: any;
  isFetching: boolean;
  setAmountRequested?: any;
  handlePaymentCollectionChange: any;
  triggerGetOrder: Function;
  viewMode?: boolean;
  height?: number;
}

const OrderInformationTab = ({
  isCustomer,
  fullOrder,
  fullOrderCustomer,
  isFetching,
  order,
  triggerGetOrder,
  height,
}: OrderInformationProps) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width:1000px)');

  const [searchText, setSearchText] = useState('');
  const [heads, setHeads] = useState<any[]>(productsHeadCellsConst);
  const [filters, setFilters] = useState<any>([]);
  const [groupedProducts, setGroupedProducts] = useState<any>([]);
  const [orderBy, setOrderBy] = useState<string>('lastName');
  const [sortOrder, setSortOrder] = useState<Order>('asc');
  const [displayNoteModal, setDisplayNoteModal] = useState({
    isVisible: false,
    productId: null,
    orderId: null,
  });
  const [excessItems, setExcessItems] = useState<any[]>([]);
  //TODO: global usage for filters
  useEffect(() => {
    let orderProducts;
    if (fullOrder) {
      orderProducts = fullOrder?.products?.filter((p) => p.quantity > 0) || [];
    } else {
      orderProducts =
        fullOrderCustomer?.products?.filter((p) => p.quantity > 0) || [];
    }
    const filteredProducts = orderProducts?.filter((product) => {
      for (const filter of filters) {
        const { operator, value, column } = filter;
        const filterBy = get(product, column)?.toString();
        if (column)
          switch (operator) {
            case 'contains':
              if (
                !filterBy ||
                !filterBy?.toLowerCase().includes(value?.toLowerCase())
              ) {
                return false;
              }
              break;
            case 'equal':
              if (
                !filterBy ||
                filterBy?.toLowerCase() !== value?.toLowerCase()
              ) {
                return false;
              }
              break;
          }
      }

      return true;
    });

    const searchedProducts = filterItems(filteredProducts, searchText, [
      '_product.code',
      '_product.name',
      '_product._category.name',
      '_product._category._parentCategory.name',
    ]);

    setGroupedProducts(
      groupBy(searchedProducts, (obj) =>
        get(obj, '_product._category._parentCategory.name'),
      ),
    );

    const excessItemsCodes = fullOrder?.deliveryInfo?.excessItems?.map(
      (item) => item.code,
    );

    setExcessItems(
      searchedProducts.filter((product) => {
        return excessItemsCodes?.includes(product._product?.code);
      }),
    );

    toggleHeadCellsValue({
      headCells: [
        {
          id: 'quantity',
          disableRow: !searchedProducts?.find((product) => product?.quantity),
        },
        {
          id: 'loadedQuantity',
          disableRow: !searchedProducts?.find(
            (product) => product?.quantityLoaded,
          ),
        },
        {
          id: 'quantityDelivered',
          disableRow: !searchedProducts?.find(
            (product) => product?.quantityDelivered,
          ),
        },
      ],
      setHeadCells: setHeads,
    });
  }, [filters, searchText, fullOrder, fullOrderCustomer]);

  const handleSort = (property: string) => {
    const isAsc = orderBy === property && sortOrder === 'asc';
    if (setSortOrder) {
      setSortOrder(isAsc ? 'desc' : 'asc');
    }
    if (property && setOrderBy) {
      setOrderBy(property);
    }
  };

  const checkIfDamaged = (productCode) => {
    const isDamaged = fullOrder?.deliveryInfo?.damagedItems?.find(
      (p) => p.code === productCode,
    );
    return !!isDamaged;
  };

  if (isFetching && !(fullOrder || fullOrderCustomer)) {
    return <LoadingPulse style={{ marginTop: '100px' }} />;
  }

  return (
    <>
      <HeaderTableWrapper>
        <SearchField
          value={searchText}
          setValue={setSearchText}
          textFieldProps={{
            variant: 'outlined',
            margin: 'none',
            placeholder: t('clients.orderDetails.search'),
            label: '',
          }}
        />
        <Filters
          headCells={heads}
          setFilters={setFilters}
          filters={filters}
          disableTitle={true}
        />
      </HeaderTableWrapper>

      <If
        condition={!isFetching}
        otherwise={<LoadingPulse style={{ marginTop: '50px' }} />}
      >
        <ProductTableWrapper>
          <StyledTableContainer
            height={height}
            isMobile={isMobile}
            sx={{
              mb: 0,
            }}
          >
            <Table
              sx={{ minWidth: 650, border: 'none' }}
              size="small"
              aria-label="a dense table"
            >
              <EnhancedTableHead
                order={sortOrder ?? 'desc'}
                orderBy={orderBy ?? ''}
                onRequestSort={handleSort}
                isLoading={isFetching}
                headCells={heads}
              />
              <TableBody>
                {Object.entries(groupedProducts).map(
                  ([family, products]: any) => (
                    <>
                      <TableRow key={family}>
                        <TableCell
                          colSpan={heads?.length}
                          align="left"
                          sx={{
                            padding: '0',
                            border: 'none',
                          }}
                        >
                          <FamilyTitle>
                            {family} ({products?.length})
                          </FamilyTitle>
                        </TableCell>
                      </TableRow>
                      {products?.map((product) => (
                        <ProductTableRow
                          product={product}
                          checkIfDamaged={checkIfDamaged}
                          isCustomer={isCustomer}
                          heads={heads}
                          deckId={fullOrder?.cache?.stats?.deckId}
                        />
                      ))}
                    </>
                  ),
                )}

                <If condition={!!excessItems?.length}>
                  <TableRow key="excessItems">
                    <TableCell
                      colSpan={heads?.length}
                      align="left"
                      sx={{
                        padding: '0',
                        border: 'none',
                      }}
                    >
                      <ExcessTitle>
                        {t('excess_items')} ({excessItems?.length})
                      </ExcessTitle>
                    </TableCell>
                  </TableRow>

                  {excessItems?.map((product) => (
                    <ProductTableRow
                      product={product}
                      checkIfDamaged={checkIfDamaged}
                      isCustomer={isCustomer}
                      heads={heads}
                      deckId={fullOrder?.cache?.stats?.deckId}
                    />
                  ))}
                </If>
              </TableBody>
            </Table>
          </StyledTableContainer>
        </ProductTableWrapper>
      </If>

      <ProductNotes
        viewMode={false}
        open={displayNoteModal.isVisible}
        productId={displayNoteModal.productId}
        orderId={displayNoteModal.orderId}
        onCloseModal={() =>
          setDisplayNoteModal({
            isVisible: false,
            productId: null,
            orderId: null,
          })
        }
        refreshData={() => triggerGetOrder(order?.id)}
      />
    </>
  );
};

export default OrderInformationTab;

const ProductTableWrapper = styled.div`
  & td,
  & th {
    font-size: 0.75rem;
  }
  *::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background-color: transparent;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    background-color: darkgray;
  }
`;

const HeaderTableWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  padding: 8px 24px;
`;

export const SummaryLabel = styled.div`
  color: ${(props) => props.theme.textColorSecondary};
  font-size: 0.9rem;
`;

export const SummaryValue = styled.div`
  font-size: 1rem;
  display: flex;
  justify-content: end;
  align-items: center;
`;
export const SummaryTableValue = styled.div`
  padding-top: 3px;
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 0.8725rem;
`;
export const SummaryTableCell = styled(TableCell)`
  padding: 5px !important;
  width: 50%;
  vertical-align: top !important;
  border: 1px solid ${(props) => props.theme.tableBorder};
`;
const FamilyTitle = styled.div`
  color: ${(props) => props.theme.grey2};
  font-size: 0.75rem;
  font-weight: 600;
  padding: 1rem 0;
`;
const ExcessTitle = styled(FamilyTitle)`
  color: ${(props) => props.theme.Cinnabar};
`;
interface StyledTableContainerProps {
  isMobile: boolean;
  height?: number;
}
const StyledTableContainer = styled(TableContainer)<StyledTableContainerProps>`
  overflow: auto;
  max-height: ${(props) =>
    props.height ? `calc(${props.height}px - 192px)` : 'auto'};
  white-space: nowrap;

  *::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background-color: transparent;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    background-color: darkgray;
  }

  & tbody tr:last-child td {
    border-bottom: none;
  }
`;
