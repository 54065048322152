import React, { useEffect, useRef, useState } from 'react';
import { Page } from 'react-pdf';
import { useSelector, useDispatch } from 'react-redux';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import { selectTermsReaded } from 'common/store/app/selectors';
import { appActions } from 'common/store/app';

import { PDFViewer } from '../ModalPDFViewer';

function AcceptTermsAction({ acceptLabel, onAccept }) {
  const termsReaded = useSelector(selectTermsReaded);

  return (
    <Button
      disabled={termsReaded !== true}
      variant="contained"
      onClick={onAccept}
      sx={{ margin: '0 auto', display: 'block', marginBottom: '10px' }}
    >
      {acceptLabel}
    </Button>
  );
}

export default function TermsModal({
  open,
  title,
  description,
  acceptLabel,
  onAccept,
  termsFileUrl,
}) {
  const dispatch = useDispatch();
  const listInnerRef = useRef();
  const [numPages, setNumPages] = useState(1);
  const [isOpen, setIsOpen] = useState(open);

  function onDocumentLoadSuccess({ numPages }) {
    const listInnerElement: any = listInnerRef.current;

    setNumPages(numPages);

    if (listInnerElement) {
      listInnerElement.addEventListener('scroll', onScroll);

      // Clean-up
      return () => {
        listInnerElement.removeEventListener('scroll', onScroll);
      };
    }
  }

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      const isNearBottom = scrollTop + clientHeight >= scrollHeight - 700;

      if (isNearBottom) {
        console.log('Reached bottom');
        // Dispatch an event to parent component
        dispatch(appActions.setTermsReaded({ isRead: true }));
      }
    }
  };

  useEffect(() => {
    dispatch(appActions.setTermsReaded({ isRead: false }));
  }, []);

  const pages = Array.from(new Array(numPages));

  const handleAccept = () => {
    setIsOpen(false);
    onAccept();
  };

  return (
    <Dialog open={isOpen} maxWidth="lg" fullWidth>
      <DialogTitle
        sx={{
          margin: '0 auto',
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent
        sx={{
          padding: '16px',
          width: '100%',
          margin: '0 auto',
          paddingBottom: '0px',
        }}
      >
        <DialogContentText>
          <p style={{ textAlign: 'center' }}>{description}</p>

          <div
            style={{ maxHeight: '80vh', overflowY: 'auto' }}
            // @ts-ignore
            ref={listInnerRef}
          >
            <PDFViewer
              // @ts-ignore
              file={{ url: termsFileUrl }}
              className="bamptee-pdf-viewer"
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {pages.map((value, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  scale={1.75}
                />
              ))}
            </PDFViewer>
          </div>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <AcceptTermsAction acceptLabel={acceptLabel} onAccept={handleAccept} />
      </DialogActions>
    </Dialog>
  );
}
