export function formatDistance(distance: number): string {
  if (!distance) return 'N/A';

  if (distance >= 1000) {
    return Math.round(distance / 1000.0) + ' km';
  } else if (distance >= 100) {
    return Math.round(distance) + ' m';
  } else {
    return distance.toFixed(1) + ' m';
  }
}

export function formatDeliveryHours(hours: any[]): string {
  if (!hours || hours.length === 0) return 'N/A';

  if (!hours[0].begin || !hours[0].end) return 'N/A';

  return hours[0].begin + ' - ' + hours[0].end;
}
