import React, { useEffect } from 'react';
import { Stack } from '@mui/material';
import RoundtripMappingHeader from './RoundtripMappingHeader';
import RountripMappingInfoSection from './RountripMappingInfoSection';
import WarehouseCollapseCard from './WarehouseCollapseCard';
import AddMapping from './AddMapping';
import { useManagedContext } from '../../../../../common/UtilityComponents/ManagedContext/useManagedContext';
import { useGetRoundtripMappingsQuery } from '../../../../../common/services/roundtripMappings';
import { IRoundtripMappingsContext } from '../index';
import { useGetUsersBasicQuery } from '../../../../../common/services/userApi';
import { LoadingIndicator } from '../../../../components/LoadingIndicator';
import { useGetVehiclesQuery } from '../../../../../common/services/vehicleApi';
import { useSelector } from 'react-redux';
import {
  selectDecks,
  selectInactiveDecks,
} from '../../../../../common/store/organization/selectors';
function FunctionalRoundtripMappings() {
  const { roundtripMappings, updateDataWithFunction, currentWarehouse } =
    useManagedContext<IRoundtripMappingsContext>('RoundtripMappingsContext');
  const { data, isLoading, isFetching } = useGetRoundtripMappingsQuery('');
  const {
    data: driversData,
    isLoading: loadingDrivers,
    isFetching: fetchingDrivers,
  } = useGetUsersBasicQuery('?roles=driver&limit=1000');
  const warehouses = useSelector(selectDecks);
  const inactiveDecks = useSelector(selectInactiveDecks);
  const isFirstRender = React.useRef(true);
  useEffect(() => {
    if (isLoading || isFetching) return;
    const sortedData = [...(data || [])].sort((a, b) => {
      if (a.deckName < b.deckName) return -1;
      if (a.deckName > b.deckName) return 1;
      return 0;
    });
    if (isFirstRender.current) {
      updateDataWithFunction((prev) => {
        prev.roundtripMappings = sortedData?.map((warehouse) => {
          return {
            id: warehouse.deckId,
            name: warehouse.deckName,
            nbOfRoundtrips: warehouse.mappings.length,
            color: warehouse.color,
            status: warehouses
              .concat(inactiveDecks)
              .find((w) => w._id === warehouse.deckId)?.status,
            roundtrips: warehouse.mappings.map((roundtrip) => {
              return {
                id: roundtrip._id,
                area: roundtrip.area,
                roundtripCode: roundtrip._roundtripGescom,
                driver: roundtrip._driver,
                vehicle: roundtrip._vehicle,
              };
            }),
          };
        });
      });
    } else {
      updateDataWithFunction((prev) => {
        const updatedWarehouse = sortedData?.find(
          (warehouse) => warehouse.deckId === currentWarehouse,
        );
        if (updatedWarehouse) {
          prev.roundtripMappings = prev.roundtripMappings?.map((w) => {
            if (w.id === prev.currentWarehouse) {
              return {
                id: updatedWarehouse.deckId,
                name: updatedWarehouse.deckName,
                nbOfRoundtrips: updatedWarehouse.mappings.length,
                color: updatedWarehouse.color,
                status: warehouses
                  .concat(inactiveDecks)
                  .find((w) => w._id === updatedWarehouse.deckId)?.status,
                roundtrips: updatedWarehouse.mappings.map((roundtrip) => {
                  return {
                    id: roundtrip._id,
                    area: roundtrip.area,
                    roundtripCode: roundtrip._roundtripGescom,
                    driver: roundtrip._driver,
                    vehicle: roundtrip._vehicle,
                  };
                }),
              };
            }
            return w;
          });
        }
      });
    }
    isFirstRender.current = false;
  }, [data, isLoading, isFetching]);

  useEffect(() => {
    updateDataWithFunction((prev) => {
      const incomingRoundripGescomCodes = data?.map((warehouse) =>
        warehouse.mappings.map((roundtrip) => roundtrip._roundtripGescom),
      );
      prev.roundtripCodes = prev.roundtripCodes?.filter(
        (code) => !incomingRoundripGescomCodes?.flat().includes(code),
      );
    });
  }, [roundtripMappings]);
  useEffect(() => {
    if (loadingDrivers || fetchingDrivers) return;
    updateDataWithFunction((prev) => {
      prev.drivers = driversData?.docs?.map((d) => ({
        fullName: d.fullName,
        id: d._id,
      }))!;
    });
  }, [driversData, loadingDrivers, fetchingDrivers]);

  const {
    data: vehicles,
    isLoading: vehiclesLoading,
    isFetching: vehiclesFetching,
  } = useGetVehiclesQuery('?limit=100');

  useEffect(() => {
    if (vehiclesLoading || vehiclesFetching) return;
    updateDataWithFunction((prev) => {
      prev.vehicles = vehicles.docs.map((element) => ({
        fullName:
          element?.model +
          ' /' +
          element?.numberPlate +
          ' / ' +
          element.parkNumber,
        id: element._id,
      }));
    });
  }, [vehicles, vehiclesLoading, vehiclesFetching]);

  if (isLoading || loadingDrivers || vehiclesLoading)
    return <LoadingIndicator />;
  return (
    <Stack>
      <RoundtripMappingHeader />
      <Stack direction="row" px="20px" justifyContent="space-evenly">
        <RountripMappingInfoSection />
        <Stack flex="1" px="20px">
          <Stack gap={3}>
            {roundtripMappings.map((warehouse) => {
              return <WarehouseCollapseCard warehouse={warehouse} />;
            })}
          </Stack>
          <AddMapping />
        </Stack>
        <Stack />
      </Stack>
    </Stack>
  );
}

export default FunctionalRoundtripMappings;
