import { useDebounce } from 'common/hooks/useDebounce';
import { useGetUsersBasicQuery } from 'common/services/userApi';
import { useQueryParams } from 'hooks/useQueryParams';
import { useMemo, useState } from 'react';
import { AssigneeAutoComplete } from '../..';
import { AssigneeType } from 'app/pages/Accessories/Types';
import { selectOrganization } from 'common/store/organization/selectors';
import { useSelector } from 'react-redux';
import { getAgent } from 'common/helpers/agent';
import i18next from 'i18next';

interface Props {
  assignee?: any;
  control?: any;
  disabled?: boolean;
  title?: string;
}

export const UserAutoComplete = ({
  assignee,
  control,
  disabled,
  title,
}: Props) => {
  const [userSearch, setUserSearch] = useState('');

  const debouncedUserSearch = useDebounce(userSearch, 300);
  const queryParams = useQueryParams({
    page: 1,
    limit: 200,
    sort: 'lastName',
    isActive: true,
    isLocked: false,
    isArchived: false,
    text: debouncedUserSearch,
  });
  const {
    data: userData,
    isFetching,
    isLoading,
  } = useGetUsersBasicQuery(queryParams);
  const authOrganization = useSelector(selectOrganization);
  const users = useMemo(() => {
    if (isLoading || !userData) return [];
    return userData.docs.map((user) => {
      const preferredAgent = getAgent(user, authOrganization);
      return {
        value: user._id,
        label: preferredAgent.firstName + ' ' + preferredAgent.lastName,
        subLabel:
          preferredAgent?._role?.localeName?.[
            i18next?.resolvedLanguage ? i18next?.resolvedLanguage : 'en'
          ],
      };
    });
  }, [isLoading, userData, authOrganization]);

  const defaultValue = useMemo(() => {
    if (!assignee) return undefined;
    return {
      value: assignee?._id,
      label: assignee?.fullName,
      subLabel: assignee?.currentAgent?._role?.key || '',
    };
  }, [assignee]);

  return (
    <>
      {/*<If condition={!isLoading}>*/}
      <AssigneeAutoComplete
        options={users}
        setSearch={setUserSearch}
        isFetching={isFetching}
        control={control}
        disabled={disabled}
        title={title || 'common.employee'}
        type={AssigneeType.employee}
        defaultValue={defaultValue}
      />
      {/*</If>*/}
    </>
  );
};
