import { useState } from 'react';
import print from 'print-js';
import { useSelector } from 'react-redux';
import { selectAuthToken } from 'app/slices/auth/selectors';
import { useToaster } from 'hooks/useToaster';
import { appActions } from 'common/store/app';
import { useDispatch } from 'react-redux';
import { useLoadingAlert } from 'app/components/LoadingAlert/hooks';
import { useApplication } from 'hooks/useApplication';
import { useLocation } from 'react-router-dom';
import { SETTINGS } from 'utils/routes';

/**
 * @deprecated this custom hook served for 1 usecase only, please use the new one that will replace this permanatly later
 *
 */
export const usePrint = () => {
  const baseUrl = process.env.REACT_APP_API_URL;

  const { showLoading, hideLoading } = useLoadingAlert();

  const [loading, setLoading] = useState(false);
  const currentApp = useApplication();
  const location = useLocation();
  const inSettings = location.pathname?.includes(SETTINGS);
  const authApp = inSettings ? 'idm' : currentApp?.id;

  const authToken = useSelector(selectAuthToken);
  const dispatch = useDispatch();

  const toast = useToaster();

  async function triggerPrint({
    clusterId,
    roundtripId,
  }: {
    clusterId: number | string;
    roundtripId?: string;
  }) {
    if (!clusterId) return;
    try {
      print({
        printable: `${
          baseUrl || 'https://api-dev.bamptee.com'
        }/roundtrips/clusters/${clusterId}/details?format=pdf&jwt=${authToken}${
          roundtripId ? `&roundtripId=${roundtripId}` : ''
        }&authApplication=${authApp}`,
        onLoadingStart() {
          showLoading({ message: 'Loading PDF' });
          setLoading(true);
        },
        onLoadingEnd() {
          dispatch(appActions.cleanLastToasterNotification());
          hideLoading();
          setLoading(false);
        },
      });
    } catch (e: any) {
      toast(3000, 'error', e.message);
    }
  }

  return { loadPrint: loading, triggerPrint };
};

export const usePrintV2 = () => {
  const baseUrl = process.env.REACT_APP_API_URL;

  const { showLoading, hideLoading } = useLoadingAlert();

  const [loading, setLoading] = useState(false);

  const currentApp = useApplication();
  const location = useLocation();
  const inSettings = location.pathname?.includes(SETTINGS);
  const authApp = inSettings ? 'idm' : currentApp?.id;

  const authToken = useSelector(selectAuthToken);
  const dispatch = useDispatch();

  const toast = useToaster();

  async function triggerPrint({ uri }: { uri: string }) {
    try {
      print({
        printable: `${
          baseUrl || 'https://api-dev.bamptee.com'
        }/${uri}&jwt=${authToken}&authApplication=${authApp}`,
        onLoadingStart() {
          showLoading({ message: 'Loading PDF' });
          setLoading(true);
        },
        onLoadingEnd() {
          dispatch(appActions.cleanLastToasterNotification());
          hideLoading();
          setLoading(false);
        },
      });
    } catch (e: any) {
      toast(3000, 'error', e.message);
    }
  }

  return { loadPrint: loading, triggerPrint };
};
