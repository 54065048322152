import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

export const useSelectors = (
  selectedRef,
  roundtripActions,
  selectEditingRoundtripUser,
  users,
) => {
  const [isSelected, setIsSelected] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [editProduct, setEditProduct] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setLoadingUpdate(false);
    dispatch(roundtripActions.setEditingRoundtripVehicle(false));
    setIsSelected(false);
  }, [users]);
  const roundtripUser: boolean = useSelector(selectEditingRoundtripUser);
  useEffect(() => {
    setEditProduct(roundtripUser);
  }, [roundtripUser]);
  const handleSelectClick = () => {
    setIsSelected(true);
  };
  const handleSelectLeave = () => {
    setIsSelected(false);
  };

  const handleClickOutside = useCallback((event) => {
    if (selectedRef.current && !selectedRef.current.contains(event.target)) {
      handleSelectLeave();
    }
  }, []);

  useEffect(() => {
    if (isSelected) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }
  }, [isSelected]);

  return {
    handleSelectClick,
    handleSelectLeave,
    isSelected,
    loadingUpdate,
    editProduct,
    setLoadingUpdate,
    setIsSelected,
  };
};
