import React, { useEffect, useRef } from 'react';

export const StreetViewPanorama = ({ options }) => {
  const panoramaRef: any = useRef(null);

  useEffect(() => {
    if (!options.visible) return;

    const loadStreetViewPanorama = () => {
      const google = window.google;
      const panorama = new google.maps.StreetViewPanorama(
        panoramaRef.current,
        options,
      );

      new google.maps.Marker({
        position: options.position,
        map: panorama,
        icon: {
          url: options?.markerIconUrl ? options.markerIconUrl : null,
        },
      });
    };

    if (window.google && window.google.maps) {
      loadStreetViewPanorama();
    }
  }, [options]);

  return options.visible ? (
    <div
      ref={panoramaRef}
      style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
    />
  ) : null;
};
