import { Permission, usePermission } from 'hooks/Abilities/usePermission';
import styled from 'styled-components';
import LOCK from 'assets/img/lock.svg';
import { Box, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ImgWrapper, Overlay } from './style';

export const InputBlur = ({
  subject,
  children,
}: {
  subject?: string;
  children?: React.ReactNode;
}) => {
  const { t } = useTranslation();
  const can = usePermission(subject || '');
  if (subject && can(Permission.VIEW)) {
    return <>{children}</>;
  }
  return (
    <Box
      position="relative"
      sx={{
        '& input': {
          filter: 'blur(6px)',
        },
        '& .MuiAutocomplete-endAdornment': { display: 'none' },
      }}
    >
      <Overlay>
        <Tooltip
          title={
            <Typography
              width="188px"
              textAlign="center"
              fontSize="0.635rem"
              fontWeight={500}
              lineHeight="0.875rem"
            >
              {t('no_section_access')}
            </Typography>
          }
        >
          <Wrapper>
            <img src={LOCK} alt="Lock" width="18px" />
          </Wrapper>
        </Tooltip>
      </Overlay>

      {children}
    </Box>
  );
};

const Wrapper = styled(ImgWrapper)`
  padding: 4px;
  top: 18px;
`;
