import { Avatar, IconButton, Stack, Typography } from '@mui/material';
import { ArrowLeft } from '@phosphor-icons/react';
import { Icon } from 'app/components/Icon';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { Tooltip } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { createContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { themes } from 'styles/theme/themes';
import _ from 'lodash';
import { GetCustomerFrequency } from 'app/pages/Customers/components/CustomerFrequencyIcons';
import {
  useGetCustomerFinancialsQuery,
  useGetCustomerQuery,
  useUpdateCustomerMutation,
} from 'common/services/customerApi';
import { CreditScoreBadge } from 'app/components/CreditScoreBadge';
import If from 'app/components/If';
import { GridProvider } from 'app/components/Grid/context';
import { CustomerInfoCard } from './components/CustomerInfoCard';
import { CustomerTabs } from './components/Tabs';
import { PlaceProvider } from './context';
import { Permission, usePermission } from 'hooks/Abilities/usePermission';
import { CustomerStatus } from 'common/types/Customer';
import { CreateOrderDrawerSales } from 'app/pages/Ordoria/Orders/components/CreateOrderDrawerSales';
import { useApplication } from 'hooks/useApplication';
import { InviteEmails } from './components/CustomerInfoCard/components/InviteEmails';
import { GridActionMenu } from 'app/components/Grid/components/GridActionMenu';
import { ActionsWrapper } from 'app/styledComponents/DetailsSection.styled';
import { ORDORIA_CUSTOMER, CUSTOMER, STOCKEE_CUSTOMER } from 'utils/routes';
interface ContextProps {
  customer: any;
  canEdit?: boolean;
  canExport?: boolean;
}

export const ViewCustomercontext = createContext<ContextProps>({
  customer: null,
  canEdit: false,
  canExport: false,
});

export const ViewCustomer = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { id } = useParams();

  const can = usePermission('customers');
  const canEdit = can(Permission.EDIT);
  const canExport = can(Permission.EXPORT);
  const currentApplication = useApplication();

  const [openCreateOrderDrawer, setOpenCreateOrderDrawer] = useState(false);
  const [customer, setCustomer] = useState<any>(null);
  const [radioValue, setRadioValue] = useState<string>('delivery');
  const [nextDeliveryDate, setNextDeliveryDate] = useState<string>(
    customer?._organizationDeck?.nextDeliveryDate ?? '',
  );
  const [openInviteEmails, setOpenInviteEmails] = useState<boolean>(false);

  const { data: customerDetail, isLoading } = useGetCustomerQuery(id, {
    skip: !id,
  });

  const [updateCustomer] = useUpdateCustomerMutation();

  const { data: customerFinancials } = useGetCustomerFinancialsQuery({
    id: id,
  });

  function handleChangeStatus(id: string) {
    updateCustomer({ id, status: CustomerStatus.ARCHIVED }).unwrap();
  }

  function handleCreateOrder(customer) {
    setCustomer(customer);
    setOpenCreateOrderDrawer(true);
  }

  const handleBack = () => {
    if (currentApplication.id === 'ordoria') {
      navigate(ORDORIA_CUSTOMER);
    } else if (currentApplication.id === 'stockee') {
      navigate(STOCKEE_CUSTOMER);
    } else {
      navigate(CUSTOMER);
    }
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <StyledForm>
      <TopSection>
        <Stack direction="row" gap="8px" alignItems="center">
          <IconButton onClick={() => handleBack()}>
            <Icon icon={<ArrowLeft />} size="24px" />
          </IconButton>
          <Avatar
            src={customerDetail?.logo?.[0]?.url}
            sx={{ width: '36px', height: '36px' }}
          />
          <Typography fontWeight={600}>{customerDetail?.publicName}</Typography>
          <If condition={customerDetail?.creditScore}>
            <Tooltip title={t('customer.creditScore')}>
              <div>
                <CreditScoreBadge
                  score={customerDetail?.creditScore?.commonValue}
                  size="small"
                />
              </div>
            </Tooltip>
          </If>
          {customerDetail?.cache?.financials?.frequencyStatus === 'noOrder' ? (
            <Tooltip title={t('customer-info-no-orders-yet')}>
              <div>
                {customerDetail?.cache?.financials?.frequencyStatus && (
                  <GetCustomerFrequency
                    size={24}
                    frequency={
                      customerDetail?.cache?.financials?.frequencyStatus
                    }
                  />
                )}
              </div>
            </Tooltip>
          ) : (
            <Tooltip title={t('Order Frequency')}>
              <div>
                {customerDetail?.cache?.financials?.frequencyStatus && (
                  <GetCustomerFrequency
                    size={24}
                    frequency={
                      customerDetail?.cache?.financials?.frequencyStatus
                    }
                  />
                )}
              </div>
            </Tooltip>
          )}
          <If condition={canEdit}>
            <ActionsWrapper>
              <GridActionMenu
                options={[
                  {
                    label: 'orders.create_orders',
                    action: () => handleCreateOrder(customerDetail),
                  },
                  {
                    label: 'delete',
                    action: () => handleChangeStatus(customerDetail?._id),
                    isDelete: true,
                  },
                ]}
              />
            </ActionsWrapper>
          </If>
        </Stack>

        <CustomerInfoCard
          customerDetail={customerDetail}
          customerFinancials={customerFinancials}
          setInviteEmails={setOpenInviteEmails}
        />

        <InviteEmails
          customerDetail={customerDetail}
          open={openInviteEmails}
          onClose={() => setOpenInviteEmails(false)}
        />
      </TopSection>

      <GridProvider>
        <PlaceProvider>
          <ViewCustomercontext.Provider
            value={{
              customer: customerDetail,
              canEdit,
              canExport,
            }}
          >
            <CustomerTabs customerDetail={customerDetail} />
          </ViewCustomercontext.Provider>
        </PlaceProvider>
      </GridProvider>

      <CreateOrderDrawerSales
        open={openCreateOrderDrawer}
        closeDrawer={() => setOpenCreateOrderDrawer(false)}
        radioValue={radioValue}
        dateValue={nextDeliveryDate}
        setDateValue={setNextDeliveryDate}
        setRadioValue={setRadioValue}
        setNextDeliveryDate={setNextDeliveryDate}
        selectedCustomer={customer?._id}
      />
    </StyledForm>
  );
};

const TopSection = styled.div`
  background-color: white;
  padding: 8px 20px 5px 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: sticky;
  top: 0;
  z-index: 2;
`;

const StyledForm = styled.form`
  width: 100%;
  overflow-y: hidden;
  height: 100%;
  background-color: ${themes?.default?.pageGreyBg};
`;
