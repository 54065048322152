import { useEffect } from 'react';
import { StateSetter } from 'types';

interface Params {
  responseData: any[];
  data: any[];
  setData: StateSetter<any>;
  page: number;
}
export const useInfiniteScroll = ({
  responseData,
  data,
  setData,
  page,
}: Params) => {
  useEffect(() => {
    if (!data?.length || page === 1) {
      setData(responseData);
    } else {
      setData((prev) => [...prev, ...responseData]);
    }
  }, [responseData]);
};
