import { TableNavContext } from '../../../..';
import { useContext, useMemo } from 'react';
import { GridActionMenu } from 'app/components/Grid/components/GridActionMenu';

export const HoverMenu = () => {
  const { handleDelete, updateByFile, mode } = useContext(TableNavContext);

  const options = useMemo(() => {
    const allowedOptions: {
      label: string;
      action: () => void;
      isDelete?: boolean;
    }[] = [
      {
        label: 'delete',
        action: () => {
          handleDelete();
        },
        isDelete: true,
      },
    ];
    if (mode === 'byFile') {
      allowedOptions.unshift({
        label: 'update_file',
        action: () => {
          updateByFile();
        },
      });
    }
    return allowedOptions;
  }, [handleDelete, updateByFile, mode]);

  return <GridActionMenu options={options} />;
};
