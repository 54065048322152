import { useState, useMemo, useEffect } from 'react';

export function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIntersecting(entry.isIntersecting),
      ),
    [ref],
  );

  useEffect(() => {
    if (!ref?.current) return;
    observer.observe(ref?.current);
    return () => observer.disconnect();
  }, []);

  return isIntersecting;
}
