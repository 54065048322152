import React, { useEffect, useMemo, useRef, useState } from 'react';
import { roundtripActions } from 'common/store/roundtrips';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { selectEditingRoundtripUser } from 'common/store/roundtrips/selectors';
import { useSelectors } from './hook/useSelector';
import SelectorComponent from './components/SelectorComponent';
import { UserPropsObj } from './components/UserPropsObj';
import _ from 'lodash';
import {
  Driver,
  Ripper,
} from 'app/components/RoundTrips/RoundTripData/statuses';
import { selectConfiguration } from 'common/store/organization/selectors';

interface UserSelectorProps {
  user: any;
  users: any;
  userRole: string;
  addFuction: any;
  roundTripCopy: any;
  updateRoundtripData: any;
  roundtripId: any;
  agentId: string;
  schedule?: Function;
  clusterId?: string;
  runningVehicle?: boolean;
  clusterStatus?: string;
  minimal?: boolean;
}

const UserSelector = React.memo(
  function UserSelectorComponent({
    user,
    users,
    userRole,
    addFuction,
    roundTripCopy,
    updateRoundtripData,
    roundtripId,
    agentId,
    // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
    schedule,
    // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
    clusterId,
    // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
    runningVehicle,
    // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
    clusterStatus,
    minimal,
  }: UserSelectorProps) {
    const usersSorted = useMemo(() => {
      const sortedUsers = [...users];
      return sortedUsers?.sort((a, b) => {
        return a.fullName.localeCompare(b.fullName);
      });
    }, [users]);
    const dispatch = useDispatch();
    const configuration = useSelector(selectConfiguration);

    const getAgentIdByRole = (roleKey: string) => {
      return configuration?.roles.find((role) => role.key === roleKey);
    };

    const [selectedUser, setSelectedUser] = useState(user);
    const selectedRef = useRef(null);

    useEffect(() => {
      setSelectedUser(user);
    }, [user]);

    const {
      handleSelectClick,
      handleSelectLeave,
      isSelected,
      loadingUpdate,
      editProduct,
      setLoadingUpdate,
      setIsSelected,
    } = useSelectors(
      selectedRef,
      roundtripActions,
      selectEditingRoundtripUser,
      usersSorted,
    );
    const UserProps = UserPropsObj(usersSorted);

    const existingAgent = useMemo(() => {
      let agent = {};
      if (userRole === Driver) {
        agent = {
          _user: roundTripCopy?.agents?.find(
            (data) => data?._role?.key === Ripper,
          )?._user?.id,
          _role: getAgentIdByRole(Ripper),
          userkey: Ripper,
        };
      } else if (userRole === Ripper) {
        agent = {
          _user: roundTripCopy?.agents?.find(
            (data) => data?._role?.key === Driver,
          )?._user?.id,
          _role: getAgentIdByRole(Driver),
          userkey: Ripper,
        };
      }
      return agent;
    }, [roundTripCopy, agentId, userRole]);

    return (
      <SelectorComponent
        minimal={minimal}
        selectedRef={selectedRef}
        user={selectedUser}
        handleSelectClick={() => {
          setIsSelected(true);
          handleSelectClick();
        }}
        loadingUpdate={loadingUpdate}
        isSelected={isSelected}
        editProduct={editProduct}
        UserProps={UserProps}
        type={userRole}
        onChange={async (e, value) => {
          //hide condition
          // if (clusterStatus === 'active' && runningVehicle) {
          //   toast(5000, 'error', 'alerts.roundtrips.prevent_clear_sales');
          //   return;
          // }
          setSelectedUser(value);
          setLoadingUpdate(true);
          dispatch(roundtripActions.setEditingRoundtripUser(true));
          try {
            await addFuction({
              id: roundTripCopy.id,
              data: {
                agents: [
                  existingAgent,
                  {
                    _user: value?.['_id'],
                    _role: agentId,
                    userkey: userRole,
                  },
                ],
              },
              UpdateRoundtripData: updateRoundtripData,
            });
          } catch (e) {
            setSelectedUser(user);
          }
          setLoadingUpdate(false);
          dispatch(roundtripActions.setEditingRoundtripUser(false));
          handleSelectLeave();
        }}
        roundtripId={roundtripId}
      />
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.agentId === nextProps.agentId &&
      _.isEqual(prevProps.user, nextProps.user) &&
      _.isEqual(prevProps.users, nextProps.users) &&
      prevProps.runningVehicle === nextProps.runningVehicle &&
      prevProps.clusterStatus === nextProps.clusterStatus
    );
  },
);

export default UserSelector;
