import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useMemo, useState } from 'react';

import { SquaresFour, Users, PresentationChart } from '@phosphor-icons/react';

import { MainNavItem, ScrollableSection, OptionsWrapper } from '../helpers';
import {
  DASHBOARD_ALL,
  IAM,
  REPORTS_ALL,
  USERS,
} from '../../../../utils/routes';
import { themes } from '../../../../styles/theme/themes';
import { Icon } from 'app/components/Icon';
import { isActiveRoute } from '../index';
import { IconWrapper } from '../../../../styles/components/muitable/TableViewPopover';
import { MenuTitleWrapper } from '../Components';
import { useSelector } from 'react-redux';
import { selectRoles } from 'common/store/organization/selectors';
import { selectCurrentApplication } from 'common/store/app/selectors';
import { useAbility } from 'hooks/Abilities';
import If from 'app/components/If';
import { Permission } from 'hooks/Abilities/usePermission';

const dashboardAll = `${IAM}${DASHBOARD_ALL}`;
const reportAll = `${IAM}${REPORTS_ALL}`;

export default function IAMMenu({ theme, mode }) {
  const ability = useAbility();
  const { t } = useTranslation();
  const location = useLocation();
  const isDashboardAll = location.pathname === dashboardAll;
  const isReportAll = location.pathname === reportAll;
  const isMinify = mode === 'minify';
  const [openSectionIndex, setOpenSectionIndex] = useState<number[]>([0]);

  const roles = useSelector(selectRoles);
  const currentApplication = useSelector(selectCurrentApplication);

  const displayEmployees = useMemo(() => {
    return (
      roles.filter((r) =>
        r.privileges?.roleproperties?.includes(
          `${currentApplication?.id}.showinemployee`,
        ),
      )?.length && ability.can(Permission.VIEW, 'users')
    );
  }, [currentApplication?.id, roles]);

  const handleRowToggle = (index: number) => {
    setOpenSectionIndex((prevIndices) => {
      if (prevIndices.includes(index)) {
        return prevIndices.filter((i) => i !== index);
      } else {
        return [index];
      }
    });
  };

  if (isDashboardAll || isReportAll) {
    return (
      <OptionsWrapper>
        <MainNavItem
          to={dashboardAll}
          text={t('nav.mainNav.dashboard')}
          mode={mode}
          icon={
            <Icon
              icon={<SquaresFour />}
              color={
                location.pathname === dashboardAll
                  ? theme.primaryActiveColor
                  : themes?.default?.black54
              }
              size={20}
            />
          }
        />

        <MainNavItem
          to={reportAll}
          text={t('nav.mainNav.reports')}
          mode={mode}
          icon={
            <IconWrapper isActive={isActiveRoute(location, reportAll)}>
              <PresentationChart />
            </IconWrapper>
          }
        />
      </OptionsWrapper>
    );
  }

  return (
    <ScrollableSection>
      <OptionsWrapper>
        <If condition={!!displayEmployees}>
          <MenuTitleWrapper
            isMinify={isMinify}
            onClick={() => handleRowToggle(0)}
            openSectionIndex={openSectionIndex}
            theme={theme}
            title={t('nav.mainNav.title2')}
            index={0}
          >
            <MainNavItem
              to={`${IAM}${USERS}`}
              text={t('nav.mainNav.employees')}
              mode={mode}
              active={location?.pathname?.includes(USERS)}
              icon={
                <Icon
                  icon={<Users />}
                  color={
                    location?.pathname?.includes(USERS)
                      ? theme.primaryActiveColor
                      : themes?.default?.black54
                  }
                  size={20}
                />
              }
            />
          </MenuTitleWrapper>
        </If>
      </OptionsWrapper>
    </ScrollableSection>
  );
}
