import { IconProps as PhosphorIconProps } from '@phosphor-icons/react';
import React from 'react';
import { themes } from 'styles/theme/themes';
import { Tooltip } from '@mui/material';

interface IconProps extends PhosphorIconProps {
  icon: JSX.Element;
  tooltip?: string;
  tooltipPosition?: 'top' | 'right' | 'bottom' | 'left';
  tooltipWithArrow?: boolean;
}
export const Icon = ({
  icon,
  color,
  size,
  tooltip,
  tooltipPosition,
  tooltipWithArrow,
  ...extraProps
}: IconProps) => {
  const renderedIcon = React.cloneElement(icon, {
    color: color || themes.default.iconColor,
    size: size || themes.default.iconSize,
    ...extraProps,
  });
  return (
    <Tooltip
      placement={tooltipPosition || undefined}
      title={tooltip || ''}
      arrow={tooltipWithArrow}
    >
      {renderedIcon}
    </Tooltip>
  );
};
