import { useTranslation } from 'react-i18next';
import { ItemLegend, Legend } from '../../style';
import { Stack } from '@mui/material';
import { RoundtripStatus } from 'app/components/RoundTrips/RoundTripData/statuses';
import { StatusIndicator } from 'app/components/RoundtripStatus';
import { getStatusesToShow } from '../../functions';
import React, { useMemo } from 'react';
import { themes } from 'styles/theme/themes';
import styled from 'styled-components';
import {
  Circle,
  CaretLeft,
  CaretRight,
  Plus,
  Minus,
} from '@phosphor-icons/react';
import { Icon } from 'app/components/Icon';
import { KeyboardShortcuts } from 'app/pages/RoundTrips/components/KeyboardShortcuts';
import { ButtonBase } from '@mui/material';

import StopsOp1 from 'assets/img/map/legend/order/StopsOp1.svg';
import Stops from 'assets/img/map/legend/order/Stops.svg';
import StopsDot from 'assets/img/map/legend/order/Stops-NEW-1.svg';
import StopsNotRespecting from 'assets/img/map/legend/order/StopsNotRespecting.svg';
import StopsSelected from 'assets/img/map/legend/order/StopsSelected.svg';
import StopsDelivered from 'assets/img/map/legend/order/StopsDelivered.svg';
import StopsCanceled from 'assets/img/map/legend/order/StopsCanceled.svg';
import { StateSetter } from 'types';
import { MapLegendPopover } from 'app/MapLegendPopover';
import { vehicleLabels } from 'app/MapLegendPopover/data';

const roundtripStatusesToShow = [
  RoundtripStatus.Pending,
  RoundtripStatus.Planned,
  RoundtripStatus.Loaded,
  RoundtripStatus.OnWay,
  RoundtripStatus.Completed,
  RoundtripStatus.Paused,
  RoundtripStatus.OnDock,
  RoundtripStatus.Unloaded,
  RoundtripStatus.Parked,
  RoundtripStatus.Canceled,
];

interface LegendProps {
  setFilterStatus: any;
  roundtrips: any;
  mapView?: boolean;
  assigned?: any;
  unassigned?: any;
  shortcutsCallbacks?: any;
  filters?: any;
  setFilters?: StateSetter<any>;
  viewMode?: boolean;
}

export const LegendComponent: React.FC<LegendProps> = ({
  setFilterStatus,
  roundtrips,
  mapView,
  assigned,
  unassigned,
  shortcutsCallbacks,
  filters,
  setFilters,
  viewMode,
}) => {
  roundtrips = ([] as any[]).concat(roundtrips);
  const statusesToShow = getStatusesToShow(roundtripStatusesToShow);
  const { t } = useTranslation();

  const mapLegends = [
    {
      title: t('order_labels'),
      legends: [
        { text: t('labels.assigned_not_selected'), icon: StopsOp1 },
        { text: t('labels.assigned_selected'), icon: Stops },
        { text: t('labels.unassigned_normal'), icon: StopsDot },
        { text: t('labels.unassigned_low_priority'), icon: StopsNotRespecting },
        { text: t('labels.selected'), icon: StopsSelected },
        { text: t('labels.order_delivered'), icon: StopsDelivered },
        { text: t('labels.order_canceled'), icon: StopsCanceled },
      ],
    },
    vehicleLabels,
  ];

  const isMac = useMemo(() => {
    return navigator.platform.toLowerCase().indexOf('mac') >= 0;
  }, [navigator]);

  const shortcuts = [
    {
      key1: 'escape',
      label: t('shortcuts.unselect_all'),
      icon: <LegendIcon>Esc</LegendIcon>,
      action: {
        callback: () => shortcutsCallbacks.unselectAll(),
        eventName: 'keydown',
      },
    },
    {
      key1: 'leftClick',
      label: t('shortcuts.show_stop'),
      icon: <LegendIcon>{t('key.click')}</LegendIcon>,
      action: {
        callback: () => {},
        eventName: 'mouseup',
      },
    },
    {
      key1: 'leftClick',
      label: t('shortcuts.select_stop'),
      icon: (
        <LegendIconsWrapper>
          <LegendIcon>{isMac ? 'Cmd' : 'Ctrl'}</LegendIcon>
          <LegendIcon>{t('key.click')}</LegendIcon>
        </LegendIconsWrapper>
      ),
      action: {
        callback: () => {},
        eventName: 'mouseup',
      },
    },
    {
      key1: 'ctrl',
      key2: 'click',
      label: t('shortcuts.select_one_by_one'),
      icon: (
        <LegendIconsWrapper>
          <LegendIcon>{isMac ? 'Cmd' : 'Ctrl'}</LegendIcon>
          <LegendIcon>{t('key.click')}</LegendIcon>
        </LegendIconsWrapper>
      ),
      action: {
        callback: () => {},
        eventName: 'mouseup',
      },
    },
    {
      key1: 'shift',
      key2: 'LeftClick',
      label: t('shortcuts.select_multiple_group'),
      icon: (
        <LegendIconsWrapper>
          <LegendIcon>{t('shortcuts.left_click')}</LegendIcon>
          <LegendIcon>{t('common.drag')}</LegendIcon>
        </LegendIconsWrapper>
      ),
      action: {
        callback: () => {},
        eventName: 'mouseup',
      },
    },
    {
      key1: 'rightClick',
      label: t('shortcuts.show_actions'),
      icon: (
        <LegendIconsWrapper>
          <LegendIcon>{t('shortcuts.right_click')}</LegendIcon>
        </LegendIconsWrapper>
      ),
      action: {
        callback: () => {},
        eventName: 'mouseup',
      },
    },
    {
      key1: 'ctrl',
      key2: 'o',
      label: t('shortcuts.open_order'),
      icon: (
        <LegendIconsWrapper>
          <LegendIcon>{isMac ? 'Cmd' : 'Ctrl'}</LegendIcon>
          <LegendIcon>O</LegendIcon>
        </LegendIconsWrapper>
      ),
      action: {
        callback: () => shortcutsCallbacks.openOrder(),
        eventName: 'keydown',
      },
    },
    {
      key1: 'ctrl',
      key2: 'p',
      label: t('shortcuts.change_position'),
      icon: (
        <LegendIconsWrapper>
          <LegendIcon>{isMac ? 'Cmd' : 'Ctrl'}</LegendIcon>
          <LegendIcon>P</LegendIcon>
        </LegendIconsWrapper>
      ),
      action: {
        callback: () => {},
        eventName: 'keydown',
      },
    },
    {
      key1: 'ctrl',
      key2: 'r',
      label: t('shortcuts.assign_to_roundtrip'),
      icon: (
        <LegendIconsWrapper>
          <LegendIcon>{isMac ? 'Cmd' : 'Ctrl'}</LegendIcon>
          <LegendIcon>R</LegendIcon>
        </LegendIconsWrapper>
      ),
      action: {
        callback: () => {},
        eventName: 'keydown',
      },
    },
    {
      key1: 'ctrl',
      key2: 's',
      label: t('shortcuts.switch'),
      icon: (
        <LegendIconsWrapper>
          <LegendIcon>{isMac ? 'Cmd' : 'Ctrl'}</LegendIcon>
          <LegendIcon>S</LegendIcon>
        </LegendIconsWrapper>
      ),
      action: {
        callback: () => {},
        eventName: 'keydown',
      },
    },
    {
      key1: '',
      key2: '',
      label: t('shortcuts.navigate_stops'),
      icon: (
        <LegendIconsWrapper>
          <LegendIcon>
            <Icon
              icon={<CaretLeft />}
              size={10}
              color={themes?.default?.secondaryIconColor}
            />
          </LegendIcon>
          <LegendIcon>
            <Icon
              icon={<CaretRight />}
              size={10}
              color={themes?.default?.secondaryIconColor}
            />
          </LegendIcon>
        </LegendIconsWrapper>
      ),
      action: {
        callback: () => {},
        eventName: 'keydown',
      },
    },
    {
      key1: 'left',
      label: '',
      icon: '',
      action: {
        callback: () => shortcutsCallbacks.selectStop('left'),
        eventName: 'keyup',
      },
    },
    {
      key1: 'right',
      label: '',
      icon: '',
      action: {
        callback: () => shortcutsCallbacks.selectStop('right'),
        eventName: 'keyup',
      },
    },
    {
      key1: 'ctrl',
      key2: 'num',
      label: t('shortcuts.roundtrip_selection'),
      icon: (
        <LegendIconsWrapper>
          <LegendIcon>{isMac ? 'Cmd' : 'Ctrl'}</LegendIcon>
          <LegendIcon>(Number)</LegendIcon>
        </LegendIconsWrapper>
      ),
      action: {
        callback: () => {},
        eventName: 'keydown',
      },
    },
    {
      key1: 'Space',
      label: t('shortcuts.show_hand'),
      icon: <LegendIcon>Space</LegendIcon>,
      action: {
        callback: () => {},
        eventName: 'keydown',
      },
    },
    {
      key1: '0',
      label: t('shortcuts.map_focus'),
      icon: <LegendIcon>0</LegendIcon>,
      action: {
        callback: () => shortcutsCallbacks.mapFocus(),
        eventName: 'keydown',
      },
    },
    {
      key1: '',
      key2: '',
      label: t('shortcuts.zoom_in_out'),
      icon: (
        <LegendIconsWrapper>
          <LegendIcon>
            <Icon
              icon={<Plus />}
              weight="bold"
              size={10}
              color={themes?.default?.secondaryIconColor}
            />
          </LegendIcon>
          <LegendIcon>
            <Icon
              icon={<Minus />}
              weight="bold"
              size={10}
              color={themes?.default?.secondaryIconColor}
            />
          </LegendIcon>
        </LegendIconsWrapper>
      ),
      action: {
        callback: () => {},
        eventName: 'keydown',
      },
    },
    {
      key1: 'plus',
      label: '',
      icon: '',
      action: {
        callback: () => shortcutsCallbacks.mapZoom('in'),
        eventName: 'keyup',
      },
    },
    {
      key1: '-',
      label: '',
      icon: '',
      action: {
        callback: () => shortcutsCallbacks.mapZoom('out'),
        eventName: 'keyup',
      },
    },
  ];

  function handleFilterByAssigned(label, value) {
    if (!!filters?.assigned.filter((filter) => filter.value === value).length) {
      setFilters &&
        setFilters((prevState) => ({
          ...prevState,
          assigned: filters?.assigned.filter(
            (filter) => filter.value !== value,
          ),
        }));
    } else {
      setFilters &&
        setFilters((prevState) => ({
          ...prevState,
          assigned: [...filters?.assigned, { label: label, value: value }],
        }));
    }
  }

  return (
    <Legend>
      <Stack
        direction="row"
        gap="15px"
        alignItems="center"
        justifyContent="center"
        sx={{
          overflowX: 'auto',
          position: 'relative',
        }}
        height="40px"
      >
        {!viewMode && (
          <>
            {!mapView ? (
              <>
                {statusesToShow.map((roundtripStatus, index) => {
                  const status = roundtripStatus.status;
                  const count: number = roundtrips.filter(
                    (roundtrip) => roundtrip?.status === status,
                  ).length;

                  return (
                    <ItemLegend
                      key={index}
                      onClick={() => {
                        setFilterStatus(status);
                      }}
                      opaque={!count || count === 0}
                    >
                      <LegendWrapper>
                        <StatusIndicator color={roundtripStatus.color} />
                        <LegendText>
                          <b>{count}</b> {t(`roundtrips.status.${status}`)}
                        </LegendText>
                      </LegendWrapper>
                    </ItemLegend>
                  );
                })}
              </>
            ) : (
              <>
                <ItemLegend opaque={!assigned || assigned === 0}>
                  <ButtonBase
                    onClick={() => handleFilterByAssigned(t('assigned'), 'yes')}
                  >
                    <LegendWrapper
                      selected={
                        !!filters?.assigned.filter(
                          (filter) => filter.value === 'yes',
                        ).length
                      }
                    >
                      <Icon
                        icon={<Circle />}
                        color={themes.default.nobel}
                        weight="fill"
                        size={8}
                      />
                      <LegendText>
                        <b>{assigned}</b> {t(`assigned`)}
                      </LegendText>
                    </LegendWrapper>
                  </ButtonBase>
                </ItemLegend>
                <ItemLegend>
                  <ButtonBase
                    onClick={() =>
                      handleFilterByAssigned(t('unassigned'), 'no')
                    }
                  >
                    <LegendWrapper
                      selected={
                        !!filters?.assigned.filter(
                          (filter) => filter.value === 'no',
                        ).length
                      }
                    >
                      <Icon
                        icon={<Circle />}
                        color={themes.default.iconColor}
                        weight="fill"
                        size={8}
                      />
                      <LegendText>
                        <b>{unassigned}</b> {t(`unassigned`)}
                      </LegendText>
                    </LegendWrapper>
                  </ButtonBase>
                </ItemLegend>
              </>
            )}
          </>
        )}
        {mapView && (
          <InfoMenuBtnWrapper>
            {!viewMode && <KeyboardShortcuts shortcuts={shortcuts} />}
            <MapLegendPopover content={mapLegends} />
          </InfoMenuBtnWrapper>
        )}
      </Stack>
    </Legend>
  );
};

const LegendWrapper = styled.div<{ selected?: any }>`
  padding: 2px 8px;
  display: flex;
  align-items: center;
  gap: 4px;
  border: 0.5px solid
    ${(props) =>
      props.selected ? themes.default.primary : themes.default.gainsboro2};
  background: ${(props) =>
    props.selected
      ? themes.default.primaryLight1
      : themes.default.roundtripGreyBg};
  border-radius: 3px;
  font-size: 1.2rem;
  cursor: pointer;
  transition-duration: 0.15s;

  :hover {
    box-shadow: inset 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
  }
`;

const LegendText = styled.p`
  margin: 0 !important;
  font-size: 0.6em;
  font-weight: 400;
  letter-spacing: 0.1599999964237213px;
  color: ${themes?.default?.textColorPrimary};
  height: fit-content;
  white-space: nowrap;
`;

const InfoMenuBtnWrapper = styled.div`
  display: flex;
  gap: 10px;
  position: absolute;
  right: 16px;
  top: 7px;
`;

const LegendIconsWrapper = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const LegendIcon = styled.span<{ textOnly?: boolean }>`
  height: 24px;
  min-width: ${(props) => (!props.textOnly ? '24px' : 'auto')};
  padding: ${(props) => (!props.textOnly ? '4px' : '4px 0px')};
  gap: 4px;
  border-radius: 2px;
  background: ${(props) => !props.textOnly && themes?.default?.lightGrey};
  border: ${(props) =>
    !props.textOnly && `0.5px solid ${themes?.default?.gainsboro2}`};
  color: ${themes?.default?.clientMarkerBg};
  font-size: 0.6875rem;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
`;
