import { removeUndefined } from '../../../utils/dto';

const getCustomerData = (response: any) => {
  if (!response) return;
  return {
    code: response?.code,
    publicName: response?.publicName,
  };
};

const getDeliveryInfo = (response: any) => {
  if (!response) return;
  return {
    distance: response?.distance,
  };
};

const getCacheData = (response: any) => {
  if (!response) return;
  return {
    _deck: response._deck,
  };
};

const OrderObjDto = (response: any) => {
  if (!response) return;

  return {
    _id: response?._id,
    status: response?.status,
    code: response?.code,
    getDeliveryDateFormat: response?.getDeliveryDateFormat,
    updatedStatusAt: response?.updatedStatusAt,
    countPackages: response?.countPackages,
    type: response?.type,
    distance: response?.distance,
    amountRequested: response?.amountRequested,
    _customer: getCustomerData(response?._customer),
    deliveryInfo: getDeliveryInfo(response?.deliveryInfo),
    cache: getCacheData(response.cache),
    supportUnitsList: response.supportUnitsList,
    totalWeight: response.totalWeight,
    totalAmount: response.totalAmount,
    deliveryComment: response.deliveryComment,
    phoneBooks: response.phoneBooks,
  };
};

const orderDocDto = (response: any) => {
  if (!response) return;
  return response.map((res: any) => {
    return OrderObjDto(res);
  });
};

export const getOrderDto = (response: any) => {
  return removeUndefined({
    docs: orderDocDto(response?.docs),
    page: response.page,
    totalDocs: response.totalDocs,
  });
};
