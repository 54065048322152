import { Column } from '../../../../../components/Grid/types';

export const Columns: Column[] = [
  {
    id: 'name',
    label: 'warehouse.heads.name',
    sortField: 'warehouse.heads.name',
    cellStyle: {
      width: '200px',
      minWidth: '200px !important',
    },
    disableSort: true,
    sticky: 'left',
  },
  {
    id: 'initials',
    label: 'warehouse.heads.initials',
    sortField: 'warehouse.heads.initials',
    disableSort: true,
  },

  {
    id: 'location',
    label: 'warehouse.heads.location',
    sortField: 'warehouse.heads.location',
    disableSort: true,
  },
  {
    id: 'capacity',
    label: 'Capacity',
    sortField: 'warehouse.heads.capacity',
    canChangeView: true,
    disableSort: true,
  },
  {
    id: 'manager',
    label: 'Add_Client_Manager',
    sortField: 'cache._manager.name',
    canChangeView: true,
    disableSort: true,
  },
  {
    id: 'contact',
    label: 'Contact',
    sortField: 'warehouse.heads.contact',
    canChangeView: true,
    disableSort: true,
  },
  {
    id: 'operatingHours',
    label: 'Operating Hours',
    sortField: 'warehouse.heads.hours',
    canChangeView: true,
    disableSort: true,
  },
  {
    id: 'status',
    label: 'Status',
    sortField: 'warehouse.heads.status',
    canChangeView: true,
    disableSort: true,
  },
  {
    id: 'actions',
    label: '',
    disableSort: true,
    cellStyle: {
      width: '100px',
      minWidth: '100px !important',
    },
    sticky: 'right',
  },
];
