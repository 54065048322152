import { Divider, Pagination, Paper, Stack } from '@mui/material';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import AllInbox from '@mui/icons-material/AllInbox';
import SendIcon from '@mui/icons-material/Send';
import InboxTwoTone from '@mui/icons-material/InboxTwoTone';
import Grid from '@mui/material/Unstable_Grid2';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSubscribeObject } from 'common/hooks/ws';
import { useLazyGetListConversationsCustomerQuery } from 'common/services/customerApi';
import { useLazyGetOrganizationActivitiesQuery } from 'common/services/organizationApi';

import Conversation from '../Conversation';
import ConversationDetail from '../Conversation/ConversationDetail';

export function Complaint({
  authUser,
  customer,
  onNewMessage,
  token,
  password,
}) {
  const [currentAddressId, setCurrentAddressId] = useState<string | null>(null);
  const [currentConversation, setCurrentConversation] = useState<object | null>(
    null,
  );
  const [page, setPage] = useState(1);
  const [trigger, { data: conversations, isFetching, isLoading }] =
    useLazyGetListConversationsCustomerQuery();
  const [triggerGetActivities] = useLazyGetOrganizationActivitiesQuery();
  const { t } = useTranslation();

  const wsMsg = useSubscribeObject('customer', customer._id, [
    'new-message',
    'new-conversation',
  ]);

  const handleNewMessage = () => {
    if (onNewMessage) {
      onNewMessage();
      setPage(1);
      fetchConversations(1);
    }
  };

  const fetchConversations = (pageNumber) => {
    if (token && password) {
      trigger({
        password,
        page: pageNumber,
        contactAddressId: currentAddressId,
        token,
      });
    }
  };

  const handleChangeOrderPagination = (event, value) => {
    setPage(value);
  };

  const handleSelectAddress = (addressId) => {
    setCurrentAddressId(addressId);
  };

  const handleDisplayConversation = (conversation) => {
    setCurrentConversation(conversation);
  };

  const handleCloseConversation = () => {
    fetchConversations(page);
    triggerGetActivities();
    setCurrentConversation(null);
  };

  useEffect(() => {
    triggerGetActivities();
    fetchConversations(page);
  }, [currentAddressId, page]);

  useEffect(() => {
    if (wsMsg && page === 1) {
      fetchConversations(page);
    }
  }, [wsMsg]);

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid xs={12} sm={12} md={3}>
          <Paper sx={{ maxWidth: '100%' }}>
            <MenuList>
              {authUser?.currentAgent?._customer || !authUser ? (
                <>
                  <MenuItem onClick={handleNewMessage}>
                    <ListItemIcon>
                      <SendIcon />
                    </ListItemIcon>
                    <ListItemText>Nouveau message</ListItemText>
                  </MenuItem>
                  <Divider />
                </>
              ) : null}

              <MenuItem
                selected={currentAddressId === null}
                onClick={() => handleSelectAddress(null)}
              >
                <ListItemIcon>
                  <AllInbox />
                </ListItemIcon>
                <ListItemText>{t('customerPortal.messages.all')}</ListItemText>
              </MenuItem>

              {customer.contactAddresses.map((address) => (
                <MenuItem
                  key={address._id}
                  selected={address._id === currentAddressId}
                  onClick={() => handleSelectAddress(address._id)}
                >
                  <ListItemIcon>
                    <InboxTwoTone />
                  </ListItemIcon>
                  <ListItemText>{address.label}</ListItemText>
                </MenuItem>
              ))}
            </MenuList>
          </Paper>
        </Grid>
        <Grid xs={12} sm={12} md={9}>
          <div style={{ opacity: isFetching ? 0.7 : 1 }}>
            {!isLoading && conversations?.docs.length === 0 && (
              <h3 style={{ textAlign: 'center', opacity: 0.5 }}>
                Aucune réclamation
              </h3>
            )}

            {conversations?.docs.map((conversation) => (
              <Conversation
                isNew={conversation.viewByCustomer !== true}
                key={conversation._id}
                subject={conversation.subject}
                date={conversation.createdAt}
                customer={conversation._customer}
                lastMessage={conversation._lastMessage}
                contactAddressId={conversation?.metadata?.contactAddressId}
                style={{ marginBottom: '16px' }}
                onClick={() => handleDisplayConversation(conversation)}
              />
            ))}
          </div>

          {conversations?.docs.length > 0 && (
            <Stack spacing={2}>
              <Pagination
                count={conversations?.totalPages || 0}
                onChange={handleChangeOrderPagination}
              />
            </Stack>
          )}
        </Grid>
      </Grid>

      <ConversationDetail
        conversation={currentConversation}
        authUser={authUser}
        customer={customer}
        token={token}
        password={password}
        onClose={handleCloseConversation}
      />
    </>
  );
}
