import { removeUndefined } from '../../../utils/dto';

const agentsObjDto = (response: any) => {
  if (!response) return;
  return {
    _organization: response._organization,
    firstName: response.firstName,
    lastName: response.lastName,
    status: response.status,
    phone: response.phone,
    pictureUrl: response.pictureUrl,
    type: response.type,
    workingHours: response.workingHours,
  };
};

const agentsDto = (response: any) => {
  if (!response) return;
  return response.map((res: any) => {
    return agentsObjDto(res);
  });
};

const currentAgentObjDto = (response: any) => {
  if (!response) return;
  return {
    companyPhone: response.companyPhone,
    contractType: response.contractType,
    joinAt: response.joinAt,
  };
};

const cacheAgentsDto = (response: any) => {
  if (!response) return;
  const result: any = {};
  for (const [key, value] of Object.entries(response) as [string, any][]) {
    result[key] = {
      ...(value.leaveRequests && { leaveRequests: value.leaveRequests }),
    };

    // Remove the entry if it's empty
    if (Object.keys(result[key]).length === 0) {
      delete result[key];
    }
  }
  return result;
};

const cacheDto = (response: any) => {
  if (!response) return;
  return {
    agents: cacheAgentsDto(response.agents),
  };
};

const docsObjDto = (response: any) => {
  if (!response) return;
  return {
    companyEmail: response.companyEmail,
    roundtrip: response.roundtrip, // obj
    pictureUrl: response.pictureUrl,
    agents: agentsDto(response.agents), // obj
    isActive: response.isActive,
    cache: cacheDto(response.cache), // obj
    fullName: response.fullName,
    _id: response._id,
    currentAgent: currentAgentObjDto(response.currentAgent), // obj
    firstName: response.firstName,
    lastName: response.lastName,
    _currentOrganization: response._currentOrganization,
  };
};

const docsDto = (response: any) => {
  if (!response) return;
  return response.map((res: any) => {
    return docsObjDto(res);
  });
};

export const getUsersDto = (response: any) => {
  return removeUndefined({
    facets: response.facets,
    limit: response.limit,
    page: response.page,
    totalPages: response.totalPages,
    docs: docsDto(response.docs),
    totalDocs: response.totalDocs,
  });
};
