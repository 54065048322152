import React from 'react';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { Avatar } from '@mui/material';
import { stringAvatar } from 'app/helpers/helpers';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { UserStatusDot } from 'app/components/AppHeader/components/UserStatusDot';

interface Props {
  size: number;
  userImagePath?: string;
  fullName?: string;
  status?: 'online' | 'offline';
  count?: number;
}

export const UserAvatar: React.FC<Props> = ({
  size,
  userImagePath,
  fullName,
  status,
  count,
}) => {
  const theme = useSelector(selectTheme);
  return (
    <Wrapper>
      {userImagePath ? (
        <div style={{ position: 'relative' }}>
          <Avatar
            sx={{
              width: size,
              height: size,
              position: 'relative',
              overflow: 'visible',
              borderRadius: `${size / 2}px !important`,
              '& img': {
                borderRadius: `${size / 2}px !important`,
              },
            }}
            src={userImagePath}
          />
          {count && count > 0 && (
            <Count>
              <p>{count}</p>
            </Count>
          )}
          {status && <UserStatusDot size={size / 4} status={status} />}
        </div>
      ) : (
        <Avatar
          sx={{
            width: size,
            height: size,
            background: theme.primary,
            fontFamily: 'Helvetica !important',
            fontSize: '0.75rem',
            color: theme.accordionWhiteBg,
            position: 'relative',
            overflow: 'visible',
          }}
        >
          {stringAvatar(`${fullName}`)}
          {count && (
            <Count>
              <p>{count}</p>
            </Count>
          )}
          {status && <UserStatusDot size={size / 4} status={status} />}
        </Avatar>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Count = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50px;
  position: absolute;
  top: -3px;
  right: -5px;
  background: ${themes?.default?.errorRed};
  p {
    margin: 0;
    font-size: 0.625rem;
    line-height: 14px;
    letter-spacing: 0.15px;
    text-align: center;
    color: ${themes?.default?.accordionWhiteBg};
  }
`;
