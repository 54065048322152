import { removeUndefined } from '../../../utils/dto';

const vehicleDto = (response: any) => {
  if (!response) return;
  return {
    model: response.model,
    numberPlate: response.numberPlate,
    status: response.status,
    fullName: response.fullName,
    lastPosition: response.lastPosition, // obj
    _id: response._id,
    capacity: response.capacity,
    engineRunning: response.engineRunning,
  };
};

const roundtripCache = (response: any) => {
  if (!response) return;
  return {
    stats: response.stats, // obj
    driverUser: response.driverUser,
    salesUser: response.salesUser,
    directions: response.directions,
    ripperUser: response.ripperUser,
    status: response.status,
  };
};

const geometryDto = (response: any) => {
  if (!response) return;
  return {
    coordinates: response.coordinates,
  };
};

const locationDto = (response: any) => {
  if (!response) return;
  return {
    geometry: geometryDto(response.geometry),
  };
};

const startEndPlaceDto = (response: any) => {
  if (!response) return;
  return {
    location: locationDto(response.location),
  };
};

const roleDto = (response: any) => {
  if (!response) return;
  return {
    key: response.key,
  };
};

const agentDto = (response: any) => {
  if (!response) return;
  return {
    _user: response._user, // obj
    _role: roleDto(response._role),
  };
};

const agentsDto = (response: any) => {
  if (!response) return;
  return response.map((res: any) => {
    return agentDto(res);
  });
};

const stopDto = (response: any) => {
  if (!response) return;
  return {
    _order: response._order, // dto
    status: response.status,
    _id: response._id,
    _place: response._place, // dto
    type: response.type,
  };
};

const stopsDto = (response: any) => {
  if (!response) return;
  return response.map((res: any) => {
    return stopDto(res);
  });
};

const roundTripObjDto = (response: any) => {
  if (!response) return;
  return {
    _id: response._id,
    code: response.code,
    internalCode: response.internalCode,
    _vehicle: vehicleDto(response._vehicle),
    supportUnitsRequestedCapacity: response.supportUnitsRequestedCapacity,
    cache: roundtripCache(response.cache),
    supportUnitsListCollected: response.supportUnitsListCollected,
    stopCount: response.stopCount,
    _startPlace: startEndPlaceDto(response._startPlace),
    _endPlace: startEndPlaceDto(response._endPlace),
    stops: stopsDto(response.stops), // obj
    agents: agentsDto(response.agents), // obj
    startAt: response.startAt,
    defaultStartAt: response.defaultStartAt,
    supportUnitsList: response.supportUnitsList,
    status: response.status,
  };
};

const roundtripsDto = (response: any) => {
  if (!response) return;
  return response.map((res: any) => {
    return roundTripObjDto(res);
  });
};

export const getRoundTripsClusterDto = (response: any) => {
  return removeUndefined({
    _id: response._id,
    roundtrips: roundtripsDto(response.roundtrips),
    facets: response.facets,
  });
};
