/* eslint-disable @typescript-eslint/no-unused-vars */
import { Stack } from '@mui/material';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';
import { VerticalDivider } from 'app/components/EnhancedTableToolbar/components/VerticalDivider';
import If from 'app/components/If';
import { ChangeOrderButton } from 'app/components/OrderDetail/components/ChangeOrderButton';
import { useCallback, useMemo } from 'react';

interface ItemsNavigationProps {
  itemId: string;
  allItems: any[];
  handleChangeItem: (fullItem: any) => void;
}

export const NavigationButtons = ({
  itemId,
  allItems,
  handleChangeItem,
}: ItemsNavigationProps) => {
  const currentItemIndex = useMemo(() => {
    return allItems.findIndex((item) => item?._id === itemId);
  }, [itemId, allItems]);

  const prevDisabled = useMemo(() => {
    return currentItemIndex === 0;
  }, [currentItemIndex]);

  const nextDisabled = useMemo(() => {
    return currentItemIndex === allItems.length - 1;
  }, [currentItemIndex, allItems]);

  const handleNextPrev = useCallback(
    (destination) => {
      if (destination === 'prev') {
        handleChangeItem(allItems[currentItemIndex - 1]);
      } else {
        handleChangeItem(allItems[currentItemIndex + 1]);
      }
    },
    [handleChangeItem, allItems, currentItemIndex],
  );

  return (
    <If condition={!!itemId}>
      <Stack direction="row" alignItems="center" gap="12px" height="24px">
        <Stack direction="row" alignItems="center" gap="8px">
          <ChangeOrderButton
            icon={<CaretLeft weight="bold" />}
            action={() => handleNextPrev('prev')}
            disabled={prevDisabled}
          />
          <ChangeOrderButton
            icon={<CaretRight weight="bold" />}
            action={() => handleNextPrev('next')}
            disabled={nextDisabled}
          />
        </Stack>
        <VerticalDivider sx={{ height: '100%' }} />
      </Stack>
    </If>
  );
};
