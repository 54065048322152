export function filterArray(array, key1, key2, key3, key4, setArray) {
  if (!key1[0] && !key2[0] && !key3[0] && !key4[0]) {
    setArray(array);
  } else {
    const filteredArray = array.filter((item) => {
      if (key1 !== '' && item.priority !== key1) {
        return false;
      }
      if (key2 !== '' && item.key2 !== key2) {
        return false;
      }
      if (key3 !== '' && item.status !== key3) {
        return false;
      }
      return !(key4 !== '' && item.key4 !== key4);
    });
    setArray(filteredArray);
  }
}

export function removeItemsFromOrder(order, ids) {
  const orders = [...order];
  ids.forEach((id) => {
    const index = orders.findIndex((item) => item.id === id.id);
    if (index !== -1) {
      orders.splice(index, 1);
    }
  });
  return orders;
}

export function addObjectsToStops(roundtrip, newObjects) {
  const updatedNewObjects = newObjects.map((obj) => ({
    ...obj,
    _order: obj?.id,
    type: obj?.type,
    _place: obj?._deck?._id,
  }));

  return {
    ...roundtrip,
    stops: [...roundtrip.stops, ...updatedNewObjects],
  };
}

export const handleAccordionToggleHover = (
  group: number,
  index: number,
  accordionStatus,
  setAccordionStatus,
) => {
  let accordionStatusCopy;
  if (Array.isArray(accordionStatus)) {
    accordionStatusCopy = JSON.parse(JSON.stringify(accordionStatus));
  }
  if (accordionStatusCopy?.[group]) {
    accordionStatusCopy[group].value[index] = true;
    setAccordionStatus?.(accordionStatusCopy);
  }
};
