import { Button } from '@mui/material';
import React from 'react';
export type Color =
  | 'inherit'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'error'
  | 'info'
  | 'warning';

interface Props {
  size?: 'small' | 'large' | 'medium' | undefined;
  iconComponent?: any;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  style?: React.CSSProperties | undefined;
  text?: string;
  iconStyle?: React.CSSProperties | undefined;
  variant?: 'text' | 'contained' | 'outlined' | undefined;
  invert?: boolean;
  color?: Color;
  sx?: any;
  disabled?: any;
}
export const IconButtonComponent: React.FC<Props> = ({
  iconComponent: iconeComponent,
  onClick,
  style,
  size,
  text,
  variant,
  iconStyle,
  invert,
  color,
  sx,

  ...props
}) => {
  return (
    <div style={style || {}}>
      <Button
        color={color}
        size={size || 'small'}
        onClick={(e) => {
          onClick && onClick(e);
        }}
        variant={variant || 'contained'}
        sx={sx ?? ''}
        {...props}
      >
        {invert ? (
          <>
            <span style={iconStyle || {}}>{text || ''}</span>
            {iconeComponent || ''}
          </>
        ) : (
          <>
            {iconeComponent || ''}
            <span style={iconStyle || {}}>{text || ''}</span>
          </>
        )}
      </Button>
    </div>
  );
};
