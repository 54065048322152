import * as React from 'react';
import Popover from '@mui/material/Popover';
import { IconButton, TextField } from '@mui/material';

import { ColumnAutoComplete } from './ColumnAutoComplete';
import CloseIcon from '@mui/icons-material/Close';
import { selectTheme } from '../../../styles/theme/slice/selectors';
import { useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import styled from 'styled-components';
import { Operator } from 'app/components/Operator';
import { useTranslation } from 'react-i18next';
import { NO_DATA_REGULAR } from '../const';
import { FunnelSimple } from '@phosphor-icons/react';
import { themes } from 'styles/theme/themes';

interface Props {
  handleEdit?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  setFilters?: any;
  headCells: any[];
  name?: any;
  disableTitle?: boolean;
  filters?: any[];
}

export interface IFilter {
  field: string;
  operator: string;
  value: string;
  column?: string;
}

export const Filters: React.FC<Props> = ({
  headCells,
  setFilters,
  name,
  disableTitle,
  filters,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [filter, setFilter] = React.useState<IFilter[]>([
    {
      field: '',
      operator: '',
      value: '',
      column: '',
    },
  ]);

  const theme = useSelector(selectTheme);

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setFilters(filter);
    setAnchorEl(null);
  };

  const filterLength = React.useMemo(() => {
    return filters?.filter(
      (filterRow) => filterRow.field && filterRow.operator && filterRow.value,
    )?.length;
  }, [filters]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const { t } = useTranslation();
  return (
    <div>
      <div
        onClick={(e) => {
          if (name !== NO_DATA_REGULAR) handleClick(e);
        }}
        style={{
          fontSize: '0.875rem',
          opacity: name === NO_DATA_REGULAR ? 0.2 : 1,
          fontWeight: '500',
          cursor: 'pointer',
          width: 'fit-content',
          display: 'flex',
          alignItems: 'center',
          textTransform: 'uppercase',
        }}
      >
        <IconButton>
          <FunnelSimple
            size={themes?.default?.iconSize}
            color={
              !!filterLength || open
                ? theme.primaryActiveColor
                : theme.iconColor
            }
          />
        </IconButton>
        {disableTitle ? null : t('nav.table_nav.tab_filter')}
      </div>
      <Popover
        style={{ minWidth: 460 }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <PopoverWrapper>
          <TitleElWrapper>
            <TitleEl>{t('common.filter.column')}</TitleEl>
            <TitleEl>{t('common.filter.operator')}</TitleEl>
            <TitleEl>{t('common.filter.value')}</TitleEl>
          </TitleElWrapper>
          {filter.map((_f, index) => {
            return (
              <Filter key={index}>
                <CloseIcon
                  onClick={() => {
                    const filterCopy = [...filter];
                    if (filterCopy.length === 1) {
                      setFilter([
                        {
                          field: '',
                          operator: '',
                          value: '',
                          column: '',
                        },
                      ]);
                    } else {
                      filterCopy.splice(index, 1);
                      setFilter(filterCopy);
                    }
                  }}
                />
                <ColumnAutoComplete
                  handleSelectedValue={(e) => {
                    const filterCopy = [...filter];
                    filterCopy[index].field = e?.sortField;
                    filterCopy[index].column = e?.sortValue;
                    setFilter(filterCopy);
                  }}
                  data={filter[index]}
                  headCells={headCells}
                />
                <Operator
                  handleSelectedValue={(e) => {
                    const filterCopy = [...filter];
                    filterCopy[index].operator = e;
                    setFilter(filterCopy);
                  }}
                  operators={['contains', 'equal']}
                  data={filter[index]}
                />
                <TextField
                  onChange={(e) => {
                    const filterCopy = [...filter];
                    filterCopy[index].value = e.target.value;
                    setFilter(filterCopy);
                  }}
                  placeholder={t('common.filter.value')}
                  value={filter[index].value}
                  style={{ width: 150 }}
                  variant="standard"
                />
              </Filter>
            );
          })}
          <AddFilter
            onClick={() => {
              setFilter((oldState) => {
                return [
                  ...oldState,
                  {
                    field: '',
                    operator: '',
                    value: '',
                    column: '',
                  },
                ];
              });
            }}
          >
            <AddIcon sx={{ color: theme.primary }} />
            {t('common.filter.add_button')}
          </AddFilter>
        </PopoverWrapper>
      </Popover>
    </div>
  );
};

const Filter = styled.div((props) => ({
  display: 'flex',
  '& svg': {
    cursor: 'pointer',
    marginRight: 10,
    marginTop: 5,
    color: props.theme.icon,
  },
}));
const AddFilter = styled.div((props) => ({
  color: props.theme.primary,
  fontWeight: 500,
  width: 'fit-content',
  cursor: 'pointer',
}));
const PopoverWrapper = styled.div`
  min-width: 460px;
  margin: 12px;
`;
const TitleElWrapper = styled.div`
  display: flex;
  margin-left: 34px;
`;
const TitleEl = styled.div`
  flex: 1;
`;
