import { appActions } from 'common/store/app';
import { t } from 'i18next';
import { AgentCategories } from '../../RoundTripData/agentCategories';

export const functionRoundTripStatus = async (
  status,
  id,
  roundTripStatus,
  dispatch,
  translation,
  pageRows = '',
) => {
  try {
    const resp = await roundTripStatus({
      status: status,
      roundtripIds: id,
      pageRows,
    }).unwrap();
    if (resp && dispatch && translation) {
      dispatch(
        appActions.setLastToasterNotification({
          duration: 3000,
          severity: 'success',
          message: translation('status_updated_successfully'),
        }),
      );
    }
  } catch (e: any) {
    if (e.status === 400 && dispatch && translation) {
      dispatch(
        appActions.setLastToasterNotification({
          duration: 5000,
          severity: 'error',
          message: translation(e.data?.message),
        }),
      );
    }
  }
};

export const addFuction = async (id: string, round, UpdateRoundtripData) => {
  try {
    const resp = await UpdateRoundtripData({
      id: id,
      data: { stops: [] },
    }).unwrap();
    if (resp) {
    }
  } catch (e: any) {
    if (e.status === 401) {
      console.log(e);
    }
  }
};

export const deleteFunction = async (id: string, DeleteRoundtrip: any) => {
  try {
    DeleteRoundtrip({ id: id }).unwrap();
  } catch (e: any) {
    if (e.status === 401) {
      console.log(e);
    }
  }
};

export const bulkClear = (selected, UpdateRoundtripData) => {
  const firstKey = Object.keys(selected)[0];

  selected[firstKey].map((val) => {
    addFuction(val, { stops: [] }, UpdateRoundtripData);
  });
};

export const bulkDelete = (selected, deleteFunction, DeleteRoundtrip) => {
  const firstKey = Object.keys(selected)[0];

  selected[firstKey].map((val) => {
    deleteFunction(val, DeleteRoundtrip);
  });
};

export const schedule = async (
  clusterId,
  updateRoundtripCluster,
  dispatch,
  roundtripActions,
  setClusterId,
  status = 'active',
  message = '',
  agentCategory = AgentCategories.Logistic,
) => {
  try {
    const resp = await updateRoundtripCluster({
      id: clusterId,
      status: status,
      agentCategory,
    }).unwrap();

    if (resp) {
      dispatch(
        appActions.setLastToasterNotification({
          duration: 5000,
          severity: 'success',
          message: message,
        }),
      );
      dispatch(roundtripActions.setClusterId(resp.cluster?._id));
      setClusterId(resp.cluster?._id);
    }
  } catch (e: any) {
    if (e.status === 401) {
    } else if (e.status === 400) {
      dispatch(
        appActions.setLastToasterNotification({
          duration: 5000,
          severity: 'error',
          message: t(e?.data?.message),
        }),
      );
    }
  }
};

export const updateOrderFunction = async (
  id: string,
  updateObj: any,
  UpdateOrders,
  queryParams,
) => {
  try {
    return await UpdateOrders({ id, queryParams, ...updateObj }).unwrap();
  } catch (e: any) {
    if (e.status === 401) {
      console.log(e);
    }
  }
};

export const deleteFunctionStop = async (
  deleteStop,
  roundtrip,
  selectedStop,
) => {
  try {
    const resp = await deleteStop({
      roundtripId: roundtrip?._id,
      stopId: selectedStop?._id,
    }).unwrap();
    if (resp) {
      roundtrip.stops = roundtrip.stops.filter(
        (stop) => stop._id !== selectedStop?._id,
      );
    }
  } catch (e: any) {
    if (e.status === 401) {
      console.log(e);
    }
  }
};
