import {
  AlertColor,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { themes } from 'styles/theme/themes';
import { useMemo, useState } from 'react';
import If from 'app/components/If';
import { MainTypo } from 'app/pages/Ordoria/CallPlan/components/CallDetailsDrawer/components/Info/styles';
import { useTranslation } from 'react-i18next';
import { X } from '@phosphor-icons/react';
import styled from 'styled-components';
import { AgentStatuses } from 'app/pages/Users/data/types';
import { useToaster } from 'hooks/useToaster';
import { useInviteUsersBulkMutation } from 'common/services/userApi';

export function InviteEmails({ customerDetail, open, onClose }) {
  const { t } = useTranslation();
  const toast = useToaster();
  const [inviteUsers, { isLoading: isInviting }] = useInviteUsersBulkMutation();

  const [checkedEmails, setCheckedEmails] = useState<string[]>([]);

  const emailList = useMemo(() => {
    const activeEmails = customerDetail?.agents
      ?.filter((agent) =>
        [AgentStatuses.Active, AgentStatuses.Invited]?.includes(agent.status),
      )
      ?.map((agent) => agent.email);
    const allEmails = [customerDetail.email, ...customerDetail.otherEmails];
    return allEmails?.filter((email) => !activeEmails?.includes(email)) || [];
  }, [customerDetail]);

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setCheckedEmails(isChecked ? emailList : []);
  };

  const selectEmail = (
    event: React.ChangeEvent<HTMLInputElement>,
    email: string,
  ) => {
    const checked = event.target.checked;
    if (checked) {
      setCheckedEmails((prev) => [...prev, email]);
    } else {
      setCheckedEmails((prev) => prev?.filter((em) => em !== email));
    }
  };

  async function inviteCustomer(selectedEmails: string[]) {
    const usersToInvite = selectedEmails.map((email) => ({
      email: email,
      source: 'customer',
      role: 'customer',
      applications: ['ordoria'],
      firstName: 'customer',
      lastName: 'customer',
      customerId: customerDetail?._id,
    }));
    try {
      const { message, messageType, data } = await inviteUsers({
        users: usersToInvite,
      }).unwrap();
      onClose();
      toast(
        3000,
        messageType as AlertColor,
        t(message, {
          role: 'customer',
          count: data.count,
          total: data.totalUsers,
        }),
      );
    } catch (e) {}
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <Grid margin="32px 24px">
        <Grid container flexDirection="column">
          <Grid container alignItems="center" marginBottom="24px">
            <Typography fontWeight="500" fontSize="1.25rem">
              {t('client.emails.modal.content.title')}
            </Typography>
            <IconButton
              onClick={onClose}
              sx={{
                ml: '33px',
              }}
            >
              <X />
            </IconButton>
          </Grid>
          <FormControlLabel
            label={t('common.buttons.select_all')}
            sx={{
              marginBottom: '16px',
            }}
            control={
              <Checkbox
                checked={
                  checkedEmails.length > 0 &&
                  checkedEmails?.length === emailList?.length
                }
                indeterminate={
                  checkedEmails.length > 0 &&
                  checkedEmails?.length < emailList?.length
                }
                onChange={(e) => handleSelectAll(e)}
              />
            }
          />
          {emailList?.map((email, index) => (
            <Grid container alignItems="center">
              <FormControlLabel
                key={index}
                label={email}
                control={
                  <Checkbox
                    checked={checkedEmails?.includes(email)}
                    onChange={(e) => selectEmail(e, email)}
                  />
                }
              />
              <If condition={email === customerDetail?.email}>
                <MainTypo
                  sx={{
                    backgroundColor: themes?.default?.primaryLight1,
                    border: `1px solid ${themes?.default?.strokePrimary}`,
                  }}
                >
                  {t('main')}
                </MainTypo>
              </If>
            </Grid>
          ))}
          <Footer>
            <Button
              onClick={onClose}
              sx={{
                color: themes?.default?.textColorPrimary,
              }}
            >
              {t('cancel')}
            </Button>
            <Button
              variant="contained"
              onClick={() => inviteCustomer(checkedEmails)}
              disabled={!checkedEmails?.length || isInviting}
            >
              {t('client.modal.button.title')}
            </Button>
          </Footer>
        </Grid>
      </Grid>
    </Dialog>
  );
}

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 24px;
`;
