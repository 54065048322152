import { FileRejection } from 'react-dropzone';
import { UploadedFile } from '../UploadComponent';
import { useToaster } from 'hooks/useToaster';
import { useState } from 'react';
import If from '../If';
import { Box, IconButton, Typography } from '@mui/material';
import { themes } from 'styles/theme/themes';
import { TrashSimple } from '@phosphor-icons/react';
import styled from 'styled-components';
import { UploadZone } from '../UploadFiles';
import Icon from 'assets/img/customers/AddImage.svg';

interface Props {
  description: string;
  imageUrl: string;
  onUpload: any;
  onDelete: any;
  disabled?: boolean;
}

export const ImageUpload = ({
  description,
  imageUrl,
  onUpload,
  onDelete,
  disabled,
}: Props) => {
  const toast = useToaster();
  const [isHovering, setIsHovering] = useState(false);

  return (
    <Box height="105px" width="80px">
      <UploadZone
        customUI={true}
        titleNode={
          <Typography
            fontSize="0.6875rem"
            color={themes.default.nobel}
            sx={{
              textWrap: 'nowrap',
              paddingBottom: '8px',
            }}
          >
            {description}
          </Typography>
        }
        icon={
          <IconWrapper>
            <img src={Icon} alt="" />
          </IconWrapper>
        }
        maxFiles={1}
        maxSize={2000000} //2MB
        onOk={(files: UploadedFile[]) => {
          onUpload?.(files);
        }}
        onUnOk={(rejection: FileRejection) => {
          toast(5000, 'error', rejection?.errors?.[0]?.message);
        }}
        acceptFile={{
          'image/png': [],
          'image/jpg': ['.jpeg', '.jpg'],
          'image/svg+xml': ['.svg'],
        }}
        style={{
          height: '80px',
          position: 'relative',
        }}
        items={
          <Box
            onMouseEnter={() => !!disabled && setIsHovering(true)}
            onMouseLeave={() => !!disabled && setIsHovering(false)}
          >
            <If condition={!!imageUrl}>
              <img
                src={imageUrl}
                height="80px"
                width="80px"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                }}
                alt=""
              />

              <If condition={isHovering && !!onDelete}>
                <IconButton
                  onClick={async (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onDelete();
                  }}
                  size="small"
                  sx={{
                    backgroundColor: themes.default.accordionWhiteBg,
                    borderRadius: '8px',
                    ':hover': {
                      backgroundColor: themes.default.lightGrey,
                    },
                    position: 'absolute',
                    right: '5px',
                    bottom: '5px',
                  }}
                >
                  <TrashSimple color={themes.default.progressRed} />
                </IconButton>
              </If>
            </If>
          </Box>
        }
        disabled={disabled}
      />
    </Box>
  );
};

const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 20px;
  width: 20px;
`;
