import { Chip } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { Completed, Pending } from './statuses';
import React from 'react';

interface Props {
  status:
    | 'draft'
    | 'planned'
    | 'started'
    | 'completed'
    | 'cancelled'
    | 'pending';
}
// export function RoundTripsStatus: React.FC<Props>({
export const RoundTripsStatus: React.FC<Props> = ({ status }) => {
  const theme = useSelector(selectTheme);
  return status === Completed ? (
    <Chip
      label="Completed"
      sx={{
        color: theme.greenMain,
        backgroundColor: theme.success,
        height: '22px',
      }}
    />
  ) : status === 'started' ? (
    <Chip
      label="Completed"
      sx={{
        color: theme.greenMain,
        backgroundColor: theme.success,
        height: '22px',
      }}
    />
  ) : status === 'cancelled' ? (
    <Chip
      label="Cancelled"
      sx={{
        color: theme.errorMain,
        backgroundColor: theme.errorBackground,
        height: '22px',
      }}
    />
  ) : status === Pending ? (
    <Chip
      label="Pending"
      sx={{
        color: theme.mediumDarkGrey,
        backgroundColor: theme.cultured,
        height: '22px',
      }}
    />
  ) : (
    <Chip
      label="On Way"
      sx={{
        color: theme.warningMain,
        backgroundColor: theme.warningBackground,
        height: '22px',
      }}
    />
  );
};
