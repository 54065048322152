import * as React from 'react';
import { Chip } from '@mui/material';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';

interface Props {
  status: string | undefined;
  text: string;
}

export const statusColor: {
  [key: string]: { color: string; background: string };
} = {
  info: {
    color: '#FBC02D',
    background: '#FBC02D1a',
  },
  warning: {
    color: '#FF6F00',
    background: '#FF6F001a',
  },
  pending: {
    color: '#FF6F00',
    background: '#FF6F001a',
  },
  success: {
    color: '#04BC85',
    background: '#04BC851a',
  },
  error: {
    color: '#D32F2F',
    background: '#D32F2F1a',
  },
  initiated: {
    color: '#757573',
    background: '#7575731a',
  },
  default: {
    color: '#757573',
    background: '#7575731a',
  },
  blue: {
    color: '#01579B',
    background: '#E1F5FE',
  },
};

export const StatusChip: React.FC<Props> = ({ text, status }) => {
  const theme = useSelector(selectTheme);

  if (!text) {
    return null;
  }

  return (
    <StatusWrapper theme={theme}>
      <Chip
        label={text}
        className="status"
        sx={{
          color:
            statusColor[status || 'default']?.color ||
            statusColor['default']?.color,
          backgroundColor:
            statusColor[status || 'default']?.background ||
            statusColor['default']?.background,
        }}
      />
    </StatusWrapper>
  );
};

const StatusStyle = css`
  align-items: center;
  // max-width: fit-content;
  height: 22px;
`;

const StatusWrapper = styled.div<{ theme: any }>`
  align-self: center;
  font-family: Roboto;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 1.0125rem */
  letter-spacing: 0.025rem;
  .status {
    ${StatusStyle}
  }
`;
