export const getStopType = (stop: any) => {
  return stop?._order?.type || stop?.type;
};

export const isOrderDelivery = (stop: any) => {
  return (
    getStopType(stop) === 'delivery' ||
    getStopType(stop) === 'collection' ||
    getStopType(stop) === 'express' ||
    getStopType(stop) === 'repair'
  );
};

export const getRoundtripStatus = (stop: any) => {
  if (isOrderDelivery(stop)) {
    return stop?._order.status;
  }
  return stop?.status;
};

export const convertStatusToDb = (status: string) => {
  if (status === 'readyForDelivery') {
    return 'ready-for-delivery';
  } else if (status === 'onTheRoad') {
    return 'on-the-road';
  }

  return status;
};

export const convertToRunningStatus = (status: string) => {
  if (status === 'on-the-road') {
    return 'ready-for-delivery';
  } else if (status === 'parked') {
    return 'delivered';
  }

  return status;
};

export const isCompletedStop = (stop: any) => {
  return (
    getRoundtripStatus(stop) === 'delivered' ||
    getRoundtripStatus(stop) === 'canceled' ||
    getRoundtripStatus(stop) === 'completed' ||
    getRoundtripStatus(stop) === 'postponed'
  );
};
