import { Stack, Typography } from '@mui/material';
import React, { useMemo, useState, useTransition } from 'react';
import { Details } from './components/Details';
import { useTranslation } from 'react-i18next';
import { Departments } from './components/Departments';
import { Permission, usePermission } from 'hooks/Abilities/usePermission';
import If from 'app/components/If';
import { TabsMenu } from '../../../components/TabsMenu';
import Warehouses from './components/Warehouses';
import { useTabs, TabType } from 'app/components/TabsMenu/hooks/useTabs';
import styled from 'styled-components';

export function CompanyProfile() {
  const { t } = useTranslation();
  const [, startTransition] = useTransition();
  const can = usePermission('companysettings');
  const canedit = can(Permission.EDIT);
  const [currentTabIndex, setCurrentTabIndex] = useState(canedit ? '0' : '1');

  const handleTabChange = (_e, tabIndex) => {
    startTransition(() => {
      setCurrentTabIndex(tabIndex);
    });
  };
  const tabsArray: TabType[] = useMemo(() => {
    return [
      {
        label: <p>{t('settings.companyProfile.tab.details')}</p>,
        value: '0',
      },

      {
        label: <p>{t('settings.companyProfile.tab.warehouses')}</p>,
        value: '1',
      },
      {
        label: <p>{t('settings.companyProfile.tab.departments')}</p>,
        value: '2',
      },

      // {
      //   label: <p>{t('settings.companyProfile.tab.companyStructure')}</p>,
      //   value: '3',
      // },
    ];
  }, [t]);

  const [tabs, setTabs] = useTabs({
    defaultTabs: tabsArray,
    moduleName: 'companyProfile',
  });

  if (!can(Permission.VIEW)) return null;

  return (
    <>
      <ContentWrapper>
        <Stack direction="row" gap={10}>
          <Stack justifyContent="center">
            <Typography fontSize="1rem" fontWeight="500" alignItems="center">
              {t('settings.companyProfile.title')}
            </Typography>
          </Stack>
          <Stack height="40px">
            <TabsMenu
              css={{ width: '100%' }}
              defaultTabs={tabsArray}
              tabs={tabs}
              setTabs={setTabs}
              selectedTab={currentTabIndex}
              handleOnSwitchTab={handleTabChange}
            />
          </Stack>
        </Stack>
        <div id="toolbar" />
      </ContentWrapper>
      <If
        condition={canedit}
        otherwise={
          <>
            {currentTabIndex === '1' && <Warehouses />}
            {currentTabIndex === '2' && <Departments />}
          </>
        }
      >
        {currentTabIndex === '0' && <Details />}
        {currentTabIndex === '1' && <Warehouses />}
        {currentTabIndex === '2' && <Departments />}
      </If>
    </>
  );
}
const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 20px;
  height: 40px;
`;
