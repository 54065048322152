import { themes } from 'styles/theme/themes';

interface StatusColors {
  backgroundColor: string;
  color: string;
}

interface Statuses {
  [status: string]: StatusColors;
}

export const statuses: Statuses = {
  canceled: {
    backgroundColor: themes?.default?.errorBackground,
    color: themes?.default?.errorMain,
  },
  completed: {
    backgroundColor: themes?.default?.lightGreen55,
    color: themes?.default?.lightGreen600,
  },
  'ready-for-delivery': {
    backgroundColor: themes?.default?.lightGreen55,
    color: themes?.default?.lightGreen600,
  },
  delivered: {
    backgroundColor: themes?.default?.success,
    color: themes?.default?.greenMain,
  },
  'on-dock': {
    backgroundColor: themes?.default?.success,
    color: themes?.default?.greenMain,
  },
  'pending-review': {
    backgroundColor: themes?.default?.bgPending,
    color: themes?.default?.textPending,
  },
  unloaded: {
    backgroundColor: themes?.default?.indigo50,
    color: themes?.default?.unloadedStatusColor,
  },
  blocked: {
    backgroundColor: themes?.default?.linen,
    color: themes?.default?.brown,
  },
  parked: {
    backgroundColor: themes?.default?.linen,
    color: themes?.default?.brown,
  },
  'on-the-road': {
    backgroundColor: themes?.default?.orange50,
    color: themes?.default?.darkOrange,
  },
  loaded: {
    backgroundColor: themes?.default?.lightblue900,
    color: themes?.default?.lightblue50,
  },
  validated: {
    backgroundColor: themes?.default?.lightblue900,
    color: themes?.default?.lightblue50,
  },
  prepared: {
    backgroundColor: themes?.default?.purple50,
    color: themes?.default?.purple,
  },
  planned: {
    backgroundColor: themes?.default?.pink50,
    color: themes?.default?.plannedStatusColor,
  },
  confirmed: {
    backgroundColor: themes?.default?.teal50,
    color: themes?.default?.teal800,
  },
  'pending-updates': {
    backgroundColor: themes?.default?.yellow100,
    color: themes?.default?.textPendingCustomer,
  },
  initiated: {
    backgroundColor: themes?.default?.whisper,
    color: themes?.default?.textPrimary,
  },
  paused: {
    backgroundColor: themes?.default?.whisper,
    color: themes?.default?.textPrimary,
  },
  'on-way': {
    backgroundColor: themes?.default?.purple50,
    color: themes?.default?.purple,
  },
};
