export function formatNumber(num: number) {
  if (num >= 1000) {
    const inThousands = num / 1000;
    return `${inThousands.toFixed(1)}K`;
  }
  return num?.toString();
}

export function scrollToBeginning(ref) {
  return ref?.current?.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}
