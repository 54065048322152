import { Chip, Radio, RadioProps, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { useRadioGroup } from '@mui/material/RadioGroup';

interface Props extends RadioProps {
  label: string;
}

export const ChipRadio = ({
  value,
  label,
  disabled,
  checked,
  onChange,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const theme = useSelector(selectTheme);
  const radioGroup = useRadioGroup();

  const isChecked = radioGroup ? radioGroup.value === value : checked;

  const handleChipClick = (event: React.MouseEvent) => {
    if (
      event.target instanceof HTMLInputElement &&
      event.target.type === 'radio'
    ) {
      return;
    }

    const syntheticEvent = {
      target: {
        value,
        checked: !isChecked,
        name: props.name,
      },
      stopPropagation: () => {},
      preventDefault: () => {},
    } as React.ChangeEvent<HTMLInputElement>;

    if (radioGroup && radioGroup.onChange) {
      radioGroup.onChange(syntheticEvent, value as string);
    }
    onChange?.(syntheticEvent, !isChecked);
  };

  return (
    <Chip
      onClick={!disabled ? handleChipClick : undefined}
      label={
        <Stack direction="row" alignItems="center" gap="5px">
          <Radio
            {...props}
            value={value}
            sx={{
              ...props.sx,
              p: 0,
              color: isChecked
                ? theme.primaryActiveColor
                : theme.chipsBorderGrey,
              '&.Mui-checked': {
                color: isChecked
                  ? theme.primaryActiveColor
                  : theme.chipsBorderGrey,
              },
            }}
            disabled={disabled}
            size="small"
            checked={isChecked}
            onChange={(e) => {
              if (radioGroup && radioGroup.onChange) {
                radioGroup.onChange(e, value as string);
              }
              onChange?.(e, e.target.checked);
            }}
          />
          <Typography fontWeight={isChecked ? '500' : '400'} fontSize="1rem">
            {t(label)}
          </Typography>
        </Stack>
      }
      variant="outlined"
      sx={{
        backgroundColor: isChecked ? theme.primaryLight1 : 'white',
        borderColor: isChecked ? theme.strokePrimary : theme.chipsBorderGrey,
        color: isChecked ? theme.primaryActiveColor : theme.textBlack,
        cursor: disabled ? 'default' : 'pointer',
        '&:hover': {
          backgroundColor: isChecked ? theme.primaryLight1 : 'white',
        },
      }}
      size="medium"
    />
  );
};
