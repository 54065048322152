import {
  Driver,
  Sales,
} from 'app/components/RoundTrips/RoundTripData/statuses';
import { Data } from 'interfaces/roundTrips';
export interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
  disableSort?: boolean;
  disableRow?: boolean;
  canChangeView?: boolean;
  size: number;
  isAccordion: boolean;
  minWidth?: number;
  key?: string;
  filter?: boolean;
  sortField?: any;
  maxWidth?: any;
  isSticky?: boolean;
  stickyLeftPosition?: number;
  stickyRightPosition?: number;
  hidden?: boolean;
  marginLeft?: string;
}
export const excelHeadCellsConst: HeadCell[] = [
  // const headCells: readonly HeadCell[] = [
  {
    id: 'key_point',
    sortField: 'code',
    numeric: false,
    disablePadding: false,
    label: 'info.roundtrip',
    disableRow: false,
    canChangeView: false,
    size: 1,
    isAccordion: false,
    disableSort: false,
    minWidth: 140,
    maxWidth: 140,
    filter: true,
    key: 'key',
  },
  {
    id: 'vehicle',
    sortField: 'cache._vehicle.numberPlate',
    numeric: true,
    disablePadding: false,
    label: 'common.vehicle',
    disableRow: false,
    canChangeView: true,
    size: 2,
    isAccordion: false,
    disableSort: false,
    minWidth: 240,
    maxWidth: 240,
    filter: true,
    key: 'vehicle',
  },
  {
    id: Sales,
    sortField: 'cache.salesUser.fullName',

    numeric: true,
    disablePadding: false,
    label: 'info.sales',
    disableRow: true,
    canChangeView: false,
    size: 2,
    isAccordion: false,
    disableSort: false,
    minWidth: 240,
    maxWidth: 240,
    filter: true,
    key: Sales,
  },
  {
    id: Driver,
    sortField: 'cache.driverUser.fullName',

    numeric: true,
    disablePadding: false,
    label: 'info.driver',
    disableRow: false,
    canChangeView: true,
    size: 2,
    isAccordion: false,
    disableSort: false,
    minWidth: 240,
    maxWidth: 240,
    filter: true,
    key: Driver,
  },
  {
    id: 'ripper',
    sortField: 'cache.ripperUser.fullName',
    numeric: true,
    disablePadding: false,
    label: 'ripper',
    disableRow: true,
    canChangeView: true,
    size: 2,
    isAccordion: false,
    disableSort: false,
    minWidth: 240,
    maxWidth: 240,
    filter: true,
    key: 'ripper',
  },
  {
    id: 'stops_nbs',
    sortField: 'cache.stats.stops',
    numeric: true,
    disablePadding: false,
    label: 'common.stops',
    disableRow: false,
    canChangeView: true,
    size: 1,
    isAccordion: true,
    disableSort: false,
    minWidth: 205,
    maxWidth: 205,
    filter: true,
    key: 'Stops',
  },
  {
    id: 'weight',
    sortField: 'weight',
    numeric: true,
    disablePadding: false,
    label: 'roundtrips.tooltip.weight',
    disableRow: false,
    canChangeView: true,
    size: 1.2,
    isAccordion: false,
    disableSort: true,
    minWidth: 150,
    maxWidth: 150,
    filter: true,
    key: 'weight',
  },
  {
    id: 'volume',
    sortField: 'volume',
    numeric: true,
    disablePadding: false,
    label: 'roundtrips.tooltip.volume',
    disableRow: false,
    canChangeView: true,
    size: 1.2,
    isAccordion: false,
    disableSort: true,
    minWidth: 150,
    maxWidth: 150,
    filter: true,
    key: 'volume',
  },
  {
    id: 'distance',
    sortField: 'distance',
    numeric: true,
    disablePadding: false,
    label: 'roundtrips.tooltip.distance',
    disableRow: false,
    canChangeView: false,
    size: 1,
    isAccordion: false,
    disableSort: true,
    minWidth: 150,
    maxWidth: 150,
    filter: true,
    key: 'distance',
  },
  {
    id: 'time',
    sortField: 'time',
    numeric: true,
    disablePadding: false,
    label: 'roundtrips.tooltip.time',
    disableRow: false,
    canChangeView: false,
    size: 1,
    isAccordion: false,
    disableSort: true,
    minWidth: 150,
    maxWidth: 150,
    filter: true,
    key: 'time',
  },
  {
    id: 'support_units',
    sortField: 'support_units',
    numeric: true,
    disablePadding: false,
    label: 'roundtrip.support_units',
    disableRow: false,
    canChangeView: false,
    size: 1,
    isAccordion: false,
    disableSort: true,
    minWidth: 130,
    maxWidth: 130,
    filter: true,
    key: 'Support',
    isSticky: true,
    stickyRightPosition: 180,
    marginLeft: 'auto',
  },
  {
    id: 'status',
    sortField: 'status',
    numeric: true,
    disablePadding: false,
    label: 'common.status',
    disableRow: false,
    canChangeView: false,
    size: 2,
    isAccordion: false,
    disableSort: false,
    minWidth: 120,
    maxWidth: 120,
    filter: true,
    key: 'status',
    isSticky: true,
    stickyRightPosition: 60,
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: '',
    disableRow: false,
    canChangeView: false,
    size: 1,
    isAccordion: false,
    disableSort: true,
    minWidth: 60,
    maxWidth: 60,
    filter: true,
    key: 'actions',
    isSticky: true,
    stickyRightPosition: 0,
  },
];
