import React, { useCallback, useState } from 'react';
import { Menu } from '../../../../components/CustomMenu';
import { Button } from '@mui/material';
import { Plus } from '@phosphor-icons/react';
import { useSelector } from 'react-redux';
import {
  selectDecks,
  selectInactiveDecks,
} from '../../../../../common/store/organization/selectors';
import { MenuOption } from '../../../../components/CustomMenu/types';
import { useManagedContext } from '../../../../../common/UtilityComponents/ManagedContext/useManagedContext';
import { useTranslation } from 'react-i18next';

function AddMapping() {
  const [menuAnchor, setMenuAcnhor] = useState<null | HTMLElement>(null);
  const roundtripMappingsContext = useManagedContext(
    'RoundtripMappingsContext',
  );
  const open = Boolean(menuAnchor);

  const openMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setMenuAcnhor(event.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setMenuAcnhor(null);
  }, []);
  const warehouses = useSelector(selectDecks);
  const inactiveDecks = useSelector(selectInactiveDecks);
  const { t } = useTranslation();
  const existingWarehousesMappings =
    roundtripMappingsContext.roundtripMappings.map((w) => w.id);
  const menuOptions: MenuOption[] = warehouses
    .concat(inactiveDecks)
    .filter((w) => !existingWarehousesMappings.includes(w._id))
    .map((w) => ({
      label: w.name as string,
      action: () => {
        roundtripMappingsContext.updateDataWithFunction((prev) => {
          prev.roundtripMappings.push({
            id: w._id as string,
            name: w.name as string,
            nbOfRoundtrips: 0,
            status: w.status as string,
            roundtrips: [],
          });
        });
      },
    }));
  return (
    <>
      <Menu
        options={menuOptions}
        open={open}
        anchorEl={menuAnchor}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      />
      <Button
        variant="text"
        color="primary"
        disableRipple
        sx={{
          width: 'fit-content',
          height: '30px',
          py: 0,
          px: 1.3,
          gap: 1.3,
          borderRadius: '4px',
          marginTop: '20px',
        }}
        onClick={(e) => openMenu(e)}
      >
        <Plus />
        {t('add_warehouse')}
      </Button>
    </>
  );
}

export default AddMapping;
