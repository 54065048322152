import { Stack, Tooltip } from '@mui/material';
import { AssigneeType } from 'app/pages/Accessories/Types';
import styled from 'styled-components';
import { Icon } from 'app/components/Icon';
import { EmailOutlined, PhoneOutlined } from '@mui/icons-material';
import { A } from 'app/components/A';
import If from 'app/components/If';
import EllipsisText from 'app/components/EllipsisText';
import { themes } from 'styles/theme/themes';
import { SalesmanIcon } from '../SalesmanIcon';

export const SalesmanPopup = ({ _salesman }) => {
  const getSalesmanLabel = (_salesman) => {
    return _salesman?.fullNameGridFormat || _salesman?.fullName;
  };

  return (
    <Tooltip
      title={
        <Wrapper>
          <Stack direction="row" alignItems="baseline" width="100%">
            <SalesmanIcon _salesman={_salesman} />
            <Stack
              gap="16px"
              padding="12px"
              width="100%"
              className="assignee-info"
            >
              <Text>{_salesman?.fullName}</Text>
              <Text>
                <Icon icon={<EmailOutlined />} />
                <If condition={!!_salesman?.companyEmail} otherwise="-">
                  <A
                    role="button"
                    className="link email"
                    href={`mailto:${_salesman?.companyEmail}`}
                  >
                    {_salesman?.companyEmail}
                  </A>
                </If>
              </Text>
              <Text>
                <Icon icon={<PhoneOutlined />} />
                <If condition={!!_salesman?.currentAgent?.phone} otherwise="-">
                  <A
                    role="button"
                    className="link"
                    href={`tel:${_salesman?.currentAgent?.phone}`}
                  >
                    {_salesman?.currentAgent?.phone}
                  </A>
                </If>
              </Text>
              {/* <Text>
                  <Icon icon={<LocationSearchingOutlined />} />
                  <If
                    condition={
                      !!_salesman?.agents?.[0]?.orderValidation?.[0].position
                        .geometry
                    }
                    otherwise={'-'}
                  >
                    <A
                      role="button"
                      className="link address"
                      onClick={() => {
                        setAnchorEL(null);
                        handleOpenMap(
                          _salesman?.agents?.[0]?.orderValidation?.[0].position
                            .geometry.coordinates,
                        );
                      }}
                    >
                      {getAddressLabel(
                        _salesman?.agents?.[0]?.orderValidation?.[0].position
                          .geometry,
                      )}
                    </A>
                  </If>
                </Text> */}
            </Stack>
          </Stack>
        </Wrapper>
      }
      slotProps={{
        tooltip: {
          sx: {
            backgroundColor: 'white',
            boxShadow: themes.default.muiBoxShadow,
          },
        },
      }}
    >
      <Stack direction="row" gap="8px" alignItems="center">
        <SalesmanIcon _salesman={_salesman} />
        <A role="button" className="link">
          <EllipsisText width={150} text={getSalesmanLabel(_salesman)} />
        </A>
      </Stack>
    </Tooltip>
  );
};

interface WrapperProps {
  type?: AssigneeType;
}

const Wrapper = styled.div<WrapperProps>`
  padding: 8px;
  width: 250px;
  .assignee-info div {
    justify-content: ${(props) =>
      props.type === AssigneeType?.vehicle ? 'space-between' : ''};
  }
`;
const Text = styled.div`
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  text-transform: capitalize;
  line-height: 9px;
  letter-spacing: 0.17px;
  color: black;

  .address,
  .email {
    line-height: normal;
    max-width: 145px;
  }

  .number-plate {
    font-weight: 700;
  }

  & svg {
    color: ${(props) => props.theme?.textColorSecondary};
  }
`;
