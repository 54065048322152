import { TableCell } from '@mui/material';
import React from 'react';
import { Accordion, AccordionSummary } from '../styles/accordion';
import { StyledTableRow } from 'app/components/TableComponent/helpers/excel';
import {
  AccordionSummaryContent,
  StopTitle,
} from 'app/components/TableComponent/styles';
import {
  AccordionSummaryContentWrapper,
  AccordionSummaryLabel,
  NumberOfStopsWrapper,
} from 'app/components/TableComponent/helpers/styles';
import { themes } from 'styles/theme/themes';

export const ReOptimizeFunction = ({
  rows,
  headCells,
  stopsAccordion,
  setStopsAccordion,
  theme,
  selectedRoundTrip,
}) => {
  const tablesData: JSX.Element[] = [];
  if (rows.length) rows = rows[selectedRoundTrip].roundtrip.option;
  for (let i = 0; i < rows.length; i++) {
    tablesData.push(
      headCells.map((headCell, headCellIndex) => {
        let sticky = {};
        if (headCell.sticky) {
          sticky = {
            backgroundColor: themes?.default?.accordionWhiteBg,
            position: 'sticky',
            top: 37.8 + 38.8 * headCellIndex,
            zIndex: 2,
          };
        }
        if (headCell.isAccordion) {
          const dummyArray: number[] = [];
          for (let i = 0; i < rows[0]?.accordion.length; i++) {
            dummyArray.push(0);
          }
          return (
            <React.Fragment key={headCell.id}>
              <Accordion expanded={stopsAccordion} style={{ ...sticky }}>
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                  expandIcon={null}
                  sx={{ height: 38, minHeight: 'unset', padding: 'unset' }}
                >
                  <AccordionSummaryContentWrapper>
                    <AccordionSummaryContent
                      style={{
                        borderBottom: `0.063rem solid ${theme.tableBorder}`,
                      }}
                    >
                      <AccordionSummaryLabel className="number-stops-label">
                        <NumberOfStopsWrapper
                          onClick={(event) => {
                            event.stopPropagation();
                            setStopsAccordion((oldState) => {
                              return !oldState;
                            });
                          }}
                        >
                          <span>Number of Stops</span>
                        </NumberOfStopsWrapper>
                      </AccordionSummaryLabel>
                      {rows.map((row, parentMapIndex) => {
                        let cellWidth: {};
                        if (!parentMapIndex) {
                          cellWidth = {
                            minWidth: 250,
                          };
                        } else {
                          cellWidth = {
                            minWidth: 250,
                          };
                        }

                        return (
                          <TableCell
                            key={parentMapIndex}
                            align="left"
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              cursor: 'pointer',
                              borderBottom: 'unset',
                              height: '38px',
                              borderRight:
                                parentMapIndex !== rows.length - 1
                                  ? `0.063rem solid ${theme.primaryLightO}`
                                  : 'unset',
                            }}
                            style={{
                              flex: 1,
                              overflow: 'hidden',
                              ...cellWidth,
                            }}
                          >
                            {row.data[headCell.id]?.value}
                          </TableCell>
                        );
                      })}
                    </AccordionSummaryContent>
                  </AccordionSummaryContentWrapper>
                </AccordionSummary>
              </Accordion>
              {dummyArray.map((_, parentMapIndex) => {
                return (
                  <div
                    key={parentMapIndex}
                    style={{
                      display: 'flex',
                    }}
                  >
                    <StopTitle
                      style={{ position: 'sticky', left: 0, zIndex: 1 }}
                    >
                      Stop{parentMapIndex + 1}
                    </StopTitle>
                    {rows.map((row, parentMapIndex1) => {
                      return (
                        <div
                          key={parentMapIndex1}
                          style={{
                            display: 'flex',
                            flex: 1,
                          }}
                        >
                          <div
                            key={row.data.id.value}
                            style={{
                              flex: 1,
                              opacity:
                                row.accordion[parentMapIndex].status ===
                                'delivered'
                                  ? 0.5
                                  : 1,
                            }}
                          >
                            {row.accordion[parentMapIndex].data}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </React.Fragment>
          );
        }

        return (
          <React.Fragment key={headCell.id}>
            <StyledTableRow
              style={{
                borderBottom: `0.063rem solid ${theme.tableBorder}`,
                ...sticky,
              }}
              hover
              sx={{ display: 'flex' }}
            >
              <TableCell
                align="left"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  borderBottom: 'unset',
                  borderRight: `0.063rem solid ${theme.primaryLightO}`,
                  flex: 1,
                  overflow: 'hidden',
                  minWidth: 180,
                  maxWidth: 180,
                  fontWeight: 700,
                  height: '38px',
                }}
              >
                {headCell.label}
              </TableCell>
              {rows.map((row, index) => {
                return (
                  <TableCell
                    key={index}
                    align="left"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      borderBottom: 'unset',
                      borderRight:
                        index !== rows.length - 1
                          ? `0.063rem solid ${theme.primaryLightO}`
                          : 'unset',
                    }}
                    style={{
                      flex: 1,
                      overflow: 'hidden',
                      minWidth: 250,
                    }}
                  >
                    {row.data[headCell.id]?.value}
                  </TableCell>
                );
              })}
            </StyledTableRow>
          </React.Fragment>
        );
      }),
    );
  }
  return tablesData;
};
