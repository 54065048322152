import { useContext, useMemo } from 'react';
import { contextRegistry } from './ContextRegistry';

export function useManagedContext<T = any>(contextName: string) {
  const contextEntry = useMemo(() => {
    const entry = contextRegistry.get(contextName);
    if (!entry) {
      throw new Error(`Context with name '${contextName}' not found.`);
    }
    return entry;
  }, [contextName]);

  const contextValue = useContext(contextEntry);
  if (!contextValue) {
    throw new Error(
      `Context value for '${contextName}' is undefined. Ensure the context is provided.`,
    );
  }

  return contextValue as T & {
    data: T;
    updateData: (key: string, value: unknown) => void;
    updateDataWithFunction: (func: (draft: T) => void) => void;
  };
}
