import {
  Button,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { usePatchWarehouseMutation } from 'common/services/organizationApi';
import { useForm } from 'react-hook-form';
import { FieldComponent } from 'app/components/FieldComponent';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import { LocationInput } from '../../../../../../components/LocationInput';
import { TwoViewModal } from 'app/components/TwoViewModal';
import { IWarehousesContext } from '../index';
import { useManagedContext } from '../../../../../../../common/UtilityComponents/ManagedContext/useManagedContext';
import { IWarehouseLocation } from '../../../../../../../common/types/Warehouses';

export function WarehouseDrawer() {
  const warehousesContext =
    useManagedContext<IWarehousesContext>('warehousesContext');
  const { dayjs } = window;
  const { t } = useTranslation();
  const [patchWarehouse] = usePatchWarehouseMutation();
  const [isSwitchOn, setSwitchOn] = useState<boolean>(false);

  useEffect(() => {
    setSwitchOn(warehousesContext.selectedWarehouse?.status === 'active');
  }, [warehousesContext.selectedWarehouse]);

  const [location, setLocation] = useState<IWarehouseLocation | undefined>(
    warehousesContext.selectedWarehouse?.location,
  );
  const [openingTime, setOpeningTime] = useState<Dayjs | null>(
    warehousesContext.selectedWarehouse?.operatingHours?.openingTime
      ? dayjs(warehousesContext.selectedWarehouse.operatingHours.openingTime)
      : dayjs().startOf('day'),
  );
  const [closingTime, setClosingTime] = useState<Dayjs | null>(
    warehousesContext.selectedWarehouse?.operatingHours?.closingTime
      ? dayjs(warehousesContext.selectedWarehouse.operatingHours.closingTime)
      : dayjs().startOf('day'),
  );

  const methods = useForm({
    defaultValues: {
      name: warehousesContext.selectedWarehouse?.name,
      capacity: warehousesContext.selectedWarehouse?.capacity,
      manager: warehousesContext.selectedWarehouse?.manager,
      phone: warehousesContext.selectedWarehouse?.phone,
    },
  });
  const { control, watch } = methods;

  const handleSwitchChange = () => {
    setSwitchOn((prevState) => !prevState);
  };

  const name = watch('name');
  const capacity = watch('capacity');
  const manager = watch('manager');
  const contact = watch('phone');

  async function handlePatchWarehouse() {
    const newState = isSwitchOn ? 'active' : 'inactive';
    const formatClosingTime = closingTime?.format('hh:mm');
    const formatOpeningTime = openingTime?.format('hh:mm');

    try {
      await patchWarehouse({
        deckId: warehousesContext.selectedWarehouse?._id,
        name: name,
        location,
        capacity: capacity,
        manager: manager,
        phone: contact,
        status: newState,
        openingHours: [
          {
            openingTime: formatOpeningTime,
            closingTime: formatClosingTime,
          },
        ],
      });
      warehousesContext.updateDataWithFunction((prev) => {
        prev.openDrawer = false;
        prev.selectedWarehouse = null;
        prev.editMode = false;
      });
    } catch (e) {
      console.log('error on update warehousesContext.selectedWarehouse' + e);
    }
  }

  return (
    <TwoViewModal
      onClose={() => {
        warehousesContext.updateDataWithFunction((prev) => {
          prev.openDrawer = false;
          prev.selectedWarehouse = null;
          prev.editMode = false;
        });
      }}
      onOpen={warehousesContext.openDrawer}
      title={
        warehousesContext.selectedWarehouse
          ? warehousesContext.canEdit
            ? t('edit_warehouse')
            : t('view_warehouse')
          : t('add_warehouse')
      }
    >
      <Wrapper>
        <TextFieldWrapper>
          <FieldComponent
            label="Warehouse Name"
            size="small"
            name="name"
            control={control}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              width: '100%',
            }}
            defaultValue={
              warehousesContext.selectedWarehouse
                ? warehousesContext.selectedWarehouse.name
                : ''
            }
            disabled={!warehousesContext.canEdit}
          />

          <LocationInput
            label={t('common.address')}
            defaultValue={warehousesContext.selectedWarehouse?.location}
            size="small"
            onSelect={(loc) => {
              setLocation(loc);
            }}
            disabled={!warehousesContext.canEdit}
          />

          <FieldComponent
            label="Capacity"
            size="small"
            id="filled-number"
            type="number"
            name="capacity"
            control={control}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              width: '100%',
            }}
            InputProps={{
              endAdornment: (
                <Typography
                  component="span"
                  variant="body2"
                  sx={{
                    color: 'rgba(0, 0, 0, 0.78)',
                  }}
                >
                  m<sup>3</sup>
                </Typography>
              ),
            }}
            defaultValue={
              warehousesContext.selectedWarehouse
                ? warehousesContext.selectedWarehouse.capacity
                : ''
            }
            disabled={!warehousesContext.canEdit}
          />
          <FieldComponent
            label="Manager"
            size="small"
            name="manager"
            control={control}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              width: '100%',
            }}
            defaultValue={
              warehousesContext.selectedWarehouse
                ? warehousesContext.selectedWarehouse.manager
                : ''
            }
            disabled={!warehousesContext.canEdit}
          />
          <FieldComponent
            label="Contact"
            size="small"
            name="contact"
            control={control}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              width: '100%',
            }}
            defaultValue={
              warehousesContext.selectedWarehouse
                ? warehousesContext.selectedWarehouse.phone
                : ''
            }
            disabled={!warehousesContext.canEdit}
          />
        </TextFieldWrapper>
        <div
          className="TextFieldLine"
          style={{
            display: 'flex',
            margin: '0px 20px 0px 20px',
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              label="Opening Time"
              value={openingTime}
              onChange={(newValue) => setOpeningTime(newValue)}
              renderInput={(props) => (
                <TextField
                  {...props}
                  sx={{
                    width: '50%',
                    mr: '19px',
                    height: '37px',
                  }}
                  defaultValue={openingTime}
                  size="small"
                />
              )}
              disabled={!warehousesContext.canEdit}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              label="Closing Time"
              value={closingTime}
              onChange={(newValue) => setClosingTime(newValue)}
              inputFormat="HH:mm"
              renderInput={(props) => (
                <TextField
                  {...props}
                  sx={{
                    width: '50%',
                    height: '37px',
                  }}
                  defaultValue={closingTime}
                  size="small"
                />
              )}
              disabled={!warehousesContext.canEdit}
            />
          </LocalizationProvider>
        </div>
        <FormGroup
          sx={{
            ml: '24px',
          }}
        >
          {warehousesContext.selectedWarehouse ? (
            <Typography component="div">
              <Switch
                disabled={!warehousesContext.canEdit}
                checked={isSwitchOn}
                onChange={handleSwitchChange}
              />
              {isSwitchOn ? 'Active' : 'Non active'}
            </Typography>
          ) : (
            <FormControlLabel
              control={
                <Switch disabled={!warehousesContext.canEdit} checked={false} />
              }
              label="Non active"
            />
          )}
        </FormGroup>
      </Wrapper>

      <FooterWrapper>
        <Button
          variant="outlined"
          sx={{ mr: '12px' }}
          onClick={() => {
            warehousesContext.updateDataWithFunction((prev) => {
              prev.openDrawer = false;
              prev.selectedWarehouse = null;
              prev.editMode = false;
            });
          }}
        >
          {t('common.buttons.cancel')}
        </Button>
        {warehousesContext.selectedWarehouse ? (
          <Button
            variant="contained"
            sx={{ mr: '20px' }}
            onClick={handlePatchWarehouse}
            disabled={!warehousesContext.canEdit}
          >
            {t('common.buttons.save')}
          </Button>
        ) : (
          <Button
            variant="contained"
            sx={{ mr: '20px' }}
            disabled={!warehousesContext.canEdit}
          >
            {t('common.add')}
          </Button>
        )}
      </FooterWrapper>
    </TwoViewModal>
  );
}

const TextFieldWrapper = styled.div`
  margin: 40px 20px 0 20px;
`;

const Wrapper = styled.div`
  margin: 0 15px;
  overflow-y: hidden;
  max-height: 400px;
`;

const FooterWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 0 24px;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  background: #fff;
`;

// const ButtonWrapper = styled.div`
//   margin-left: auto;
// `;
