import { capitalize, isUndefined } from 'lodash';
import { useTranslation } from 'react-i18next';

export const useErrorFormatter = () => {
  const { t } = useTranslation();

  function formatError(error, fieldName = 'email') {
    if (!error) {
      return;
    }
    const field = capitalize(t(`common.${error.ref?.name}`));
    const requiredMessage = !isUndefined(error.ref?.name)
      ? t('rules.required.field', { field })
      : error.message;
    // TODO: add more cases and tranlations / improve this to be more flexible
    switch (error.type) {
      case 'required':
        return requiredMessage;
      case 'validate':
        // assuming it's only for emails now
        return t('field_already_exists', { field: fieldName });
      case 'pattern':
        return error.message;
      default:
        return requiredMessage;
    }
  }

  return { formatError };
};
