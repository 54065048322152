import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  Button,
  IconButton,
  InputAdornment,
  Popover,
  TextField,
} from '@mui/material';
import Box from '@mui/material/Box';
import { useEffect, useRef, useState } from 'react';
import { selectTheme } from 'styles/theme/slice/selectors';
import { useSelector } from 'react-redux';
import { ItemOrder } from '../ItemOrder';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  addObjectsToStops,
  handleAccordionToggleHover,
  removeItemsFromOrder,
} from './function';
import { Sorting } from './components/sorting';
import { AddIconButton } from 'app/components/AddIconButton';
import SearchIcon from '@mui/icons-material/Search';
import {
  useDeleteOrderClusterMutation,
  useUpdateRoundTripMutation,
} from 'common/services/roundtripApi';
import AddDialog from './components/add_dialog';
import { LoadingPulse } from 'app/components/LoadingPulse';
import { StatusIndicator } from 'app/components/TableComponent/helpers/Components/StatusPopover';
import { useToaster } from 'hooks/useToaster';
import { handleCloseAssignRoundTrip } from 'functions/setAnchorFunction';
import { themes } from 'styles/theme/themes';
import { AddRoundtripWraper } from 'app/components/RoundTrips/styles/styles';
import OrderFilter from 'app/components/OrderFilter';
import If from 'app/components/If';
import DialogClearRoundtrip from '../DialogClearRoundtrip';
import { updateRoundtripFuction } from '../../functions';
import { RightBarNoData } from '../RightBarNoData';
import { AgentCategories } from 'app/components/RoundTrips/RoundTripData/agentCategories';
import {
  MagnifyingGlass,
  CaretUpDown,
  FunnelSimple,
  ArrowSquareRight,
  TrashSimple,
} from '@phosphor-icons/react';
import { cloneDeep } from 'lodash';
import { Icon } from 'app/components/Icon';
import { useAbility } from 'hooks/Abilities';

interface MyData {
  id: any;
  code: string;
  dateBegin: any;
  requestAddRoundTripData: any;
  orderCount: number;
  setOrderCount: any;
  accordionStatus: any;
  setAccordionStatus?: (e) => void;
  checked: any;
  setChecked: any;
  clusterId: any;
  isDragged: any;
  idDrag: any;
  onDisplayOrder: any;
  dateValue: any;
  warehouseData: any;
  dateEnd: any;
  requestOrderData: any;
  nextPageOrder: number;
  orderNext: boolean;
  orderInitCount: any;
  setSearch: any;
  search: any;
  setFilters: any;
  filters: any;
  filterCount: number;
  setDeletedIds: React.Dispatch<React.SetStateAction<string[]>>;
  setSorting: React.Dispatch<React.SetStateAction<any>>;
  sorting: string;
  setOrderInitCount?: React.Dispatch<React.SetStateAction<number>>;
  userContext: AgentCategories;
}
export const RightBar = ({
  orders,
  setOrders,
  roundTripCopy,
  requestAddRoundTripData,
  accordionStatus,
  setAccordionStatus,
  setDeletedIds,
  checked,
  setChecked,
  clusterId,
  isLoading,
  isFetching,
  onDisplayOrder,
  dateValue,
  requestOrderData,
  nextPageOrder,
  orderNext,
  orderInitCount,
  search,
  setSearch,
  setFilters,
  filterCount,
  sorting,
  setSorting,
  setOrderInitCount,
  disableDragAndDrop,
  userContext,
}) => {
  const ability = useAbility();
  const toast = useToaster();
  const { t } = useTranslation();
  const [searchOn, setSearchOn] = useState<boolean>(false);
  const [searchAddRoundtrip, setSearchAddRoundtrip] = useState<string>('');
  const [filteredRoundTripCopy, setFilteredRoundTripCopy] = useState<any[]>([]);
  const [sortingOn, setSortingOn] = useState<HTMLButtonElement | null>(null);
  const [filterOn, setFilterOn] = useState<HTMLButtonElement | null>(null);

  const [openBulkDelete, setOpenBulkDelete] = useState(false);
  const [open, setOpen] = useState(false);
  const [ordersCopy, setOrdersCopy] = useState<MyData[] | null>(null);

  const [addRoundTripOpen, setAddRoundTripOpen] =
    useState<HTMLButtonElement | null>(null);
  const [UpdateRoundtripData] = useUpdateRoundTripMutation();
  const [deleteStopRight] = useDeleteOrderClusterMutation();

  const theme = useSelector(selectTheme);

  useEffect(() => {
    const sortedRoundtripCopy = [...roundTripCopy];
    setFilteredRoundTripCopy(
      sortedRoundtripCopy?.sort((a, b) => a.code.localeCompare(b.code)),
    );
  }, [roundTripCopy]);

  useEffect(() => {
    if (searchAddRoundtrip !== '')
      setFilteredRoundTripCopy(
        roundTripCopy.filter((item) => {
          return (
            item.code &&
            item.code.toLowerCase().includes(searchAddRoundtrip.toLowerCase())
          )?.sort((a, b) => a.code.localeCompare(b.code));
        }),
      );
    else {
      const sortedRoundtripCopy = [...roundTripCopy];
      setFilteredRoundTripCopy(
        sortedRoundtripCopy?.sort((a, b) => a.code.localeCompare(b.code)),
      );
    }
  }, [searchAddRoundtrip]);

  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef.current]);

  const setTextInputRef = (element: HTMLInputElement) => {
    inputRef.current = element;
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };
  const handleSearchAddRoundtripChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchAddRoundtrip(event.target.value);
  };

  const [lastCheckedIndex, setLastCheckedIndex] = useState(0);

  const handleChange2 = (id, event) => {
    if (!ability.can('canedit', 'orders')) {
      return;
    }
    event?.stopPropagation();
    let newOrders = [...checked];
    if (!checked?.find((d) => d.id === id)) {
      // if clicked is not checked -> select
      if (event.ctrlKey) {
        newOrders.push(orders?.find((d) => d.id === id));
      } else if (event.shiftKey) {
        const clickedIndex = orders.indexOf(orders?.find((d) => d.id === id));
        if (lastCheckedIndex <= clickedIndex) {
          const shiftItems = orders?.slice(lastCheckedIndex, clickedIndex + 1);
          const shiftItemsIds = shiftItems.map((item) => item.id);

          const alreadySelected = newOrders.filter(
            (order) => !shiftItemsIds.includes(order.id),
          );

          newOrders = [...alreadySelected, ...shiftItems];
        } else {
          const shiftItems = orders?.slice(clickedIndex, lastCheckedIndex);
          const shiftItemsIds = shiftItems.map((item) => item.id);

          const alreadySelected = newOrders.filter(
            (order) => !shiftItemsIds.includes(order.id),
          );

          newOrders = [...alreadySelected, ...shiftItems];
        }
      } else {
        newOrders = [];
        newOrders.push(orders?.find((d) => d.id === id));
      }
    } else if (!event.ctrlKey) {
      newOrders = [];
    } else {
      newOrders = newOrders.filter((item) => item.id !== id);
    }

    setLastCheckedIndex(orders.indexOf(orders?.find((d) => d.id === id)));
    setChecked(newOrders);
  };

  // const [cities, setCities] = useState([]);

  // useEffect(() => {
  //   const cityOrderMap = {};

  //   orders?.forEach((order, index) => {
  //     const cityName = order._deck.location.city;

  //     if (cityOrderMap.hasOwnProperty(cityName)) {
  //       cityOrderMap[cityName].orders.push(order);
  //     } else {
  //       cityOrderMap[cityName] = { cityName: cityName, orders: [order], expanded: true };
  //     };
  //   });

  //   const citiesArray:any = Object.values(cityOrderMap);
  //   setCities(citiesArray);
  // }, [orders]);

  // const handleSectionCollapse = (cityName) => {
  //   setCities((prevCities:any) => {
  //     return prevCities.map(city => {
  //         if (city.cityName === cityName) {
  //             return {
  //                 ...city,
  //                 expanded: !city.expanded
  //             };
  //         }
  //         return city;
  //     });
  // });
  // };

  // useEffect(() => {
  //   let filteredOrders = orders.filter(order => {
  //     return order.code?.toLowerCase().includes(search.toLowerCase());
  //   });
  //   setCount(filteredOrders.length);
  // }, [search, orders]);

  useEffect(() => {
    setOrdersCopy(orders);
  }, [orders, roundTripCopy]);

  useEffect(() => {
    setSearchOn(false);
  }, [checked?.length]);

  useEffect(() => {
    if (search.length === 0) {
      setSearchOn(false);
    }
  }, [search]);

  useEffect(() => {
    const checkedOrders = checked.filter((co) =>
      ordersCopy?.some((o) => o.id === co.id),
    );
    setChecked(checkedOrders || []);
  }, [ordersCopy]);

  const openSort = Boolean(sortingOn);
  const openFilter = Boolean(filterOn);
  const openAddRoundtrip = Boolean(addRoundTripOpen);

  const id = openSort ? 'simple-popover' : undefined;
  const id2 = openFilter ? 'filter' : undefined;
  const id3 = openAddRoundtrip ? 'open-add-roundtrip' : undefined;

  const handleClose = () => {
    setSortingOn(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSortingOn(event.currentTarget);
  };

  const handleClickAddRoundTrip = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAddRoundTripOpen(event.currentTarget);
  };
  const handleCloseFilter = () => {
    setFilterOn(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleScroll = (event) => {
    const element = event.target;

    // TODO: use a true infinite scroll
    if (element.scrollHeight - element.scrollTop < element.clientHeight + 100) {
      if (orderNext) {
        requestOrderData(nextPageOrder);
      }
    }
  };

  const handleCloseOnEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      setSearchOn(false);
    }
  };

  const handleOpenFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFilterOn(event.currentTarget);
  };

  const updateOrders = () => {
    const updatedOrders = removeItemsFromOrder(ordersCopy, checked);
    setOrderInitCount(Math.max(orderInitCount - checked?.length || 0, 0));
    setOrders(updatedOrders);
  };

  if (
    !filterCount &&
    !search &&
    !orderInitCount &&
    !isFetching &&
    !openSort &&
    !openFilter &&
    !openAddRoundtrip
  ) {
    return (
      <RightBarNoData
        clusterId={clusterId}
        orders={orders}
        requestAddRoundTripData={requestAddRoundTripData}
        isLoading={isLoading}
        isFetching={isFetching}
        userContext={userContext}
      />
    );
  }

  return (
    <RelativeSection>
      <Background>
        <AddDialog
          clusterId={clusterId}
          open={open}
          setOpen={setOpen}
          orders={orders}
          requestAddRoundTripData={requestAddRoundTripData}
          userContext={userContext}
        />
        {searchOn && !checked?.length && (
          <TextField
            inputRef={setTextInputRef}
            size="small"
            onKeyUp={handleCloseOnEnter}
            variant="outlined"
            onChange={handleSearchChange}
            value={search}
            placeholder={t('orders.search_orders')}
            sx={{
              marginLeft: '5px',
              width: '235px',
              height: '33px',
              marginTop: '0',
              marginBottom: ' 0 !important',
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    aria-label="back"
                    component="label"
                    onClick={() => {
                      setSearchOn(false);
                    }}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
        <IconGroup>
          <FadedIcon display={searchOn && !checked?.length}>
            <IconButton
              aria-label="delete"
              component="label"
              onClick={() => {
                setSearchOn(true);
              }}
              disabled={!(checked.length === 0)}
            >
              <MagnifyingGlass
                size={16}
                style={
                  checked.length === 0
                    ? {
                        color:
                          search || searchOn
                            ? theme.primaryActiveColor
                            : themes.default.iconColor,
                      }
                    : { color: themes.default.iconGrey }
                }
              />
            </IconButton>
            <IconButton
              aria-describedby={id}
              aria-label="delete"
              onClick={handleClick}
              disabled={!(checked.length === 0)}
            >
              <CaretUpDown
                size={13}
                // style={{
                //   color:
                //     (sorting.value || sortingOn) && !checked.length
                //       ? theme.primaryActiveColor
                //       : 'auto',
                // }}
                style={
                  checked.length === 0
                    ? {
                        color:
                          (sorting.value || sortingOn) && !checked.length
                            ? theme.primaryActiveColor
                            : themes.default.iconColor,
                      }
                    : { color: themes.default.iconGrey }
                }
              />
            </IconButton>
            <Sorting
              openSort={openSort}
              id={id}
              sortingOn={sortingOn}
              handleClose={handleClose}
              setSorting={setSorting}
              sorting={sorting}
            />
            <IconButton
              aria-describedby={id2}
              aria-label="delete"
              onClick={handleOpenFilter}
              disabled={!(checked.length === 0)}
            >
              <FunnelSimple
                size={16}
                style={
                  checked.length === 0
                    ? {
                        color:
                          (filterCount || filterOn) && !checked.length
                            ? theme.primaryActiveColor
                            : themes.default.iconColor,
                      }
                    : { color: themes.default.iconGrey }
                }
              />
            </IconButton>
            <If condition={filterCount}>
              <span
                style={{
                  color: !checked.length
                    ? theme.primaryActiveColor
                    : theme.greyDisabled,
                }}
              >
                ({filterCount})
              </span>
            </If>
            <OrderFilter
              filterOn={filterOn}
              handleCloseFilter={handleCloseFilter}
              setFilters={setFilters}
              excludedFilters={[
                'quantity',
                'lines',
                'deliveryDate',
                'products',
                'salesman',
                'withDeleted',
                'createdBy',
              ]}
              clusterId={clusterId}
            />
          </FadedIcon>
          {!searchOn && !!checked.length && (
            <Icons>
              <IconButton
                aria-describedby={id3}
                aria-label="addBulk"
                onClick={(e) => {
                  handleClickAddRoundTrip(e);
                }}
              >
                <Icon
                  icon={<ArrowSquareRight />}
                  tooltip={t('orders.add_to_roundtrip')}
                />
              </IconButton>
              <Popover
                id={id3}
                open={openAddRoundtrip}
                anchorEl={addRoundTripOpen}
                onClose={() => {
                  handleCloseAssignRoundTrip(setAddRoundTripOpen);
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <AddRoundtripWraper>
                  <TextField
                    size="small"
                    variant="outlined"
                    onChange={handleSearchAddRoundtripChange}
                    value={searchAddRoundtrip}
                    placeholder={t('search_trips')}
                    sx={{
                      marginLeft: '5px',
                      width: '184px',
                      marginTop: '10px',
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            aria-label="search"
                            component="label"
                            onClick={() => {
                              setSearchOn(false);
                            }}
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <div className="roundtrips">
                    {filteredRoundTripCopy.map((roundtrip) => {
                      return (
                        <Button
                          key={roundtrip?.id}
                          sx={{
                            display: 'flex',
                            gap: '17px',
                            color: themes?.default?.black,
                          }}
                          className="roundtrip_item"
                          onClick={() => {
                            const indexFind = roundTripCopy?.findIndex(
                              (trip) => trip?.id === roundtrip?.id,
                            );
                            updateOrders();
                            let data = addObjectsToStops(roundtrip, checked);
                            data = cloneDeep(data);
                            data.userkey = userContext;
                            //using function from functions file since its used in addrountrip also
                            updateRoundtripFuction(
                              { id: roundtrip.id, data },
                              UpdateRoundtripData,
                              checked,
                              toast,
                            ).then(() => {
                              setOrders((prev) =>
                                prev.filter(
                                  (order) =>
                                    !checked.some(
                                      (checkedOrder) =>
                                        checkedOrder?._id === order?._id,
                                    ),
                                ),
                              );
                            });
                            handleAccordionToggleHover(
                              0,
                              indexFind,
                              accordionStatus,
                              setAccordionStatus,
                            );
                            setChecked([]);
                            // setOrderCount(0);
                            handleCloseAssignRoundTrip(setAddRoundTripOpen);
                          }}
                        >
                          <div className="roundtrip_code">
                            <StatusIndicator status={roundtrip.status} />
                            {roundtrip.code}~{roundtrip.internalCode}
                          </div>
                          <div>
                            {t('stop_count', {
                              count: roundtrip?.cache?.stats?.customers,
                            })}
                            {!!roundtrip?.['_vehicle']?.category
                              ? ` - ${roundtrip?.['_vehicle']?.category}`
                              : ''}
                          </div>
                        </Button>
                      );
                    })}
                  </div>
                </AddRoundtripWraper>
              </Popover>
              <IconButton
                aria-label="delete"
                component="label"
                onClick={() => setOpenBulkDelete(true)}
                sx={{ marginRight: '9px', zIndex: '10000' }}
              >
                <Icon icon={<TrashSimple />} tooltip={t('delete')} />
              </IconButton>
            </Icons>
          )}
          {!searchOn && !checked.length && (
            // <Icons>
            //   <Tooltip
            //     title={t('orders.add_order')}
            //     style={{ marginRight: '17px', zIndex: '1200' }}
            //     disableTouchListener
            //     arrow
            //   >
            //     <Button
            //       disableRipple
            //       sx={{
            //         '& svg': {
            //           color: theme?.primaryActiveColor,
            //         },
            //         minWidth: 'auto',
            //         padding: '0',
            //       }}
            //       onClick={() => handleClickOpen()}
            //     >
            //       <AddCircleIcon sx={{ height: '32px', width: '32px' }} />
            //     </Button>
            //   </Tooltip>
            // </Icons>
            <AddIconButton onClick={handleClickOpen} />
          )}
        </IconGroup>
        <CheckAll>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              padding: '0 8px',
              ml: 3,
              overflowY: 'scroll',
              height: 'calc(100vh - 150px)',
              overflowX: 'hidden',
              marginLeft: 0,
              opacity: isFetching ? 0.5 : 1,
            }}
            onScroll={handleScroll}
          >
            {!!orders?.length &&
              orders.map((data, index) => {
                return (
                  <div onClick={(e) => handleChange2(data?.id, e)}>
                    <ItemOrder
                      dateValue={dateValue}
                      handleChange2={handleChange2}
                      data={data}
                      checked={checked}
                      index={index}
                      setDeletedIds={setDeletedIds}
                      key={data.id}
                      onDisplayOrder={onDisplayOrder}
                      accordionStatus={accordionStatus}
                      setAccordionStatus={setAccordionStatus}
                      roundTripCopy={roundTripCopy}
                      clusterId={clusterId}
                      requestOrderData={requestOrderData}
                      orders={orders}
                      setOrders={setOrders}
                      setOrderInitCount={setOrderInitCount}
                      orderInitCount={orderInitCount}
                      disableDragAndDrop={disableDragAndDrop}
                      disableEdit={!ability.can('canedit', 'orders')}
                    />
                  </div>
                );
              })}
            {/* {!!orders?.length &&
              cities?.map((city:any) => {
                return (
                  <>
                    <Section>
                      <div className='sectionTitle'>
                        <CrosshairSimple color={themes?.default?.primary} size={10} />
                        <span>
                          {city.cityName}
                        </span>
                      </div>
                      <IconButton
                        onClick={() => handleSectionCollapse(city.cityName)}
                        className='collapseBtn' sx={{padding: '5px'}}
                      >
                        <CaretDown style={{transitionDuration: '0.2s', transform: city.expanded ? 'rotate(180deg)' : ''}} color={themes?.default?.iconColor} size={10} />
                      </IconButton>
                    </Section>
                    {city.expanded ? city?.orders.map((data, index) => {
                      return(
                        <ItemOrder
                          dateValue={dateValue}
                          handleChange2={handleChange2}
                          data={data}
                          checked={checked}
                          index={index}
                          key={data.id}
                          onDisplayOrder={onDisplayOrder}
                          accordionStatus={accordionStatus}
                          setAccordionStatus={setAccordionStatus}
                          roundTripCopy={roundTripCopy}
                          clusterId={clusterId}
                          requestOrderData={requestOrderData}
                          orders={orders}
                          setOrders={setOrders}
                          setOrderInitCount={setOrderInitCount}
                          orderInitCount={orderInitCount}
                          disableDragAndDrop={disableDragAndDrop}
                        />
                      )
                    }) : null}
                  </>
                );
              })} */}
          </Box>
        </CheckAll>
      </Background>

      {isLoading && (
        <LoadingLayer>
          <LoadingPulse cover />
        </LoadingLayer>
      )}

      <DialogClearRoundtrip
        open={openBulkDelete}
        setOpen={setOpenBulkDelete}
        title={
          checked?.length === 1
            ? t('delete_single_stop_confirm')
            : t('stops_bulk_delete_confirm', { count: checked?.length })
        }
        description=""
        actionYes={async () => {
          setOpenBulkDelete(false);
          updateOrders();
          const orderIds = checked.map((o) => o.id);
          await deleteStopRight({
            id: clusterId,
            orderIds,
          }).unwrap();
          if (orderIds?.length > 1) {
            toast(5000, 'success', 'alerts.orders.mass_delete_success');
          } else {
            toast(5000, 'success', 'alerts.orders.delete_success');
          }
        }}
        actionYesText={t('orders.actions.delete')}
        actionNoText={t('common.buttons.close_dialogue')}
        yesButtonSx={{
          background: themes?.default?.Cinnabar,
          colorProp: 'error',
        }}
      />
    </RelativeSection>
  );
};

export const Background = styled.div`
  background: white;
  padding-top: 4px;
  height: 100%;
`;

interface FadedIconProps {
  display: boolean;
}
const FadedIcon = styled.div<FadedIconProps>`
  display: ${(props) => (props.display ? 'none' : 'block')};
  margin-left: 9px;
`;
const Icons = styled.div`
  svg {
    color: ${themes?.default?.textColorPrimary};
  }
`;
const IconGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 33px;
  margin-bottom: 20px;
  position: relative;
  z-index: 1200;
`;
const CheckAll = styled.div`
  span {
    font-weight: 700;
    line-height: 143%;
    letter-spacing: 0.17px;
  }
`;

export const PopUpContainerFilter = styled.div`
  width: 288px;
  padding: 16px;
  .seperator {
    border: 0.8px solid ${themes?.default?.textColorSecondary}80;
    width: 171px;
  }
  button {
    width: 100%;
    justify-content: left;
  }
  .button_icon {
    display: flex;
    align-items: center;
  }
  .title_action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      width: 37px;
      height: 37px;
    }
    cursor: pointer;
  }
`;

export const LoadingLayer = styled.div`
  position: absolute;
  top: 80px;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 2000;
  height: 100%;
  width: 100%;
`;

export const RelativeSection = styled.div`
  position: relative;
  height: 100%;
  .count_order {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 143%;
    -webkit-letter-spacing: 0.17px;
    -moz-letter-spacing: 0.17px;
    -ms-letter-spacing: 0.17px;
    letter-spacing: 0.17px;
    color: ${themes?.default?.textColorPrimary};
    user-select: none;
  }
`;
