import styled from 'styled-components';

export function Title() {
  return (
    <Logo>
      <img
        src="https://bamptee-cdn.s3.eu-west-3.amazonaws.com/assets/bamptee-white.png"
        alt="Bamptee"
      />
    </Logo>
  );
}

const Logo = styled.div`
  text-align: center;
  padding-top: 30px;
  padding-bottom: 10px;
  img {
    max-width: 160px;
  }
`;
