import { IconButton } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

export function ZoomOptionMap({ setMapZoom, mapZoom }) {
  return (
    <div
      style={{
        height: '69px',
        width: '39px',
        backgroundColor: '#FFF',
        position: 'absolute',
        bottom: '60px',
        right: '20px',
        boxShadow: '2px 5px 4px rgba(1,1,1,0.3)',
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <IconButton
        onClick={() => setMapZoom(mapZoom + 1)}
        sx={{
          p: 0,
        }}
      >
        <ZoomInIcon fontSize="large" />
      </IconButton>
      <div
        style={{
          height: '1px',
          width: '35px',
          backgroundColor: '#0000001F',
          margin: '0 auto',
        }}
      />
      <IconButton
        onClick={() => setMapZoom(mapZoom - 1)}
        sx={{
          p: 0,
        }}
      >
        <ZoomOutIcon fontSize="large" />
      </IconButton>
    </div>
  );
}
