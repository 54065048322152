import { LinearProgress, linearProgressClasses, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

export function VehicleLinearProgress({
  fuelLevelPercentage,
  withLabel = false,
}) {
  const { t } = useTranslation();
  const GreenLinearProgress = styled(LinearProgress)(() => ({
    height: 6,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#CECCCC',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: 'rgb(85, 185, 137)',
    },
  }));

  const BorderLinearProgress = styled(LinearProgress)(() => ({
    height: 6,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#CECCCC',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: '#ffa726',
    },
  }));

  const ErrorBorderLinearProgress = styled(LinearProgress)(() => ({
    height: 6,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#CECCCC',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: '#de3428',
    },
  }));

  return (
    <>
      <FuelPercentage>
        {withLabel && t('vehicles.gas_tank_level')} {fuelLevelPercentage || 0}%
      </FuelPercentage>
      {fuelLevelPercentage < 40 ? (
        <ErrorBorderLinearProgress
          variant="determinate"
          value={fuelLevelPercentage || 0}
          sx={{ margin: '0 auto' }}
        />
      ) : fuelLevelPercentage >= 40 && fuelLevelPercentage < 80 ? (
        <BorderLinearProgress
          variant="determinate"
          value={fuelLevelPercentage || 0}
          sx={{ margin: '0 auto' }}
        />
      ) : (
        <GreenLinearProgress
          variant="determinate"
          value={fuelLevelPercentage || 0}
          sx={{ margin: '0 auto' }}
        />
      )}
    </>
  );
}

const FuelPercentage = styled('div')`
  text-align: center;
`;
