import { Chip, Tooltip } from '@mui/material';
import { OrderStatuses } from 'app/pages/Orders/data/statuses';
import React from 'react';

interface Props {
  status: string;
  styling?: any;
  updatedBy?: string;
  createdBy?: string;
  showTooltip: boolean;
  tooltipUneditable?: boolean;
}

export const StatusChips: React.FC<Props> = ({
  status,
  styling,
  updatedBy,
  createdBy,
  showTooltip,
  tooltipUneditable,
}) => {
  const chipObject = OrderStatuses.find((s) => s.status === status);
  const chipStyle = chipObject
    ? { background: chipObject.background, color: chipObject.color }
    : {};

  return (
    <div
      style={{
        ...styling,
      }}
    >
      {status === 'initiated' &&
        (showTooltip ? (
          <Tooltip title={'Created by: ' + createdBy}>
            <Chip
              label="Draft"
              size="small"
              sx={{
                backgroundColor: chipStyle?.background,
                color: chipStyle?.color,
              }}
            />
          </Tooltip>
        ) : (
          <Chip
            label="Draft"
            size="small"
            sx={{
              backgroundColor: chipStyle?.background,
              color: chipStyle?.color,
            }}
          />
        ))}
      {status === 'initiated-customer' &&
        (showTooltip ? (
          <Tooltip title={'Created by: ' + createdBy}>
            <Chip
              label="Draft"
              size="small"
              sx={{
                backgroundColor: chipStyle?.background,
                color: chipStyle?.color,
              }}
            />
          </Tooltip>
        ) : (
          <Chip
            label="Draft"
            size="small"
            sx={{
              backgroundColor: chipStyle?.background,
              color: chipStyle?.color,
            }}
          />
        ))}
      {status === 'initiated,initiated-customer' &&
        (showTooltip ? (
          <Tooltip title={'Created by: ' + createdBy}>
            <Chip
              label="Draft"
              size="small"
              sx={{
                backgroundColor: chipStyle?.background,
                color: chipStyle?.color,
              }}
            />
          </Tooltip>
        ) : (
          <Chip
            label="Draft"
            size="small"
            sx={{
              backgroundColor: chipStyle?.background,
              color: chipStyle?.color,
            }}
          />
        ))}
      {status === 'pending' &&
        (showTooltip ? (
          <Tooltip title={updatedBy}>
            <Chip
              label="Pending Review"
              size="small"
              sx={{
                backgroundColor: chipStyle?.background,
                color: chipStyle?.color,
              }}
            />
          </Tooltip>
        ) : tooltipUneditable ? (
          <Tooltip title="Pending Review is not editable">
            <Chip
              label="Pending Review"
              size="small"
              sx={{
                backgroundColor: chipStyle?.background,
                color: chipStyle?.color,
                // backgroundColor: '#FDF0E5',
                // color: '#BF360C',
              }}
            />
          </Tooltip>
        ) : (
          <Chip
            label="Pending Review"
            size="small"
            sx={{
              backgroundColor: chipStyle?.background,
              color: chipStyle?.color,
              // backgroundColor: '#FDF0E5',
              // color: '#BF360C',
            }}
          />
        ))}
      {status === 'pending-updates' ||
        (status === 'pending-updates,pending-customer' &&
          (showTooltip ? (
            <Tooltip title={updatedBy}>
              <Chip
                label="Pending Updates"
                size="small"
                sx={{
                  backgroundColor: chipStyle?.background,
                  color: chipStyle?.color,
                }}
              />
            </Tooltip>
          ) : (
            <Chip
              label="Pending Updates"
              size="small"
              sx={{
                backgroundColor: chipStyle?.background,
                color: chipStyle?.color,
              }}
            />
          )))}
      {status === 'pending-customer' &&
        (showTooltip ? (
          <Tooltip title={updatedBy}>
            <Chip
              label="Pending Updates"
              size="small"
              sx={{
                backgroundColor: chipStyle?.background,
                color: chipStyle?.color,
              }}
            />
          </Tooltip>
        ) : (
          <Chip
            label="Pending Updates"
            size="small"
            sx={{
              backgroundColor: chipStyle?.background,
              color: chipStyle?.color,
            }}
          />
        ))}
      {status === 'validated' &&
        (showTooltip ? (
          <Tooltip title={updatedBy}>
            <Chip
              label="Validated"
              size="small"
              sx={{
                backgroundColor: chipStyle?.background,
                color: chipStyle?.color,
              }}
            />
          </Tooltip>
        ) : (
          <Chip
            label="Validated"
            size="small"
            sx={{
              backgroundColor: chipStyle?.background,
              color: chipStyle?.color,
            }}
          />
        ))}
      {status === 'confirmed' &&
        (showTooltip ? (
          <Tooltip title={updatedBy}>
            <Chip
              label="Confirmed"
              size="small"
              sx={{
                backgroundColor: chipStyle?.background,
                color: chipStyle?.color,
              }}
            />
          </Tooltip>
        ) : (
          <Chip
            label="Confirmed"
            size="small"
            sx={{
              backgroundColor: chipStyle?.background,
              color: chipStyle?.color,
            }}
          />
        ))}
      {status === 'prepared' &&
        (showTooltip ? (
          <Tooltip title={updatedBy}>
            <Chip
              label="Prepared"
              size="small"
              sx={{
                backgroundColor: chipStyle?.background,
                color: chipStyle?.color,
              }}
            />
          </Tooltip>
        ) : (
          <Chip
            label="Prepared"
            size="small"
            sx={{
              backgroundColor: chipStyle?.background,
              color: chipStyle?.color,
            }}
          />
        ))}
      {status === 'loaded' &&
        (showTooltip ? (
          <Tooltip title={updatedBy}>
            <Chip
              label="Loaded"
              size="small"
              sx={{
                backgroundColor: chipStyle?.background,
                color: chipStyle?.color,
              }}
            />
          </Tooltip>
        ) : (
          <Chip
            label="Loaded"
            size="small"
            sx={{
              backgroundColor: chipStyle?.background,
              color: chipStyle?.color,
            }}
          />
        ))}
      {status === 'ready-for-delivery' &&
        (showTooltip ? (
          <Tooltip title={updatedBy}>
            <Chip
              label="Ready for Delivery"
              size="small"
              sx={{
                backgroundColor: chipStyle?.background,
                color: chipStyle?.color,
              }}
            />
          </Tooltip>
        ) : (
          <Chip
            label="Ready for Delivery"
            size="small"
            sx={{
              backgroundColor: chipStyle?.background,
              color: chipStyle?.color,
            }}
          />
        ))}
      {status === 'on-the-road' &&
        (showTooltip ? (
          <Tooltip title={updatedBy}>
            <Chip
              label="On the Road"
              size="small"
              sx={{
                backgroundColor: chipStyle?.background,
                color: chipStyle?.color,
              }}
            />
          </Tooltip>
        ) : (
          <Chip
            label="On the Road"
            size="small"
            sx={{
              backgroundColor: chipStyle?.background,
              color: chipStyle?.color,
            }}
          />
        ))}
      {status === 'delivered' &&
        (showTooltip ? (
          <Tooltip title={updatedBy}>
            <Chip
              label="Delivered"
              size="small"
              sx={{
                backgroundColor: chipStyle?.background,
                color: chipStyle?.color,
              }}
            />
          </Tooltip>
        ) : (
          <Chip
            label="Delivered"
            size="small"
            sx={{
              backgroundColor: chipStyle?.background,
              color: chipStyle?.color,
            }}
          />
        ))}
      {status === 'canceled' &&
        (showTooltip ? (
          <Tooltip title={updatedBy}>
            <Chip
              label="Canceled"
              size="small"
              sx={{
                backgroundColor: chipStyle?.background,
                color: chipStyle?.color,
              }}
            />
          </Tooltip>
        ) : (
          <Chip
            label="Canceled"
            size="small"
            sx={{
              backgroundColor: chipStyle?.background,
              color: chipStyle?.color,
            }}
          />
        ))}
      {status === 'blocked' &&
        (showTooltip ? (
          <Tooltip title={updatedBy}>
            <Chip
              label="Blocked"
              size="small"
              sx={{
                backgroundColor: chipStyle?.background,
                color: chipStyle?.color,
              }}
            />
          </Tooltip>
        ) : (
          <Chip
            label="Blocked"
            size="small"
            sx={{
              backgroundColor: chipStyle?.background,
              color: chipStyle?.color,
            }}
          />
        ))}
    </div>
  );
};
