import api from './api';

const createUploadEndpoint = () => ({
  query: ({
    formData,
    objectType,
    objectId,
    preserveName,
    type,
    persist,
  }: any) => {
    return {
      url: `/files/upload?objectType=${objectType || ''}&objectId=${
        objectId || ''
      }&preserveName=${preserveName}&type=${type}&persist=${persist}`,
      method: 'POST',
      body: formData,
    };
  },
});

const updateUploadEndpoint = () => ({
  query: ({
    formData,
    objectType,
    objectId,
    preserveName,
    type,
    persist,
    fileId,
  }: any) => {
    return {
      url: `/files/${fileId}?objectType=${objectType || ''}&objectId=${
        objectId || ''
      }&preserveName=${preserveName}&type=${type}&persist=${persist}`,
      method: 'PATCH',
      body: formData,
    };
  },
});

export const filesApi = api.injectEndpoints({
  endpoints: builder => ({
    upload: builder.mutation<any, any>(createUploadEndpoint()),
    uploadWithLoader: builder.mutation<any, any>(createUploadEndpoint()),
    updateFile: builder.mutation<any, any>(updateUploadEndpoint()),
    deleteFile: builder.mutation<any, any>({
      query: id => ({
        url: `/files/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useUpdateFileMutation,
  useUploadMutation,
  useUploadWithLoaderMutation,
  useDeleteFileMutation,
} = filesApi;

export default filesApi;
