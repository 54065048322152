export const Columns: any = [
  {
    id: 'code',
    label: 'Code',
    cellStyle: {
      paddingLeft: '24px !important',
    },
    sticky: 'left',
  },
  {
    id: 'branchName',
    label: 'branch_name',
    canChangeView: false,
    sortField: 'cache.userCount',
    cellStyle: {
      minWidth: '200px !important',
    },
    sticky: 'left',
  },

  {
    id: 'address',
    label: 'Add_Client_Address',
    canChangeView: true,
    sortField: 'cache.userCount',
    cellStyle: { width: '188px', minWidth: '188px !important' },
  },
  {
    id: 'siretNumber',
    label: 'clientModule.fields.siret',
    canChangeView: true,
    sortField: 'cache.userCount',
    cellStyle: { width: '161px', minWidth: '161px !important' },
  },
  {
    id: 'vatNumber',
    label: 'vatNumber',
    canChangeView: true,
    sortField: 'cache.userCount',
    cellStyle: { width: '141.43px', minWidth: '141.43px !important' },
  },
  {
    id: 'apeCode',
    label: 'code_ape',
    canChangeView: true,
    sortField: 'cache.userCount',
    cellStyle: {
      width: '120px',
      minWidth: '120px !important',
    },
  },
  {
    id: 'mainsSalesContact',
    label: 'Main Sales Contact',
    canChangeView: true,
    sortField: 'cache.userCount',
    cellStyle: { width: '180.43px', minWidth: '180.43px !important' },
  },
  {
    id: 'mainLogisticsContact',
    label: 'Main Logistics Contact',
    disableSort: true,
    canChangeView: true,
    sortField: 'cache.userCount',
    cellStyle: { width: '200.43px', minWidth: '200.43px !important' },
  },
  {
    id: 'deliveryType',
    label: 'clients.orderDetails.delivery_type',
    canChangeView: true,
    sortField: 'cache.userCount',
    cellStyle: { width: '180.43px', minWidth: '180.43px !important' },
  },
  {
    id: 'preferredSupportUnits',
    label: 'customer.field.supportUnitPreferred',
    canChangeView: true,
    sortField: 'cache.userCount',
    cellStyle: { width: '230px', minWidth: '230px !important' },
  },
  {
    id: 'vehicleCondition',
    label: 'customer.field.accessConditions',
    canChangeView: true,
    sortField: 'cache.userCount',
    cellStyle: { width: '180.43px', minWidth: '180.43px !important' },
  },
  {
    id: 'deliveryDays',
    label: 'delivery_days',
    canChangeView: true,
    sortField: 'cache.userCount',
    cellStyle: { width: '180.43px', minWidth: '180.43px !important' },
  },
  {
    id: 'status',
    label: 'customer_Status',
    canChangeView: false,
    sortField: 'cache.userCount',
    cellStyle: {
      width: '141.43px',
      minWidth: '141.43px !important',
    },
    sticky: 'right',
  },
  {
    id: 'actions',
    label: '',
    disableSort: true,
    className: 'actions-cell',
  },
];
