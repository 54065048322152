import React, {
  createContext,
  useMemo,
  useState,
  useCallback,
  useEffect,
  Context,
} from 'react';
import { Draft, produce } from 'immer';
import dot from 'dot-object';
import { contextRegistry } from './ContextRegistry';

interface Props<T extends object> {
  contextName: string;
  defaultValue: T;
  children: React.ReactNode;
}

export default function ManagedContext<T extends object>({
  contextName,
  defaultValue,
  children,
}: Props<T>) {
  const [data, setData] = useState(defaultValue || {});
  type ContextValueType = T & {
    data: T;
    updateData: (key: string, value: never) => void;
    updateDataWithFunction: (func: (draft: T) => void) => void;
  };
  const contextRef = React.useRef<Context<
    ContextValueType | null | unknown
  > | null>(null);

  if (!contextRef.current) {
    contextRef.current = createContext<ContextValueType | null | unknown>(null);
  }

  const currentContext = contextRef.current;

  const updateData = useCallback(
    (key: string, value: unknown) => {
      if (!(key in defaultValue))
        throw new Error(`Key '${key}' not found in defaultValue`);
      setData((oldData: T) =>
        produce(oldData, (draft: Draft<T>) => {
          dot.str(key, value, draft);
        }),
      );
    },
    [setData, defaultValue],
  );

  const updateDataWithFunction = useCallback(
    (func: (draft: Draft<T>) => void) => {
      setData((oldData: T) =>
        produce(oldData, draft => {
          func(draft);
          Object.keys(draft).forEach(key => {
            if (!(key in defaultValue)) {
              throw new Error(`Key '${key}' not found in defaultValue`);
            }
          });
        }),
      );
    },
    [defaultValue],
  );

  const value = useMemo(
    () =>
      ({
        ...(data || {}),
        data,
        updateData,
        updateDataWithFunction,
      }) as ContextValueType,
    [data, updateData, updateDataWithFunction],
  );

  const isFirstRender = React.useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    return () => {
      contextRegistry.delete(contextName);
    };
  }, [contextName]);
  contextRegistry.set(contextName, currentContext);
  if (!value.data) return null;
  return (
    <currentContext.Provider value={value}>{children}</currentContext.Provider>
  );
}
