import styled from 'styled-components';
import { Box, Paper } from '@mui/material';

export const TimelineContainer = styled(Box)`
  position: relative;
`;

export const TimelineItemContainer = styled(Box)`
  position: relative;
  padding-left: 28px;
  padding-bottom: 20px;

  &:last-child::before {
    display: none;
  }

  &::before {
    content: '';
    position: absolute;
    left: 5px;
    top: 5px;
    bottom: -5px;
    width: 1px;
    background-color: ${(props) => props.theme.silver};
  }
`;

interface TimelineDotProps {
  color?: string;
}
export const TimelineDot = styled(Box)<TimelineDotProps>`
  position: absolute;
  left: 0;
  top: 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.color ? props.color : props.theme.silver};
  z-index: 1;
`;

export const ContentPaper = styled(Paper)`
  padding: 8px;
  background-color: ${(props) => props.theme.lightGrey};
  border: 1px solid ${(props) => props.theme.gainsboro2};
`;

export const ActivityDot = styled.div`
  height: 6px;
  width: 6px;
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.grey7};
  background: ${(props) => props.theme.grey2};
  margin-left: 16px;
  margin-right: 8px;
`;

export const LoaderText = styled('div')`
  text-align: center;
  padding: 20px;
`;
