import { Chip } from '@mui/material';
import _ from 'lodash';
interface CountChipProps {
  count: number | undefined;
}

export const CountChip = ({ count }: CountChipProps) => {
  if (_.isUndefined(count)) {
    return null;
  }
  return (
    <div>
      <Chip
        size="small"
        label={count || 0}
        sx={{
          paddingTop: '1px',
          height: '20px',
          '& span': {
            fontSize: '0.625rem',
            fontWeight: 700,
          },
        }}
      />
    </div>
  );
};
