import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { EmptyCenter } from 'app/components/TableComponent/styles';
import { NoDataComponents } from 'app/components/NoData/NoDataComponents';
import ContactsSectionEmptyState from 'assets/img/chat/ContactsSectionEmptyState.svg';
import MessagesSectionEmptyState from 'assets/img/chat/MessagesSectionEmptyState.svg';

interface Props {
  type?: 'regular' | 'note' | 'noMessages';
}

export const ChatWindowEmptyState: React.FC<Props> = ({ type = 'regular' }) => {
  const { t } = useTranslation();

  return (
    <Wrapper narrow={type === 'regular'}>
      <EmptyCenter>
        {type === 'regular' ? (
          <NoDataComponents
            viewMode={true}
            Icon={ContactsSectionEmptyState}
            text={t('chat.chat_window.empty_state')}
            textButton=""
          />
        ) : type === 'note' ? (
          <NoteWrapper>
            <NotePaper>
              <NoteTitle>{t('chat.notes.title')}</NoteTitle>
              <NoteBody>
                <ul>
                  <li>{t('chat.notes.note1')}</li>
                  <li>{t('chat.notes.note2')}</li>
                  <li>{t('chat.notes.note3')}</li>
                  <li>{t('chat.notes.note4')}</li>
                </ul>
              </NoteBody>
            </NotePaper>
          </NoteWrapper>
        ) : type === 'noMessages' ? (
          <NoMessagesStateWrapper>
            <NoDataComponents
              viewMode={true}
              Icon={MessagesSectionEmptyState}
              text={t('noMessageYet')}
              textButton=""
            />
            <EmptyStateDescription>
              {t('chat.send_first_message')}
            </EmptyStateDescription>
          </NoMessagesStateWrapper>
        ) : null}
      </EmptyCenter>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ narrow: boolean }>`
  flex: 1;
  margin: 0 0 ${60 + 24 + 42}px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const NoteWrapper = styled.div`
  width: 336px;
  min-height: 128px;
  gap: 4px;
  border-radius: 8px;
  background: linear-gradient(166.61deg, #29b6f6 -0.09%, #2a59ff 105.26%);
  padding: 1px;
`;

const NotePaper = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 7px;
  background: ${themes?.default?.accordionWhiteBg};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  padding: 12px 12px 12px 20px;
`;

const NoteTitle = styled.div`
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 20px;
`;

const NoteBody = styled.div`
  font-size: 0.75rem;
  line-height: 20px;
  text-align: left;
  width: 100%;

  ul {
    margin: 0;
    padding: 0;
    list-style-position: inside;
  }
`;

const NoMessagesStateWrapper = styled.div`
  .NoDataComponentText {
    font-family: Roboto;
    height: 25.6px;
    font-size: 1rem;
    font-weight: 500;
    line-height: 25.6px;
    letter-spacing: 0.15px;
    text-align: center;
    color: ${themes?.default?.textBlack};
  }
`;

const EmptyStateDescription = styled.p`
  margin: 0;
  height: 20px;
  font-family: Roboto;
  font-size: 0.875rem;
  line-height: 20px;
  letter-spacing: 0.17px;
  text-align: center;
  color: ${themes?.default?.textColorDashboard};
`;
