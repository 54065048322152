import { useCallback, useMemo } from 'react';
import { useAbility } from '.';
import { hashObject } from 'common/utils/hashObject';

export enum Permission {
  VIEW = 'canview',
  EDIT = 'canedit',
  EXPORT = 'canexport',
  MANAGE_PERSONAL = 'managepersonal',
  MANAGE_TEAM = 'manageteam',
  MANAGE_ALL = 'manageall',
}

export const usePermission = (section: string) => {
  const ability = useAbility();

  const permissions: { [key: string]: boolean } = useMemo(() => {
    return Object.values(Permission).reduce((acc, permission) => {
      acc[permission] = ability.can(permission, section);
      return acc;
    }, {});
  }, [ability, section]);

  const hashedPerms = hashObject(permissions);

  return useCallback(
    (action: Permission) => permissions[action],
    [hashedPerms],
  );
};
