import { Pagination, Stack, TablePagination } from '@mui/material';
import { useGrid } from '../../hooks';
import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import { useResetPaginaton } from 'hooks/useResetPagniation';

export const GridPagination = () => {
  const { t } = useTranslation();
  const {
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    totalRows,
    debouncedSearchTerm,
    debouncedFilters,
  } = useGrid();

  useResetPaginaton(setPage, [debouncedSearchTerm, debouncedFilters]);

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const totalPages = useMemo(() => {
    return Math.ceil(totalRows / rowsPerPage);
  }, [totalRows, rowsPerPage]);

  if (!totalRows) return null;

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
      columnGap="20px"
    >
      <TablePagination
        labelRowsPerPage={t('rows_per_page')}
        rowsPerPageOptions={[10, 20, 30, 50, 100]}
        component="div"
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          position: 'sticky',
          bottom: 0,
          background: 'white',
          overflow: 'hidden',
          '.MuiTablePagination-select': { paddingTop: '5px' },
        }}
        ActionsComponent={() => {
          return null;
        }}
      />
      <Pagination
        color="primary"
        onChange={(_e, val) => {
          setPage(val);
        }}
        page={page}
        count={totalPages}
        shape="rounded"
      />
    </Stack>
  );
};
