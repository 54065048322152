import React from 'react';
import { Alert, Button, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { useManagedContext } from '../../../../../common/UtilityComponents/ManagedContext/useManagedContext';
import { IRoundtripMappingsContext } from '../index';
import { useTranslation } from 'react-i18next';

function AddRoundtripsWithTemplate({ warehouseId }) {
  const RoundtripMappingsContext = useManagedContext<IRoundtripMappingsContext>(
    'RoundtripMappingsContext',
  );
  const { t } = useTranslation();
  const onAddNewRountripsTemplate = () => {
    RoundtripMappingsContext.updateDataWithFunction((prev) => {
      const newRoundtrips = Array.from({ length: 1 }, (_, i) => ({
        id: `New ${i}`,
        area: '',
        roundtripCode: '',
        driver: '',
        vehicle: '',
      }));
      prev.roundtripMappings = prev.roundtripMappings.map((mapping) => {
        if (mapping.id === warehouseId) {
          mapping.roundtrips = [...mapping.roundtrips, ...newRoundtrips];
          mapping.nbOfRoundtrips += 1;
        }
        return mapping;
      });
    });
  };
  return (
    <CardContent>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          paddingBottom: '40px !important',
        }}
      >
        <Alert severity="info">
          <Typography fontSize="14px" fontWeight={400} lineHeight="20px">
            {t('roundtrip.mapping.empty.state')}
          </Typography>
        </Alert>

        <Stack
          direction="row"
          gap={2}
          justifyContent="center"
          alignItems="baseline"
          height="fit-content"
        >
          <Typography
            fontSize="14px"
            fontWeight={400}
            lineHeight="17px"
            display="flex"
            alignItems="center"
          >
            Add
          </Typography>

          <Typography
            fontSize="14px"
            fontWeight={400}
            lineHeight="17px"
            display="flex"
            alignItems="center"
          >
            Roundtrip
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{
              width: '80px',
              height: '30px',
              py: 1.3,
              px: 1.3,
              gap: 1.3,
              borderRadius: '4px',
              marginTop: '20px',
            }}
            onClick={() => {
              onAddNewRountripsTemplate();
            }}
          >
            GO
          </Button>
        </Stack>
      </Stack>
    </CardContent>
  );
}

export default AddRoundtripsWithTemplate;
