import { useMemo } from 'react';
import { Stack, Tooltip, Typography } from '@mui/material';
import { RoadHorizon, Truck, User } from '@phosphor-icons/react';
import FormatNumber from 'app/components/FormatNumber';
import { Icon } from 'app/components/Icon';
import { VehicleLinearProgress } from 'app/components/vehicleLinearProgress';
import styled from 'styled-components';
import UNPLUGGED from 'assets/img/vehicles/plugged_in_empty.svg';
import PLUGGED from 'assets/img/vehicles/plugged_in_charging.svg';
import { themes } from 'styles/theme/themes';
import { snakeCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import { VehicleData } from 'app/pages/Vehicles/types';
import If from 'app/components/If';

type VehicleInfoCardProps = {
  selectedVehicle: VehicleData;
};

export const VehicleInfoCard = ({ selectedVehicle }: VehicleInfoCardProps) => {
  const { t } = useTranslation();

  const STATUSES = useMemo(
    () => ({
      available: { color: themes.default?.progressGreen },
      in_use: { color: themes.default?.progressOrange },
      out_of_service: { color: themes.default?.progressRed },
    }),
    [],
  );

  const statusColor = useMemo(() => {
    const status = snakeCase(
      selectedVehicle?.availabilityStatus,
    )?.toLowerCase();
    return STATUSES[status]?.color;
  }, [selectedVehicle.availabilityStatus, STATUSES]);

  return (
    <div
      style={{
        width: '264px',
        background: 'white',
        borderRadius: '8px',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        padding: '12px 8px',
      }}
    >
      <Stack gap="4px">
        <Typography fontWeight="700" fontSize="0.75rem">
          {selectedVehicle?.numberPlate}
        </Typography>
        <Row>
          <Icon icon={<User />} size={12} />
          <div style={{ fontSize: '0.8rem' }}>
            {selectedVehicle.primaryDriver?.fullName}
          </div>
        </Row>

        <Row>
          <Icon icon={<Truck />} size={12} />
          <div style={{ fontSize: '0.8rem' }}>
            {selectedVehicle?.type} - {selectedVehicle?.brand}
          </div>
        </Row>

        <Row>
          <Icon icon={<RoadHorizon />} size={12} />
          <Stack direction="row" gap="4px">
            <div style={{ fontSize: '0.8rem' }}>
              {t('vehicles.park_number')}
            </div>
            <Typography fontSize="0.7rem" fontWeight="500">
              {selectedVehicle?.parkNumber}
            </Typography>
          </Stack>
        </Row>

        <Typography fontSize="0.625rem">
          <VehicleLinearProgress
            withLabel={true}
            fuelLevelPercentage={selectedVehicle?.fuelLevelPercentage}
          />
        </Typography>

        <Stack direction="row" gap="7px" alignItems="center" padding="8px 0">
          <If condition={!!selectedVehicle.odometer}>
            <StatChip>
              {selectedVehicle.odometer ? (
                <FormatNumber number={selectedVehicle.odometer} />
              ) : (
                '__'
              )}
            </StatChip>
          </If>

          <If condition={!!selectedVehicle?.parking}>
            <StatChip>{selectedVehicle?.parking}</StatChip>
          </If>

          <If condition={!!selectedVehicle?.lastSensors?.[0]?.value}>
            <StatChip>{selectedVehicle?.lastSensors?.[0]?.value}°C</StatChip>
          </If>
        </Stack>

        <Stack direction="row" gap="8px" justifyContent="space-between">
          <Stack direction="row" gap="12px" alignItems="center">
            <Tooltip
              title={t(`vehicle.status.${selectedVehicle?.availabilityStatus}`)}
            >
              <StatusCircle color={statusColor} />
            </Tooltip>

            <div>
              {!selectedVehicle.pluggedIn ? (
                <Tooltip title={t('roundtrips.not_plugged_in')}>
                  <img
                    width="16px"
                    height="16px"
                    src={UNPLUGGED}
                    alt="UnPlugged"
                  />
                </Tooltip>
              ) : (
                <Tooltip title={t('roundtrips.plugged_in')}>
                  <img width="16px" height="16px" src={PLUGGED} alt="Plugged" />
                </Tooltip>
              )}
            </div>
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
};

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 0.625rem;
`;

const StatChip = styled.div`
  border-radius: 3px;
  background: ${(props) => props.theme.grey4};
  padding: 3px;

  color: ${(props) => props.theme.lightGrayLabel};
  font-size: 0.625rem;
  font-weight: 500;
  line-height: 1.05419rem;
`;

const StatusCircle = styled.span<{ color: string }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${(props) => props.color};
`;
