import { TableCell, TableRow } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { Order } from '../TableComponent';
import { useTranslation } from 'react-i18next';
import { FixedTableHead } from '../TableComponent/helpers';
import { Key } from 'react';

interface EnhancedTableProps {
  order: Order;
  orderBy: string;
  onRequestSort: (property: string) => void;
  isLoading: boolean;
  headCells: any;
}

export function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, headCells } = props;

  const { t } = useTranslation();
  const theme = useSelector(selectTheme);

  return (
    <FixedTableHead
      sx={{
        backgroundColor: theme.grey,
      }}
    >
      <TableRow
        sx={{
          display: 'table-row',
        }}
      >
        {headCells.map(
          (headCell: {
            disableRow: any;
            center: any;
            id: Key | null | undefined;
            sortField: string;
            minWidth: any;
            maxWidth: any;
            label: any;
          }) => {
            if (headCell.disableRow) return null;
            let styles = {};
            if (headCell.center) {
              styles = { display: 'flex', justifyContent: 'center' };
            }
            return (
              <TableCell
                key={headCell.id}
                align="left"
                sortDirection={orderBy === headCell.sortField ? order : false}
                sx={{
                  flex: 1,
                  padding: '6px 8px',
                  minWidth: headCell.minWidth || 0,
                  maxWidth: headCell.maxWidth || 'unset',
                  overflow: 'hidden',
                  ...styles,
                }}
              >
                {/* {headCell.disableSort ? (
                                <span style={{ fontWeight: 500 }}>{t(headCell.label)}</span>
                            ) : (
                                <TableSortLabel
                                    active={orderBy === headCell.sortValue}
                                    direction={orderBy === headCell.sortValue ? order : 'asc'}
                                    onClick={createSortHandler(headCell.sortValue)}
                                >
                                    <span style={{ fontWeight: 500 }}>{t(headCell.label)}</span>
                                    {orderBy === headCell.sortValue ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {order === 'desc'
                                                ? 'sorted descending'
                                                : 'sorted ascending'}
                                        </Box>
                                    ) : null}
                                </TableSortLabel>
                            )} */}

                <span style={{ fontWeight: 500 }}>{t(headCell.label)}</span>
              </TableCell>
            );
          },
        )}
      </TableRow>
    </FixedTableHead>
  );
}
