import {
  Avatar,
  Checkbox,
  FormControlLabel,
  Grid,
  Popover,
  Typography,
} from '@mui/material';
import { FilterHeader } from 'app/components/FilterComponents/components/FilterHeader';
import MultiSelect from 'app/components/FilterComponents/components/MultiSelect';
import { FilterContainer } from 'app/components/FilterComponents/styles';
import { handleSelectFilterChange } from 'app/helpers/helpers';
import { useLazyGetUsersWebQuery } from 'common/services/userApi';
import { useQueryParams } from 'hooks/useQueryParams';
import { useEffect, useMemo } from 'react';

export function FilterDocs({ filterOn, handleClose, setFilters, filters }) {
  const open = Boolean(filterOn);

  const [triggerSalesmen, { data: sales }] = useLazyGetUsersWebQuery();

  const salesQueryParams = useQueryParams({
    roles: 'sales',
    context: 'users',
    page: 1,
    limit: 1000,
  });

  const searchSalesmen = (search: string) => {
    triggerSalesmen(`${salesQueryParams}&text=${search}`);
  };

  const salesmen = useMemo(() => {
    return sales?.docs || [];
  }, [sales]);

  useEffect(() => {
    triggerSalesmen(salesQueryParams);
  }, []);

  const handleOptionsChange = (
    filterKey: string,
    options: { value: string; label: string }[],
  ) => {
    handleSelectFilterChange(filterKey, options, setFilters);
  };

  const handleOptionClick = (option, filterKey) => {
    const currentOptions = filters?.[filterKey] || [];
    const isSelected = currentOptions.some(
      (selectedOption) => selectedOption.value === option.value,
    );

    let newSelectedOptions;
    if (isSelected) {
      newSelectedOptions = currentOptions.filter(
        (selectedOption) => selectedOption.value !== option.value,
      );
    } else {
      newSelectedOptions = [...currentOptions, option];
    }

    handleOptionsChange(filterKey, newSelectedOptions);
  };

  return (
    <Popover
      open={open}
      onClose={handleClose}
      anchorEl={filterOn}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <FilterContainer>
        <FilterHeader
          onClearAll={() => {
            setFilters({});
          }}
        />
        <div
          style={{
            width: 'calc(100% - 14px)',
          }}
        >
          <MultiSelect
            filterName="Sales person"
            selectedOptions={filters?.salesman || []}
            handleFilterChange={(options) => {
              handleOptionsChange('salesman', options);
            }}
            filterItems={salesmen?.map((sales: any) => {
              return {
                value: sales?._id,
                label: sales?.fullName,
              };
            })}
            onSearch={searchSalesmen}
            autoCompleteProps={{
              filterOptions: (options) => {
                return options;
              },
              renderTags: (selectedOptions) => {
                return selectedOptions.map((option) => (
                  <Avatar
                    sizes="small"
                    sx={{
                      width: '16px',
                      height: '16px',
                    }}
                  >
                    <Typography fontSize="8px">
                      {option.label.substring(0, 2)}
                    </Typography>
                  </Avatar>
                ));
              },
              renderOption: (props, option) => (
                <div
                  {...props}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleOptionClick(option, 'salesman');
                  }}
                >
                  <Grid container alignItems="center">
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters?.salesman?.some(
                              (selectedOption) =>
                                selectedOption.value === option.value,
                            )}
                            onClick={() => {
                              handleOptionClick(option, 'salesman');
                            }}
                          />
                        }
                        label={
                          <Avatar
                            sizes="small"
                            sx={{
                              width: '32px',
                              height: '32px',
                            }}
                          >
                            <Typography fontSize="14px">
                              {option.label.substring(0, 2)}
                            </Typography>
                          </Avatar>
                        }
                      />
                    </Grid>
                    <Grid item>
                      <div>{option.label}</div>
                    </Grid>
                  </Grid>
                </div>
              ),
            }}
            placeholder="Select sales person"
          />
        </div>
      </FilterContainer>
    </Popover>
  );
}
