import { lazyLoad } from 'utils/loadable';
import { LoadingIndicator } from 'app/components/LoadingIndicator';

export const PricingGroupsPage = lazyLoad(
  () => import('./PricingGroups'),
  (module) => module.PricingGroups,
  {
    fallback: <LoadingIndicator />,
  },
);
