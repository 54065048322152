import React from 'react';
import { Autocomplete, Stack, TextField } from '@mui/material';
import { useUpdateRoundtripMappingMutation } from '../../../../../../common/services/roundtripMappings';
import { useManagedContext } from '../../../../../../common/UtilityComponents/ManagedContext/useManagedContext';

function DriverAutoComplete({ field, warehouseId, roundtrip }) {
  const drivers = useManagedContext('RoundtripMappingsContext').drivers;
  const [updateRoundtripMappings] = useUpdateRoundtripMappingMutation();
  const handleBlur = (selection) => {
    if (roundtrip.driver !== selection.id)
      updateRoundtripMappings({
        id: roundtrip.id,
        body: {
          _roundtripGescom: roundtrip.roundtripCode,
          _driver: selection.id || null,
          _deck: warehouseId,
        },
      });
  };
  return (
    <Stack>
      <Autocomplete
        // open={open}
        // onOpen={() => {
        //   setOpen(true);
        //   getAvailableInternalCodes('671f2beb9485bed5a39f5014');
        // }}
        // onClose={() => setOpen(false)}
        // loading={isLoading}
        {...field}
        options={drivers || []}
        value={
          field?.value || {
            fullName: '',
            id: '',
          }
        }
        getOptionLabel={(option: { fullName: string; id: string }) =>
          option.fullName
        }
        onChange={(event, newValue) => {
          field.onChange(newValue);
          handleBlur(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Drivers"
            margin="normal"
            size="small"
            variant="outlined"
            value={field.value.id || ''}
            label="Drivers"
            // onBlur={handleBlur}
            InputProps={{
              ...params.InputProps,
              // endAdornment: (
              //   <>
              //     {isLoading ? (
              //       <CircularProgress color="inherit" size={20} />
              //     ) : null}
              //     {params.InputProps.endAdornment}
              //   </>
              // ),
            }}
          />
        )}
      />
    </Stack>
  );
}
export default DriverAutoComplete;
