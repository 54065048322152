import {
  CircularProgress,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  Flag,
  PaperPlaneTilt,
  PlusCircle,
  Prohibit,
  Trash,
} from '@phosphor-icons/react';
import { ActionIconButton } from 'app/components/ActionIconButton';
import { useModal } from 'app/components/Dialog/hooks';
import { FormSection, SectionTitle } from 'app/components/Form/styles';
import If from 'app/components/If';
import { ViewCustomercontext } from 'app/pages/Customers/components/ViewCustomer';
import { MainTypo } from 'app/pages/Ordoria/CallPlan/components/CallDetailsDrawer/components/Info/styles';
import { AgentStatuses } from 'app/pages/Users/data/types';
import { useUpdateCustomerMutation } from 'common/services/customerApi';
import {
  useChangeUserStatusMutation,
  useInviteUserMutation,
} from 'common/services/userApi';
import Each from 'common/UtilityComponents/Each';
import { useErrorFormatter } from 'hooks/Forms/useErrorFormatter';
import { useToaster } from 'hooks/useToaster';
import { useContext, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectTheme } from 'styles/theme/slice/selectors';
import { themes } from 'styles/theme/themes';
import { EmailStatus } from './components/EmailStatus';

export const ContactInformation = ({ control, errors, reset }) => {
  const toast = useToaster();
  const { t } = useTranslation();
  const { customer, canEdit } = useContext(ViewCustomercontext);
  const { formatError } = useErrorFormatter();
  const theme = useSelector(selectTheme);
  const { openModal, closeModal, setActionDisabled } = useModal();

  const [mainEmail, setMainEmail] = useState(customer?.email);
  const [otherEmails, setOtherEmails] = useState(customer?.otherEmails);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const [updateCustomer, { isLoading: isAddingEmail }] =
    useUpdateCustomerMutation();
  const [inviteUser, { isLoading: isInviting }] = useInviteUserMutation();
  const [updateUserStatus] = useChangeUserStatusMutation();

  const emails = useMemo(() => {
    const mainEmailAddress = {
      main: true,
      created: true,
      email: mainEmail,
    };
    const list = otherEmails?.map((email) => {
      const agent = customer.agents.find((a) => a.email === email);
      return {
        main: false,
        created: !!agent,
        email,
      };
    });
    return [mainEmailAddress, ...list];
  }, [mainEmail, otherEmails, customer.agents]);

  const emailInput = useMemo(() => {
    return control._getWatch()?.emailInput;
  }, [control._getWatch()?.emailInput]);

  const addEmail = async () => {
    openModal({
      title: t('invite_user', { name: t('common.user') }),
      content: t('user.invite_by_name', { name: emailInput }),
      action: {
        actionText: t('common.buttons.invite'),
        actionCallback: async () => {
          try {
            setActionDisabled(true);
            reset();
            await updateCustomer({
              id: customer?._id,
              otherEmails: [...otherEmails, emailInput],
            }).unwrap();
            setOtherEmails((prev) => [...prev, emailInput]);
            await invite(emailInput);
            setActionDisabled(false);
            closeModal();
          } catch (e) {
            setActionDisabled(false);
          }
        },
        buttonProps: {
          disabled: !!isAddingEmail,
        },
      },
      cancel: {
        actionText: t('common.buttons.skip-for-now'),
        actionCallback: async () => {
          try {
            setActionDisabled(true);
            reset();
            await updateCustomer({
              id: customer?._id,
              otherEmails: [...otherEmails, emailInput],
            }).unwrap();
            setOtherEmails((prev) => [...prev, emailInput]);
            setActionDisabled(false);
            closeModal();
            toast(5000, 'success', `user.creation_success`);
          } catch (e) {
            setActionDisabled(false);
          }
        },
        buttonProps: {
          disabled: !!isAddingEmail,
        },
      },
    });
  };

  const removeEmail = async (
    email: { email: string; main: boolean },
    userId: string,
  ) => {
    openModal({
      title: t('delete_email'),
      content: t('delete_email_confirmation'),
      action: {
        actionText: t('delete'),
        actionCallback: async () => {
          setActionDisabled(true);

          let newEmails = [...otherEmails];

          if (email.main) {
            const nextMainEmail = otherEmails[0];
            newEmails = newEmails.filter((e) => e !== nextMainEmail);
            await updateCustomer({
              id: customer?._id,
              otherEmails: newEmails,
              email: nextMainEmail,
            });
            setMainEmail(nextMainEmail);
          } else {
            newEmails = newEmails.filter((e) => e !== email.email);

            await updateCustomer({
              id: customer?._id,
              otherEmails: newEmails,
            }).unwrap();
          }
          setOtherEmails(newEmails);
          setActionDisabled(false);
          closeModal();
          if (userId) {
            updateUserStatus({
              userIds: [userId],
              isArchived: true,
            }).unwrap();
          }
        },
        buttonProps: {
          sx: { background: themes.default.Cinnabar },
          color: 'error',
        },
      },
      cancel: true,
    });
  };

  const toggleMain = async (email: { email: string; main: boolean }) => {
    let newEmails: string[] = [];

    if (email.main) {
      newEmails = [email.email, ...otherEmails];
      const nextMainEmail = otherEmails[0];
      newEmails = newEmails.filter((e) => e !== nextMainEmail);
      await updateCustomer({
        id: customer?._id,
        otherEmails: newEmails,
        email: nextMainEmail,
      });
      setMainEmail(nextMainEmail);
      setOtherEmails(newEmails);
    } else {
      newEmails = [customer.email, ...otherEmails];
      newEmails = newEmails.filter((e) => e !== email.email);
      await updateCustomer({
        id: customer?._id,
        otherEmails: newEmails,
        email: email.email,
      });
      setMainEmail(email.email);
      setOtherEmails(newEmails);
    }
  };

  const invite = async (email: string) => {
    await inviteUser({
      customerId: customer?._id,
      role: 'customer',
      companyEmail: email,
      agent: {
        firstName: 'customer',
        lastName: 'customer',
      },
      email: email,
      source: 'customer',
    }).unwrap();

    toast(3000, 'success', 'customer_invited_successfully');
  };

  return (
    <FormSection>
      <SectionTitle>{t('Add_Client_Contact_Information')}</SectionTitle>
      <Typography fontWeight={500} fontSize="0.8125rem" marginBottom="20px">
        {t('email_list')}
      </Typography>

      <Stack direction="row" gap="12px" alignItems="flex-start">
        <Controller
          name="emailInput"
          control={control}
          rules={{
            pattern: {
              value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
              message: t('validation.email'),
            },
            validate(value) {
              console.log('emailsemailsemails', emails);
              if (!value) {
                return true;
              }

              return ![customer?.email, ...customer?.otherEmails]?.includes(
                value?.toString(),
              );
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              type="text"
              label={t('email_address')}
              variant="outlined"
              error={!!errors?.emailInput}
              helperText={formatError(errors?.emailInput)}
              fullWidth
              disabled={!canEdit}
              onChange={(value) => field.onChange(value)}
            />
          )}
        />

        <IconButton
          disabled={!emailInput || !!errors?.emailInput || !canEdit}
          onClick={addEmail}
        >
          <PlusCircle
            color={
              emailInput && !errors?.emailInput
                ? theme.primaryActiveColor
                : theme.iconColor
            }
            size="24"
            weight="fill"
          />
        </IconButton>
      </Stack>

      <Each
        of={emails}
        render={(email, index) => {
          const agent = customer.agents.find(
            (agent) => agent.email === email.email,
          );
          return (
            <EmailRow
              key={index}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <Stack direction="row" alignItems="center" width="100%">
                <Stack direction="row" alignItems="center" gap="8px">
                  <Typography
                    sx={{
                      marginLeft: '4px',
                      color: agent?.isLocked ? theme.grey2 : 'black',
                    }}
                  >
                    <>{email.email}</>
                  </Typography>
                  <If condition={agent?.isLocked}>
                    <Prohibit color={theme.chipRedBg} />
                  </If>
                  <If condition={email.main}>
                    <MainTypo
                      sx={{
                        backgroundColor: theme?.primaryLight1,
                        border: `1px solid ${theme.strokePrimary}`,
                      }}
                    >
                      {t('main')}
                    </MainTypo>
                  </If>
                  <EmailStatus agent={agent} />
                </Stack>

                <If condition={!!canEdit && hoveredIndex === index}>
                  <Stack direction="row" alignItems="center" marginLeft="auto">
                    <If
                      condition={
                        !agent || agent?.status === AgentStatuses.Inactive
                      }
                    >
                      <If
                        condition={!isInviting}
                        otherwise={
                          <CircularProgress color="primary" size={16} />
                        }
                      >
                        <ActionIconButton
                          title="invite"
                          onClick={() => {
                            invite(email.email);
                          }}
                          icon={<PaperPlaneTilt />}
                          disabled={!!isInviting}
                        />
                      </If>
                    </If>
                    <If condition={!!otherEmails?.length}>
                      <ActionIconButton
                        title={
                          email.main
                            ? 'customer.main.remove'
                            : 'customer.main.markAsMain'
                        }
                        onClick={() => toggleMain(email)}
                        icon={<Flag />}
                        iconProps={{
                          weight: email.main ? 'fill' : 'regular',
                          color: email.main ? 'goldenrod' : 'inherit',
                        }}
                        disabled={!!isAddingEmail}
                      />

                      <ActionIconButton
                        title="delete"
                        onClick={() => removeEmail(email, agent?._user)}
                        icon={<Trash color={theme?.chipRedBg} />}
                        disabled={!!isAddingEmail}
                      />
                    </If>
                  </Stack>
                </If>
              </Stack>
            </EmailRow>
          );
        }}
      />
    </FormSection>
  );
};

interface EmailRowProps {
  hovered?: boolean;
}

const EmailRow = styled.div<EmailRowProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 10px);
  height: 32px;
  padding: 2px 8px;
  gap: 8px;
  border: 1px solid #adaaaaef;
  opacity: 1;

  &:hover {
    background-color: #fafafa;
  }
  &:first-child {
    border-radius: 5px 5px 0 0;
  }

  &:last-child {
    border-bottom: 1px solid #adaaaaef;
    border-radius: 0 0 5px 5px;
  }

  &:not(:last-child) {
    border-bottom: none;
    border-radius: 0;
  }
`;
