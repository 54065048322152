export enum AgentStatuses {
  Active = 'active',
  Inactive = 'inactive',
  Invited = 'invited',
  Disabled = 'disabled',
}

export enum LicenseValidationStatus {
  NOT_VALIDATED = 0,
  VALID = 1,
  INVALID = 2,
}

export interface IEmployeeRowData {
  __typeIcon: {
    cellData: string;
  };
  __roundtripIcon: {
    cellData: boolean;
  };
  name: {
    cellData: {
      firstName: string;
      lastName: string;
      isActive: boolean;
      id: string;
    };
  };
  warehouse: {
    cellData: string;
  };
  vehicle: {
    cellData: string;
  };
  phone_Number: {
    cellData: string;
  };
  email: {
    cellData: string;
  };
  validation_Status: {
    cellData: any;
  };
  last_active: {
    preferredAgent: any;
    userId: string;
    companyEmail: string;
  };
  score: {
    score: number;
    orderValidationCache: any;
  };
  __actions: {
    cellData: {
      id: string;
    };
  };
}
