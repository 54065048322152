interface Props {
  color: string; //css color
}

export function StatusDot({ color }: Props) {
  return (
    <div
      style={{
        width: '8px',
        height: '8px',
        borderRadius: '50px',
        backgroundColor: color,
        marginRight: '4px',
      }}
    />
  );
}
