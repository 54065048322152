import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useUpdateRoundTripMutation } from 'common/services/roundtripApi';
import { useTranslation } from 'react-i18next';
import { useToaster } from 'hooks/useToaster';
import { themes } from 'styles/theme/themes';
import { SwitchStopWrapper } from 'app/components/RoundTrips/styles/styles';
import { useCreateShortcut } from 'app/pages/RoundTrips/components/KeyboardShortcuts/hooks/useCreateShortcut';

interface Props {
  setAnchorEl_switchStop: any;
  currentRoundtrip: any;
  currentPosition: any;
  rtId: any;
  setAssignedOrders: any;
  refreshOrders?: () => void;
}

export const SwitchStopPopover: React.FC<Props> = ({
  setAnchorEl_switchStop,
  currentRoundtrip,
  currentPosition,
  rtId,
  setAssignedOrders,
  refreshOrders,
}) => {
  const { t } = useTranslation();
  const toast = useToaster();

  const handlePopoverClose = () => {
    setAnchorEl_switchStop(null);
    setSelectedIndex(null);
  };

  const [updateRoundtripData] = useUpdateRoundTripMutation();

  const handleSwitchStop = async (e, oldIndex, newIndex) => {
    e?.stopPropagation();
    setAnchorEl_switchStop(null);
    if (currentRoundtrip?.stops) {
      const from = currentRoundtrip.stops[oldIndex];
      const to = currentRoundtrip.stops[newIndex];
      const newOrder = [...currentRoundtrip.stops];

      newOrder[newIndex] = from;
      newOrder[oldIndex] = to;

      setAssignedOrders(newOrder);
      await updateRoundtripData({
        id: rtId,
        data: { stops: newOrder },
      });
      refreshOrders?.();
      toast(5000, 'success', 'roundtrip.switched_stop');
    }
  };

  const [selectedIndex, setSelectedIndex] = useState<null | number>(null);
  const selectedItemRef = useRef<any>(null);

  function SelectOption(direction) {
    const stopsCount = currentRoundtrip?.stops?.length;

    if (direction === 'up' && selectedIndex === null) {
      if (currentPosition === stopsCount) {
        setSelectedIndex(stopsCount - 2);
      } else {
        setSelectedIndex(stopsCount - 1);
      }
    } else if (direction === 'down' && selectedIndex === null) {
      if (currentPosition === 1) {
        setSelectedIndex(1);
      } else {
        setSelectedIndex(0);
      }
    } else if (direction === 'up' && selectedIndex !== null) {
      if (currentPosition === 1 && selectedIndex === 1) {
        setSelectedIndex(stopsCount - 1);
      } else if (currentPosition === stopsCount && selectedIndex === 0) {
        setSelectedIndex(stopsCount - 2);
      } else if (currentPosition > 1 && selectedIndex === 0) {
        setSelectedIndex(stopsCount - 1);
      } else if (selectedIndex === currentPosition) {
        setSelectedIndex(selectedIndex - 2);
      } else {
        setSelectedIndex(selectedIndex - 1);
      }
    } else if (direction === 'down' && selectedIndex !== null) {
      if (currentPosition === stopsCount && selectedIndex === stopsCount - 2) {
        setSelectedIndex(0);
      } else if (currentPosition === 1 && selectedIndex === stopsCount - 1) {
        setSelectedIndex(1);
      } else if (
        currentPosition < stopsCount &&
        selectedIndex === stopsCount - 1
      ) {
        setSelectedIndex(0);
      } else if (selectedIndex === currentPosition - 2) {
        setSelectedIndex(selectedIndex + 2);
      } else {
        setSelectedIndex(selectedIndex + 1);
      }
    }
  }

  function ConfirmSelection() {
    if (selectedIndex === null) return;
    handleSwitchStop(null, currentPosition - 1, selectedIndex);
  }

  useCreateShortcut({
    shortcut: {
      key1: 'ArrowDown',
      action: {
        callback: () => SelectOption('down'),
        eventName: 'keydown',
      },
    },
  });

  useCreateShortcut({
    shortcut: {
      key1: 'ArrowUp',
      action: {
        callback: () => SelectOption('up'),
        eventName: 'keydown',
      },
    },
  });

  useCreateShortcut({
    shortcut: {
      key1: 'Enter',
      action: {
        callback: () => ConfirmSelection(),
        eventName: 'keyup',
      },
    },
  });

  useEffect(() => {
    if (selectedItemRef.current) {
      selectedItemRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [selectedIndex]);

  return (
    <SwitchStopWrapper onMouseLeave={handlePopoverClose}>
      <PopoverTitle>{t('roundtrip.switch_stop')}</PopoverTitle>
      <PopoverBody>
        <StopsWrapper>
          {currentRoundtrip?.stops?.map((stop, index) => {
            return (
              <>
                {currentPosition !== index + 1 && (
                  <>
                    {stop.type === 'fuel' || stop.type === 'maintenance' ? (
                      <StopsButton
                        selected={selectedIndex === index}
                        onClick={(e) =>
                          handleSwitchStop(e, currentPosition - 1, index)
                        }
                        ref={selectedIndex === index ? selectedItemRef : null}
                      >
                        {`${
                          index + 1
                        }. ${stop?.type[0].toUpperCase()}${stop?.type?.substr(
                          1,
                        )} - ${stop?._place?.location?.city}`}
                      </StopsButton>
                    ) : (
                      <StopsButton
                        selected={selectedIndex === index}
                        onClick={(e) =>
                          handleSwitchStop(e, currentPosition - 1, index)
                        }
                        ref={selectedIndex === index ? selectedItemRef : null}
                      >
                        {`${index + 1}. ${stop?._order?._customer?.code} - ${
                          stop?._order?._customer?.publicName
                        }`}
                      </StopsButton>
                    )}
                  </>
                )}
              </>
            );
          })}
        </StopsWrapper>
      </PopoverBody>
    </SwitchStopWrapper>
  );
};

const PopoverTitle = styled.h4`
  margin: 0;
  font-size: 0.625rem;
  color: ${themes.default.nobel};
  position: fixed;
  z-index: 1;
  background: white;
  opacity: 1;
  padding: 12px 16px 8px 16px;
`;

const PopoverBody = styled.div`
  position: relative;
  padding: 0;
  margin-top: 36px;
  height: 120px;
  overflow-y: auto;
`;

const StopsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const StopsButton = styled.button<{ selected?: boolean }>`
  font-size: 0.75rem;
  color: ${themes.default.textColorPrimary};
  white-space: nowrap;
  cursor: pointer;
  margin: 0;
  padding: 6px 16px;
  border: 0;
  background: ${(props) =>
    props.selected ? themes?.default?.solitude : 'transparent'};
  text-align: left;
  transition-duration: 0.1s;

  :hover {
    background: ${themes.default.grey};
  }

  :active {
    opacity: 0.6;
  }
`;
