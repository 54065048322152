import React, { useEffect } from 'react';

interface Props {
  path: google.maps.LatLngLiteral[];
  options: google.maps.PolylineOptions;
  map: google.maps.Map | null;
  onClick?: () => void;
}

export const PolylineComponent: React.FC<Props> = ({
  path,
  options,
  map,
  onClick,
}) => {
  useEffect(() => {
    if (!map) return;

    const polyline = new window.google.maps.Polyline({
      path,
      ...options,
      map,
    });

    if (onClick) {
      polyline.addListener('click', onClick);
    }

    return () => {
      polyline.setMap(null);
    };
  }, [path, options, map, onClick]);

  return null;
};
