import { Headers } from 'app/components/RoundTrips/components/Header';
import { useState, useEffect, createContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import { useSelector } from 'react-redux';
import { TableNavWrapper } from 'styles/components/roundTrips';
import {
  selectAccordionStatus,
  selectDateBegin,
  selectDateEnd,
  selectDateValue,
} from 'common/store/roundtrips/selectors';
import useRoundtripStorage from 'app/components/RoundTrips/hooks/useRoundtripStorage';
import { roundtripActions } from 'common/store/roundtrips';
import { AgentCategories } from 'app/components/RoundTrips/RoundTripData/agentCategories';
import { ALL_ROUNDTRIPS, SCHEDULED_ROUNDTRIPS } from 'utils/routes';

export const TabsContext = createContext<any>(null);

export function RoundtripsTabs() {
  // hooks
  const dispatch = useDispatch();
  const location = useLocation();

  // usestate and variables
  const today = dayjs().startOf('day');
  const accordionStatus = useSelector(selectAccordionStatus) || [
    { key: '', value: [] },
  ];
  const [dateBegin, setDateBegin] = useState<string>(
    useSelector(selectDateBegin) || today.format('YYYY-MM-DD'),
  );
  const [dateEnd, setDateEnd] = useState<string>(
    useSelector(selectDateEnd) || today.endOf('day').format('YYYY-MM-DD'),
  );
  const [userContext, setUserContext] = useState<AgentCategories>(
    AgentCategories.Logistic,
  );
  const [search, setSearch] = useState('');
  const [headCells, setHeadCells] = useState<any[]>([]);
  const [heads, setHeads] = useState<any[]>([]);
  const [emptyData, setEmptyData] = useState<boolean>(false);

  const stateDateValue = useSelector(selectDateValue);
  const [dateValue, setDateValue] = useState<Dayjs | null>(
    stateDateValue ? dayjs(stateDateValue) : today,
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [warehouseName, setWarehouseName] = useState('');

  const [tab, setTab] = useState(
    location.pathname?.includes(SCHEDULED_ROUNDTRIPS)
      ? SCHEDULED_ROUNDTRIPS
      : ALL_ROUNDTRIPS,
  );

  useRoundtripStorage(dateBegin, dateEnd, accordionStatus, dateValue);

  useEffect(() => {
    return () => {
      setDateValue(today);
      setDateBegin(today.format('YYYY-MM-DD'));
      setDateEnd(today.endOf('day').format('YYYY-MM-DD'));

      dispatch(roundtripActions.setDateValue(undefined));
      dispatch(roundtripActions.setDateBegin(undefined));
      dispatch(roundtripActions.setDateEnd(undefined));
    };
  }, []);

  useEffect(() => {
    if (dateValue !== null) {
      setDateBegin(dateValue.format('YYYY-MM-DD'));
      setDateEnd(dateValue.endOf('day').format('YYYY-MM-DD'));
    }
  }, [dateValue]);

  const viewMode = useMemo(() => {
    return dayjs(dateValue).isBefore(today);
  }, [dateValue, today]);

  return (
    <TableNavWrapper
      hide={accordionStatus?.every((key) => key.value.every((value) => !value))}
    >
      <TabsContext.Provider
        value={{
          dateValue,
          setDateValue,
          dateBegin,
          dateEnd,
          userContext,
          setUserContext,
          search,
          setSearch,
          headCells,
          setHeadCells,
          heads,
          setHeads,
          warehouseName,
          setWarehouseName,
          viewMode,
          emptyData,
          setEmptyData,
          anchorEl,
          setAnchorEl,
          tab,
          setTab,
        }}
      >
        <Headers />
        <Outlet />
      </TabsContext.Provider>
    </TableNavWrapper>
  );
}
