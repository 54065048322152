import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

interface DialogProps {
  open: boolean;
  setOpen: any;
  title: string;
  description: string;
  actionYesText: string;
  actionYes: any;
  actionNoText: string;
  yesButtonSx?: any;
}

/**
 *
 * @deprecated this component is deprecated, please use the useModal / or create a customer m=hook based on useModal to display a dialog
 */
const DialogClearRoundtrip: React.FC<DialogProps> = ({
  open,
  setOpen,
  title,
  description,
  actionYesText,
  actionYes,
  actionNoText,
  yesButtonSx,
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 20,
            top: 27,
            color: 'gray',
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle
          id="alert-dialog-title"
          style={{
            fontSize: '1.429rem',
            fontWeight: '500',
            padding: '32px 70px 32px 24px',
          }}
        >
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{
              fontSize: '1rem',
              color: 'black',
              fontWeight: '400',
              paddingBottom: '15px',
            }}
          >
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ paddingBottom: '20px', paddingRight: '20px' }}>
          <Button
            variant="text"
            style={{
              fontSize: '1rem',
              fontWeight: '400',
              padding: '6px 15px',
              color: 'black',
            }}
            onClick={() => {
              handleClose();
            }}
          >
            {actionNoText}
          </Button>
          <Button
            color={yesButtonSx?.colorProp || 'primary'}
            variant="contained"
            sx={{
              ...yesButtonSx,

              fontSize: '1rem',
              fontWeight: '400',
              color: 'white',
              padding: '6px 15px',
            }}
            onClick={() => {
              actionYes();
            }}
            autoFocus
          >
            {actionYesText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DialogClearRoundtrip;
