import styled from 'styled-components';

export const MapWrapper = styled.div((props) => ({
  maxHeight: `calc(100vh - ${props.theme.tableNavHeight} - ${props.theme.toolBarHeight})`,
  '& .marker-label': {
    fontSize: '0.5625rem !important',
    fontWeight: 700,
    width: '23px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  flexGrow: 1,
  '& .gm-style iframe + div': {
    border: '2px solid transparent !important',
  },
}));
