import { IconButton } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { themes } from 'styles/theme/themes';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import If from 'app/components/If';
import { selectTheme } from 'styles/theme/slice/selectors';
import { useSelector } from 'react-redux';
import React from 'react';

interface FilterIconProps {
  handleOpenFilter: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  filtersCount: number;
  filterOn: HTMLButtonElement | null;
  filterComponent?: JSX.Element | null;
  showTitle?: boolean;
}

const FilterIcon = ({
  handleOpenFilter,
  disabled,
  filtersCount,
  filterOn,
  filterComponent,
  showTitle,
}: FilterIconProps) => {
  const { t } = useTranslation();
  const theme = useSelector(selectTheme);

  const filterActive = !!filtersCount && filtersCount >= 1;

  return (
    <FilterWrapper>
      <IconButtonWrapper filterActive={filterActive}>
        <IconButton
          onClick={handleOpenFilter}
          // disableRipple
          disabled={disabled}
          sx={{
            position: 'relative',
            opacity: disabled && !filtersCount ? 0.3 : 1,
            marginRight: filterActive ? '8px' : '',
          }}
        >
          <FilterListIcon
            sx={{
              color:
                filterOn || filterActive
                  ? theme?.primaryActiveColor
                  : theme?.textColorPrimary,
              height: themes?.default?.iconSize,
              width: themes?.default?.iconSize,
            }}
          />
          <If condition={!!showTitle}>
            <FilterLabel>{t('nav.table_nav.tab_filter')}</FilterLabel>
          </If>
        </IconButton>
        <CounterLabel
          style={{
            display: filterActive ? 'block' : 'none',
            padding: 0,
            position: 'absolute',
            right: 0,
          }}
        >
          ({filtersCount})
        </CounterLabel>
      </IconButtonWrapper>
      {filterComponent}
    </FilterWrapper>
  );
};

export default FilterIcon;

const FilterLabel = styled.span`
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: uppercase;
  color: ${(props) => props.theme.textColorPrimary};
  padding-left: 5px;
`;
const CounterLabel = styled.span`
  font-size: 0.875rem;
  font-weight: 500;
  color: ${(props) => props.theme.primaryActiveColor};
`;
const FilterWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const IconButtonWrapper = styled.div<{ filterActive: boolean }>`
  display: flex;
  justify-content: center;
  flex-grow: 1;
  align-items: center;
  position: relative;
  margin-right: ${(props) => (props.filterActive ? '8px' : '')};
`;
