import { Fade, IconButton, Slide, Stack, Typography } from '@mui/material';
import { GridAccordionSummaryWrapper, SectionView } from '../styles';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ActionButtons } from '..';
import { useSubscribeObject } from 'common/hooks/ws';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { LoadingPulse } from 'app/components/LoadingPulse';
import styled from 'styled-components';
import { roundtripActions } from 'common/store/roundtrips';
import { useDispatch, useSelector } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'app/pages/AddRoundTrips/components/TableComponent/styles/accordion';
import If from 'app/components/If';
import { useTranslation } from 'react-i18next';
import { RoundTripStatus } from './RoundTripStatus';
import dayjs from 'dayjs';
import { TabsContext } from 'app/pages/RoundtripsTabs';
import { themes } from 'styles/theme/themes';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useNavigate } from 'react-router-dom';
import { ALL_ROUNDTRIPS, ROUNDTRIP } from 'utils/routes';
import { selectDecks } from 'common/store/organization/selectors';
import { usePrint, usePrintV2 } from 'hooks/RoundTrips/usePrint';
import { ManagedTooltip } from '../helpers/Components/ManagedTooltip';
import {
  RoundtripStatusesActiveDate,
  RoundtripStatusesInActiveDate,
} from 'app/pages/AddRoundTrips/data/status';
import { getStatusesToShow } from 'app/pages/AddRoundTrips/functions';
import {
  Clock,
  PencilSimple,
  PlusCircle,
  Printer,
} from '@phosphor-icons/react';
import { Icon } from 'app/components/Icon';
import { PrintMenu } from 'app/components/RoundTrips/components/PrintMenu';
import { toQueryParams } from 'hooks/useQueryParams';
import { Can } from 'hooks/Abilities/context';
import { useAbility } from 'hooks/Abilities';

interface Props {
  index: any;
  rows: any;
  expanded: any;
  handleChange: any;
  roundtripCluster: any;
  _deck: any;
  handleProceedClick: any;
  name: any;
  tableData: any;
  theme: any;
  triggerSingleCluster: any;
  clusterLoading?: string;
  handleClickOpenSchedule: any;
  setClusterId: any;
  onClickAdd: any;
  viewMode?: boolean;
  filterStatus?: any;
  setFilterStatus?: any;
  setExpanded?: React.Dispatch<React.SetStateAction<any>>;
}

export const AccordionItemCluster: React.FC<Props> = ({
  index,
  rows,
  expanded,
  handleChange,
  roundtripCluster,
  _deck,
  handleProceedClick,
  name,
  tableData,
  theme,
  triggerSingleCluster,
  clusterLoading,
  setClusterId,
  handleClickOpenSchedule,
  onClickAdd,
  viewMode,
  filterStatus,
  setFilterStatus,
  setExpanded,
}) => {
  const ability = useAbility();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const decks = useSelector(selectDecks);

  const [clusterStatusFilter, setClusterStatusFilter] = useState<any>({});
  const activeCluster = useMemo(
    () => rows[index]?.clusters?.find((cluster) => cluster.status === 'active'),
    [rows, index],
  );

  const cluster = useMemo(
    () => activeCluster ?? rows[index]?.clusters?.[0],
    [activeCluster, rows, index],
  );

  const currentCluster = useMemo(
    () => rows[index]?.clusters?.find((c) => c?._deck?._id === _deck),
    [rows, index, _deck],
  );

  const subscribedClusterId = useMemo(() => {
    return !!activeCluster
      ? activeCluster._id
      : rows[index]?.clusters?.length === 1
        ? rows[index].clusters[0]?._id || rows[index].clusters[0]?.id
        : undefined;
  }, [activeCluster, rows, index]);

  const lastMessage = useSubscribeObject(
    'roundtrip-cluster',
    subscribedClusterId,
  );
  const { triggerPrint } = usePrint();

  useEffect(() => {
    setClusterStatusFilter(
      filterStatus.find(
        (cluster) => cluster?.roundtripId === activeCluster?._id,
      ),
    );
  }, [filterStatus]);

  useEffect(() => {
    if (subscribedClusterId && lastMessage !== null) {
      triggerSingleCluster(subscribedClusterId, false);
    }
  }, [lastMessage]);

  useEffect(() => {
    const defaultDeck = decks?.[0]?._id;
    if (defaultDeck === _deck && !!subscribedClusterId) {
      triggerSingleCluster(subscribedClusterId);
      const expandedCopy = { ...expanded };
      expandedCopy[rows[index].id] = true;
      setExpanded?.(expandedCopy);
    }
  }, [subscribedClusterId]);

  const today = dayjs().startOf('day').format('YYYY-MM-DD');
  const { dateBegin, setTab } = useContext(TabsContext);
  const roundtripStatusesList = dayjs(dateBegin)?.isAfter(dayjs(today))
    ? RoundtripStatusesInActiveDate
    : RoundtripStatusesActiveDate;
  const roundtripStatuses = getStatusesToShow(roundtripStatusesList);

  const subscribedDate = dayjs(dateBegin)?.format('YYYYMMDD');
  const wsDateMessage = useSubscribeObject(
    'roundtrip-cluster-date',
    subscribedDate,
  );

  useEffect(() => {
    if (wsDateMessage) {
      const message = JSON.parse(wsDateMessage);
      if (
        message?.data.action === 'roundtrip-cluster-status-update' &&
        subscribedClusterId
      ) {
        triggerSingleCluster(subscribedClusterId, false);
      }
    }
  }, [wsDateMessage]);

  // set filter state
  const seFilter = (statusTo, e) => {
    e.stopPropagation();
    let flag = 0;
    if (filterStatus?.[0]) {
      const updatedArray = filterStatus.map((obj) => {
        if (obj.roundtripId === activeCluster?._id) {
          flag = 1;
          if (obj.statuses.includes(statusTo)) {
            obj.statuses = obj.statuses.filter((status) => status !== statusTo);
          } else {
            obj.statuses.push(statusTo);
          }
        }
        return obj;
      });
      if (flag === 0) {
        const addClusterArray = [
          ...filterStatus,
          { roundtripId: activeCluster?._id, statuses: [statusTo] },
        ];
        setFilterStatus(addClusterArray);
      } else {
        setFilterStatus(updatedArray);
      }
    } else {
      setFilterStatus([
        { roundtripId: activeCluster?._id, statuses: [statusTo] },
      ]);
    }
  };

  const getStatusCount = useCallback(
    (status) => {
      if (activeCluster?.facets?.[0]?.status) {
        return (
          activeCluster?.facets?.[0]?.status?.find(
            (s) => s._id === status.status,
          )?.count || 0
        );
      } else {
        return (
          activeCluster?.roundtripsStatus?.[status.key || status.status] || 0
        );
      }
    },
    [activeCluster],
  );

  const buttonSx = {
    width: '36px',
    height: '36px',
  };

  const { triggerPrint: triggerPrint2 } = usePrintV2();

  return (
    <Accordion
      TransitionProps={{ timeout: { appear: 1, enter: 1, exit: 200 } }}
      key={rows[index].id}
      expanded={!!expanded[rows[index].id]}
      onChange={
        activeCluster
          ? handleChange(rows[index].id, activeCluster)
          : rows[index]?.clusters?.length === 1 &&
            handleChange(rows[index].id, rows[index]?.clusters[0])
      }
      sx={{ marginBottom: '15px' }}
    >
      <GridAccordionSummaryWrapper>
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={null}
          sx={{
            width: '100%',
            height: '100%',
            position: 'sticky',
            left: 0,
            minHeight: 'auto',
          }}
          style={{
            cursor:
              !!activeCluster || rows[index]?.clusters?.length === 1
                ? 'pointer'
                : 'no-drop',
          }}
        >
          <Typography sx={{ width: '100%' }}>
            <SectionView expanded={!!expanded[rows[index].id]}>
              {!!expanded[rows[index].id] ? (
                <KeyboardArrowUpIcon
                  sx={{
                    color: theme.primary,
                    position: 'sticky',
                    left: '16px',
                  }}
                />
              ) : (
                <KeyboardArrowDownIcon
                  sx={{
                    color: !!activeCluster
                      ? theme.icon
                      : 'rgba(148,148,148,0.87)',
                    position: 'sticky',
                    left: '16px',
                  }}
                />
              )}
              <div className="dropdown-text full-width">
                <div className="warehouses_status">
                  <div className="title_warehouse">{rows[index].key}</div>
                  {rows[index]?.clusters?.length > 0 ? (
                    <>
                      <If condition={!activeCluster}>
                        <div className="inactive_roundtrip">
                          {t('cluster.not_activated')}
                        </div>
                        {rows[index]?.clusters?.length > 1 && (
                          <ResultsDiv>
                            <InfoOutlinedIcon />
                            <div>
                              {t('you_have_results', {
                                count: rows[index]?.clusters?.length,
                              })}
                              <span
                                onClick={() => {
                                  setTab(ALL_ROUNDTRIPS);
                                  navigate(`${ROUNDTRIP}/${ALL_ROUNDTRIPS}`);
                                }}
                              >
                                {t('view_details')}
                              </span>
                            </div>
                          </ResultsDiv>
                        )}
                      </If>
                      {activeCluster && (
                        <div className="status">
                          {roundtripStatuses
                            ?.filter(
                              ({ status }) =>
                                status !== 'loading' && status !== 'unloading',
                            )
                            .map((status) => (
                              <RoundTripStatus
                                title={t(`roundtrips.status.${status.status}`)}
                                color={status.color}
                                backgroundActive={clusterStatusFilter?.statuses?.find(
                                  (value) => value === status.status,
                                )}
                                number={getStatusCount(status)}
                                onClick={(e) => {
                                  seFilter(status.status, e);
                                }}
                              />
                            ))}
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="no_roundtrip">
                      {t('cluster.no_roundtrips_yet')}
                    </div>
                  )}
                </div>
                <If
                  condition={!viewMode && ability.can('canedit', 'roundtrips')}
                  otherwise={
                    <ActionButtons viewMode={true}>
                      {(activeCluster || rows[index]?.clusters?.length === 1) &&
                        ability.can('canexport', 'roundtrips') && (
                          <ManagedTooltip
                            title={t('common.buttons.print_roadmap')}
                          >
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                triggerPrint({
                                  clusterId:
                                    activeCluster?._id ??
                                    rows[index]?.clusters?.[0]?._id,
                                });
                              }}
                              sx={buttonSx}
                            >
                              <Icon
                                icon={<Printer />}
                                size={themes.default.iconSizeMedium}
                              />
                            </IconButton>
                          </ManagedTooltip>
                        )}
                    </ActionButtons>
                  }
                >
                  {rows[index]?.clusters?.length > 0 ? (
                    <ActionButtons className="action_buttons">
                      <Stack direction="row">
                        {rows[index]?.clusters?.length === 1 &&
                          !activeCluster &&
                          ability.can('canedit', 'roundtrips') && (
                            <ManagedTooltip
                              title={t('common.buttons.activate')}
                            >
                              <IconButton
                                aria-label="schedule"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setClusterId(rows[index]?.clusters[0]?._id);
                                  handleClickOpenSchedule();
                                }}
                                sx={buttonSx}
                              >
                                <Icon
                                  icon={<Clock />}
                                  size={themes.default.iconSizeMedium}
                                />
                              </IconButton>
                            </ManagedTooltip>
                          )}
                        <>
                          {(activeCluster ||
                            rows[index]?.clusters?.length === 1) && (
                            <>
                              <Can I="canexport" a="roundtrips">
                                <PrintMenu
                                  onPrintRoadmap={() => {
                                    triggerPrint({
                                      clusterId:
                                        activeCluster?._id ??
                                        rows[index]?.clusters?.[0]?._id,
                                    });
                                  }}
                                  onPrintDeliveryNotes={() => {
                                    const roundtripIds = cluster.roundtrips.map(
                                      (r) => r._id,
                                    );
                                    const query = toQueryParams({
                                      format: 'pdf',
                                      roundtripIds: roundtripIds.join(','),
                                    });
                                    triggerPrint2({
                                      uri: `roundtrips/generate-delivery-notes${query}`,
                                    });
                                  }}
                                  onPrintPreparationNotes={() => {
                                    const roundtripIds = cluster.roundtrips.map(
                                      (r) => r._id,
                                    );
                                    const query = toQueryParams({
                                      format: 'pdf',
                                      roundtripIds: roundtripIds.join(','),
                                    });
                                    triggerPrint2({
                                      uri: `roundtrips/generate-preparation-notes${query}`,
                                    });
                                  }}
                                />
                              </Can>
                              <Can I="canedit" a="roundtrips">
                                <ManagedTooltip title={t('edit')}>
                                  <IconButton
                                    aria-label="edit"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      dispatch(
                                        roundtripActions.setClusterId(
                                          activeCluster?._id ??
                                            rows[index]?.clusters?.[0]?._id,
                                        ),
                                      );
                                      handleProceedClick(
                                        {
                                          _id: _deck,
                                          name,
                                          shortName:
                                            roundtripCluster?.shortName,
                                        },
                                        {
                                          _id:
                                            activeCluster?._id ??
                                            rows[index]?.clusters?.[0]?._id,
                                        },
                                        index + 1,
                                        currentCluster?.mode,
                                        currentCluster?.agentCategory,
                                      );
                                    }}
                                    sx={buttonSx}
                                  >
                                    <Icon
                                      icon={<PencilSimple />}
                                      size={themes.default.iconSizeMedium}
                                    />
                                  </IconButton>
                                </ManagedTooltip>
                              </Can>
                            </>
                          )}
                        </>
                      </Stack>
                    </ActionButtons>
                  ) : (
                    <Can I="canedit" a="roundtrips">
                      <ActionButtons className="action_buttons">
                        <ManagedTooltip title={t('common.add')}>
                          <IconButton
                            aria-label="edit"
                            onClick={(e) => {
                              e.stopPropagation();
                              onClickAdd(e, index);
                              // dispatch(roundtripActions.setClusterId(_id));
                              // handleProceedClick(
                              //   {
                              //     _id: _deck,
                              //     name,
                              //   },
                              //   {
                              //     _id,
                              //   },
                              // );
                            }}
                            sx={buttonSx}
                          >
                            <Icon
                              icon={<PlusCircle />}
                              size={themes.default.iconSizeMedium}
                            />
                          </IconButton>
                        </ManagedTooltip>
                      </ActionButtons>
                    </Can>
                  )}
                </If>
              </div>
            </SectionView>
          </Typography>
        </AccordionSummary>
      </GridAccordionSummaryWrapper>
      <AccordionDetails>
        <If
          condition={
            !!(
              clusterLoading &&
              ((activeCluster && clusterLoading === activeCluster?._id) ||
                (rows[index]?.clusters?.length === 1 &&
                  clusterLoading === rows[index]?.clusters?.[0]?._id))
            )
          }
        >
          <Fade in unmountOnExit>
            <LoadingContainer style={{ marginLeft: '20px' }}>
              <LoadingPulse />
            </LoadingContainer>
          </Fade>
        </If>
        <If
          condition={
            !clusterLoading ||
            (activeCluster &&
              clusterLoading !== activeCluster?._id &&
              rows[index]?.clusters?.length === 1 &&
              clusterLoading !== rows[index]?.clusters?.[0]?._id)
          }
        >
          <Slide
            direction="down"
            in={!!expanded[rows[index].id]}
            mountOnEnter
            unmountOnExit
            timeout={300}
          >
            <div>{tableData}</div>
          </Slide>
        </If>
      </AccordionDetails>
    </Accordion>
  );
};

export const LoadingContainer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 43px;
  width: fit-content;
  position: sticky;
  left: 0;
`;

const ResultsDiv = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  color: ${(props) => props.theme.textColorPrimary};
  gap: 5px;
  & svg {
    width: 1rem;
    height: 1rem;
  }
  & span {
    color: ${(props) => props.theme.primaryActiveColor};
    text-decoration-line: underline;
    cursor: pointer;
    margin-left: 5px;
  }
`;
