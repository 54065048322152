import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { UserAvatar } from '../../../UserAvatar';
import { useSelector } from 'react-redux';
import { Icon } from 'app/components/Icon';
import {
  CaretLeft,
  Phone,
  Trash,
  Copy,
  PencilSimple,
  ArrowBendUpLeft,
} from '@phosphor-icons/react';
import { ButtonBase, Divider } from '@mui/material';
import { selectTheme } from 'styles/theme/slice/selectors';
import { ActionPopover, PopoverEl } from 'app/components/Popover';
import { useTranslation } from 'react-i18next';
import { useModal } from 'app/components/Dialog/hooks';
import { useDeleteChatMutation } from 'common/services/chatApi';
import { ChatContext } from 'app/components/Chat';
import { CustomIconButton } from '../../../CustomIconButton';
import {
  usePhoneCallHook,
  useVoiceCallHook,
} from '../../../../../../../hooks/VoiceCall/useVoiceCallHook';

interface Props {
  selectedUser: any;
  isCurrentUser: boolean;
  visibleElements: string;
  selectedMessagesUuid: string[];
  handleTopBarActions: (action: string) => void;
}

export const ChatWindowTopBar: React.FC<Props> = ({
  selectedUser,
  isCurrentUser,
  visibleElements,
  selectedMessagesUuid,
  handleTopBarActions,
}) => {
  // const authUser = useSelector(selectAuthUser);
  const theme = useSelector(selectTheme);
  const { i18n, t } = useTranslation();
  const locale = i18n.language;
  const { closeModal, openModal } = useModal();

  const userPhone = selectedUser?.currentAgent?.phone || selectedUser?.phone;

  const { handleCall: voiceCall } = useVoiceCallHook(selectedUser?._id);
  const { handleCall: phoneCall } = usePhoneCallHook(null, userPhone);

  const {
    selectedChatId,
    triggerRefetchChats,
    setSelectedUser,
    setSelectedChatId,
  } = useContext(ChatContext);

  const [deleteChat] = useDeleteChatMutation();

  const handleCofirmDeleteDialog = (name) => {
    openModal({
      action: {
        actionText: t('common.buttons.delete'),
        actionCallback: () => handleDeleteChat(),
      },
      title: t('chats.chat.delete.confirm'),
      deleteModal: true,
      cancel: true,
      content: t('chats.chat.delete.messsage', { name: name }),
    });
  };

  const handleDeleteChat = async () => {
    if (!selectedChatId) return;
    await deleteChat({ ids: [selectedChatId] }).then(() => {
      triggerRefetchChats();
      closeModal();
      setSelectedUser(null);
    });
  };

  const handleCloseChat = () => {
    setSelectedUser('');
    setSelectedChatId('');
  };

  const selectionMode = useMemo(() => {
    return selectedMessagesUuid?.length > 0;
  }, [selectedMessagesUuid]);

  const handleCall = () => {
    if (selectedUser?.voiceStatus === 'online') {
      voiceCall();
    } else if (userPhone) {
      phoneCall();
    }
  };

  return (
    <Wrapper>
      <LeftSection>
        {visibleElements === 'window' && (
          <CustomIconButton
            icon={<CaretLeft />}
            size={24}
            onClick={handleCloseChat}
          />
        )}
        {selectionMode ? (
          <SelectedCount>
            {t('selected_count', { count: selectedMessagesUuid?.length })}
          </SelectedCount>
        ) : (
          <>
            <UserAvatar
              size={32}
              userImagePath={selectedUser?.pictureUrl}
              fullName={`${selectedUser?.firstName} ${selectedUser?.lastName}`}
            />
            <div
              style={{ display: 'flex', gap: '4px', flexDirection: 'column' }}
            >
              <p className="name">
                {`${selectedUser?.firstName} ${selectedUser?.lastName}`}
                {isCurrentUser && (
                  <span className="role">{`(${t('you')})`}</span>
                )}
              </p>
              <p className="role">
                {selectedUser?._role?.localeName?.[`${locale}`]}
              </p>
            </div>
          </>
        )}
      </LeftSection>
      <RightSection selectionMode={selectionMode}>
        {selectionMode ? (
          <>
            {selectedMessagesUuid?.length === 1 && (
              <>
                <CustomIconButton
                  icon={<Icon icon={<ArrowBendUpLeft />} size={24} />}
                  size={24}
                  onClick={() => handleTopBarActions('reply')}
                  tooltip={t('chat.message.reply')}
                />
                <CustomIconButton
                  icon={<Icon icon={<PencilSimple />} size={24} />}
                  size={24}
                  onClick={() => handleTopBarActions('edit')}
                  tooltip={t('common.edit')}
                />
              </>
            )}
            <CustomIconButton
              icon={<Icon icon={<Copy />} size={24} />}
              size={24}
              onClick={() => handleTopBarActions('copy')}
              tooltip={t('common.copy')}
            />
            <CustomIconButton
              icon={<Icon icon={<Trash />} size={24} />}
              iconColor={themes?.default?.errorRed}
              size={24}
              tooltip={t('delete')}
              onClick={() => handleTopBarActions('delete')}
            />
            <CancelButton onClick={() => handleTopBarActions('cancel')}>
              <p>{t('Add_Client_Cancel')}</p>
            </CancelButton>
          </>
        ) : (
          <>
            <ButtonBase
              onClick={handleCall}
              sx={{
                width: '36px',
                height: '28px',
                borderRadius: '13px',
                background: `${theme.primary}14`,
              }}
            >
              <Icon
                icon={<Phone weight="fill" />}
                size={16}
                color={theme.primary}
              />
            </ButtonBase>
            <ButtonBase
              sx={{
                borderRadius: '50px',
              }}
            >
              <ActionPopover
                id={selectedUser?._id}
                handleAction={async () => {}}
                styling={{ justifyContent: 'end' }}
                iconSize={24}
                actions={[
                  {
                    action: 'openProfile',
                    onClick: () => {},
                    element: (
                      <PopoverEl redStatus="" style={{ width: '100%' }}>
                        <div className="popover-item">{t('open_profile')}</div>
                      </PopoverEl>
                    ),
                  },
                  {
                    action: 'divider',
                    seperator: true,
                    element: (
                      <PopoverEl redStatus="" style={{ width: '100%' }}>
                        <Divider />
                      </PopoverEl>
                    ),
                  },
                  {
                    action: 'delete',
                    onClick: () =>
                      handleCofirmDeleteDialog(
                        `${selectedUser?.firstName} ${selectedUser?.lastName}`,
                      ),
                    element: (
                      <PopoverEl
                        redStatus={themes.default.redStatus}
                        style={{ width: '100%' }}
                      >
                        <div className="delete popover-item">{t('delete')}</div>
                      </PopoverEl>
                    ),
                  },
                ]}
              />
            </ButtonBase>
          </>
        )}
      </RightSection>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 24px;
  border-bottom: 1px solid ${themes?.default?.gainsboro2};
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  p {
    margin: 0;
    font-family: Roboto;
    font-size: 0.92rem;
    line-height: 16px;
    letter-spacing: 0.15px;
  }
  .name {
    font-weight: 600;
  }
  .role {
    font-weight: 400;
  }
`;

const RightSection = styled.div<{ selectionMode?: boolean }>`
  display: flex;
  align-items: center;
  gap: ${(props) => (props.selectionMode ? '36px' : '16px')};
`;

const SelectedCount = styled.p`
  margin: 0;
  font-size: 1.14rem;
  line-height: 18.75px;
  color: ${themes?.default?.iconColor};
`;

const CancelButton = styled(ButtonBase)`
  p {
    margin: 0;
    font-size: 0.92rem;
    font-weight: 500;
    color: ${themes?.default?.nobel};
    text-transform: uppercase;
  }
`;
