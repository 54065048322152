import { Sales } from 'app/components/RoundTrips/RoundTripData/statuses';

export const resetData = (lastCode, setData, roundTripData, roleId, date) => {
  if (lastCode === 1 && !roundTripData?.length)
    setData([
      {
        date: date,
        code: 'R01',
        internalCode: undefined,
        stops: [],
        agents: [
          {
            _user: undefined,
            _role: roleId,
          },
        ],
      },
      // {
      //   date: date,
      //   code: 'R02',
      //   internalCode: undefined,
      //   stops: [],
      //   agents: [
      //     {
      //       _user: undefined,
      //       _role: roleId,
      //     },
      //   ],
      // },
      // {
      //   date: date,
      //   code: 'R03',
      //   internalCode: undefined,
      //   stops: [],
      //   agents: [
      //     {
      //       _user: undefined,
      //       _role: roleId,
      //     },
      //   ],
      // },
    ]);
  else {
    setData([
      {
        date: date,
        code: lastCode < 10 ? 'R0' + lastCode : 'R' + lastCode,
        internalCode: undefined,
        stops: [],
        agents: [
          {
            _user: undefined,
            _role: roleId,
          },
        ],
      },
    ]);
  }
};

export const AddRoundTrip = (lastCode, setData, data, date, roleId) => {
  const arr = [...data];
  if (lastCode < 10) {
    arr.push({
      date: date,
      code: 'R0' + lastCode,
      internalCode: undefined,
      stops: [],
      agents: [
        {
          _role: roleId,
        },
      ],
    });
  } else {
    arr.push({
      date: date,
      code: 'R' + lastCode,
      internalCode: undefined,
      stops: [],
      agents: [
        {
          _role: roleId,
        },
      ],
    });
  }
  setData(arr);
};

export const addInArrVehicle = (code, value, data, setData) => {
  let arr = [...data];
  arr = arr.map((object) => {
    if (object._vehicle === value?._id) {
      return { ...object, _vehicle: null, truck: null };
    }
    if (code === object.code)
      return { ...object, _vehicle: value?._id, truck: value };
    else return { ...object };
  });
  setData(arr);
};
export const addInArrByRole = (
  code,
  value,
  data,
  setData,
  roleId,
  key,
  organizationId = '',
) => {
  let arr = [...data];
  let agentsArray;
  const prefferedAgent =
    value?.cache?.agents[organizationId] ||
    value?.agents?.find((a) => a._organization === organizationId);
  const prefferedVehicle = prefferedAgent?._preferredVehicle;
  const prefferedVehicleId = prefferedVehicle?._id
    ? prefferedVehicle?._id
    : prefferedVehicle;

  console.log('object sales', prefferedVehicle);

  arr = arr.map((object) => {
    if (object.driver?._id === value?._id) {
      agentsArray = object.agents.map((agent) => {
        if (agent?._role?.key === key || agent?._role === roleId)
          return {
            _user: null,
            _role: roleId,
          };
        else return { ...agent };
      });
      return { ...object, agents: agentsArray, [key]: null };
    }
    if (object.sales?._id === value?._id) {
      agentsArray = object.agents.map((agent) => {
        if (agent?._role?.key === key || agent?._role === roleId)
          return {
            _user: null,
            _role: roleId,
          };
        else return { ...agent };
      });
      if (object._vehicle === prefferedVehicleId) {
        return {
          ...object,
          agents: agentsArray,
          [key]: null,
          _vehicle: null,
          truck: null,
        };
      }
      return {
        ...object,
        agents: agentsArray,
        [key]: null,
      };
    }
    if (code === object.code) {
      agentsArray = object.agents.map((agent) => {
        if (agent?._role?.key === key || agent?._role === roleId)
          return {
            _user: value?._id,
            _role: roleId,
          };
        else return { ...agent };
      });
      if (key === Sales) {
        return {
          ...object,
          agents: agentsArray,
          [key]: value,
          _vehicle: prefferedVehicleId,
          truck: prefferedVehicle,
        };
      }
      return {
        ...object,
        agents: agentsArray,
        [key]: value,
      };
    } else return { ...object };
  });
  setData(arr);
};
export const addInArrRoundrip = (code, value, data, setData) => {
  let arr = [...data];
  arr = arr.map((object) => {
    if (code === object.code)
      return { ...object, internalCode: value?.internalCode };
    else return { ...object };
  });
  setData(arr);
};
export const deleteButton = (code, setData, data, roundTripData) => {
  let arr = [...data];
  arr = arr.filter(function (item) {
    return item.code !== code;
  });
  const resp: any = [];
  arr.map((item) => {
    const lastCode = getLastCode(resp, roundTripData);
    resp.push({
      ...item,
      code: lastCode < 10 ? 'R0' + lastCode : 'R' + lastCode,
    });
  });
  setData(resp);
};

const getLastCode = (data, roundTripData) => {
  let a = 0;
  let i = 1;
  let code = 0;
  while (a === 0) {
    if (
      data?.find((d) => d.code === 'R' + i || d.code === 'R0' + i)?.code ||
      roundTripData?.find((d) => d.code === 'R' + i || d.code === 'R0' + i)
        ?.code
    ) {
      i = i + 1;
    } else {
      code = i;
      a = 1;
      break;
    }
  }
  return code;
};
