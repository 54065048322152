import { Grid } from '../../../../../../../components/Grid';
import { DepartmentRow } from '../DepartmentRow';
import React from 'react';
import {
  Department,
  DepartmentResponseDto,
} from '../../../../../../../../common/types/CompanyDepartments';
import { DepartmentsDrawer } from '../DepartmentsDrawer';
import Each from '../../../../../../../../common/UtilityComponents/Each';

export default function DepartmentsList({
  departmentsResponse,
}: {
  departmentsResponse: DepartmentResponseDto;
}) {
  return (
    <>
      <Grid>
        <Each
          of={departmentsResponse?.departments}
          render={(department) => {
            return (
              <DepartmentRow
                key={department.id}
                department={department as Department}
              />
            );
          }}
        />
      </Grid>
      <DepartmentsDrawer />
    </>
  );
}
