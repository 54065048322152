import NoRoundtrip from 'assets/img/roundTrips/no-data-roundtrip.svg';
import { IconButtonComponent } from 'app/components/IconButtonComponent';
import { Text } from 'app/pages/AddRoundTrips/components/RightBarNoData';
import { themes } from 'styles/theme/themes';
import React from 'react';
import { Typography } from '@mui/material';
interface Props {
  Icon?: string;
  open?: any;
  handleClick?: any;
  text: string;
  IconButton?: any;
  textButton: string;
  imgStyle?: any;
  viewMode?: any;
  buttonBackground?: string;
  headerText?: string;
}
export const NoDataComponents: React.FC<Props> = ({
  Icon,
  open,
  handleClick,
  text,
  IconButton,
  textButton,
  imgStyle,
  viewMode,
  buttonBackground = '',
  headerText,
}) => {
  return (
    <>
      <img src={Icon || NoRoundtrip} alt="" style={imgStyle || {}} />
      {headerText && (
        <Typography
          variant="h5"
          component="p"
          style={{
            fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: '24px',
            lineHeight: '32.02px',
            textAlign: 'center',
          }}
        >
          {headerText}
        </Typography>
      )}
      <Text
        className="NoDataComponentText"
        style={{ padding: '5px', color: 'rgba(0, 0, 0, 0.6)' }}
      >
        {text}
      </Text>
      {!viewMode && (
        <IconButtonComponent
          iconComponent={IconButton}
          size="small"
          style={{
            fontWeight: '500',
            margin: '0 auto',
            width: '169px',
          }}
          text={textButton}
          iconStyle={{ color: themes?.default?.accordionWhiteBg }}
          variant="contained"
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          invert={true}
          aria-label="add_roundtrip"
          sx={{ width: '100%', background: buttonBackground }}
        />
      )}
    </>
  );
};
