import { Button, DialogActions } from '@mui/material';
import React from 'react';

interface Props {
  handleCloseApi: any;
  Action: any;
  textCancel: any;
  textAction: any;
  disabled?: boolean;
  width?: string;
}

export const DialogActionsComponent: React.FC<Props> = ({
  handleCloseApi,
  Action,
  textCancel,
  textAction,
  disabled,
  width = '100%',
}) => {
  return (
    <DialogActions
      style={{
        display: 'flex',
        justifyContent: 'right',
        width,
        margin: '11px 14px 11px 0',
        paddingRight: '0.6rem',
      }}
    >
      <Button
        onClick={() => {
          handleCloseApi();
        }}
        color="inherit"
      >
        {textCancel}
      </Button>
      <Button
        disabled={!!disabled}
        onClick={() => {
          handleCloseApi();
          Action();
        }}
        variant="contained"
        autoFocus
      >
        {textAction}
      </Button>
    </DialogActions>
  );
};
