import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { toolsActions } from 'app/slices/tools';
import { Modal } from '@mui/material';
import { themes } from 'styles/theme/themes';
import Truck from 'assets/img/roundTrips/truck.svg';
import If from '../If';
import { GreenPulse } from '../RoundtripTruckIcon';
import TruckIcon from '../TruckIcon';
import { Icon } from 'app/components/Icon';
import { ArrowsIn, ArrowsOut, X, Crosshair } from '@phosphor-icons/react';
import { GoogleMapComponent } from 'app/components/GoogleMapComponent';
import { Marker, AdvancedMarker } from '@vis.gl/react-google-maps';
import { StreetViewPanorama } from 'app/components/GoogleMapComponent/components/StreetViewPanorama';

interface MapPopoverProps {
  latitude: any;
  longitude: any;
  open: boolean;
  handleClose: Function;
  editMode?: boolean;
  editMap?: boolean;
  setSelectedStopCopy?: any;
  isTruck?: boolean;
  driverLat?: any;
  driverLong?: any;
  vehicle?: any;
}

const MapPopover = ({
  latitude,
  longitude,
  open,
  handleClose,
  editMode,
  editMap,
  setSelectedStopCopy,
  isTruck,
  driverLat,
  driverLong,
  vehicle,
}: MapPopoverProps) => {
  const dispatch = useDispatch();

  const [streetViewVisible, setStreetViewVisible] = useState(false);
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [center, setCenter] = useState({
    lat: latitude,
    lng: longitude,
  });
  const [driverPosition, setDriverPosition] = useState({
    lat: driverLat,
    lng: driverLong,
  });
  const [containerStyle, setContainerStyle] = useState({
    width: '603px',
    height: '391px',
    transition: 'width 0.2s ease-in-out, height 0.2s ease-in-out',
    borderRadius: '4px',
  });

  useEffect(() => {
    if (latitude && longitude) {
      setCenter({
        lat: latitude,
        lng: longitude,
      });
    }
  }, [latitude, longitude]);

  useEffect(() => {
    if (driverLat && driverLong) {
      setDriverPosition({
        lat: driverLat,
        lng: driverLong,
      });
    }
  }, [driverLat, driverLong]);

  const handleFullscreen = () => {
    setContainerStyle(
      fullScreen
        ? { ...containerStyle, width: '603px', height: '391px' }
        : { ...containerStyle, width: '100vw', height: '100vh' },
    );
    setFullScreen(!fullScreen);
    if (!editMode) {
      dispatch(toolsActions.setZoomIn({ status: !fullScreen }));
    }
  };

  const handleCloseWrapper = () => {
    if (fullScreen && !editMode) {
      dispatch(toolsActions.setZoomIn({ status: false }));
    }
    setFullScreen(false);
    setContainerStyle({ ...containerStyle, width: '603px', height: '391px' });
    closeStreetView();
    handleClose();
  };

  const toggleStreetView = () => {
    setStreetViewVisible(!streetViewVisible);
  };

  const closeStreetView = () => {
    setStreetViewVisible(false);
  };

  return (
    <If condition={!!center} otherwise={null}>
      {/* <Fade in={open}> */}
      <Modal
        open={open}
        onClose={() => handleClose()}
        sx={{
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
          zIndex: '1001',
        }}
      >
        <MapWrapper fullScreen={fullScreen} editMode={editMode}>
          <GoogleMapComponent
            containerStyle={containerStyle}
            disableDefaultUI={true}
            defaultCenter={
              !!center?.lat && !!center?.lng ? center : { lat: 48, lng: 2 }
            }
            defaultZoom={15}
            onMapClick={(e) => {
              if (editMap) {
                setCenter({ lat: e?.latLng?.lat(), lng: e?.latLng?.lng() });
                setSelectedStopCopy([e?.latLng?.lat(), e?.latLng?.lng()]);
              }
            }}
          >
            <>
              <FullScreenButton
                fullScreen={fullScreen}
                onClick={handleFullscreen}
              >
                {fullScreen ? (
                  <Icon
                    icon={<ArrowsIn />}
                    size={20}
                    color={themes?.default?.iconColor}
                  />
                ) : (
                  <Icon
                    icon={<ArrowsOut />}
                    size={20}
                    color={themes?.default?.iconColor}
                  />
                )}
              </FullScreenButton>
              <CloseButton onClick={handleCloseWrapper}>
                <Icon
                  icon={<X />}
                  size={20}
                  color={themes?.default?.iconColor}
                />
              </CloseButton>
              <StreetViewButton
                streetView={streetViewVisible}
                onClick={toggleStreetView}
              >
                <Icon
                  icon={<Crosshair />}
                  size={20}
                  color={themes?.default?.iconColor}
                />
              </StreetViewButton>
              <If condition={!isTruck}>
                <Marker position={center} />
              </If>
              <If
                condition={
                  (isTruck && center?.lat && center?.lng) ||
                  (!isTruck && driverPosition?.lat && driverPosition?.lng)
                }
              >
                <AdvancedMarker position={isTruck ? center : driverPosition}>
                  {/* TODO: check other modules for the usage of this component */}
                  {/* This is only fixed in vehicle table */}
                  {/* maybe create a new component and use it instead of this one */}
                  {vehicle ? (
                    <TruckIcon
                      vehicle={false}
                      running={vehicle?.engineRunning}
                      driver={false}
                      notTracked={!vehicle?.isTracked}
                      iconStyle={{
                        icon: { height: '30px', width: '30px' },
                        img: { height: '15px', width: '15px' },
                      }}
                      status={vehicle.status}
                      marker={true}
                    />
                  ) : (
                    <GreenPulse style={{ height: '30px', width: '30px' }}>
                      <img
                        src={Truck}
                        style={{ height: '15px', width: '15px' }}
                      />
                    </GreenPulse>
                  )}
                </AdvancedMarker>
              </If>
              {streetViewVisible && (
                <StreetViewPanorama
                  options={{
                    position: { lat: latitude, lng: longitude },
                    visible: streetViewVisible,
                    disableDefaultUI: true,
                    enableCloseButton: false,
                  }}
                />
              )}
            </>
          </GoogleMapComponent>
        </MapWrapper>
      </Modal>
    </If>
  );
};

export default MapPopover;

const MapButton = styled.div((props) => ({
  position: 'absolute',
  right: '51px',
  top: '15px',
  cursor: 'pointer',
  color: props.theme.icon,
  padding: '5px',
  backgroundColor: 'rgb(255,255,255)',
  borderRadius: '4px',
  zIndex: '1500',
  boxShadow: '1px 1px 4px 1px rgba(0, 0, 0, 0.25)',
}));

interface FullscreenButtonProps {
  fullScreen?: boolean;
}

const FullScreenButton = styled(MapButton)<FullscreenButtonProps>`
  background-color: ${(props) =>
    props.fullScreen ? props.theme.blueToggledButton : ''};
`;

const CloseButton = styled(MapButton)(() => ({
  right: '15px',
}));

interface StreetViewButtonProps {
  streetView?: boolean;
}

export const StreetViewButton = styled(CloseButton)<StreetViewButtonProps>`
  right: 87px;
  background-color: ${(props) =>
    props.streetView ? props.theme.blueToggledButton : ''};
`;

interface MapProps {
  fullScreen?: boolean;
  editMode?: boolean;
}

const MapWrapper = styled.div<MapProps>`
  position: fixed;
  right: ${(props) => (props.fullScreen ? '0' : '17px')};
  z-index: 2100;
  bottom: ${(props) =>
    props.fullScreen ? '0' : props.editMode ? '40px' : '22px'};
  box-shadow: 0px 5px 30px 0px ${themes?.default?.suvaGrey}40;
  border-radius: 4px;
`;
