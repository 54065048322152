import { useSelector } from 'react-redux';
import { selectAuthToken } from 'app/slices/auth/selectors';
import { useToaster } from 'hooks/useToaster';
import { useCallback, useState } from 'react';
import { useLoadingAlert } from 'app/components/LoadingAlert/hooks';
import { useApplication } from './useApplication';

export const useExportCSV = () => {
  const baseUrl = process.env.REACT_APP_API_URL;

  const authToken = useSelector(selectAuthToken);
  const toast = useToaster();
  const { showLoading, hideLoading } = useLoadingAlert();
  const currentApplication = useApplication();

  const [loadingCSV, setLoadingCSV] = useState(false);

  const triggerExport = useCallback(
    async (queryParams: string, fileName: string) => {
      setLoadingCSV(true);
      showLoading({ message: 'loading_csv' });
      const inSettings = window.location.pathname?.includes('/settings');
      await fetch(
        `${
          baseUrl || 'https://api-dev.bamptee.com'
        }/${queryParams}&format=csv&authApplication=${
          inSettings ? 'idm' : currentApplication?.id
        }`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        },
      )
        .then(async (response) => {
          setLoadingCSV(false);
          hideLoading();
          const utf8BOM = '\uFEFF';
          const csv = await response.text();
          const blob = new Blob([utf8BOM + csv], {
            type: 'text/csv;charset=utf-8;',
          });
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `${fileName}.csv`;
          a.click();
          URL.revokeObjectURL(url);

          toast(5000, 'success', 'csv_export_success');
        })
        .catch((e) => {
          setLoadingCSV(false);
          hideLoading();
          toast(5000, 'error', e.message);
        });
    },
    [authToken, baseUrl, hideLoading, showLoading, toast],
  );

  return { loadingCSV, triggerExport };
};
