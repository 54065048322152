import { useEffect, useState, useRef, useMemo } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import If from 'app/components/If';
import MarkerPinBlue from 'assets/img/map/CustomerMap/MarkerPrimaryBlue.svg';
import { themes } from 'styles/theme/themes';
import { Branch } from '../ViewCustomer/components/Type';
import Grid3x3Icon from '@mui/icons-material/Grid3x3';
import { TypoMain } from '../styles';
import { Marker, InfoWindow } from '@vis.gl/react-google-maps';

interface MarkerBranchProps {
  coordinates: [number, number];
  code?: string;
  name?: string;
  onToggleOpen: () => void;
  isSelected: boolean;
  names: boolean;
  clusterer?: any;
  branch: Branch;
}

export const MarkerBranch = ({
  coordinates,
  code,
  name,
  onToggleOpen,
  isSelected,
  branch,
}: MarkerBranchProps) => {
  const [openLayer, setOpenLayer] = useState<boolean>(false);
  const infoWindowContentRef: any = useRef(null);

  const places = {
    lat: coordinates[1],
    lng: coordinates[0],
  };

  const handleOpenLayer = () => {
    setOpenLayer(!openLayer);
  };

  useEffect(() => {
    if (!isSelected) {
      setOpenLayer(false);
    }
  }, [isSelected]);

  const infoWindowPixelOffset: [number, number] = useMemo(() => {
    if (!coordinates) return [0, 140];
    if (infoWindowContentRef?.current?.offsetHeight) {
      return [0, infoWindowContentRef.current.offsetHeight + 20];
    } else {
      return [0, 140];
    }
  }, [coordinates, infoWindowContentRef]);

  return (
    <>
      <Marker
        position={places}
        clickable={true}
        onClick={() => {
          handleOpenLayer();
          onToggleOpen();
        }}
        icon={MarkerPinBlue}
      />
      {openLayer && (
        <InfoWindow
          position={places}
          pixelOffset={infoWindowPixelOffset}
          zIndex={201}
        >
          <div
            style={{
              backgroundColor: '#fff',
              minWidth: '215px',
              minHeight: 'fit-content',
              position: 'relative',
              borderRadius: '4px',
              zIndex: 999,
              display: 'flex',
              flexDirection: 'column',
              border: `1px solid ${themes?.default?.gainsboro2}`,
              padding: '12px',
            }}
            ref={infoWindowContentRef}
          >
            <Grid container alignItems="center">
              <Grid3x3Icon
                sx={{
                  width: '12px',
                  height: '12px',
                  mr: '2px',
                  color: themes?.default?.actionButtonColor,
                }}
              />
              <Typography fontSize="10px" fontWeight="400">
                {code}
              </Typography>
              <Typography
                fontSize="10px"
                fontWeight="400"
                sx={{
                  color: '#9E9E9E',
                  maxWidth: '80px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {name}
              </Typography>
            </Grid>
            <Grid container alignItems="center">
              <Typography fontWeight={500} fontSize="14px">
                {branch?.shortName}
              </Typography>
              <If condition={branch?.markAsMain}>
                <TypoMain
                  fontWeight={500}
                  fontSize="10px"
                  sx={{
                    ml: 'auto',
                  }}
                >
                  Main
                </TypoMain>
              </If>
            </Grid>
            <Grid
              flexDirection="row"
              sx={{
                mt: '7px',
              }}
            >
              <Typography
                fontSize="12px"
                fontWeight="400"
                color={themes?.default?.nobel}
              >
                Main contacts
              </Typography>
              <Typography
                fontSize="10px"
                fontWeight={400}
                color={themes?.default?.primary}
                sx={{
                  cursor: 'pointer',
                }}
              >
                +more
              </Typography>
            </Grid>
            <Grid container alignItems="center">
              <Button
                size="small"
                sx={{
                  mt: '10px',
                  textDecoration: 'underline',
                  ':hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                create order
              </Button>
            </Grid>
          </div>
        </InfoWindow>
      )}
    </>
  );
};
