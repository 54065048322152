import { Switch, SwitchProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CustomSwitch = (switchProps: SwitchProps) => {
  return <AntSwitch size="small" {...switchProps} />;
};

export const CustomIOSSWitch = (switchProps: SwitchProps) => {
  return <IOSSwitch size="small" {...switchProps} />;
};

//MUI AntSwitch
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 21,
  height: 12,
  padding: 0,
  display: 'flex',
  alignItems: 'center',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 8,
      height: 8,
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: '0 1.5px',
    position: 'absolute !important',
    top: '50% !important',
    transform: 'translate(0, -50%)',
    '&.Mui-checked': {
      transform: 'translate(9px, -50%)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 1px 2px 0 rgb(0 35 11 / 20%)',
    width: 8,
    height: 8,
    borderRadius: '50%',
    transition: theme.transitions.create(['width', 'left', 'right'], {
      duration: 1500,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 12 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

//MUI IOSSwitch
const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 50,
  height: 24,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(22px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 24,
    height: 20,
    borderRadius: 12,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
