import { isEmpty } from 'lodash';
import polylabel from '@mapbox/polylabel';

export function area(poly) {
  const ring = poly[0];
  const ringMax = ring.length - 1;

  let s = 0.0;

  for (let i = 0; i < ringMax; i++) {
    s += ring[i][0] * ring[i + 1][1] - ring[i + 1][0] * ring[i][1];
  }

  return 0.5 * s;
}

export function centroid(polygonPath, omitFirst = false, omitLast = false) {
  let clonePath = [...polygonPath];

  if (omitFirst) {
    clonePath = clonePath.slice(1);
  }

  if (omitLast) {
    clonePath = clonePath.slice(0, clonePath.length - 1);
  }

  clonePath.push(clonePath[0]);

  const poly = [clonePath.map((p) => [p.lng, p.lat])];
  const ring = poly[0];
  const ringMax = ring.length - 1;

  const c = [0, 0];

  for (let i = 0; i < ringMax; i++) {
    c[0] +=
      (ring[i][0] + ring[i + 1][0]) *
      (ring[i][0] * ring[i + 1][1] - ring[i + 1][0] * ring[i][1]);
    c[1] +=
      (ring[i][1] + ring[i + 1][1]) *
      (ring[i][0] * ring[i + 1][1] - ring[i + 1][0] * ring[i][1]);
  }

  const a = area(poly);

  c[0] /= a * 6;
  c[1] /= a * 6;

  console.log(c);

  return c;
}

export function centroidLabel(
  polygonPath,
  omitFirst = false,
  omitLast = false,
) {
  let clonePath = [...polygonPath];

  if (omitFirst) {
    clonePath = clonePath.slice(1);
  }

  if (omitLast) {
    clonePath = clonePath.slice(0, clonePath.length - 1);
  }

  clonePath.push(clonePath[0]);

  const poly = polylabel([clonePath.map((p) => [p.lng, p.lat])], 1.0);

  return poly;
}

export function getShortLocation(location: any) {
  if (isEmpty(location)) {
    return null;
  }

  const { city, zipCode } = location;

  if (!city || !zipCode) {
    return null;
  }

  return `${city} (${zipCode})`;
}
