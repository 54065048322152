import { Stack, styled } from '@mui/material';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import {
  DateRangePickerDay,
  DateRangePickerDayProps,
} from '@mui/x-date-pickers-pro/DateRangePickerDay';
import If from 'app/components/If';
import { useLazyGetRoundtripsForCalendarQuery } from 'common/services/roundtripApi';
import dayjs, { Dayjs } from 'dayjs';
import { Permission, usePermission } from 'hooks/Abilities/usePermission';
import { toQueryParams, useQueryParams } from 'hooks/useQueryParams';
import { useEffect, useMemo } from 'react';
import { themes } from 'styles/theme/themes';

interface Props {
  dateValue: dayjs.Dayjs | null | { begin: string | null; end: string | null };
  showDots?: boolean;
  isRange?: boolean;
}

export const useHighlightedDay = ({
  dateValue,
  showDots = false,
  isRange = false,
}: Props) => {
  const roundtripsCan = usePermission('roundtrips');
  const beginDate = useMemo(() => {
    if (dayjs.isDayjs(dateValue)) {
      return dayjs(dateValue);
    } else if (isRange && dateValue?.begin) {
      return dayjs(dateValue.begin);
    }
  }, [dateValue, isRange]);
  const endDate = useMemo(() => {
    if (dayjs.isDayjs(dateValue)) {
      return dayjs(dateValue);
    } else if (isRange && dateValue?.end) {
      return dayjs(dateValue.end);
    }
  }, [isRange, dateValue]);

  const formatDate = (date: Dayjs | undefined) => {
    return date?.format('YYYY-MM-DD');
  };

  const query = useQueryParams({
    beginAt: formatDate(beginDate?.startOf('month')) || '',
    endAt: formatDate(beginDate?.add(1, 'month').endOf('month')) || '',
  });

  const [getRoundtrips, { data: datesData }] =
    useLazyGetRoundtripsForCalendarQuery();

  useEffect(() => {
    if (!showDots || !roundtripsCan(Permission.VIEW)) {
      return;
    }
    getRoundtrips({ query });
  }, [query, showDots, getRoundtrips, roundtripsCan]);

  interface HightLightedDayProps {
    date: Dayjs;
    pickersDayProps?: PickersDayProps<Dayjs>;
    dateRangePickerDayProps?: DateRangePickerDayProps<Dayjs>;
  }

  function HightLightedDay({
    date,
    pickersDayProps,
    dateRangePickerDayProps,
  }: HightLightedDayProps) {
    const formattedDate = formatDate(date);
    const isSameDate =
      !date?.isBefore(beginDate, 'day') && !date?.isAfter(endDate, 'day');
    const currentDate = formattedDate ? datesData?.[formattedDate] : null;
    const showDots =
      !isSameDate &&
      currentDate &&
      (!!currentDate.active || !!currentDate.inactive);
    const hasActive = currentDate && !!currentDate.active;
    const hasInactive = currentDate && !!currentDate.inactive;
    const dotColor = !showDots
      ? 'transparent'
      : hasActive
        ? themes?.default?.primaryActiveColor
        : hasInactive
          ? themes?.default?.darkTangerine
          : 'transparent';

    const isHighlighting = dateRangePickerDayProps?.isEndOfHighlighting
      ? true
      : dateRangePickerDayProps?.isHighlighting;

    return (
      <If
        condition={!!isRange && !!dateRangePickerDayProps}
        otherwise={
          !!pickersDayProps && (
            <PickersDay {...pickersDayProps}>
              <DayWarpper>
                {pickersDayProps?.day.format('D')}
                <Dot color={dotColor} />
              </DayWarpper>
            </PickersDay>
          )
        }
      >
        {!!dateRangePickerDayProps && (
          <DateRangePickerDay
            {...dateRangePickerDayProps}
            isHighlighting={!!isHighlighting}
          >
            <DayWarpper>
              {dateRangePickerDayProps?.day.format('D')}
              <Dot color={dotColor} isRange={true} />
            </DayWarpper>
          </DateRangePickerDay>
        )}
      </If>
    );
  }

  function onMonthChange(month: Dayjs) {
    if (!showDots) {
      return;
    }
    const query = toQueryParams({
      beginAt: formatDate(month.startOf('month')) || '',
      endAt: isRange
        ? formatDate(month.add(1, 'month').endOf('month')) || ''
        : formatDate(month.endOf('month')) || '',
    });
    getRoundtrips({ query });
  }

  return { HightLightedDay, onMonthChange };
};

interface DotProps {
  color: string;
  isRange?: boolean;
}

const Dot = styled('div')<DotProps>`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  position: absolute;
  bottom: ${(props) => (props.isRange ? '-5px' : '5px')};
`;
const DayWarpper = styled(Stack)`
  align-items: center;
  position: 'relative';
`;
