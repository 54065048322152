import api from './api';

export const roundtripMappingsApi = api.injectEndpoints({
  endpoints: builder => ({
    getRoundtripMappings: builder.query<any, string>({
      query: queryParams => ({
        url: `/roundtrips-mappings${queryParams}`,
      }),
      providesTags: ['RoundtripMappings'],
    }),
    addRoundtripMapping: builder.mutation<any, any>({
      query: body => ({
        url: '/roundtrips-mappings',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['RoundtripMappings'],
    }),
    deleteRoundtripMapping: builder.mutation<any, { ids: string[] }>({
      query: ({ ids }) => ({
        url: `/roundtrips-mappings`,
        method: 'PATCH',
        body: { ids },
      }),
      // invalidatesTags: ['RoundtripMappings'],
    }),
    updateRoundtripMapping: builder.mutation({
      query: ({ id, body }) => ({
        url: `/roundtrips-mappings/${id}`,
        method: 'PATCH',
        body,
      }),
      // invalidatesTags: ['RoundtripMappings'],
    }),
  }),
});

export const {
  useGetRoundtripMappingsQuery,
  useAddRoundtripMappingMutation,
  useDeleteRoundtripMappingMutation,
  useUpdateRoundtripMappingMutation,
} = roundtripMappingsApi;
