/**
 * Asynchronously loads the component for NotFoundPage
 */

import { lazyLoad } from 'utils/loadable';
import { LoadingIndicator } from 'app/components/LoadingIndicator';

export const JoinWorkspace = lazyLoad(
  () => import('./index'),
  (module) => module.JoinWorkspace,
  {
    fallback: <LoadingIndicator />,
  },
);
