export const Canceled = 'canceled';
export const Pending = 'pending';
export const Planned = 'planned';
export const OnWay = 'on-way';
export const Completed = 'completed';
export const Prepared = 'prepared';
export const Loaded = 'loaded';
export const Loading = 'loading';
export const Validated = 'validated';
export const Confirmed = 'confirmed';
export const Unloaded = 'unloaded';
export const Unloading = 'unloading';
export const OnDock = 'on-dock';
export const Parked = 'parked';
export const Paused = 'paused';
export const Active = 'active';
export const InActive = 'inactive';
export const Stopped = 'stopped';
export const Blocked = 'blocked';
// user role
export const Driver = 'driver';
export const Picker = 'picker';
export const Sales = 'sales';
export const Ripper = 'ripper';
export enum StatusBranch {
  Active = 'active',
  InActive = 'inactive',
  Stopped = 'stopped',
  Blocked = 'blocked',
  Preliminary = 'preliminary',
  Canceled = 'canceled',
  Pending = 'pending',
  Planned = 'planned',
  OnWay = 'on-way',
  Completed = 'completed',
  Prepared = 'prepared',
  Loaded = 'loaded',
  Validated = 'validated',
  Confirmed = 'confirmed',
  Unloaded = 'unloaded',
  Unloading = 'unloading',
  OnDock = 'on-dock',
  Parked = 'parked',
  Paused = 'paused',
  Loading = 'loading',
}
