import { Box, Stack, Typography } from '@mui/material';
import { ActivityTimeline } from 'app/components/ActivityTimeline';
import { StatusCustomer } from 'app/components/StatusCustomer';
import {
  LeftSection,
  MainSection,
  RightSection,
} from 'app/styledComponents/DetailsSection.styled';
import {
  useGetCustomerActivitiesByDateQuery,
  useLazyGetCustomerActivitiesQuery,
} from 'common/services/customerApi';
import { CustomerActions } from 'common/types/Customer';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  customerId: string;
}

export const HistoryTab = ({ customerId }: Props) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [trigger] = useLazyGetCustomerActivitiesQuery();
  const { data, isLoading } = useGetCustomerActivitiesByDateQuery(
    {
      id: customerId,
      query: `?page=${page}&limit=20`,
    },
    { skip: !customerId },
  );

  const titlesMap = (record) => {
    const titles = {
      [CustomerActions.CUSTOMER_EDIT]: t('action.customer-edit'),
      [CustomerActions.CUSTOMER_ADD]: t('action.customer-add'),
      [CustomerActions.CUSTOMER_EDIT_DECK]: t('action.customer-edit-deck'),
      [CustomerActions.CUSTOMER_ADD_DECK]: t('action.customer-add-deck'),
      [CustomerActions.CUSTOMER_CANCEL_SEPA]: t('action.customer-cancel-sepa'),
      [CustomerActions.CUSTOMER_VALIDATE_SEPA]: t(
        t('action.customer-validate-sepa'),
      ),
      [CustomerActions.CUSTOMER_DELETE_DECK]: t('action.customer-delete-deck'),
      [CustomerActions.CUSTOMER_STATUS_UPDATED]: (
        <Stack direction="row" gap="4px" alignItems="center">
          <Box>{t('status_set_to')}</Box>
          <StatusCustomer status={record?.data?.newStatus} small />
        </Stack>
      ),
    };

    return titles[record.name];
  };

  const getActivities = async (date: string, limit: number) => {
    return await trigger({
      id: customerId,
      query: `?date=${date}&limit=${limit}&offset=2`,
    }).unwrap();
  };

  return (
    <MainSection id="scrollable-timeline">
      <LeftSection>
        <Typography fontSize="0.875rem" fontWeight={500}>
          {t('common.history')}
        </Typography>

        <Typography fontSize="0.75rem">{t('history_tab_text')}</Typography>
      </LeftSection>
      <RightSection>
        <ActivityTimeline
          data={data}
          isLoading={isLoading}
          page={page}
          setPage={setPage}
          getActivities={getActivities}
          titlesMap={titlesMap}
        />
      </RightSection>
    </MainSection>
  );
};
