export const changeStatus = async (
  status,
  handleCloseAssignRoundTrip,
  setAddRoundTripOpen,
  roundtrip,
  uploadStop,
  stop,
  updateOrderFunction,
  UpdateOrders,
  pageRows = '',
) => {
  handleCloseAssignRoundTrip(setAddRoundTripOpen);

  if (roundtrip)
    await uploadStop({
      id: roundtrip?.id,

      stopId: stop?._id,
      status: status,
      urlQuery: pageRows,
    });
  else
    return await updateOrderFunction(
      stop.id,
      {
        status: status,
      },
      UpdateOrders,
      pageRows,
    );
};
