import TruckActiveMoving from 'assets/img/map/legend/vehicle/TruckActiveMoving.svg';
import TruckReturning from 'assets/img/map/legend/vehicle/TruckReturning.svg';
import TruckReturningMoving from 'assets/img/map/legend/vehicle/TruckReturningMoving.svg';
import TruckOutOfService from 'assets/img/map/legend/vehicle/TruckOutOfService.svg';

export const vehicleLabels = {
  title: 'vehicles_labels',
  legends: [
    {
      text: 'roundtrips.tooltip.truck.active',
      icon: (
        <img
          style={{ height: '18px', width: '18px' }}
          src={TruckActiveMoving}
          alt=""
        />
      ),
    },
    {
      text: 'vehicles.status.returning',
      icon: (
        <img
          style={{ height: '16px', width: '16px' }}
          src={TruckReturning}
          alt=""
        />
      ),
    },
    {
      text: 'labels.returning_moving',
      icon: (
        <img
          style={{ height: '18px', width: '18px' }}
          src={TruckReturningMoving}
          alt=""
        />
      ),
    },
    {
      text: 'vehicles.status.inactive',
      icon: (
        <img
          style={{ height: '16px', width: '16px' }}
          src={TruckOutOfService}
          alt=""
        />
      ),
    },
  ],
};
