import React, { createContext, useCallback, useState } from 'react';
import { Column, ContextProps, GridSetupProps, Order } from '../types';
import { useExportCSV } from 'hooks/useExportCSV';
import { useExportXLSX } from 'hooks/useExportXLSX';

import dayjs from 'dayjs';
import { useDebounce } from 'common/hooks/useDebounce';

export const GridContext = createContext<ContextProps | undefined>(undefined);

export const GridProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { triggerExport } = useExportCSV();
  const { triggerXLSXExport } = useExportXLSX();

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [order, setOrder] = useState<Order>('');
  const [orderBy, setOrderBy] = useState<string>('');
  const [selected, setSelected] = useState<string[]>([]);
  const [columns, setColumns] = useState<Column[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [search, setSearch] = useState('');
  const [rows, setRows] = useState<any[]>([]);
  const [withCheckbox, setWithCheckbox] = useState(false);
  const [filters, setFilters] = useState<any>({});
  const [columnWidths, setColumnWidths] = useState<Record<string, number>>({});
  const [withColumnReordering, setWithColumnReordering] = useState(false);
  const [filterInit, setFilterInit] = useState<any>({});
  const debouncedFilters = useDebounce(filters, 300);
  const debouncedSearchTerm = useDebounce(search, 300);

  const setupGrid = useCallback(
    ({
      columns,
      totalRows,
      rows,
      withCheckbox = true,
      withColumnReordering = false,
    }: GridSetupProps) => {
      setColumns(columns);
      setTotalRows(totalRows);
      setRows(rows || []);
      setWithCheckbox(withCheckbox);
      setWithColumnReordering(withColumnReordering);
    },
    [],
  );

  const exportRows = useCallback(
    (url: string, fileName: string) => {
      triggerExport(
        `${url}&limit=25000&idsFilters=${
          selected?.length ? selected.join(',') : ''
        }`,
        `${fileName}_${dayjs().format('DD/MM/YYYY')}`,
      );
    },
    [triggerExport, selected],
  );

  const exportRowsIdsArray = useCallback(
    (url: string, fileName: string) => {
      if (selected?.length) {
        selected.forEach((id) => {
          url += `&idsFilters[]=${id}`;
        });
      }

      triggerXLSXExport(
        `${url}&limit=25000`,
        `${fileName}_${dayjs().format('DD/MM/YYYY')}`,
      );
    },
    [triggerXLSXExport, selected],
  );

  return (
    <GridContext.Provider
      value={{
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        order,
        setOrder,
        orderBy,
        setOrderBy,
        selected,
        setSelected,
        columns,
        setColumns,
        totalRows,
        setupGrid,
        search,
        setSearch,
        exportRows,
        rows,
        withCheckbox,
        filters,
        debouncedFilters,
        setFilters,
        debouncedSearchTerm,
        columnWidths,
        setColumnWidths,
        withColumnReordering,
        exportRowsIdsArray,
        filterInit,
        setFilterInit,
      }}
    >
      {children}
    </GridContext.Provider>
  );
};
