import styled from 'styled-components';
import { themes } from 'styles/theme/themes';

export const KeyPoint = styled.div((props) => ({
  fontWeight: 500,
  fontSize: '0.625rem',
  color: themes?.default?.accordionWhiteBg,
  paddingLeft: 10,
  paddingRight: 10,
  background: props.theme.greyDisabled,
  borderRadius: 9,
  height: 20,
  display: 'flex',
  alignItems: 'center',
  zIndex: 2,
}));

export const TimelineWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
`;

export const StopWrapper = styled.div`
  left: 55%;
  z-index: 1;
  position: absolute;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: ${themes?.default?.accordionWhiteBg};
  justify-content: center;
  align-items: center;
  display: flex;
`;

interface TimelineDistanceProps {
  left: string;
  width: string;
}

export const TimelineDistance = styled.div<TimelineDistanceProps>((props) => ({
  position: 'absolute',
  left: props.left,
  width: props.width,
  display: 'flex',
  justifyContent: 'center',
  top: '-5px',
  fontSize: '0.5625rem',
  color: props.theme.iconColor,
}));

export const DriverRipperFields = styled.div({
  display: 'flex',
  position: 'relative',
  justifyContent: 'space-between',
  height: 38,
  width: 160,
  // borderBottom:  `0.063rem solid ${props.theme.borderGrey}`,
  alignItems: 'center',
  // paddingLeft: 26,

  '& .title': {
    fontWeight: 700,
    width: 150,
  },
  '& .name': {
    textDecoration: 'underline',
  },
});

interface StopDataWrapperProps {
  borderRight: boolean;
}

export const StopDataWrapper = styled.div<StopDataWrapperProps>((props) => ({
  fontSize: '0.75rem',
  lineHeight: 1.4,
  minWidth: '250px',
  height: 120,
  background: props.theme.primaryActive,
  borderBottom: `0.063rem solid ${props.theme.tableBorder}`,
  borderRight: props.borderRight
    ? `0.063rem solid ${props.theme.primaryLightO}`
    : 'unset',
  '& .time': {
    color: props.theme.textSecondary,
  },
  '& .station-wrapper': {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    paddingLeft: 10,
    columnGap: 13,
  },
  '& .delivery-wrapper': {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
    paddingTop: 10,
  },
}));

export const DriverCode = styled.div(() => ({
  fontWeight: 700,
}));

export const CountDeliveryWrapper = styled.div((props) => ({
  display: 'flex',
  columnGap: 6,
  marginTop: 5,
  '& .count': {
    display: 'flex',
    backgroundColor: props.theme.primaryLightO,
    borderRadius: 16,
    color: props.theme.primary,
    alignItems: 'center',
    width: 'fit-content',
    padding: '0.125rem 10px',
    height: 22,
  },
  '& .delivery': {
    display: 'flex',
    backgroundColor: props.theme.success,
    borderRadius: 16,
    color: props.theme.greenMain,
    alignItems: 'center',
    width: 'fit-content',
    padding: '0.125rem 10px',
    height: 24,
  },
  '& .late': {
    display: 'flex',
    backgroundColor: themes?.default?.accordionWhiteBg,
    borderRadius: 16,
    color: props.theme.redStatus,
    alignItems: 'center',
    width: 'fit-content',
    padding: '0.125rem 10px',
    height: 24,
  },
}));

export const StopsDetailsDescription = styled.div((props) => ({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  minWidth: '250px',
  height: '100%',
  '& .count-icon': {
    display: 'flex',
    alignItems: 'center',
    columnGap: 3,
    marginRight: 5,
    '& .plus-stop': {
      fontSize: '0.5625rem',
      color: props.theme.primary,
      backgroundColor: props.theme.primaryLight,
      borderRadius: 9,
      width: 31,
      height: 17,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
}));
