import { IWarehouse, IWarehouseResponseDto } from '../../types/Warehouses';

export const processWarehouses = (response: any) => {
  const warehouses = response.docs.map((warehouse: any) => {
    delete warehouse.__v;
    return warehouse;
  }) as IWarehouse[];

  const totalDocs = response.totalDocs;
  return { warehouses, totalDocs } as unknown as IWarehouseResponseDto;
};
