import { Menu } from '@mui/material';
import { RoundTripStatus } from '../RoundtripStatus';
import React from 'react';
export const MenuStatusChange: React.FC<{
  bulkChangeStatus: any;
  selected: any;
  roundTripStatus: any;
  anchorEl: any;
  setAnchorEl: any;
  open: any;
}> = ({
  bulkChangeStatus,
  selected,
  roundTripStatus,
  anchorEl,
  setAnchorEl,
  open,
}) => {
  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeFunction = (status: string) => {
    handleClose();
    bulkChangeStatus(status, selected, roundTripStatus);
  };

  return (
    <Menu
      id="status_menu"
      aria-labelledby="status_menu_button"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <RoundTripStatus changeFunction={changeFunction} />
    </Menu>
  );
};
