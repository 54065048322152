import React, { createContext, ReactNode } from 'react';
import { useDefineAbilitiesFor } from 'utils/ability';
import { Application } from 'common/store/app/types';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'app/slices/auth/selectors';
import { useApplication } from 'hooks/useApplication';
import { createContextualCan } from '@casl/react';

export const AbilityContext = createContext<any>(null);
export const Can = createContextualCan(AbilityContext.Consumer);

interface AbilityProviderProps {
  children: ReactNode;
}

export const AbilityProvider: React.FC<AbilityProviderProps> = ({
  children,
}) => {
  const authUser = useSelector(selectAuthUser);
  const currentApplication: Application = useApplication();
  const ability = useDefineAbilitiesFor(authUser, currentApplication);

  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  );
};
