import If from 'app/components/If';
import TruckIcon from 'app/components/TruckIcon';
import { Avatar } from '@mui/material';
import { themes } from 'styles/theme/themes';
import { stringAvatar } from 'app/helpers/helpers';
import styled from 'styled-components';

interface Props {
  _salesman;
  minimal?: boolean;
}
export const SalesmanIcon = ({ _salesman, minimal }: Props) => {
  if (!_salesman) return null;
  return (
    <If
      condition={_salesman === _salesman.vehicle}
      otherwise={
        <If
          condition={!minimal}
          otherwise={
            <EmployeeName>
              {_salesman?.fullName ? stringAvatar(_salesman?.fullName) : ''}
            </EmployeeName>
          }
        >
          <AvatarWrapper>
            <Avatar
              sx={{
                width: 24,
                height: 24,
                background: themes.default.primaryActiveColor,
                color: 'white',
                fontSize: '0.75rem',
                padding: '0.5 0',
                boxSizing: 'content-box',
              }}
            >
              {_salesman?.fullName ? stringAvatar(_salesman?.fullName) : ''}
            </Avatar>
          </AvatarWrapper>
        </If>
      }
    >
      <TruckIcon
        vehicle={false}
        driver={false}
        running={_salesman?.engineRunning}
        status={_salesman?.status}
        iconStyle={{
          icon: { height: '24px', width: '24px' },
          img: { height: '12px', width: '12px' },
        }}
        style={{
          position: 'relative',
        }}
      />
    </If>
  );
};

const EmployeeName = styled.div`
  color: ${(props) => props.theme.teal600};
  font-size: 1.25rem;
`;

const AvatarWrapper = styled.div`
  padding: 4px;
`;
