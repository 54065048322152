import { Grid } from '@mui/material';
import { FormSection, SectionTitle } from 'app/components/Form/styles';
import FormTextField from 'app/components/Form/TextField';
import { ViewCustomercontext } from 'app/pages/Customers/components/ViewCustomer';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

export const BusinessInformation = ({ control }) => {
  const { t } = useTranslation();
  const { canEdit } = useContext(ViewCustomercontext);
  return (
    <FormSection>
      <SectionTitle>{t('business_information')}</SectionTitle>
      <Grid container spacing="12px">
        <Grid item sm={6} xs={12}>
          <FormTextField
            name="legalName"
            label="brand_name"
            control={control}
            disabled={!canEdit}
            rules={{ required: true }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormTextField
            name="vatNumber"
            label="intracommunity_vat"
            control={control}
            disabled={!canEdit}
          />
        </Grid>
      </Grid>
    </FormSection>
  );
};
