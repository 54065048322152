import { Stack } from '@mui/material';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';

export const TopSection = styled.div`
  background-color: white;
  padding: 8px 20px 5px 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: sticky;
  top: 0;
  z-index: 2;
`;

export const StyledForm = styled.form`
  width: 100%;
  overflow-y: hidden;
  height: 100%;
`;

export const TabsWrapper = styled.div`
  padding: 12px 0 12px 12px;
`;

export const TabsSection = styled.div<{ height?: string; padding?: string }>`
  padding-right: ${(props) => props.padding || '12px'};
  width: 100%;
  flex-grow: 1;
  position: relative;
  height: ${(props) =>
    props.height
      ? props.height
      : `calc(
    100vh - ${themes?.default.topBarHeight} - ${themes?.default.toolBarHeight} -
      ${themes?.default.supportStatCardsHeight} - 50px
  )`}; //50px padding
  overflow: auto;
`;

interface TabCardProps {
  width?: string;
  height?: string;
}

export const TabCard = styled.div<TabCardProps>`
  padding: 16px;
  flex: 1;
  flex-grow: 1;
  border-radius: 4px;
  border: ${(props) => `0.5px solid ${props.theme.gainsboro2}`};
  background: white;
  width: ${(props) => props.width};
  height: ${(props) => props.height || '100%'};
  overflow: hidden;
`;

interface StickyTabCardProps {
  height?: string;
}
export const StickyTabCard = styled(TabCard)<StickyTabCardProps>`
  overflow-y: scroll;
  position: sticky;
  height: ${(props) => props.height || 'calc(100vh - 242px)'};
  top: 0;
`;

export const ActionsWrapper = styled.div`
  margin-left: auto;
`;

export const PageWrapper = styled.div<{ noPadding?: boolean }>`
  padding: ${(props) => (props.noPadding ? 0 : '16px 0 16px 20px')};
  overflow: auto;
  flex: 1;
  background: ${(props) => props.theme.pageGreyBg};
`;

export const MainSection = styled(Stack)`
  flex-direction: row !important;
  gap: 20px;
  position: relative;
  width: 100%;
  overflow: auto;
  height: 100%;
`;
export const RightSection = styled(Stack)`
  width: 70%;
  padding-right: 20px;
  gap: 16px;
  height: max-content;
`;
export const LeftSection = styled(Stack)`
  width: 30%;
  gap: 12px;
  position: sticky;
  left: 0;
  top: 0;
`;
