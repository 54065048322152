import {
  useDeleteRoundtripsNoteMutation,
  useLazyGetRoundtripsNotesQuery,
  usePatchRoundtripsNoteMutation,
  useCreateRoundtripsNoteMutation,
} from 'common/services/roundtripApi';
import { useEffect, useState } from 'react';
import { useToaster } from 'hooks/useToaster';
import NoteContent from './components/NoteContent';

export function Note({ open, onCloseModal, roundtripId, stopId, viewMode }) {
  const toast = useToaster();

  const [note, setNote] = useState<string>('');
  const [updatedNote, setUpdatedNote] = useState<string>('');
  const [createNotes] = useCreateRoundtripsNoteMutation();
  const [deleteNote] = useDeleteRoundtripsNoteMutation();
  const [patchNote] = usePatchRoundtripsNoteMutation();

  const [trigger, { data: notesData }] = useLazyGetRoundtripsNotesQuery();

  function handleAddNote() {
    if (note !== '') {
      createNotes({
        roundtripId,
        stopId,
        content: note,
      })
        .unwrap()
        .then(() => {
          trigger({ roundtripId, stopId });
          setNote('');
          toast(5000, 'success', 'alerts.notes.add_success');
        })
        .catch((err) => console.log(err));
    } else {
      console.log('Note is empty');
    }
  }

  const fetchNotes = () => {
    if (roundtripId && stopId) {
      trigger({ roundtripId, stopId });
    }
  };

  useEffect(() => {
    fetchNotes();
  }, [roundtripId, stopId]);

  function handleNoteChange(event) {
    setNote(event.target.value);
  }

  function handleUpdatedNoteChange(event) {
    setUpdatedNote(event.target.value);
  }

  function handleDeleteNote(noteId: string) {
    deleteNote({
      roundtripId: roundtripId,
      noteId: noteId,
    }).then(() => {
      toast(5000, 'success', 'alerts.notes.delete_success');
      fetchNotes();
    });
  }

  function handleUpdateNote(noteId: string) {
    if (updatedNote !== '') {
      patchNote({
        roundtripId: roundtripId,
        noteId: noteId,
        content: updatedNote,
      })
        .unwrap()
        .then(() => {
          toast(5000, 'success', 'alerts.notes.update_success');
          fetchNotes();
          setUpdatedNote('');
        });
    }
  }

  return (
    <NoteContent
      open={open}
      note={note}
      onCloseModal={onCloseModal}
      viewMode={viewMode}
      notesData={notesData}
      handleAddNote={handleAddNote}
      handleNoteChange={handleNoteChange}
      handleDeleteNote={handleDeleteNote}
      handleUpdatedNoteChange={handleUpdatedNoteChange}
      handleUpdateNote={handleUpdateNote}
    />
  );
}
