import { TwoViewModal } from '../TwoViewModal';
import React from 'react';

interface Props {
  title?: string;
  open: boolean;
  handleClose: () => void;
  width?: string;
  children: React.ReactNode | JSX.Element;
}

export const DrawerComponent = ({
  title,
  open,
  handleClose,
  width = '40%',
  children,
}: Props) => {
  return (
    <TwoViewModal
      onClose={handleClose}
      onOpen={open}
      title={title || ''}
      width={width}
    >
      <>{children}</>
    </TwoViewModal>
  );
};
