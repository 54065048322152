import React, { useEffect, useRef } from 'react';

interface Props {
  paths: any[];
  fillColor: string;
  fillOpacity: number;
  strokeColor: string;
  strokeOpacity: number;
  strokeWeight: number;
  zIndex?: number;
  map: any;
  onMouseOver?: Function;
  onMouseOut?: Function;
  onClick?: Function;
  onRightClick?: Function;
  visible: boolean;
  clickable?: boolean;
}

export const PolygonComponent: React.FC<Props> = ({
  paths,
  fillColor,
  fillOpacity,
  strokeColor,
  strokeOpacity,
  strokeWeight,
  zIndex = 2,
  map,
  onMouseOver,
  onMouseOut,
  onClick,
  onRightClick,
  visible,
  clickable,
}) => {
  const polygonRef = useRef<google.maps.Polygon | null>(null);

  useEffect(() => {
    if (!visible) {
      polygonRef.current?.setMap(null);
      return;
    }

    if (!polygonRef.current) {
      polygonRef.current = new window.google.maps.Polygon({
        paths,
        fillColor,
        fillOpacity,
        strokeColor,
        strokeOpacity,
        strokeWeight,
        clickable: clickable,
        draggable: false,
        editable: false,
        geodesic: false,
        zIndex: zIndex,
        map: map,
      });

      if (onMouseOver) {
        polygonRef.current.addListener('mousemove', () => onMouseOver());
      }

      if (onMouseOut) {
        polygonRef.current.addListener('mouseout', () => onMouseOut());
      }

      if (onClick) {
        polygonRef.current.addListener('click', () => onClick());
      }

      if (onRightClick) {
        polygonRef.current.addListener('mousedown', (e) => {
          if (e?.domEvent?.button === 2) {
            onRightClick(e);
          }
        });
      }
    } else {
      polygonRef.current.setOptions({
        paths,
        fillColor,
        fillOpacity,
        strokeColor,
        strokeOpacity,
        strokeWeight,
        zIndex,
        map,
      });
    }

    return () => {
      polygonRef.current?.setMap(null);
      polygonRef.current = null;
    };
  }, [
    paths,
    fillColor,
    fillOpacity,
    strokeColor,
    strokeOpacity,
    strokeWeight,
    map,
    onMouseOver,
    onMouseOut,
    onClick,
    visible,
    clickable,
  ]);

  return null;
};
