import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useModal } from 'app/components/Dialog/hooks';
import If from 'app/components/If';
import { StatusOrder } from 'app/pages/AddRoundTrips/data/RoundTripData/components/StatusOrder';
import { useDebounce } from 'common/hooks/useDebounce';
import { useUpdateCustomerOrderMutation } from 'common/services/orderApi';
import { cancelationReason } from 'common/utils/cancelationReason';
import { OrderStatus } from 'common/utils/enum';
import { useToaster } from 'hooks/useToaster';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { themes } from 'styles/theme/themes';

export const useCancelOrder = () => {
  const { t } = useTranslation();
  const toast = useToaster();
  const [updateOrder] = useUpdateCustomerOrderMutation();

  const {
    openModal: openConfirmation,
    closeModal,
    setActionDisabled,
    setActionButton,
  } = useModal();

  const handleCancelOrder = async (id: string, reason: string) => {
    try {
      setActionDisabled(true);
      await updateOrder({
        id,
        formData: { status: OrderStatus.CANCELED, cancelReason: reason },
      }).unwrap();
      toast(3000, 'success', t('orders.status.order_successfully_canceled'));
      closeModal();
    } catch (error) {
    } finally {
      setActionDisabled(false);
    }
  };

  const ModalContent = ({ order }) => {
    const [showText, setShowText] = useState(false);
    const [text, setText] = useState('');

    const textValue = useDebounce(text, 300);

    useEffect(() => {
      setActionButton({
        actionText: t('confirm'),
        actionCallback: () => handleCancelOrder(order?._id, textValue),
        buttonProps: { disabled: !textValue },
      });
    }, [order?._id, textValue]);

    return (
      <Stack gap="10px">
        <Stack direction="row" gap="5px" alignItems="center" fontSize="1rem">
          <Typography sx={{ textWrap: 'nowrap' }}>
            {t('order.updating_status', { code: `#${order.code}` })}
          </Typography>
          <Stack direction="row" gap="5px" alignItems="center">
            <Typography>{t('common.from')}</Typography>
            <StatusOrder status={order.status} />
          </Stack>
          <Stack direction="row" gap="5px" alignItems="center">
            <Typography>{t('common.to')}</Typography>
            <StatusOrder status={OrderStatus.CANCELED} />
          </Stack>
        </Stack>

        <Stack gap="14px">
          <Typography
            fontSize="0.75rem"
            fontWeight="400"
            color={themes.default.nobel}
          >
            {t('orders.cancellation_reason')}
          </Typography>
          <FormControl>
            <InputLabel size="small" id="reason-label">
              {t('common.reason')}
            </InputLabel>
            <Select
              size="small"
              labelId="reason-label"
              onChange={(e) => {
                if (e.target.value === 'other') {
                  setShowText(true);
                  setActionDisabled(true);
                } else {
                  setShowText(false);
                  setActionButton({
                    actionText: t('confirm'),
                    actionCallback: () =>
                      handleCancelOrder(order?._id, e.target.value),
                    buttonProps: { disabled: !e.target.value },
                  });
                }
              }}
              placeholder={t('common.reason')}
              label={t('common.reason')}
              defaultValue=""
            >
              {cancelationReason.map((reason) => (
                <MenuItem
                  key={reason.label}
                  value={reason.label}
                  sx={{
                    color:
                      reason.label === 'other'
                        ? themes.default.primaryActiveColor
                        : '',
                  }}
                >
                  {t(reason.label)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <If condition={!!showText}>
            <TextField
              label={t('common.specify_reason')}
              size="small"
              fullWidth
              rows="3"
              multiline={true}
              onChange={(e) => {
                setText(e.target.value);
              }}
            />
          </If>
        </Stack>
      </Stack>
    );
  };

  const openModal = (order) => {
    openConfirmation({
      title: t('order.justify_cancellation'),
      content: <ModalContent order={order} />,
      sx: {
        '& .MuiPaper-root': {
          maxWidth: '650px',
        },
      },
      action: {
        actionText: t('confirm'),
        actionCallback: () => handleCancelOrder(order?._id, ''),
        buttonProps: {
          disabled: true,
        },
      },
      cancel: true,
    });
  };

  return {
    openModal,
  };
};
