import { Dispatch, SetStateAction } from 'react';
import { RootState } from './RootState';

export interface AuthResponse {
  user: object;
  token: string;
  sessionId: string;
}

export interface LoginRequest {
  username: string;
  password: string;
}

export interface CheckCodeProps {
  userName: string;
  code: string;
  action: string;
}
export interface ChangePasswords {
  userName: string;
  code: string;
  newPassword: string;
  action: string;
}

export interface AccessToken {
  access_token: string;
}

export type { RootState };

export type StateSetter<T> = Dispatch<SetStateAction<T>>;

export enum OrderStatus {
  Validated = 'validated',
  Pending = 'pending',
  ToBePrepared = 'to-be-prepared',
  Prepared = 'prepared',
  Confirmed = 'confirmed',
  Loaded = 'loaded',
  ReadyForDelivery = 'ready-for-delivery',
  OnTheRoad = 'on-the-road',
  Postponed = 'postponed',
  Canceled = 'canceled',
  Blocked = 'blocked',
  Delivered = 'delivered',
}

export interface DocumentFile {
  name: string;
  size: string;
  url: string;
}

export interface Address {
  name: string;
  city: string;
  department: string;
  region: string;
  continent: string;
  zipCode: string;
  formattedAddress: string;
  shortAddress: string;
  countryCode: string;
  geometry: {
    type: string;
    coordinates: number[];
  };
}
