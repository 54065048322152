export function getImgCdn(key: string, size: number | null = null) {
  const baseUrl = 'https://bamptee-cdn.s3.eu-west-3.amazonaws.com';

  if (!size || !key.includes('.jpg')) {
    return `${baseUrl}/${key}`;
  }

  let sizeUrl = '512x512';

  if (size < 64) {
    sizeUrl = '64x64';
  } else if (size < 128) {
    sizeUrl = '128x128';
  } else if (size < 256) {
    sizeUrl = '256x256';
  } else if (size < 512) {
    sizeUrl = '512x512';
  } else if (size < 1024) {
    sizeUrl = '1024x1024';
  } else {
    return `${baseUrl}/${key}`;
  }

  const newKey = key
    .replace('.jpg', `_${sizeUrl}.jpg`)
    .replace('/images/', '/images_resize/');

  return `${baseUrl}/${newKey}`;
}
