import { Autocomplete, SxProps, TextField } from '@mui/material';
import { selectDecks } from 'common/store/organization/selectors';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import React from 'react';

export interface WarehouseDropdownProps {
  value?: { label: string; id: string };
  handleChange: (newValue: { label: string; id: string }) => void;
  sx?: SxProps;
}

export const WarehouseDropdown: React.FC<WarehouseDropdownProps> = ({
  value,
  handleChange,
  sx,
}) => {
  const decks = useSelector(selectDecks);
  const { t } = useTranslation();

  const options = decks.map((deck) => ({
    label: `${deck.name} ${deck.shortName ? `(${deck.shortName})` : ''}`,
    id: deck._id,
  }));

  return (
    <Autocomplete
      disablePortal
      disableClearable
      value={value}
      options={options}
      onChange={(_event, newValue) => newValue && handleChange(newValue)}
      renderInput={(params) => (
        <TextField {...params} label={t('warehouse')} size="small" />
      )}
      sx={sx}
    />
  );
};

export default WarehouseDropdown;
