import { Chip } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import React from 'react';

interface Props {
  roundTrip: any;
}
export const LoadedStatus: React.FC<Props> = ({ roundTrip }) => {
  const theme = useSelector(selectTheme);
  return roundTrip.loaded === roundTrip.loadTotal ? (
    <Chip
      sx={{
        height: '24px',
        color: theme.green,
        border: `0.063rem solid ${theme.lightSuccess50p}`,
      }}
      label="Fully (13/13)"
      variant="outlined"
    />
  ) : roundTrip.loaded > 0 ? (
    <Chip
      sx={{
        height: '24px',
        color: theme.orangePre,
        border: `0.063rem solid ${theme.orangePre}`,
      }}
      label={`Partially (${roundTrip.loaded}/${roundTrip.loadTotal})`}
      variant="outlined"
    />
  ) : (
    <Chip
      sx={{
        height: '24px',
        color: theme.textColorSecondary,
        border: `0.063rem solid ${theme.textColorSecondary}`,
      }}
      label={`Empty (${roundTrip.loaded}/${roundTrip.loadTotal})`}
      variant="outlined"
    />
  );
};
