import { Data } from 'interfaces/roundTrips';

export function createData(
  id: { value: number | string },
  key_point: { value: JSX.Element; sort?: string },
  cluster: { value: number | string },
  vehicle: {
    value: JSX.Element;
    sort?: string;
    empty?: boolean;
    running?: boolean;
    coordinates?: any;
    tracked?: boolean;
    id?: string;
    status: string;
  },
  sales: { value: JSX.Element; sort?: string; empty?: boolean },
  driver: { value: JSX.Element; sort?: string; empty?: boolean },
  ripper: { value: JSX.Element; sort?: string },
  stops_nbs: { value: JSX.Element; sort?: string },
  load_status: { value: JSX.Element; sort?: string },
  distance: { value: JSX.Element; sort?: string },
  time: { value: JSX.Element; sort?: string },
  Parking: { value: JSX.Element; sort?: string },
  support_unit: { value: JSX.Element; sort?: string },
  r_status: { value: JSX.Element; sort?: string; statusValue?: string },
  action: { value: JSX.Element; sort?: string },
  // Add_round_trip: { value: JSX.Element; sort?: string },
): Data {
  return {
    id,
    key_point,
    cluster,
    vehicle,
    sales,
    driver,
    ripper,
    stops_nbs,
    load_status,
    distance,
    time,
    Parking,
    support_unit,
    r_status,
    action,
    // action_button,
    // Add_round_trip,
  };
}

export function stateCreateData(
  id: { value: number | string },
  key_point: { value: JSX.Element; sort?: string },
): Data {
  return {
    id,
    key_point,
  };
}

export function mapCreateData(
  id: { value: number | string },
  key_point: { value: JSX.Element; sort?: string },
): Data {
  return {
    id,
    key_point,
  };
}

export function excelCreateData(
  id: { value: number | string },
  key_point: { value: JSX.Element; sort?: string },
  driver: { value: JSX.Element; sort?: string },
  vehicle: { value: JSX.Element; sort?: string },
  ripper: { value: JSX.Element; sort?: string },
  stops_nbs: { value: JSX.Element; sort?: string },
  load_status: { value: JSX.Element; sort?: string },
  container_collected: { value: JSX.Element; sort?: string },
  roundtrip_cost: { value: JSX.Element; sort?: string },
  roundtrip_fitness: { value: JSX.Element; sort?: string },
  total_penalty: { value: JSX.Element; sort?: string },
  load_penalty: { value: JSX.Element; sort?: string },
  geolocalisation_id: { value: JSX.Element; sort?: string },
  roundtrip_status: { value: JSX.Element; sort?: string },
  parking_place: { value: JSX.Element; sort?: string },
  plugged_in: { value: JSX.Element; sort?: string },
): Data {
  return {
    id,
    key_point,
    driver,
    vehicle,
    ripper,
    stops_nbs,
    load_status,
    container_collected,
    roundtrip_cost,
    roundtrip_fitness,
    total_penalty,
    load_penalty,
    geolocalisation_id,
    roundtrip_status,
    parking_place,
    plugged_in,
  };
}
