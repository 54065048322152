import { InfoWindow, AdvancedMarker } from '@vis.gl/react-google-maps';
import { useState, useMemo, useContext, useEffect } from 'react';
import { ItemOrder } from 'app/pages/AddRoundTrips/components/ItemOrder';
import dayjs from 'dayjs';
import MarkerPinBlue from 'assets/img/map/CustomerMap/MarkerPinBlue.png';
import MarkerPinBlueSelectedHovered from 'assets/img/map/CustomerMap/MarkerPinBlueSelectedHovered.svg';
import MarkerPinGreen from 'assets/img/map/CustomerMap/MarkerPinGreen.png';
import MarkerPinGreenHoveredSelected from 'assets/img/map/CustomerMap/MarkerPinGreenHoveredSelected.png';
import MarkerPinRed from 'assets/img/map/CustomerMap/MarkerPinRed.png';
import MarkerPinRedOutlined from 'assets/img/map/CustomerMap/MarkerPinRedOutlined.png';
import { RoundtripDrawerContext } from '../../../RoundtripDrawer';
import styled from 'styled-components';
import { PinNumber } from 'app/pages/RoundTrips/components/Map/components/ClientOrderMarker';

interface ClientOrderMarkerProps {
  position: any;
  order?: any;
  index: any;
  onDisplayOrder: any;
  map?: any;
}
export const StopMarker: React.FC<ClientOrderMarkerProps> = ({
  position,
  order,
  index,
  onDisplayOrder,
  map,
}) => {
  const { pinnedOrders, setPinnedOrders } = useContext(RoundtripDrawerContext);

  const togglePinned = (id: string) => {
    if (pinnedOrders.includes(id)) {
      setPinnedOrders(prev => prev?.filter(p => p !== id));
    } else {
      setPinnedOrders(prev => [...prev, id]);
    }
  };

  const isPinned = useMemo(() => {
    return pinnedOrders.includes(order?._id);
  }, [pinnedOrders]);

  const [overlayVisible, setOverlayVisible] = useState(false);

  const handleDisplayStopWindow = (display: boolean) => {
    setOverlayVisible(display);
  };

  const markerSrc = useMemo(() => {
    const delivered = order?.status === 'delivered';
    const canceled =
      order?.status === 'canceled' || order?.status === 'unloaded';

    if (delivered) {
      return overlayVisible ? MarkerPinGreenHoveredSelected : MarkerPinGreen;
    }

    if (canceled) {
      return overlayVisible ? MarkerPinRedOutlined : MarkerPinRed;
    }

    return overlayVisible ? MarkerPinBlueSelectedHovered : MarkerPinBlue;
  }, [overlayVisible]);

  useEffect(() => {
    if (!map) return;

    google.maps.event.addListener(map, 'click', () => {
      if (!isPinned) {
        setOverlayVisible(false);
      }
    });

    return () => {
      google.maps.event.clearListeners(map, 'click');
    };
  }, [map, isPinned]);

  return (
    <AdvancedMarker
      position={position}
      onClick={() => {
        if (overlayVisible && isPinned) return;
        else setOverlayVisible(prevState => !prevState);
      }}
    >
      <MarkerPinContainer>
        <img src={markerSrc} alt="" width="22px" height="26px" />
        <PinNumber>{index}</PinNumber>
      </MarkerPinContainer>
      {overlayVisible && (
        <InfoWindow
          position={position}
          onCloseClick={() => handleDisplayStopWindow(false)}
          pixelOffset={[0, -20]}
          zIndex={100}
        >
          <ItemOrder
            dateValue={dayjs()}
            handleChange2={togglePinned}
            stop={order}
            isAssigned={true}
            index={index}
            data={order}
            key={order?._id}
            onDisplayOrder={onDisplayOrder}
            disableDragAndDrop={true}
            mapVersion={true}
            pinnedLayers={pinnedOrders}
            disableEdit={true}
          />
        </InfoWindow>
      )}
    </AdvancedMarker>
  );
};

export const MarkerPinContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 2;
  margin-bottom: -2px;

  img {
    height: 26px;
    width: 22px;
  }
`;
