import { Avatar, Button, Chip, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import styled from 'styled-components';

import If from '../If';
import { ActionPopover, PopoverEl } from '../Popover';
import { getInitials } from 'common/helpers/organization';

import FileIcon from '@mui/icons-material/AttachFileOutlined';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { avatarColors, themes } from '../../../styles/theme/themes';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { AttachEmail } from '@mui/icons-material';
import { useUploadMutation } from '../../../common/services/files';
import { getFilenameFromUrl } from '../../../utils/file';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const getIsMe = (authUser, customer, sender) => {
  if (authUser?._id && sender?._id) {
    return authUser._id === sender._id;
  }

  return customer && !sender;
};

interface MessageProps {
  sender?: any;
  message?: string;
  date?: Date;
  messageId: string;
  mode: string;
  onSave?: Function;
  onDelete?: Function;
  onCancel?: Function;
  style?: any;
  files?: string[];
  isMe?: boolean;
  title?: string;
}

const Message: React.FC<MessageProps> = ({
  sender,
  message,
  date,
  mode = 'display',
  messageId,
  onSave,
  onDelete,
  style,
  isMe,
  title,
  onCancel,
  files,
}) => {
  const { t } = useTranslation();
  const [msg, setMsg] = useState<string>(message || '');
  const [uploadedFiles, setUploadedFiles] = useState<string[]>([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  // const [displayUpload, setDisplayUpload] = useState<boolean>(false);
  const [uploadFile] = useUploadMutation();
  const theme = useSelector(selectTheme);

  function handleChangeMsg(event) {
    setMsg(event.target.value);
  }

  function handleSave() {
    setIsEdit(false);

    if (onSave) {
      onSave(msg, uploadedFiles);
    }

    setMsg('');
    setUploadedFiles([]);
  }

  function handleCancel() {
    setIsEdit(false);

    if (onCancel) {
      onCancel();
    }

    setMsg('');
    setUploadedFiles([]);
  }

  let backgroundColor = isMe ? avatarColors[2] : avatarColors[3];
  let senderName = sender?.fullName || '';
  let senderPictureUrl = sender?.currentAgent?._customer
    ? null
    : sender?.currentAgent?._organization?.pictureUrl || null;

  if (senderPictureUrl) {
    // @ts-ignore
    backgroundColor = themes?.default?.accordionWhiteBg;
  }

  if (sender?.currentAgent?._customer?.publicName) {
    senderName = `${sender.currentAgent._customer.publicName} (${senderName})`;
    senderPictureUrl = null;
  }

  const hanleUploadFiles = async (files: any) => {
    const items = [];

    for (const file of files) {
      const formData = new FormData();
      formData.append('file', file);

      const { url } = await uploadFile({
        formData,
        preserveName: true,
        persist: false,
        objectType: 'conversation',
        objectId: messageId,
      }).unwrap();

      // @ts-ignore
      items.push(url);
    }

    setUploadedFiles(items);
  };

  return (
    <MainMessage
      style={style}
      className={mode !== 'display' ? 'edit' : 'display'}
    >
      <Header>
        <If condition={mode !== 'create'}>
          <Avatar
            src={senderPictureUrl}
            imgProps={{ sx: { objectFit: 'contain' } }}
            sx={{
              marginRight: '8px',
              height: '40px',
              backgroundColor,
              objectFit: 'contain',
            }}
          >
            {getInitials(sender?.fullName)}
          </Avatar>
          <Typography fontSize="1rem" fontWeight="600">
            {senderName}
          </Typography>
        </If>
        <If condition={mode === 'create'}>
          <Typography fontSize="1rem" fontWeight="600">
            {title || t('messages.new')}
          </Typography>
        </If>
      </Header>

      <WriterWrapper>
        <If condition={mode !== 'create' && !isEdit}>
          <MessageText>{msg}</MessageText>
        </If>

        <If condition={mode === 'create' || isEdit}>
          <TextField
            label={t('messages.replyPlaceholder')}
            value={msg}
            onChange={handleChangeMsg}
            multiline
            variant="standard"
            sx={{ width: '100%' }}
          />
        </If>
      </WriterWrapper>

      <If condition={mode !== 'create' && !isEdit && !isEmpty(files)}>
        <FilesWrapper>
          {files?.map((file) => (
            <Chip
              icon={<FileIcon />}
              key={file}
              label={getFilenameFromUrl(file)}
              onClick={() => window.open(file)}
            />
          ))}
        </FilesWrapper>
      </If>

      <ButtonWrapper>
        <If condition={mode === 'create' || isEdit}>
          <FilesWrapper>
            {uploadedFiles?.map((file) => (
              <Chip
                icon={<FileIcon />}
                key={file}
                label={getFilenameFromUrl(file)}
                onClick={() => window.open(file)}
              />
            ))}
          </FilesWrapper>

          <Button
            component="label"
            role={undefined}
            variant="outlined"
            tabIndex={-1}
            sx={{ marginRight: '8px', width: '240px' }}
            startIcon={<AttachEmail />}
          >
            {t('common.attachments')}
            <VisuallyHiddenInput
              type="file"
              onChange={(event) => hanleUploadFiles(event.target.files)}
              multiple
            />
          </Button>

          <Button
            variant="outlined"
            sx={{ marginRight: '8px', width: '120px' }}
            onClick={() => handleCancel()}
            disabled={msg === ''}
          >
            {t('common.buttons.cancel')}
          </Button>

          <Button
            variant="contained"
            sx={{ width: '120px' }}
            onClick={() => handleSave()}
            disabled={isEmpty(msg)}
          >
            {t(
              mode === 'create' ? 'common.buttons.send' : 'common.buttons.save',
            )}
          </Button>
        </If>
      </ButtonWrapper>

      <If condition={!isEmpty(date)}>
        <Typography
          fontWeight="600"
          fontSize="0.75rem"
          sx={{ color: 'rgba(0, 0, 0, 0.4)', marginTop: '15px' }}
        >
          {window.dayjs(date).fromNow()}
        </Typography>
      </If>

      <If condition={mode === 'edit'}>
        <ActionWrapper>
          <ActionPopover
            id={messageId}
            handleAction={(event, action) => {
              if (action === 'edit') {
                setIsEdit(true);
              } else if (action === 'delete') {
                if (onDelete) {
                  setIsEdit(false);
                  onDelete();
                }
              }
            }}
            actions={[
              {
                action: 'edit',
                element: (
                  <>
                    <EditIcon sx={{ color: 'grey' }} />
                    <PopoverEl redStatus={theme.redStatus}>Edit</PopoverEl>
                  </>
                ),
              },
              {
                action: 'delete',
                element: (
                  <>
                    <DeleteIcon sx={{ color: 'grey' }} />
                    <PopoverEl redStatus={theme.redStatus}>Delete</PopoverEl>
                  </>
                ),
              },
            ]}
          />
        </ActionWrapper>
      </If>
    </MainMessage>
  );
};

const MainMessage = styled.div`
  width: 100%;
  box-shadow: 0px 3px 15px rgba(139, 139, 139, 0.1);
  border-radius: 4px;
  padding: 20px;
  position: relative;

  &.edit {
    border: 1px solid ${themes.default.dodgerBlue};
  }

  &.display {
    background: ${themes?.default?.accordionWhiteBg};
  }
`;

const WriterWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 7px;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 7px;
`;

const FilesWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 7px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ActionWrapper = styled.div`
  margin-left: auto;
  position: absolute;
  top: 12px;
  right: 8px;
`;

const MessageText = styled.div`
  width: 100%;
  color: ${(props) => props.theme.textColorSecondary};
  font-size: 0.875rem;
`;

export default Message;
