import React from 'react';
import { Button, SxProps } from '@mui/material';
import { UploadSimple } from '@phosphor-icons/react';
import styled from 'styled-components';
import { Icon } from 'app/components/Icon';

interface ExportButtonProps {
  placeholder?: string;
  disabled?: boolean;
  onClick?: any;
  buttonSx?: SxProps;
}

export const ExportButton: React.FC<ExportButtonProps> = ({
  placeholder,
  disabled,
  onClick,
  buttonSx,
}) => {
  return (
    <ExportButtonContainer>
      <Button
        size="small"
        variant="outlined"
        disabled={disabled}
        onClick={onClick}
        sx={{
          border: '1px solid #E0E0E0',
          color: '#000000',
          fontSize: '0.625rem',
          fontWeight: '500',
          display: 'flex',
          alignItems: 'center',
          gap: '4px',
          textTransform: 'none',
          margin: '0 8px',
          ...buttonSx,
        }}
      >
        <Icon icon={<UploadSimple />} />
        {placeholder || null}
        {/* {t('common.export_all')} */}
      </Button>
    </ExportButtonContainer>
  );
};

export default ExportButton;

const ExportButtonContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  white-space: nowrap;
`;
