import { useSubscribeObject } from 'common/hooks/ws';
import api from 'common/services/api';
import { selectOrganization } from 'common/store/organization/selectors';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

export const useInvalidateUserData = () => {
  const dispatch = useDispatch();
  const org = useSelector(selectOrganization);
  const lastMessage = useSubscribeObject('update-user', org?._id || '');

  useEffect(() => {
    dispatch(api.util.invalidateTags(['Users']));
  }, [dispatch, lastMessage]);
};
