export const vehicleCondition = (data) => {
  let vehicleCondition: any = [];

  if (data?._deck?.vehicleCondition?.length > 0) {
    vehicleCondition = data._deck.vehicleCondition;
  } else if (data?.cache?._deck?.vehicleCondition?.length > 0) {
    vehicleCondition = data.cache._deck.vehicleCondition;
  } else {
    return [];
  }

  return vehicleCondition.map((condition, index, array) => {
    return index === array.length - 1
      ? condition.toUpperCase()
      : condition.toUpperCase() + ', ';
  });
};
