import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { Typography } from '@mui/material';

export const GeneralInfoFieldsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const GeneralInfoField = styled.div`
  &:nth-child(1) {
    border: 0.8px solid ${(p) => p.theme.lightFill};
    border-top-left-radius: 4px;
  }
  &:nth-child(2) {
    border-bottom: 0.8px solid ${(p) => p.theme.lightFill};
    border-top: 0.8px solid ${(p) => p.theme.lightFill};
    border-right: 0.8px solid ${(p) => p.theme.lightFill};
    border-top-left-radius: 4px;
  }
  &:nth-child(3) {
    border-bottom: 0.8px solid ${(p) => p.theme.lightFill};
    border-left: 0.8px solid ${(p) => p.theme.lightFill};
    border-right: 0.8px solid ${(p) => p.theme.lightFill};
    border-top-left-radius: 4;
  }
  &:nth-child(4) {
    border-bottom: 0.8px solid ${(p) => p.theme.lightFill};
    border-right: 0.8px solid ${(p) => p.theme.lightFill};
    border-top-left-radius: 4px;
  }
  width: 197px;
`;

export const SectionWrapper = styled.div`
  display: flex !important;
  column-gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: end;
  & .title-field {
    display: flex;
    flex-direction: column;
    & .title {
      margin: 20px 0 20px 0;
      margin-left: 0.25rem;
      font-weight: 500;
      width: 187px;
      white-space: nowrap;
    }
  }
`;

export const FooterWrapper = styled.div`
  width: 100%;
  border-top: 0.063rem solid lightgrey;
  height: 70px;
  position: sticky;
  bottom: 0;
  background: ${themes?.default?.accordionWhiteBg};
  z-index: 100;
  & .footer {
    background: ${themes?.default?.accordionWhiteBg};
    display: flex;
    justify-content: space-between;
    padding: 24px;
    align-items: center;
    box-shadow:
      0px 7px 8px -4px rgba(0, 0, 0, 0.2),
      0px 12px 17px 2px rgba(0, 0, 0, 0.14),
      0px 5px 22px 4px ${themes?.default?.lightFill};
  }
  & .action-buttons {
    display: flex;
    column-gap: 16px;
  }
`;

export const GeneralInfoFieldContent = styled.div`
  margin-left: 14px;
  height: 60px;
  & div {
    &:nth-child(1) {
      color: ${(p) => p.theme.textColorSecondary};
      font-size: 0.688rem;
      padding-top: 5px;
    }

    &:nth-child(2) {
      font-size: 0.875rem;
      padding-top: 7px;
    }
  }
`;

export const TitleField = styled.div`
  display: flex;
`;

export const InputFields = styled.div`
  display: flex;

  > *:not(:last-child) {
    margin-right: 16px;
  }
`;

export const ClientInformationTitle = styled.div`
  font-family: 'Roboto', sans-serif;
  width: 150px;
  height: 24px;
  font-weight: 500;
  margin-bottom: 3px;
  margin-right: auto;
  float: left;
`;

export const ClientInformationTitleContainer = styled.div`
  display: flex;
`;

export const Gap20 = styled.div`
  width: 20px;
  height: 20px;
`;

export const TypoMain = styled(Typography)`
  width: fit-content;
  height: 18px;
  border-radius: 4px;
  padding: 0px 4px;
  background-color: ${themes?.default?.primaryLight1};
  border: 1px solid ${themes?.default?.strokePrimary};
`;
