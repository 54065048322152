import { Autocomplete, Grid, TextField } from '@mui/material';
import { PhoneInputField } from 'app/components/Form/PhoneInputField';
import { FormSection, SectionTitle } from 'app/components/Form/styles';
import { useContext, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormTextField from 'app/components/Form/TextField';
import { useGetUsersBasicQuery } from 'common/services/userApi';
import { selectOrganization } from 'common/store/organization/selectors';
import { useSelector } from 'react-redux';
import { getAgent } from 'common/helpers/agent';
import { ViewCustomercontext } from 'app/pages/Customers/components/ViewCustomer';

export const ContactDetails = ({ control }) => {
  const { t } = useTranslation();
  const { canEdit, customer } = useContext(ViewCustomercontext);
  const { data: salesData } = useGetUsersBasicQuery(
    '?roles=sales&isArchived=false&limit=50',
  );
  const organization = useSelector(selectOrganization);

  const salesPhone = useMemo(() => {
    const agent = getAgent(customer?._salesman, organization);
    return agent?.companyPhone || '';
  }, [customer?._salesman, organization]);

  const salesProps = useMemo(() => {
    const options = salesData?.docs
      ? [...salesData?.docs, customer?._salesman]
      : [customer?._salesman];
    return {
      options:
        options?.map((user) => {
          return {
            value: user._id,
            label: `${user.firstName + ' ' + user.lastName}`,
          };
        }) || [],
      getOptionLabel: (option) => option?.label,
      value: {
        value: customer?._salesman?._id,
        label: `${
          customer?._salesman?.firstName + ' ' + customer?._salesman?.lastName
        }`,
      },
    };
  }, [customer?._salesman, salesData?.docs]);

  return (
    <FormSection>
      <SectionTitle>{t('contact_details')}</SectionTitle>
      <Grid container spacing="12px">
        <Grid item sm={6} xs={12}>
          <Controller
            name="mobilePhone"
            control={control}
            rules={{
              required: false,
            }}
            render={({ field }) => (
              <PhoneInputField
                {...field}
                specialLabel={t('mobile_phone')}
                disabled={!canEdit}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            name="mobilePhone2"
            control={control}
            rules={{
              required: false,
            }}
            render={({ field }) => (
              <PhoneInputField
                {...field}
                specialLabel={t('secondary_mobile_phone')}
                disabled={!canEdit}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing="12px">
        <Grid item sm={6} xs={12}>
          <FormTextField
            name="managerName"
            label="manager"
            control={control}
            disabled={!canEdit}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormTextField
            name="managerName2"
            label="secondary_manager"
            control={control}
            disabled={!canEdit}
          />
        </Grid>
      </Grid>

      <Grid container spacing="12px">
        <Grid item sm={6} xs={12}>
          <Controller
            name="_salesman"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Autocomplete
                {...field}
                {...salesProps}
                onChange={(_event, value) => {
                  field.onChange(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    size="small"
                    label={t('sales')}
                    variant="outlined"
                  />
                )}
                disabled={!canEdit}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            name="salePhone"
            control={control}
            rules={{
              required: false,
            }}
            render={({ field }) => (
              <PhoneInputField
                {...field}
                specialLabel={t('sales_phone')}
                disabled={true}
                value={salesPhone}
              />
            )}
          />
        </Grid>
      </Grid>
    </FormSection>
  );
};
