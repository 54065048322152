import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';

export function StopStatusUpdated({ content }) {
  const { i18n } = useTranslation();
  const locale = i18n.language;

  const title = content?.[locale]?.title;
  const body = content?.[locale]?.body;

  return (
    <MessageBody>
      <Grid container flexDirection="column">
        <Grid container alignItems="center" gap="4px">
          <CodeTitle>{title}</CodeTitle>
        </Grid>
        <Grid container alignItems="center" gap="4px">
          <SubTitle>{body}</SubTitle>
        </Grid>
      </Grid>
    </MessageBody>
  );
}

const MessageBody = styled.div`
  flex: 1;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  padding: 8px;
  gap: 4px;
  align-items: center;
`;

const CodeTitle = styled(Typography)`
  font-size: 12px !important;
  font-weight: 700 !important;
  text-decoration: underline;
`;

const SubTitle = styled(Typography)`
  font-size: 12px !important;
  font-weight: 400 !important;
  color: ${themes?.default?.nobel};
`;
