import React, { useEffect, useRef } from 'react';
import { useManagedContext } from '../../../../../../common/UtilityComponents/ManagedContext/useManagedContext';
import { IWarehousesContext } from './index';
import { useGrid } from '../../../../../components/Grid/hooks';
import {
  Permission,
  usePermission,
} from '../../../../../../hooks/Abilities/usePermission';
import { useGetWarehousesQuery } from '../../../../../../common/services/organizationApi';
import { createPortal } from 'react-dom';
import { GridToolbar } from '../../../../../components/Grid/components/GridToolbar';
import WarehousesList from './WarehousesList';
import { Columns } from './Columns';
import NoResults from '../../../../../components/NoData/NoResults';
import { Button } from '@mui/material';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import MapPopover from '../../../../../components/MapPopover';
import { WarehouseDrawer } from './WarehouseDrawer';
import { LoadingIndicator } from '../../../../../components/LoadingIndicator';
import If from '../../../../../components/If';

function FunctionalWarehouses() {
  const warehousesContext =
    useManagedContext<IWarehousesContext>('warehousesContext');
  const { setupGrid, orderBy, setOrderBy } = useGrid();

  const can = usePermission('companysettings');
  useEffect(() => {
    const canEdit = can(Permission.EDIT);
    warehousesContext.updateData('canEdit', canEdit);
  }, [can, warehousesContext]);

  const { data: warehousesResponse, isLoading } = useGetWarehousesQuery('');
  const { t } = useTranslation();

  useEffect(() => {
    setupGrid({
      columns: Columns,
      rows: warehousesResponse?.warehouses,
      totalRows: warehousesResponse?.totalDocs || 0,
      withCheckbox: false,
    });
  }, [
    setupGrid,
    warehousesResponse,
    orderBy,
    setOrderBy,
    can,
    warehousesContext.canEdit,
  ]);
  const toolBarRef = useRef<HTMLElement | null>(null);
  useEffect(() => {
    toolBarRef.current = document.getElementById('toolbar');
  }, []);
  return (
    <>
      {toolBarRef.current &&
        createPortal(
          <GridToolbar
            onAddClick={
              warehousesContext.canEdit
                ? () => {
                    warehousesContext.updateData('openDrawer', true);
                  }
                : undefined
            }
            disabled={false}
            onBulkDelete={() => {}}
            sx={{ pr: '0 !important', borderBottom: 'none' }}
          />,
          toolBarRef.current,
        )}
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <If condition={isLoading}>
          <LoadingIndicator />
        </If>
        <If condition={!isLoading && warehousesResponse?.totalDocs === 0}>
          <AddWarehouseWrapper>
            <EmptyCenter>
              <NoResults />
            </EmptyCenter>

            <Button
              variant="contained"
              sx={{ mt: 1 }}
              onClick={() => warehousesContext.updateData('openDrawer', true)}
            >
              {t('common.add')}
            </Button>
          </AddWarehouseWrapper>
        </If>
        <If condition={!isLoading && warehousesResponse?.totalDocs > 0}>
          <WarehousesList warehouses={warehousesResponse?.warehouses} />
        </If>

        <WarehouseDrawer />
        <MapPopover
          open={!!warehousesContext.mapCoordinates}
          handleClose={() => {
            warehousesContext.updateDataWithFunction((prev) => {
              prev.mapCoordinates = undefined;
              prev.selectedWarehouse = null;
            });
          }}
          latitude={warehousesContext.mapCoordinates?.[1]}
          longitude={warehousesContext.mapCoordinates?.[0]}
        />
      </div>
    </>
  );
}
const AddWarehouseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 65vh;
`;

const EmptyCenter = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;
export default FunctionalWarehouses;
