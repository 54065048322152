import _ from 'lodash';
import { RoundtripStatuses } from './data/status';

export const handleLogoClick = (
  dispatch,
  toolsActions,
  DASHBOARD,
  navigate,
) => {
  dispatch(toolsActions.setZoomIn({ status: false }));
  navigate(DASHBOARD);
};

export const handleCloseOrderDrawer = (setSelectedOrder) => {
  setSelectedOrder(null);
};

export const handleCloseStopMap = (setSelectedStop) => {
  setSelectedStop(null);
};

export const getStatusesToShow = (roundtripStatusesToShow: string[]) => {
  return _.intersectionWith(
    RoundtripStatuses,
    roundtripStatusesToShow,
    (a, b) => a.status === b,
  );
};

export function getStatusColor(status: string) {
  // @ts-ignore
  return _.find(RoundtripStatuses, { status })?.color;
}

export const updateRoundtripFuction = async (
  data_update: any,
  updateRoundtripData: any,
  checked: any,
  toast: any,
) => {
  try {
    const resp = await updateRoundtripData(data_update).unwrap();

    if (resp) {
      if (checked?.length > 1) {
        toast(5000, 'success', 'alerts.orders.mass_assign_success');
      } else {
        toast(5000, 'success', 'alerts.orders.single_assign_success');
      }
    }
  } catch (e: any) {
    if (e.status === 401) {
      console.log(e);
    }
  }
};
