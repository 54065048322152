import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';

interface CreditScoreBadgeProps {
  score: string;
  size?: string;
  style?: any;
  titleTooltip?: boolean;
}
export function CreditScoreBadge({
  score,
  size,
  style,
  titleTooltip,
}: CreditScoreBadgeProps) {
  const { t } = useTranslation();

  if (titleTooltip === true) {
    return (
      <Tooltip title={t('creditScore.A')}>
        <ScoreBadge className={`score${score} ${size}`} style={style}>
          {score}
        </ScoreBadge>
      </Tooltip>
    );
  }

  return (
    <ScoreBadge className={`score${score} ${size}`} style={style}>
      {score}
    </ScoreBadge>
  );
}

const ScoreBadge = styled.div`
  color: #fff;
  text-decoration: none;
  display: inline-flex;
  width: 30px;
  height: 30px;
  text-align: center;
  font-size: 1.143rem;
  font-weight: bold;
  justify-content: center;
  border-radius: 6px;
  align-items: center;

  &.scoreA {
    background: #64cd34;
  }

  &.scoreB {
    background: #98ed39;
  }

  &.scoreC {
    background: #ffc104;
  }

  &.scoreD {
    background: #ef4c35;
  }

  &.scoreE {
    background: #333;
  }

  &.small {
    width: 20px;
    height: 20px;
    font-size: 0.857rem;
  }
`;
