import { IconButton, Stack } from '@mui/material';
import { Printer, CaretDown } from '@phosphor-icons/react';
import { Icon } from 'app/components/Icon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { themes } from 'styles/theme/themes';
import { useTranslation } from 'react-i18next';

type IPrintMenu = {
  onPrintRoadmap: () => void;
  onPrintDeliveryNotes: () => void;
  onPrintPreparationNotes: () => void;
};

export const PrintMenu = ({
  onPrintRoadmap,
  onPrintDeliveryNotes,
  onPrintPreparationNotes,
}: IPrintMenu) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handlePrintRoadmap = (e) => {
    e.stopPropagation();
    handleClose(e);
    onPrintRoadmap();
  };

  const handlePrintDeliveryNotes = (e) => {
    e.stopPropagation();
    handleClose(e);
    onPrintDeliveryNotes();
  };

  const handlePrintPreparationNotes = (e) => {
    e.stopPropagation();
    handleClose(e);
    onPrintPreparationNotes();
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        position="relative"
        marginRight="8px"
      >
        <IconButton onClick={handleOpenMenu} sx={{ marginRight: '8px' }}>
          <Icon icon={<Printer />} size={themes.default.iconSizeMedium} />
        </IconButton>
        <Icon
          icon={<CaretDown />}
          weight="fill"
          size="12px"
          style={{ position: 'absolute', right: 0 }}
        />
      </Stack>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handlePrintRoadmap}>
          {t('common.buttons.print_roadmap')}
        </MenuItem>
        <MenuItem onClick={handlePrintDeliveryNotes}>
          {t('common.buttons.print_delivery_notes')}
        </MenuItem>
        <MenuItem onClick={handlePrintPreparationNotes}>
          {t('print.preparation-note-button')}
        </MenuItem>
      </Menu>
    </>
  );
};
