import { lazyLoad } from 'utils/loadable';
import { LoadingIndicator } from 'app/components/LoadingIndicator';

export const ClientGroupsPage = lazyLoad(
  () => import('./ClientGroups'),
  (module) => module.ClientGroups,
  {
    fallback: <LoadingIndicator />,
  },
);
