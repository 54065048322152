import React, { useContext, useRef, useCallback } from 'react';
import { ChatContext } from 'app/components/Chat';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { CustomIconButton } from '../../../CustomIconButton';
import { MagnifyingGlass, ArrowLeft } from '@phosphor-icons/react';
import { TextField, IconButton } from '@mui/material';
import { Icon } from 'app/components/Icon';
import { StateSetter } from 'types';

interface Props {
  search: string;
  setSearch: StateSetter<string>;
  addNewChatMode: boolean;
  setAddNewChatMode: StateSetter<boolean>;
}

export const TopBar: React.FC<Props> = ({
  search = '',
  setSearch,
  addNewChatMode,
  setAddNewChatMode,
}) => {
  const { t } = useTranslation();
  const searchFieldRef: any = useRef(null);
  const { setContactsOffset, totalChats } = useContext(ChatContext);

  const handleShowSearchField = useCallback(() => {
    setAddNewChatMode(true);
    setTimeout(() => {
      searchFieldRef.current?.focus();
    }, 300);
  }, [addNewChatMode, searchFieldRef]);

  const handleExitAddNewChatMode = () => {
    setContactsOffset(0);
    setAddNewChatMode(false);
  };

  return (
    <Wrapper>
      <TopBarWrapper>
        <MainTopBar inView={!addNewChatMode}>
          <Title>
            <p>{t('chat.chats')}</p>
            <div className="counter">{totalChats}</div>
          </Title>
          <CustomIconButton
            icon={<MagnifyingGlass />}
            size={24}
            onClick={handleShowSearchField}
          />
        </MainTopBar>
        <SearchTopBar inView={addNewChatMode}>
          <CustomIconButton
            icon={<ArrowLeft />}
            size={24}
            onClick={handleExitAddNewChatMode}
          />
          <div style={{ flex: 1, height: '32px', width: '100%' }}>
            <TextField
              placeholder={t('search_for_chat')}
              inputRef={searchFieldRef}
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              sx={{
                width: '100%',
                margin: '0 !important',
                height: '32px !important',
                '.MuiInputBase-root': { height: '32px !important' },
              }}
              size="small"
              InputProps={{
                startAdornment: (
                  <IconButton sx={{ marginLeft: '-14px' }} disableRipple>
                    <Icon
                      icon={<MagnifyingGlass />}
                      color={themes?.default?.iconColor}
                      size={16}
                    />
                  </IconButton>
                ),
              }}
            />
          </div>
        </SearchTopBar>
      </TopBarWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const TopBarWrapper = styled.div`
  height: 60px;
  padding: 14px 24px;
  border-bottom: 1px solid ${themes?.default?.gainsboro2};
  overflow: hidden;
  position: relative;
`;

const MainTopBar = styled.div<{ inView: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: ${(props) => (props.inView ? '14px' : '-28px')};
  left: 24px;
  right: 24px;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
`;

const SearchTopBar = styled.div<{ inView: boolean }>`
  display: flex;
  gap: 12px;
  align-items: center;
  position: absolute;
  top: ${(props) => (props.inView ? '14px' : '60px')};
  left: 24px;
  right: 24px;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  p {
    margin: 0;
    font-size: 1.14rem;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0.14px;
    color: ${themes?.default?.black};
    padding: 2px 0 0 0;
  }
  .counter {
    width: 16px;
    height: 17px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${themes?.default?.whisper};
    font-size: 0.92rem;
    font-weight: 700;
    letter-spacing: 0.16px;
    color: ${themes?.default?.black};
    padding: 2px 0 0 0;
  }
`;
