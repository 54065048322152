import { TableCell, Typography } from '@mui/material';
import { MapProps } from 'app/components/ClientMap';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';

export const Wrapper = styled.div`
  margin-top: 16px;
  width: 100%;
  height: calc(100vh - 260px);
  overflow: auto;
  padding-right: 20px;
`;

export const TitleSection = styled(Typography)`
  font-weight: 500 !important;
  padding-bottom: 8px;
  font-size: 15px;
  color: ${themes?.default?.textBlack};
`;

export const SectionWrapper = styled.div`
  margin-bottom: 24px;
`;

export const MapWrapper = styled.div<MapProps>`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.mapWidth};
  height: ${(props) => props.mapHeight};
  z-index: 2100;
  border-radius: 4px;
  margin-right: 17px;
`;

export const MainTypo = styled(Typography)`
  width: fit-content;
  height: 18px;
  color: ${themes?.default?.textBlack};
  padding: 1px 8px 2px 8px;
  background-color: rgb(243, 244, 245);
  border-radius: 4px;
  border: 1px solid ${themes?.default?.whisper};
  font-size: 10px !important;
  font-weight: 500 !important;
`;

export const SubTitle = styled(Typography)`
  font-size: 12px !important;
  font-weight: 400 !important;
  color: ${themes?.default?.nobel};
`;

export const CustomeTableCell = styled(TableCell)`
  height: 24px !important;
  padding: 2px 4px !important;
  margin: 0 !important;
  border: 1px solid ${themes?.default?.gainsboro2};
  width: 339px !important;
  cursor: pointer;
`;
