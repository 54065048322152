import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { HeadCell } from 'interfaces/roundTrips';
import { IFilter } from '.';
import { useTranslation } from 'react-i18next';

interface Props {
  handleEdit?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;

  headCells: HeadCell[];
  handleSelectedValue: (value: any) => void;
  data: IFilter;
}

export const ColumnAutoComplete: React.FC<Props> = ({
  headCells,
  handleSelectedValue,
  data,
}) => {
  const { t } = useTranslation();
  const defaultProps = {
    options:
      headCells?.filter((headCell) => headCell?.hasOwnProperty('sortField')) ||
      [],
    getOptionLabel: (option: HeadCell) => t(option.sortField || ''),
  };
  return (
    <Autocomplete
      style={{ flex: 1 }}
      {...defaultProps}
      autoComplete={false}
      onChange={(_e, value) => {
        handleSelectedValue(value);
      }}
      inputValue={t(data?.field || '')}
      renderInput={(params) => (
        <TextField
          placeholder={t('common.filter.column')}
          style={{ width: 150 }}
          {...params}
          variant="standard"
        />
      )}
    />
  );
};
