import React, { useEffect } from 'react';
import { Map, useMap } from '@vis.gl/react-google-maps';
import { APIProvider } from '@vis.gl/react-google-maps';
import { ENV } from 'common/constants';
import { StateSetter } from 'types';
import { PolygonComponent } from 'app/pages/RoundTrips/components/Map/components/PolygonComponent';
import { CSSObject } from 'styled-components';
import styled from 'styled-components';

export interface PolygonOptions {
  fillColor: string;
  fillOpacity: number;
  strokeColor: string;
  strokeOpacity: number;
  strokeWeight: number;
  clickable: boolean;
  draggable: boolean;
  editable: boolean;
  geodesic: boolean;
  zIndex: number;
}

interface GoogleMapComponentProps extends MapComponentProps {}

interface MapComponentProps {
  id?: string;
  defaultCenter?: {
    lat: any;
    lng: any;
  };
  defaultZoom?: number;
  zoom?: number;
  disableDefaultUI?: boolean;
  setMap?: StateSetter<any>;
  polygons?: {
    paths: { type?: string; lat: number; lng: number }[];
    options: PolygonOptions;
    canceledPaths?: { lat: number; lng: number }[];
    canceledPathsOptions?: PolygonOptions;
  }[];
  onMapClick?: any;
  containerStyle?: CSSObject;
  onZoomChanged?: (e: any) => void;
  children: JSX.Element;
}

export const GoogleMapComponent: React.FC<GoogleMapComponentProps> = ({
  id,
  defaultCenter,
  defaultZoom = 10,
  zoom,
  disableDefaultUI,
  setMap,
  polygons,
  onMapClick,
  containerStyle,
  onZoomChanged,
  children,
}) => {
  return (
    <APIProvider apiKey={ENV.REACT_APP_GOOGLE_MAP_API_KEY}>
      <MapComponent
        id={id}
        defaultCenter={defaultCenter}
        zoom={zoom}
        defaultZoom={defaultZoom}
        disableDefaultUI={disableDefaultUI}
        setMap={setMap}
        polygons={polygons}
        onMapClick={onMapClick}
        onZoomChanged={onZoomChanged}
        containerStyle={containerStyle}
      >
        {children}
      </MapComponent>
    </APIProvider>
  );
};

const MapComponent: React.FC<MapComponentProps> = ({
  id,
  defaultCenter,
  zoom,
  defaultZoom,
  disableDefaultUI,
  setMap,
  polygons,
  onMapClick,
  containerStyle,
  onZoomChanged,
  children,
}) => {
  const map = useMap(id || ENV.REACT_APP_GOOGLE_MAP_ID);

  useEffect(() => {
    if (!map || !setMap) return;
    setMap(map);
  }, [map]);

  useEffect(() => {
    if (defaultCenter?.lat && defaultCenter?.lng) {
      map?.panTo(defaultCenter);
    }
  }, [defaultCenter]);

  useEffect(() => {
    if (!!zoom) {
      map?.setZoom(zoom);
    }
  }, [zoom]);

  return (
    <MapContainer
      containerStyle={containerStyle || { height: '100%', width: '100%' }}
    >
      <Map
        id={id || ENV.REACT_APP_GOOGLE_MAP_ID}
        mapId={id || ENV.REACT_APP_GOOGLE_MAP_ID}
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}
        disableDefaultUI={disableDefaultUI}
        onClick={onMapClick}
        onZoomChanged={onZoomChanged}
      >
        {polygons?.map((polygon, index) => (
          <>
            <PolygonComponent
              key={index}
              paths={polygon?.paths.filter((path) => path.type !== '')}
              fillColor={polygon?.options?.fillColor}
              fillOpacity={polygon?.options?.fillOpacity}
              strokeColor={polygon?.options?.strokeColor}
              strokeOpacity={polygon?.options?.strokeOpacity}
              strokeWeight={polygon?.options?.strokeWeight}
              zIndex={polygon?.options?.zIndex}
              map={map}
              visible={true}
            />
            {polygon?.canceledPaths && (
              <PolygonComponent
                key={index}
                paths={polygon?.canceledPaths || []}
                fillColor={
                  polygon?.canceledPathsOptions?.fillColor ||
                  polygon?.options?.fillColor
                }
                fillOpacity={
                  polygon?.canceledPathsOptions?.fillOpacity ||
                  polygon?.options?.fillOpacity
                }
                strokeColor={
                  polygon?.canceledPathsOptions?.strokeColor ||
                  polygon?.options?.strokeColor
                }
                strokeOpacity={
                  polygon?.canceledPathsOptions?.strokeOpacity ||
                  polygon?.options?.fillOpacity
                }
                strokeWeight={
                  polygon?.canceledPathsOptions?.strokeWeight ||
                  polygon?.options?.fillOpacity
                }
                zIndex={
                  polygon?.canceledPathsOptions?.zIndex ||
                  polygon?.options?.fillOpacity
                }
                map={map}
                visible={true}
              />
            )}
          </>
        ))}
        {children}
      </Map>
    </MapContainer>
  );
};

const MapContainer = styled.div<{ containerStyle }>((props) => ({
  ...props.containerStyle,
  '.gm-style iframe + div': { border: 'none !important' },
}));
