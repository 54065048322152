import styled from 'styled-components';

export const Overlay = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% + 8px);
  margin-left: -4px;
  z-index: 105;
`;

export const ImgWrapper = styled.div`
  border-radius: 8px;
  background: white;
  box-shadow: ${(props) => props.theme.blurShadow};
  display: inline-flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
