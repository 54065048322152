export const weekDays = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export const Days = [
  { label: 'Mon', value: 'monday', color: '#2A59FF' },
  { label: 'Tue', value: 'tuesday', color: '#2A59FF' },
  { label: 'Wed', value: 'wednesday', color: '#2A59FF' },
  { label: 'Thu', value: 'thursday', color: '#2A59FF' },
  { label: 'Fri', value: 'friday', color: '#2A59FF' },
  { label: 'Sat', value: 'saturday', color: '#2A59FF' },
  { label: 'Sun', value: 'sunday', color: '#2A59FF' },
];

export const daysOfWeek = [
  { day: 'Mon', isHighlighted: true },
  { day: 'Tue', isHighlighted: false },
  { day: 'Wed', isHighlighted: false },
  { day: 'Thu', isHighlighted: false },
  { day: 'Fri', isHighlighted: false },
  { day: 'Sat', isHighlighted: true },
  { day: 'Sun', isHighlighted: false },
];

export const daysOfWeekPhone = [
  { day: 'Mon', isHighlighted: true },
  { day: 'Tue', isHighlighted: false },
  { day: 'Wed', isHighlighted: false },
  { day: 'Thu', isHighlighted: false },
  { day: 'Fri', isHighlighted: false },
  { day: 'Sat', isHighlighted: true },
  { day: 'Sun', isHighlighted: false },
];
