import React from 'react';
import Popover from '@material-ui/core/Popover';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Typography, ButtonBase } from '@mui/material';
import { PopOverElement } from 'app/components/Popover';
import { PopOverElWrapper } from 'app/pages/AddRoundTrips/data/RoundTripData/components/Popover';
import { selectTheme } from 'styles/theme/slice/selectors';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { StateSetter } from 'types';
import styled from 'styled-components';
import { Icon } from 'app/components/Icon';
import { X } from '@phosphor-icons/react';
import { themes } from 'styles/theme/themes';

interface Props {
  open: boolean;
  position?: {
    x: number | 'center' | 'right' | 'left';
    y: number | 'bottom' | 'top' | 'center';
  };
  anchorEl?: any;
  setOpenAddRoundtrip: StateSetter<any>;
  setAddModalOpen?: Function;
  handleMenuClose?: Function;
  handleClearSelection?: Function;
  roundtripsCount: number;
  selectedItemsCount?: number;
  leftNestedMenuPosition?: boolean;
  setOpenRoundtripAnchorEl?: StateSetter<any>;
}

export const AssignedRoundtripsPopover = ({
  open = false,
  position = { x: 0, y: 0 },
  anchorEl,
  setOpenAddRoundtrip = () => {},
  setAddModalOpen = () => {},
  handleMenuClose = () => {},
  handleClearSelection = () => {},
  roundtripsCount = 0,
  selectedItemsCount = 0,
  leftNestedMenuPosition = false,
  setOpenRoundtripAnchorEl = () => {},
}: Props) => {
  const theme = useSelector(selectTheme);
  const { t } = useTranslation();
  return (
    <Popover
      open={open}
      anchorOrigin={{
        vertical: position.y || 'center',
        horizontal: position.x || 'center',
      }}
      anchorEl={anchorEl || undefined}
      onClose={() => handleMenuClose()}
      onContextMenuCapture={(e) => {
        e?.preventDefault();
        e?.stopPropagation();
      }}
    >
      <ContentWrapper>
        <div className="title">
          <Typography
            fontWeight={600}
            lineHeight="17px"
            fontSize="0.85rem"
            letterSpacing="0.17px"
          >
            {t('assign_stops_to_roundtrip', { count: selectedItemsCount })}
          </Typography>
          <ButtonBase
            onClick={() => {
              handleClearSelection();
              handleMenuClose();
            }}
            sx={{ borderRadius: '50%', padding: '4px' }}
          >
            <Icon
              icon={<X weight="bold" />}
              size={16}
              color={theme?.iconColorDark}
            />
          </ButtonBase>
        </div>
        <div className="options">
          {roundtripsCount > 0 ? (
            <PopOverElement
              onClick={(e) => {
                setOpenAddRoundtrip(true);
                setOpenRoundtripAnchorEl(e?.target);
              }}
              primaryActiveColor={theme.primaryActiveColor}
              style={{
                padding: '4px 12px',
                height: '27px',
              }}
            >
              <PopOverElWrapper
                style={{
                  gap: '8px',
                  alignItems: 'center',
                  justifyContent: leftNestedMenuPosition ? '' : 'space-between',
                }}
              >
                {leftNestedMenuPosition && (
                  <KeyboardArrowLeftRoundedIcon
                    sx={{
                      color: themes?.default?.iconColor,
                      width: '16px',
                      height: '16px',
                    }}
                  />
                )}
                {t('existing_roundtrip')}
                {!leftNestedMenuPosition && (
                  <KeyboardArrowRightRoundedIcon
                    sx={{
                      color: themes?.default?.iconColor,
                    }}
                  />
                )}
              </PopOverElWrapper>
            </PopOverElement>
          ) : null}
          <PopOverElement
            onClick={(e) => {
              handleMenuClose();
              setAddModalOpen(e, true);
            }}
            primaryActiveColor={theme.primaryActiveColor}
            style={{
              padding: '4px 12px',
              height: '27px',
            }}
          >
            <PopOverElWrapper
              style={{
                gap: '8px',
                alignItems: 'center',
              }}
            >
              {/* <Icon icon={<Plus weight="bold" />} size={16} /> */}
              <AddRoundedIcon
                sx={{
                  height: '16px',
                  width: '16px',
                  color: themes?.default?.iconColor,
                }}
              />
              <div>{t('new_roundtrip')}</div>
            </PopOverElWrapper>
          </PopOverElement>
        </div>
      </ContentWrapper>
    </Popover>
  );
};
export default AssignedRoundtripsPopover;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 0;
  gap: 8px;
  min-width: 206px;

  .title {
    display: flex;
    height: 25px;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px 8px 12px;
    border-bottom: 1px solid ${themes?.default?.gainsboro2};
  }

  .options {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`;
